import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"

import { Link, withRouter } from "react-router-dom"


import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// Redux Store
import {
  buscarEmpresaPorUsuario,
  changeSidebarType,
  logoutUser,
  setUserPreference,
  showRightSidebarAction,
  toggleLeftmenu
} from "../../store/actions"
import { getDashboardData } from "../../store/dashboard/actions"
import { setDashboardFilters } from "../../store/auth/login/actions"
import moment from "moment"
import { buscarMovimentacaoCaixas } from "../../store/auth/profile/actions"
import DatePicker from "react-datepicker"

import logo from "../../assets/images/logo.svg"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"
import {
    checkIsMobile,
    getCompanies,
    getCompaniesValuesByOptions,
    getCompanyOption, getPeriodsStartEndDateByType,
    isCollectionEmpty
} from "../../helpers/utils"

import { MultiSelect } from "react-multi-select-component"

import toastr from "toastr"

const PeriodType = {
  month: "Mês",
  today: "Dia atual",
  yesterday: "Dia anterior",
  period: "Período",
  lastDays: "Últ. 7 dias",
  lastWeek: "Últ. semana"
}

const Header = props => {

  const [search, setsearch] = useState(false)
  const [periodStr, setPeriodStr] = useState(moment().format("YYYY-MM-DD"))
  const [periodStart, setPeriodStart] = useState(moment().startOf("month"))
  const [periodStartDate, setPeriodStartDate] = useState(
    moment().startOf("month").toDate()
  )
  const [periodEnd, setPeriodEnd] = useState(moment().endOf("month"))
  const [periodEndDate, setPeriodEndDate] = useState(
    moment().endOf("month").toDate()
  )
  const [periodType, setPeriodType] = useState("month")
  const [periodsType, setPeriodsType] = useState([])
  const [periods, setPeriods] = useState([])
  const [companies, setCompanies] = useState([])
  const [companiesOption, setCompaniesOption] = useState([])
  const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador


  useEffect(() => {
    if (isAdmin) {
      props.buscarEmpresaPorUsuario()
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOption(companiesOptions)
    }
  }, [props.user])

  useEffect(() => {
    if (isAdmin && Array.isArray(props.empresas)) {
      let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa))
      setCompaniesOption(companiesOptions)
    }

    return () => {
      setCompaniesOption([])
    }
  }, [props.empresas])

  useEffect(() => {
    popularEmpresa(companiesOption)
  }, [companiesOption])

  useEffect(() => {
    const tmpPeriods = []
    for (let i = 0; i <= 11; i++) {
      tmpPeriods.push({
        label: moment().subtract(i, "month").format("MMMM/YY"),
        value: moment().subtract(i, "month").format("YYYY-MM-DD")
      })
    }
    setPeriods(tmpPeriods)

    const tmpPeriodsType = []
    for (const tmpPeriodsTypeElement of Object.keys(PeriodType)) {
      tmpPeriodsType.push({
        label: PeriodType[tmpPeriodsTypeElement],
        value: tmpPeriodsTypeElement
      })
    }
    setPeriodsType(tmpPeriodsType)
  }, [])

  const popularEmpresa = (companiesOption) => {
    if (!isCollectionEmpty(companiesOption)) {
      let companiesSelected = props.dashboardFilters.companiesSelected
      if (isCollectionEmpty(companiesSelected)) {
        // somente selecionar uma empresa caso não seja usuário inforangra administrador
        if (!(isAdmin)) {
          companiesSelected = props.preferences && Array.isArray(props.preferences.Companies_Selected) && props.preferences.Companies_Selected.length > 0 ? props.preferences.Companies_Selected : [companiesOption[0]]
          companiesSelected = companiesSelected.map(company => {
            const option = companiesOption.find(option => option.value === company.value);
            return option ? {
              ...company,
              dashboardInicial: option.dashboardInicial
            } : null;
          }).filter(Boolean);
          doSetCompany(companiesSelected)
        }
      } else {
        doSetCompany(companiesSelected)
      }
    }
  }
  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
  const isMobile = checkIsMobile()

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  const doSetPeriodType = value => {
    setPeriodType(value)

    let { periodStart, periodEnd } = getPeriodsStartEndDateByType(value)

    setPeriodStart(periodStart)
    setPeriodEnd(periodEnd)
    setPeriodStartDate(periodStart.toDate())
    setPeriodEndDate(periodEnd.toDate())

    getData(periodStart, periodEnd)
}

  const buscarDadosDashboard = (dateStartStr, dateEndStr, companiesId) => {
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "vendasHora")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "vendasDias")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "tiposPagamentos")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "gruposMaisVendas")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "vendasMes")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "maisVendidos")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "vendas")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "notas")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "notasPeriodo")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "notasHoje")
  }

  const getData = (periodStart, periodEnd) => {
    if (periodStart && periodEnd) {
      const dateStartStr = periodStart.format("YYYY-MM-DD 00:00:00")
      const dateEndStr = periodEnd.format("YYYY-MM-DD 23:59:59")
      const companiesId = getCompaniesValuesByOptions(companies)
      props.setDashboardFilters({
        periodStart: dateStartStr,
        periodEnd: dateEndStr,
        companiesId: companiesId,
        companiesSelected: companies
      })

      if (!possuiVinculo) return

      if (props.location.pathname === "/dashboard-padrao") {
        buscarDadosDashboard(dateStartStr, dateEndStr, companiesId)
      } else if (props.location.pathname === "/notas-emitidas") {
        buscarDadosNotasEmitidas(dateStartStr, dateEndStr, companiesId)
      }
    }
  }

  const doSetPeriodStr = value => {
    if (value) {
      const periodStartMoment = moment(value, "YYYY-MM-DD").startOf("month")
      const periodEndMoment = moment(value, "YYYY-MM-DD").endOf("month")
      setPeriodStr(value)
      setPeriodStart(periodStartMoment)
      setPeriodEnd(periodEndMoment)
      setPeriodStartDate(periodStartMoment.toDate())
      setPeriodEndDate(periodEndMoment.toDate())

      getData(periodStartMoment, periodEndMoment)
    }
  }

  const doSetPeriodStartDate = value => {
    if (value) {
      const periodStartMoment = moment(value)
      setPeriodStart(periodStartMoment)
      setPeriodStartDate(value)

      if (periodEnd) {
        getData(periodStartMoment, periodEnd)
      }
    }
  }

  const doSetPeriodEndDate = value => {
    if (value) {
      const periodEndMoment = moment(value)
      setPeriodEnd(periodEndMoment)
      setPeriodEndDate(value)

      if (periodStart) {
        getData(periodStart, periodEndMoment)
      }
    }
  }

  const buscarDadosNotasEmitidas = (dateStartStr, dateEndStr, companiesId) => {
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "notasSemanas")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "notasMeses")
    props.getDashboardData(dateStartStr, dateEndStr, companiesId, "notasAnos")
  }

  const doSetCompany = companiesSelected => {

    setCompanies(companiesSelected)

    const dateStartStr = periodStart.format("YYYY-MM-DD")
    const dateEndStr = periodEnd.format("YYYY-MM-DD")

    let companiesId = getCompaniesValuesByOptions(companiesSelected)
    props.setDashboardFilters({
      companiesId: companiesId,
      companiesSelected: companiesSelected,
      periodStart: dateStartStr,
      periodEnd: dateEndStr
    })
    props.setUserPreference("Companies_Selected", companiesSelected)

    if (!possuiVinculo) return

    if (props.location.pathname === "/dashboard-padrao") {
      buscarDadosDashboard(dateStartStr, dateEndStr, companiesId)
    } else if (props.location.pathname === "/notas-emitidas") {
      buscarDadosNotasEmitidas(dateStartStr, dateEndStr, companiesId)
    }
  }

  const ArrowRenderer = ({ expanded }) => <span className="arrow"></span>
  const ClearRenderer = () => <span className="clear"></span>

  let periodFilter
  if (periodType === "month") {
    periodFilter = (
      <div style={{ width: "7.7rem" }} className="ps-2">
        <select
          className="form-select form-select-sm"
          value={periodStr}
          onChange={e => doSetPeriodStr(e.target.value)}
        >
          {periods.map((periodItem, key) => (
            <option key={periodItem.value} value={periodItem.value}>
              {periodItem.label}
            </option>
          ))}
        </select>
      </div>
    )
  } else if (periodType === "period") {
    periodFilter = (
      <div className="d-flex flex-row ps-2" style={{ maxWidth: "10rem" }}>
        <div>
          {isMobile && <DatePicker
            selected={periodStartDate}
            onChange={date => doSetPeriodStartDate(date)}
            className="form-control form-control-sm"
            placeholderText="Selecione uma data"
            locale="ptBR"
            dateFormat="dd/MM"
            withPortal
            portalId="root-portal"
          />}
          {!isMobile && <DatePicker
            selected={periodStartDate}
            onChange={date => doSetPeriodStartDate(date)}
            className="form-control form-control-sm"
            placeholderText="Selecione uma data"
            locale="ptBR"
            dateFormat="dd/MM"
          />}
        </div>
        <div className="ps-1">
          {isMobile && <DatePicker
            selected={periodEndDate}
            onChange={date => doSetPeriodEndDate(date)}
            className="form-control form-control-sm"
            placeholderText="Selecione uma data"
            locale="ptBR"
            minDate={periodStartDate}
            dateFormat="dd/MM"
            withPortal
            portalId="root-portal"
          />}
          {!isMobile && <DatePicker
            selected={periodEndDate}
            onChange={date => doSetPeriodEndDate(date)}
            className="form-control form-control-sm"
            placeholderText="Selecione uma data"
            locale="ptBR"
            minDate={periodStartDate}
            dateFormat="dd/MM"
          />}
        </div>
      </div>
    )
  } else if (periodType === "lastDays" || periodType === "lastWeek") {
    periodFilter = (
      <div
        style={{ "maxWidth": "9rem" }}
        className="align-self-center px-2 form-control-sm"
      >
        {periodStart.format("DD/MM")} - {periodEnd.format("DD/MM")}
      </div>
    )
  }

  const changeSelectedCompanies = (companiesSelected) => {
    const newlySelected = companiesSelected.filter(
      item => !companies.some(selected => selected.value === item.value)
    );

    if (newlySelected.length > 0 && companies.length > 0 && companies[0].dashboardInicial !== newlySelected[0].dashboardInicial) {
      toastr.error('Selecione empresas do mesmo tipo!', 'Validação de empresas')
    } else {
      doSetCompany(companiesSelected)
    }
  }

  const CustomItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
        style={{marginRight: '2px'}}
      />
      <span>{option.label}</span>
    </div>
  )

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="px-0 navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                  <img src={logo} alt="" height="22" />
                                </span>
                <span className="logo-lg">
                                  <img src={logoDark} alt="" height="17" />
                                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                  <img src={logoLightSvg} alt="" height="22" />
                                </span>
                <span className="logo-lg">
                                  <img src={logoLightPng} alt="" height="19" />
                                </span>
              </Link>
            </div>

            <button type="button" onClick={() => {
              tToggle()
            }}
                    className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                    id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>


            {(props.location.pathname === "/dashboard-padrao" ||
              props.location.pathname === "/notas-emitidas") && (

              <form className="app-period-selector d-lg-flex d-none">

                <div className="flex-fill">
                  <div className="input-group input-group-sm">
                    <MultiSelect
                      className="header"
                      options={companiesOption}
                      ItemRenderer={CustomItemRenderer}
                      value={companies}
                      onChange={changeSelectedCompanies}
                      labelledBy="Select"
                      disableSearch={false}
                      overrideStrings={{
                        "allItemsAreSelected": "Todos selecionados.",
                        "clearSearch": "Limpar",
                        "clearSelected": "Deselecionar todos",
                        "noOptions": "Não há opções",
                        "search": "Pesquisar",
                        "selectAll": "Selecionar todos",
                        "selectAllFiltered": "Selecionar todos",
                        "selectSomeItems": "Selecione",
                        "create": "Criar"
                      }
                      }
                      hasSelectAll={!(isAdmin)}
                      ClearIcon={<ClearRenderer />}
                      ClearSelectedIcon={<ClearRenderer />}
                      ArrowRenderer={ArrowRenderer}
                    >
                    </MultiSelect>
                    <label className="input-group-text">Empresa</label>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="d-flex flex-row">
                    <select
                      style={{ width: "6.6rem" }}
                      className="form-select form-select-sm"
                      value={periodType}
                      onChange={e => doSetPeriodType(e.target.value)}
                    >
                      {periodsType.map((periodTypeItem, key) => (
                        <option
                          key={periodTypeItem.value}
                          value={periodTypeItem.value}
                        >
                          {periodTypeItem.label}
                        </option>
                      ))}
                    </select>
                    {periodFilter}
                  </div>
                </div>
              </form>
            )}
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <NotificationDropdown {...{ history: props.history }} />
            <ProfileMenu {...{ history: props.history }} />

            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>

              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {(props.location.pathname === "/dashboard-padrao" ||
          props.location.pathname === "/notas-emitidas") && (
          <div className="navbar-header d-inline-block d-lg-none p-1 p-sm-4">
            <div>
            </div>
            <form className="app-period-selector">
              <div className="flex-fill">
                <div className="input-group input-group-sm">
                  <MultiSelect
                    className="header"
                    options={companiesOption}
                    ItemRenderer={CustomItemRenderer}
                    value={companies}
                    onChange={changeSelectedCompanies}
                    labelledBy="Select"
                    disableSearch={false}
                    overrideStrings={{
                      "allItemsAreSelected": "Todos selecionados.",
                      "clearSearch": "Limpar",
                      "clearSelected": "Deselecionar todos",
                      "noOptions": "Não há opções",
                      "search": "Pesquisar",
                      "selectAll": "Selecionar todos",
                      "selectAllFiltered": "Selecionar todos",
                      "selectSomeItems": "Selecione",
                      "create": "Criar"
                    }
                    }
                    hasSelectAll={!(isAdmin)}
                    ClearIcon={<ClearRenderer />}
                    ClearSelectedIcon={<ClearRenderer />}
                    ArrowRenderer={ArrowRenderer}
                  >
                  </MultiSelect>
                  <label className="input-group-text">Empresa</label>
                </div>
              </div>
              <div className="flex-fill">
                <div className="d-flex flex-row">
                  <select
                    style={{ width: "6.6rem" }}
                    className="form-select form-select-sm"
                    value={periodType}
                    onChange={e => doSetPeriodType(e.target.value)}
                  >
                    {periodsType.map((periodTypeItem, key) => (
                      <option
                        key={periodTypeItem.value}
                        value={periodTypeItem.value}
                      >
                        {periodTypeItem.label}
                      </option>
                    ))}
                  </select>
                  {periodFilter}
                </div>
              </div>
            </form>
          </div>)}
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  getDashboardData: PropTypes.func,
  user: PropTypes.any,
  logoutUser: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout
  const { user, dashboardFilters, preferences } = state.Login
  const { empresas, empresasLoading } = state.Profile

  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    user,
    dashboardFilters,
    preferences,
    empresas,
    empresasLoading
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
    getDashboardData,
    setDashboardFilters,
    buscarMovimentacaoCaixas,
    logoutUser,
    setUserPreference,
    buscarEmpresaPorUsuario
  })((Header))
)
