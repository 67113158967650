import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Label } from "reactstrap"
import { isArray } from "leaflet/src/core/Util"
import DatePicker from "react-datepicker"

const FormFilterPeriod = props => {
  const {
    periodAttribute, periodDescription, periodValue, periodChangeHandeler, options, placeHolder,
    startDateAttribute, startDateDescription, startDateValue, startDateChangeHandeler,
    endDateAttribute, endDateDescription, endDateValue, endDateChangeHandeler, t, months, monthChange
  } = props

  return (
    <React.Fragment>
      <div className="col-xl col-sm-6">
        <FormGroup className="mt-3 mb-0">
          <Label>{periodDescription || t("label.placeholder.date.period")}</Label>
          <select id={periodAttribute || "period"}
                  className="form-select select2-search-disable"
                  value={periodValue}
                  onChange={e => periodChangeHandeler(e.target.value)}
          >
            {isArray(options) &&
              options.map((periodItem, key) => (
                <option key={periodItem.value} value={periodItem.value}>
                  {periodItem.label}
                </option>
              ))}
          </select>
        </FormGroup>
      </div>

      {periodValue && periodValue === "month" && (
        <div className="col-xl col-sm-6">
          <div>
            <FormGroup className="mt-3 mb-0">
              <Label>{props.t("label.month")}</Label>
              <select id={periodAttribute || "period"}
                      className="form-select select2-search-disable"
                      onChange={e => monthChange(e.target.value)}>
                {isArray(months) &&
                  months.map((periodItem, key) => (
                    <option key={periodItem.value} value={periodItem.value}>
                      {periodItem.label}
                    </option>
                  ))}
              </select>
            </FormGroup>
          </div>
        </div>
      )}

      {periodValue && (periodValue === "period" || periodValue === "lastDays" || periodValue === "lastWeek") && (
        <div className="col-xl col-sm-6">
          <div>
            <FormGroup className="mt-3 mb-0">
              <Label>{startDateDescription || props.t("label.start.date")}</Label>
              <DatePicker
                id={startDateAttribute || "startDate"}
                selected={startDateValue}
                onChange={startDateChangeHandeler}
                className="form-control"
                placeholderText={placeHolder || props.t("label.placeholder.date")}
                locale="ptBR"
                dateFormat="dd/MM/yyyy"
                disabled={periodValue !== "period"}
              />
            </FormGroup>

            <FormGroup className="mt-3 mb-0">
              <Label>{endDateDescription || props.t("label.end.date")}</Label>
              <DatePicker
                id={endDateAttribute || "endDate"}
                selected={endDateValue}
                onChange={date => endDateChangeHandeler(date)}
                className="form-control"
                placeholderText={placeHolder || props.t("label.placeholder.date")}
                locale="ptBR"
                minDate={startDateValue}
                dateFormat="dd/MM/yyyy"
                disabled={periodValue !== "period"}
              />
            </FormGroup>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

FormFilterPeriod.propTypes = {
  periodAttribute: PropTypes.string,
  periodDescription: PropTypes.string,
  periodValue: PropTypes.any,
  periodChangeHandeler: PropTypes.func,
  options: PropTypes.any,
  placeHolder: PropTypes.string,
  startDateAttribute: PropTypes.string,
  startDateDescription: PropTypes.string,
  startDateValue: PropTypes.object,
  startDateChangeHandeler: PropTypes.func,
  endDateAttribute: PropTypes.string,
  endDateDescription: PropTypes.string,
  endDateValue: PropTypes.object,
  endDateChangeHandeler: PropTypes.func,
  t: PropTypes.func,
  months: PropTypes.any,
  monthChange: PropTypes.func
}

export default FormFilterPeriod
