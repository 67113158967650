import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { doEditCategory, doEditQuestion } from "../../store/auth/profile/actions"
import LinkRestricted from "../LinkRestricted"
import { withTranslation } from "react-i18next"

const SidebarMenu = props => {

  const isInforangra = props.user?.usuario_info
  const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      return false;
    }
    return false;
  }


  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t('menu.label')}</li>
          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-home"/>
              <span>{props.t('menu.label.dashboard')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="dashboard_listar" to="/dashboard-padrao">{props.t('breadcrumb.dashboard')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <LinkRestricted permissionKey="usuarios_listar" to="/usuarios">
              <i className="bx bx-user"/>
              <span>{props.t('menu.label.users')}</span>
            </LinkRestricted>
          </li>

          <li>
            <LinkRestricted permissionKey="empresas_listar" to="/empresas">
              <i className="bx bx-home"/>
              <span>{isInforangra ? props.t('menu.label.companies') : props.t('menu.label.my.companies')}</span>
            </LinkRestricted>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-certification"/>
              <span>{props.t('menu.label.digital.certificates')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="empresas_editar" to="/enviar-certificado">{props.t('menu.label.digital.send.certificates')}</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="empresas_listar" to="/certificados-digitais">{props.t('menu.label.digital.certificates.list')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-copy-alt"/>
              <span>{props.t('menu.label.fiscal.management')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="notasEmitidas_listar" to="/notas-emitidas">{props.t('dashboard.lista.notasemitidas.label')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx bx-analyse"/>
              <span>{props.t('menu.label.financial.management')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="movimentoCaixa_listar" to="/movimento-caixas">{props.t('menu.label.cash.transactions')}</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="contasPagar_listar" to="/contas-pagar">{props.t('menu.label.bills')}</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="movimentoVendas_listar" to="/movimento-vendas">{props.t('menu.label.fastfood.sales')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx bx-customize"/>
              <span>{props.t('menu.label.smd.module')}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-detail"/>
                  <span>{props.t('menu.label.sign')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <LinkRestricted permissionKey="fornecedores_listar" to="/fornecedores">{props.t('menu.label.supplier')}</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="gruposProduto_listar" to="/grupos-produto">{props.t('menu.label.product.groups')}</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="produtos_listar" to="/produtos">{props.t('menu.label.product.stock')}</LinkRestricted>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bxs-pizza"/>
              <span>{props.t('menu.label.fastfood.module')}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-detail"/>
                  <span>{props.t('menu.label.sign')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <LinkRestricted permissionKey="fornecedores_listar" to="/fornecedores">{props.t('menu.label.supplier')}</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="gruposCardapio_listar" to="/grupos-cardapio">{props.t('menu.label.group.categories')}</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="gruposEstoque_listar" to="/grupos-estoque">{props.t('menu.label.group.stock.categories')}</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="estoques_listar" to="/estoques">{props.t('menu.label.input.stock')}</LinkRestricted>
                  </li>
                </ul>
              </li>
              <li>
                <LinkRestricted permissionKey="movimentoVendas_listar" to="/movimento-vendas">{props.t('menu.label.sales.transactions')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-report"/>
              <span>{props.t('menu.label.reports')}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-money"/>
                  <span>{props.t('menu.label.finance')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <LinkRestricted permissionKey="dreGerencial_listar" to="/dre">{props.t('menu.label.dre')}</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="movimentoVendas_listar" to="/movimento-vendas">{props.t('menu.label.fastfood.sales')}</LinkRestricted>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-command"/>
              <span>{props.t('menu.label.links')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="solicitarVinculos_inserir"
                                to="/solicitar-vinculo">{props.t('menu.label.links.request')}</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="vinculos_listar" to="/vinculos">{props.t('menu.label.links.list')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-book-content"/>
              <span>{props.t('menu.label.menu.order')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="dispositivos_listar"
                                to="/cardapio-dispositivos">{props.t('menu.label.menu.order.devices')}</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className={"has-arrow waves-effect"} aria-expanded="false">
              <i className="bx bx-help-circle"/>
              <span>{props.t('menu.label.faq')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="perguntas_listar" to="/perguntas-frequentes">{props.t('menu.label.faq.list')}</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="perguntasEdit_editar" to="/pergunta"
                                onClick={e => props.doEditQuestion(null)}>
                  {props.t('menu.label.faq.question')}
                </LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="categoriasEdit_editar" to="/categoria"
                                onClick={e => props.doEditCategory(null)}>
                  {props.t('menu.label.faq.category')}
                </LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <LinkRestricted permissionKey="sugestoes_listar" to="/sugestoes" className="waves-effect">
              <i className="bx bx-star"/>
              <span>{props.t('menu.label.suggestion')}</span>
            </LinkRestricted>
          </li>

          { isInforangra && <li>
            <LinkRestricted permissionKey="notificacoes_listar" to="/notificacoes" className="waves-effect">
              <i className="bx bx-bell"/>
              <span>{props.t('menu.label.notification')}</span>
            </LinkRestricted>
          </li> }

          <li>
            <LinkRestricted permissionKey="templatesEmail_listar" to="/templates-email" className="waves-effect">
              <i className="bx bx-mail-send"/>
              <span>{props.t('menu.label.email.template')}</span>
            </LinkRestricted>
          </li>

          <li>
            <LinkRestricted permissionKey="painelNotificacoes_listar" to="/painel-notificacao" className="waves-effect">
              <i className="bx bxs-bell-ring"/>
              <span>{props.t('menu.label.notification.panel')}</span>
            </LinkRestricted>
          </li>

          <li>
            <Link to="/#" className={"has-arrow waves-effect"} aria-expanded="false">
              <i className="bx bx-table"/>
              <span>{props.t('menu.label.maintenance')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="perfil_listar" to="/perfis">
                  {props.t('menu.label.user.profiles')}
                </LinkRestricted>
              </li>
              { isInforangra && <li>
                <LinkRestricted permissionKey="sinc_listar" to="/sincronismo-dados">
                  {props.t('menu.label.synchronization')}
                </LinkRestricted>
              </li> }
              <li>
                <Link to="/#" className={"has-arrow waves-effect"} aria-expanded="false">
                  <i className="bx bx-table"/>
                  <span>{props.t('menu.label.table')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <LinkRestricted permissionKey="tabelaNCM_listar" to="/tabela-ncm">
                      {props.t('menu.label.table.ncm')}
                    </LinkRestricted>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Login
  return { user }
}

SidebarMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  user: PropTypes.any
}

export default withTranslation()(
    connect(mapStatetoProps, {
      doEditCategory,
      doEditQuestion
    })(withRouter(SidebarMenu)))
