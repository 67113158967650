import React, { Component } from "react"
import { Button, Card, CardBody, Col } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../store/auth/register/actions"
import { getDashboardData } from "../../store/dashboard/actions"
import { apiError } from "../../store/auth/login/actions"
import Loader from "react-loader-spinner"
import { setUserPreference } from "../../store/auth/profile/actions"
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";

class VendasDia extends Component {
  constructor(props) {
    super(props)

    this.state = {
      graphType:
        props.preferences && props.preferences.Grafico_Vendas_Dias
          ? props.preferences.Grafico_Vendas_Dias
          : "value"
    }
  }

  static abbreviateNumber(num, fixed) {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0) {
      return "0"
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k] // append power
    return e
  }

  static getDerivedStateFromProps(props, current_state) {
    // console.debug("[vendas-dias][getDerivedStateFromProps]", { props, current_state })

    const weekdayName = dia_numerico => {
      return [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo"
      ][dia_numerico]
    }

    if (props.dashboardVendasDias) {
      return {
        ...props,
        ...{
          options: {
            grid: {
              zlevel: 0,
              x: 50,
              x2: 50,
              y: 30,
              y2: 30,
              borderWidth: 0,
              backgroundColor: "rgba(0,0,0,0)",
              borderColor: "rgba(0,0,0,0)"
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999"
                }
              }
            },
            toolbox: {
              feature: {
                dataView: { show: false, readOnly: false },
                magicType: { show: false, type: ["line", "bar"] },
                restore: { show: false },
                saveAsImage: { show: false }
              }
            },
            color: ["#3c4ccf", "#02a499"],
            legend: {
              data: [ props.t('dashboard.lista.vendasdia.vendas.label'), props.t('dashboard.lista.vendasdia.entregas.label')],
              textStyle: {
                color: ["#74788d"]
              }
            },
            xAxis: [
              {
                type: "category",
                data:
                  props.dashboardVendasDias.vendas &&
                  props.dashboardVendasDias.vendas[0]
                    ? props.dashboardVendasDias.vendas[0].map(e => e.dia_nome)
                    : [],
                axisPointer: {
                  type: "shadow"
                },
                axisLine: {
                  lineStyle: {
                    color: "#74788d"
                  }
                }
              }
            ],
            yAxis: [
              {
                type: "value",
                name: props.t('dashboard.lista.vendasdia.valor.label'),
                // min: 0,
                // max: 250,
                // interval: 50,
                axisLabel: {
                  formatter: "{value}"
                },
                axisLine: {
                  lineStyle: {
                    color: "#74788d"
                  }
                }
              },
              {
                type: "value",
                name: props.t('dashboard.lista.vendasdia.diassemana.label'),
                min: 0,
                max: 25,
                interval: 5,
                axisLabel: {
                  formatter: "{value}"
                }
              }
            ],
            series: [
              {
                name: props.t('dashboard.lista.vendasdia.vendas.label'),
                type: "bar",
                data:
                  props.dashboardVendasDias.vendas &&
                  props.dashboardVendasDias.vendas[0]
                    ? props.dashboardVendasDias.vendas[0].map(e =>
                      current_state.graphType === "quantity"
                        ? e.Qtd_Vendas
                        : e.Valor_Total
                    )
                    : []
              },
              {
                name: props.t('dashboard.lista.vendasdia.entregas.label'),
                type: "bar",
                data:
                  props.dashboardVendasDias.entregas &&
                  props.dashboardVendasDias.entregas[0]
                    ? props.dashboardVendasDias.entregas[0].map(e =>
                      current_state.graphType === "quantity"
                        ? e.Qtd_Vendas
                        : e.Valor_Total
                    )
                    : []
              }
            ],
            textStyle: {
              color: ["#74788d"]
            }
          },
          chart_options: {
            chart: {
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "45%",
                endingShape: "rounded"
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"]
            },

            colors: ["#34c38f", "#556ee6"],
            xaxis: {
              categories:
                props.dashboardVendasDias.vendas &&
                props.dashboardVendasDias.vendas[0]
                  ? props.dashboardVendasDias.vendas[0].map(e =>
                    weekdayName(e.dia_numerico)
                  )
                  : []
            },
            yaxis: {
              title: {
                text:
                  current_state.graphType === "quantity"
                    ? props.t('dashboard.lista.vendasdia.quantidade.label')
                    : props.t('dashboard.lista.vendasdia.valor.label')
              },
              labels: {
                formatter: val => {
                  return VendasDia.abbreviateNumber(val)
                }
              }
            },
            grid: {
              borderColor: "#f1f1f1"
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function(val) {
                  return parseFloat(val || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  })
                }
              }
            }
          },
          chart_series: [
            {
              name: props.t('dashboard.lista.vendasdia.vendas.label'),
              data:
                props.dashboardVendasDias.vendas &&
                props.dashboardVendasDias.vendas[0]
                  ? props.dashboardVendasDias.vendas[0].map(e =>
                    current_state.graphType === "quantity"
                      ? e.Qtd_Vendas
                      : e.Valor_Total
                  )
                  : []
            },
            {
              name: props.t('dashboard.lista.vendasdia.entregas.label'),
              data:
                props.dashboardVendasDias.entregas &&
                props.dashboardVendasDias.entregas[0]
                  ? props.dashboardVendasDias.entregas[0].map(e =>
                    current_state.graphType === "quantity"
                      ? e.Qtd_Vendas
                      : e.Valor_Total
                  )
                  : []
            }
          ]
        }
      }
    }

    return props
  }

  componentDidMount() {
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "vendasDias")
  }

  setGraphType(graphType) {
    this.setState(prevState => ({ graphType }))
    this.props.setUserPreference("Grafico_Vendas_Dias", graphType)
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="6">
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h4 className="card-title mb-4"> { this.props.t('dashboard.lista.vendasdia.label') } </h4>
                <Tooltip id='vendasdia' tooltip={ this.props.t('dashboard.lista.vendasdia.tooltip') }></Tooltip>
                <div className="ms-auto">
                  <div className="toolbar d-flex flex-wrap gap-2 text-end">
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={
                        this.state.graphType === "quantity" ? "active" : ""
                      }
                      onClick={ () => this.setGraphType("quantity") }
                    >
                      Em Quantidade
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={
                        this.state.graphType === "value" ? "active" : ""
                      }
                      onClick={ () => this.setGraphType("value") }
                    >
                      Em Valor
                    </Button>
                  </div>
                </div>
              </div>
              { this.props.loadingDashboardVendasDias ||
              !this.state.chart_options ||
              !this.state.chart_series ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : (
                <div>
                  <ReactApexChart
                    options={ this.state.chart_options }
                    series={ this.state.chart_series }
                    type="bar"
                    height={ 300 }
                  />
                </div>
              ) }
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

VendasDia.propTypes = {
  loadingDashboardVendasDias: PropTypes.any,
  dashboards: PropTypes.any,
  getDashboardData: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardVendasDias,
    dashboardVendasDias, preferences
  } = state.Login
  return {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardVendasDias,
    dashboardVendasDias,
    preferences
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  getDashboardData,
  setUserPreference
})(VendasDia))
