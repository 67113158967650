import React, { useEffect, useState } from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import { describeDateTime } from "../../helpers/utils"
import LoaderList from "../../components/Common/loaderList"
import { MDBDataTable } from "mdbreact"
import ConfigIfoodModal from "./config-ifood-modal"
import { Link, withRouter } from "react-router-dom"
import usePermission from "../../helpers/permission/usePermission"
import { buscarConfigIfoodPorEmpresa, cadastrarConfigIfood, editarConfigIfood } from "../../store/auth/profile/actions"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

const EmpresaConfigIfoodLista = props => {

  const [isEditing, setIsEditing] = useState(false)
  const [modal, setModal] = useState(false)
  const [configIfoodInitialState, setConfigIfoodInitialState] = useState({
    id: null,
    Contrato: null,
    LiberadoAte: new Date(),
    merchant_id: null,
    merchant_uuid: null,
    auth_tipo: "D",
    polling_interval: 30
  })
  const [configIfood, setConfigIfood] = useState(configIfoodInitialState)

  const [data, setData] = useState({
    columns: [
      { label: props.t('config-ifood.id'), field: "Código", sort: "asc", width: 110 },
      { label: props.t('config-ifood.contrato'), field: "Contrato", width: 230 },
      { label: props.t('config-ifood.liberado.ate'), field: "Liberado_Ate", width: 230 },
      { label: props.t('config-ifood.merchant.id'), field: "CodigoIfood", width: 230 },
      { label: props.t('config-ifood.merchant.uuid'), field: "CodigoUUID", width: 230 },
      { label: props.t('config-ifood.auth.tipo'), field: "TipoAutenticacao", width: 230 },
      { label: props.t('config-ifood.polling'), field: "Polling", width: 230 },
      { label: props.t('config-ifood.refresh.token'), field: "RefreshToken", width: 230 },
      { label: props.t('config-ifood.refresh.token.data'), field: "RefreshTokenData", width: 230 },
      { label: props.t('config-ifood.merchant.status'), field: "StatusIfood", width: 230 },
      { label: props.t('config-ifood.merchant.status.motivo'), field: "Motivo", width: 110 },
      { label: props.t('config-ifood.merchant.status.data'), field: "DataUltimoStatus", width: 110 },
      { label: props.t('config-ifood.merchant.status.altfunc'), field: "UsuarioStatus", width: 110 },
      { label: props.t('config-ifood.merchant.name'), field: "NomeCliente", width: 110 },
      { label: props.t('config-ifood.update.at'), field: "Alteracao", sort: "asc", width: 110 },
      { label: props.t('config-ifood.update.user'), field: "UsuarioAlteracao", width: 110 },
      { label: props.t('config-ifood.insert.at'), field: "DataInclusao", width: 110 },
      { label: props.t('config-ifood.insert.user'), field: "UsuarioInclusao", width: 110 },
      { label: props.t('label.actions'), field: "acoes", width: 110 }
    ],
    rows: []
  })

  const describeTipoAutenticacao = tipo => {
    if (!tipo) {
      return "N/D"
    }
    if (tipo === "D") {
      return props.t('config-ifood.auth.tipo.D')
    } else {
      return props.t('config-ifood.auth.tipo.C')
    }
  }

  const toggleModal = (item, edit) => {
    setIsEditing(edit)
    setConfigIfood(item)
    setModal(!modal)
  }

  useEffect(() => {
    if (Array.isArray(props.listaConfiguracoes)) {
      const rows = props.listaConfiguracoes?.map(item => ({
        "Código": item.id,
        "Contrato": item.idContrato,
        "Liberado_Ate": describeDateTime(item.LiberadoAte),
        "CodigoIfood": item.merchant_id,
        "CodigoUUID": item.merchant_uuid,
        "TipoAutenticacao": describeTipoAutenticacao(item.auth_tipo),
        "Polling": item.polling_interval,
        "RefreshToken": item.refresh_token,
        "RefreshTokenData": describeDateTime(item.refresh_token_data),
        "StatusIfood": item.merchant_status,
        "Motivo": item.merchant_status_motivo,
        "DataUltimoStatus": describeDateTime(item.merchant_status_data),
        "UsuarioStatus": item.merchant_status_altfunc,
        "NomeCliente": item.merchant_name,
        "Alteracao": describeDateTime(item.update_at),
        "UsuarioAlteracao": item.update_user,
        "DataInclusao": describeDateTime(item.insert_at),
        "UsuarioInclusao": item.insert_user,
        "acoes": (
          <div>
            {props.usePermissionEdit && <Link to="#" className="me-3 text-primary">
              <i
                className="mdi mdi-pencil font-size-18 me-3"
                id="edittooltip"
                onClick={e => toggleModal(item, true)}
                title="Editar"
              />
            </Link>}
          </div>
        )
      }))

      setData({
        columns: data.columns,
        rows: rows
      })
    }
  }, [props.listaConfiguracoes])

  const toggle = () => {
    setModal(!modal)
  }

  const saveConfig = config => {
    config.idEmpresa = props.empresa
    if (isEditing) {
      props.editarConfigIfood(config)
    } else {
      props.cadastrarConfigIfood(config)
    }
    setTimeout(() => props.buscarConfigIfoodPorEmpresa( {
      company: props.empresa,
      ignoreCompany: true
    }), 300)

    setModal(!modal)
  }

  return <>
    {props.loading ? <LoaderList></LoaderList> :
      <>
        <Button
          type="submit"
          color="primary"
          className="waves-effect waves-light me-2"
          disabled={!props.usePermissionEdit}
          onClick={e => toggleModal(configIfoodInitialState, false)}>
          {props.t('config-ifood.new')}
        </Button>
        <div className="table-responsive">
          <MDBDataTable
            responsive
            striped
            bordered
            data={data}
            entriesLabel={"Mostrar Registros"}
            infoLabel={["Exibindo", "a", "de", "registros"]}
            noRecordsFoundLabel={"Nenhum registro encontrado"}
            paginationLabel={["Anterior", "Próximo"]}
            searchLabel={"Pesquisar"}
            entries={5}
            entriesOptions={[5, 10, 20, 50, 100]}
          />
        </div>
        <ConfigIfoodModal
          configIfood={configIfood}
          isOpen={modal}
          toggle={() => toggle()}
          isEditing={isEditing}
          saveConfig={saveConfig}
        />
      </>
    }
  </>
}

EmpresaConfigIfoodLista.propTypes = {
  listaConfiguracoes: PropTypes.array,
  loading: PropTypes.bool,
  empresa: PropTypes.number,
  cadastrarConfigIfood: PropTypes.func,
  editarConfigIfood: PropTypes.func,
  usePermissionEdit: PropTypes.bool
}
const mapStateToProps = state => {
  const {
    configIfoodLoading,
  } = state.Profile
  return { configIfoodLoading }
}

export default withTranslation()(connect(mapStateToProps, {
  cadastrarConfigIfood, editarConfigIfood, buscarConfigIfoodPorEmpresa,
})(withRouter(EmpresaConfigIfoodLista)))
