import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label, Modal, ModalBody, ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import { connect } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"

//Date Picker
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  buscarFornecedores,
  buscarQuantidadeFornecedores, editarFornecedor, navegarEditarFornecedor, relatorioExecutar,
  removerFornecedor
} from "../../store/auth/profile/actions"
import { isArray } from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import usePermission from "helpers/permission/usePermission"
import { map } from "lodash"
import { AvForm } from "availity-reactstrap-validation"
import MiniCards from "../../components/Common/mini-card"
import CsvDownloader from "react-csv-downloader";
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import {getCompanies, getCompaniesValuesByOptions, getCompanyOption} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import { navegarBreadCrumb } from "../../store/navigator/actions"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const FornecedoresLista = props => {

  const [companies, setCompanies] = useState([])
  const [status, setStatus] = useState("")
  const [miniCards, setMiniCards] = useState([])
  const [companiesOption, setCompaniesOption] = useState([])
  const usePermissionRemove = usePermission("fornecedores_excluir")
  const usePermissionInsert = usePermission("fornecedores_inserir")
  const usePermissionEdit = usePermission("fornecedores_editar")
  const [modalRemove, setModalRemove] = useState(false)
  const [itemSelecionado, setItemSelecionado] = useState()
  const [modalInativar, setModalInativar] = useState(false)
  const [modalAtivar, setModalAtivar] = useState(false)

  const getColumns = (exportRows) => {
    let columns = [
      {label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150},
      {label: "Código", field: "Código", sort: "asc", width: 150},
      {label: "Nome", field: "Nome", sort: "asc", width: 270},
      {label: "CPF/CNPJ", field: "CPF/CNPJ", sort: "asc", width: 270},
      {label: "E-mail", field: "E-mail", sort: "asc", width: 100},
      {label: "Telefone", field: "Telefone", sort: "asc", width: 100},
      {label: "Status", field: "Status", sort: "asc", width: 150},
    ];
    if(!exportRows) {
      columns.push({label: "Ações", field: "Ações", sort: "asc", width: 100})
    }
    return columns;
  }

  const [tableData, setTableData] = useState({
    columns: getColumns(),
    rows: []
  })

  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
  const location = useLocation();

  useEffect(() => {
    if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)

    if (possuiVinculo) {
      props.buscarQuantidadeFornecedores({ company: getCompaniesValuesByOptions(props.dashboardFilters.companiesSelected) })
      doUpdate(props.dashboardFilters.companiesSelected)
    }
    props.navegarBreadCrumb({title: `menu.label.supplier`, link: location.pathname})

    return () => {
      setMiniCards({})
    }
  }, [])

  useEffect(() => {
    if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
      let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
      setCompaniesOption(companiesOptions)
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOption(companiesOptions)
    }
    return () => {
      setCompaniesOption([])
    }
  }, [props.user, props.empresas])

  const popularMiniCards = () => {
    setMiniCards([
      {
        title: "Quantidade fornecedores",
        iconClass: "bx-data",
        text:
          props.quantidadeFornecedores && props.quantidadeFornecedores.total
            ? new String(props.quantidadeFornecedores.total)
            : "0",
        loading: props.fornecedoresLoading
      },
      {
        title: "Fornecedores ativos",
        iconClass: "bx-bookmark-plus",
        text:
          props.quantidadeFornecedores && props.quantidadeFornecedores.ativos
            ? new String(props.quantidadeFornecedores.ativos)
            : "0",
        loading: props.fornecedoresLoading
      },
      {
        title: "Fornecedores inativas",
        iconClass: "bx-bookmark-minus",
        text:
          props.quantidadeFornecedores && props.quantidadeFornecedores.inativos
            ? new String(props.quantidadeFornecedores.inativos)
            : "0",
        loading: props.fornecedoresLoading
      }])
  }

  useEffect(() => {
    popularMiniCards();
    return () => setMiniCards([])
  }, [props.quantidadeFornecedores, props.fornecedoresLoading])

  const getRows = (fornecedores, exportRows) => {
    const rows = [];
    if(Array.isArray(fornecedores)) {
      for (const item of fornecedores) {
        const row = {
          "ID Cloud": item.id,
          "Código": item.codigo,
          Nome: item.razaosocial,
          "CPF/CNPJ": item.cnpj,
          "E-mail": item.email,
          Telefone: item.telefone,
          Status: exportRows ? item.status === "A" ? "Ativo" : item.status === "I" ? "Inativo" : "N/A" :
              (
                  <Badge
                      className={"font-size-12 badge-soft-" + statusBadgeClass(item)}
                      color={statusBadgeClass(item)}
                      pill
                  >
                    {item.status === "A" ? "Ativo" : item.status === "I" ? "Inativo" : "N/A"}
                  </Badge>
              )
        };
        if(!exportRows) {
          row["Ações"] = (
              <div>
                {usePermissionEdit && <Link to="#" className="me-3 text-primary">
                  <i
                      className="mdi mdi-pencil font-size-18 me-3"
                      id="edittooltip"
                      onClick={e => goToFornecedor(item)}
                      title="Editar"
                  />
                </Link>}
                {usePermissionEdit && item.status === "A" && <Link to="#" className="text-warning">
                  <i className="mdi mdi-bookmark-minus font-size-18 me-3" id="btnInativar"
                     onClick={() => showConfirmInactivate(item)} title="Inativar"/>
                </Link>}
                {usePermissionEdit && item.status === "I" && <Link to="#" className="text-info">
                  <i className="mdi mdi-bookmark-plus font-size-18 me-3" id="btnAtivar"
                     onClick={() => showConfirmActivate(item)} title="Ativar"/>
                </Link>}
                {usePermissionRemove && <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                     onClick={() => showConfirmDelete(item)} title="Remover"/>
                </Link>}
              </div>
          )
        }
        rows.push(row)
      }
    }
    return rows;
  }

  const popularTabela = () => {
    if (Array.isArray(props.fornecedores)) {
      setTableData({
        columns: getColumns(),
        rows: getRows(props.fornecedores)
      })
    }
  }

  useEffect(() => {
    popularTabela()
    return () => setTableData({ columns: [], rows: [] })
  }, [props.fornecedores, props.fornecedoresLoading])

  const doUpdate = (companiesSelected) => {
    props.buscarFornecedores({
      company: getCompaniesValuesByOptions(companiesSelected ? companiesSelected : companies),
      status
    })
  }

  const goToFornecedor = item => {
    props.navegarEditarFornecedor(item)
    props.history.push("/fornecedor"+(item?`/${item.id}`: ""))
  }

  const showConfirmDelete = item => {
    setItemSelecionado(item)
    setModalRemove(true)
  }

  const showConfirmInactivate = item => {
    setItemSelecionado(item)
    setModalInativar(true)
  }

  const showConfirmActivate = item => {
    setItemSelecionado(item)
    setModalAtivar(true)
  }

  const removerFornecedor = () => {
    props.removerFornecedor(itemSelecionado)
    setModalRemove(false)
  }

  const inativarFornecedor = () => {
    props.editarFornecedor( { status: 'I', id: itemSelecionado.id })
    setModalInativar(false)
  }

  const ativarFornecedor = () => {
    props.editarFornecedor( { status: 'A', id: itemSelecionado.id })
    setModalAtivar(false)
  }

  const csvDataHeader = () => {
    const columns = getColumns(true)
    return columns.map(column => {
      return { id: column.field, displayName: column.label }
    });
  };

  const csvData = () => {
    const rows = getRows(props.fornecedores, true)
    return Promise.resolve(rows.map(row => {
      return row
    }));
  };

  const printPDF = () => {
    let reportName = 'fornecedores';
    props.relatorioExecutar({
      company: getCompaniesValuesByOptions(companies),
      status,
      reportName
    });
  }

  const statusBadgeClass = item => {
    switch (item.status) {
      case "A": {
        return "primary"
      }
      case "I": {
        return "warning"
      }
      default: {
        return "secondary"
      }
    }
  }

  if(
    !props.user ||
    !possuiVinculo
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="menu.label.supplier" />
          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Fornecedores</h4>
                    <Form>
                      <Row>
                        { map(miniCards, (card, key) => (
                          <MiniCards
                            title={ card.title }
                            text={ card.text }
                            iconClass={ card.iconClass }
                            key={ "_card_" + key }
                            loading={ card.loading }
                          />
                        )) }
                      </Row>
                      <Row>
                        <div className="col col-auto">
                          <FormFilterCompany
                              attribute="companies"
                              value={companies}
                              description="Empresa(s)"
                              setValue={ value => setCompanies(value) }
                              options={companiesOption}
                              multiple={ true }
                              hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                          />
                        </div>

                        <div className="col col-auto">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Status</Label>
                            <select
                              className="form-select select2-search-disable"
                              value={ status }
                              onChange={ e => setStatus(e.target.value) }
                            >
                              <option value="" defaultValue>
                                Qualquer
                              </option>
                              <option value="A">Ativo</option>
                              <option value="I">Inativo</option>
                            </select>
                          </FormGroup>
                        </div>

                        <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                          <div className="mt-3 col col-auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={ e => doUpdate() }
                              disabled={ !usePermission("fornecedores_listar") }>
                              Buscar
                            </Button>
                          </div>

                          <div className="mt-3 col col-auto">
                            <Button
                              type="button"
                              color="success"
                              disabled={ !(usePermissionEdit || usePermissionInsert) }
                              onClick={ e => goToFornecedor(null) }>
                              <i className="bx bx-plus" /> Cadastrar
                            </Button>
                          </div>

                          { !props.executaRelatorioLoading && !props.fornecedoresLoading &&
                              <div className="mt-3 col col-auto">
                                <CsvDownloader
                                    filename="fornecedores"
                                    extension=".csv"
                                    separator=";"
                                    wrapColumnChar=""
                                    columns={ csvDataHeader() }
                                    datas={ csvData() }>
                                  <Button type="button" color="light">
                                    <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                    CSV
                                  </Button>
                                </CsvDownloader>
                              </div> }
                          { !props.executaRelatorioLoading && !props.fornecedoresLoading &&
                              <div className="mt-3 col col-auto">
                                <Button type="button" color="light" onClick={ e => printPDF() }>
                                  <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                  PDF
                                </Button>
                              </div>
                          }
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                <Card>
                  { props.fornecedoresLoading || props.executaRelatorioLoading ? (
                    <div className="loader-container position-absolute">
                      <Loader
                        type="ThreeDots"
                        color="#556EE6"
                        height={ 80 }
                        width={ 80 }
                      />
                    </div>
                  ) : (
                    <CardBody>
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={ tableData }
                        entriesLabel={ "Mostrar Registros" }
                        infoLabel={ ["Exibindo", "a", "de", "registros"] }
                        noRecordsFoundLabel={ "Nenhum registro encontrado" }
                        paginationLabel={ ["Anterior", "Próximo"] }
                        searchLabel={ "Pesquisar" }
                      />
                    </CardBody>
                  ) }
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={ modalRemove }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              removerFornecedor()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover esse registro?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalRemove(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={ modalInativar }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              inativarFornecedor()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente inativar esse registro ?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalInativar(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={ modalAtivar }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              ativarFornecedor()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente ativar esse registro ?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalAtivar(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

FornecedoresLista.propTypes = {
  fornecedores: PropTypes.array,
  quantidadeFornecedores: PropTypes.any,
  fornecedoresLoading: PropTypes.bool,
  fornecedor: PropTypes.any,
  fornecedorLoading: PropTypes.bool,
  buscarFornecedores: PropTypes.func,
  removerFornecedor: PropTypes.func,
  editarFornecedor: PropTypes.func,
  navegarEditarFornecedor: PropTypes.func,
  buscarQuantidadeFornecedores: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    fornecedoresLoading,
    fornecedores,
    fornecedor,
    fornecedorLoading,
    quantidadeFornecedores,
    executaRelatorioLoading
  } = state.Profile
  const { user, dashboardFilters } = state.Login
  return {
    fornecedoresLoading,
    fornecedores,
    user,
    fornecedor,
    fornecedorLoading,
    quantidadeFornecedores,
    executaRelatorioLoading, dashboardFilters
  }
}

export default connect(mapStatetoProps, {
  buscarFornecedores,
  relatorioExecutar,
  removerFornecedor,
  editarFornecedor,
  navegarEditarFornecedor,
  buscarQuantidadeFornecedores,
  navegarBreadCrumb
})(withRouter(FornecedoresLista))
