import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { map } from "lodash"
import { Link, useLocation, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  doCreatePerfil,
  doEditPerfil,
  doViewPerfil, removerPerfil, buscarPerfis
} from "../../store/auth/profile/actions"
import Loader from "react-loader-spinner"
import usePermission from "helpers/permission/usePermission"
import moment from "moment"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import { navegarBreadCrumb } from "../../store/navigator/actions"

const PerfisLista = props => {
  const { perfis, loading } = props
  const usePermissionEdit = usePermission('perfil_editar')
  const usePermissionInsert = usePermission('perfil_inserir')
  const usePermissionList = usePermission('perfil_listar')
  const isInforangra = props.user?.usuario_info
  const location = useLocation();

  useEffect(() => {
    props.buscarPerfis()
    props.navegarBreadCrumb({title: `breadcrumb.user.profiles.title`, link: location.pathname})
  }, [])

  const goToPerfil = item => {
    (usePermissionEdit && props.doEditPerfil(item)) || props.doViewPerfil(item)
    props.history.push("/perfil"+(item?`/${item.id}`: ""))
  }

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return loading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="breadcrumb.user.profiles.title"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Perfis</CardTitle>
                  <div className="button-items my-3">
                    <Link to="/perfil">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light"
                        onClick={ e => props.doCreatePerfil() }
                        disabled={ !usePermissionInsert }>
                        <i className="bx bx-plus" /> Cadastrar
                      </Button>
                    </Link>
                  </div>

                  <div className="table-responsive">
                    <table
                      className="dataTable table table-bordered table-striped table-nowrap table-centered mb-0 table-hover">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Admintrador</th>
                          <th>Alteração</th>
                          { isInforangra && <th>Empresa</th> }
                          { (usePermissionList || usePermissionEdit) && <th>Ações</th> }
                        </tr>
                      </thead>

                      <tbody>
                      { map(perfis, (item, i) => (
                        <tr key={ i }>
                          <td onClick={ e => goToPerfil(item) }>
                            <Link to="#" className="text-truncate text-dark">
                              { item.nome }
                            </Link>
                          </td>
                          <td onClick={ e => goToPerfil(item) }>
                            <Link to="#" className="text-truncate text-dark">
                              { item.administrador &&
                                <Badge className="font-size-12 badge-soft-warning" color="warning" pill>
                                  Sim
                                </Badge>
                              }
                              { !item.administrador &&
                                <Badge className="font-size-12 badge-soft-primary" color="primary" pill>
                                  Não
                                </Badge>
                              }
                            </Link>
                          </td>
                          <td onClick={ e => goToPerfil(item) }>
                            <Link to="#" className="text-truncate text-dark">
                              { describeDateTime(item.data_alteracao) }
                            </Link>
                          </td>
                          { isInforangra && (
                            <td onClick={ e => goToPerfil(item) }>
                              <Link to="#" className="text-truncate text-dark">
                                { item.empresa ? item.empresa.Fantasia : "Todas" }
                              </Link>
                            </td>
                          ) }
                          { (usePermissionEdit ||
                            usePermissionList) && (
                            <td>
                              { usePermissionEdit && <Link to="#" className="me-3 text-primary">
                                <i className="mdi mdi-pencil font-size-18 me-3" id="edittooltip" onClick={ e => goToPerfil(item) } title="Editar"/>
                              </Link> }
                              { usePermissionList && !usePermissionEdit && <Link to="#" className="me-3 text-primary">
                                <i className="mdi mdi-eye font-size-18 me-3" id="viewtooltip" onClick={ e => goToPerfil(item) } title="Visualizar" />
                              </Link> }
                            </td>
                          )}
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PerfisLista.propTypes = {
  perfis: PropTypes.array,
  loading: PropTypes.bool,
  buscarPerfis: PropTypes.func
}

const mapStatetoProps = state => {
  const { perfis, loading } = state.Profile
  const { user } = state.Login
  return { perfis, loading, user }
}

export default connect(mapStatetoProps, {
  buscarPerfis,
  doViewPerfil,
  doEditPerfil,
  doCreatePerfil,
  navegarBreadCrumb
})(withRouter(PerfisLista))
