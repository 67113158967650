import { DASHBOARD , DASHBOARD_DATA} from "./actionTypes"

export const getDashboardData = (
  periodStart = null,
  periodEnd = null,
  companiesId = null,
  type = null
) => {
  return {
    type: DASHBOARD,
    payload: { periodStart, periodEnd, companiesId, type }
  }
}

export const dashboardData = data => {
  return {
    type: DASHBOARD_DATA,
    payload: data
  }
}
