import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap"
import Dropzone from "react-dropzone"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../components/Common/Breadcrumb"
import profileImg from "../assets/images/profile-img.png"
import avatar from "../assets/images/users/default-avatar.png"
// actions
import { editProfile } from "../store/actions"
import InputMask from "react-input-mask"
import PasswordStrengthBar from "react-password-strength-bar"
import usePermission from "helpers/permission/usePermission"
import { navegarBreadCrumb } from "../store/navigator/actions"
import { apiGetAvatar } from "../helpers/backend_helper"

const Perfil = props => {
  const { user } = props
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [telefone, settelefone] = useState("")
  const [password, setPassword] = useState("")
  const [passwordScore, setPasswordScore] = useState(0)
  const [selectedFiles, setselectedFiles] = useState([])
  const location = useLocation();
  const [fileUploadError, setFileUploadError] = useState(null)
  const [userAvatar, setUserAvatar] = useState("")
  const usePermissionEdit = usePermission('profileEdit_editar')

  useEffect(() => {
    setname(user.nome_completo)
    setemail(user.email)
    settelefone(user.telefone)
    props.navegarBreadCrumb({ title: `breadcrumb.my.profile`, link: location.pathname })

    const userAv = localStorage.getItem("avatarUser")
    setUserAvatar(userAv)

    return () => {
      setname("")
      setemail("")
      settelefone("")
    }
  }, [props.user])


  useEffect(async() => {

    const userAvatarResponse = await apiGetAvatar()
    if(userAvatarResponse && userAvatarResponse.size > 0) {
      const imageBytes = await userAvatarResponse.arrayBuffer();
      const blob = new Blob([imageBytes], { type: "image/jpeg" });
      const imageUrl = URL.createObjectURL(blob);

      localStorage.setItem("avatarUser", imageUrl)
      setUserAvatar(imageUrl)
    }
  }, [props.user.avatar])


  const handleValidSubmit = (event, values) => {
    const fileBase64 = selectedFiles && selectedFiles.length ? selectedFiles[0].b64 : null
    if (fileBase64) {
      setUserAvatar(fileBase64)
    }
    props.editProfile({
      ...values,
      ...{
        avatar:
          fileBase64
      }
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file => {
      const reader = new FileReader()
      reader.onload = () => {

        let fileSize = file.size / 1024 / 1024
        if (fileSize > 5 ) {
          setFileUploadError("Arquivo não pode ser maior que 5MB")
          return
        } else { setFileUploadError(null) }
        setselectedFiles([
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            b64: reader.result
          })
        ])
      }
      reader.readAsDataURL(file)
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */ }
          <Breadcrumb title="breadcrumb.user.data"/>

          <Row>
            <Col sm={ 12 }>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Seja bem-vindo!</h5>
                        <p>
                          Aqui você pode visualizar e alterar informações do seu
                          perfil.
                        </p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end" style={ { textAlign: "right" } }>
                      <img src={ profileImg } alt="" className="img-fluid" style={ { maxHeight: "150px" } }
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img src={ (userAvatar || avatar) } alt="" className="img-thumbnail rounded-circle" />
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        Nome: { user.nome_completo }
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        Telefone: { user.telefone } { !user.telefone && "N/A" }
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col sm={ 12 }>
              <Card>
                <CardBody>
                  <CardTitle>Seus Dados</CardTitle>
                  <CardSubtitle className="mb-3">
                    Preencha as informações abaixo
                  </CardSubtitle>

                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={ handleValidSubmit }
                  >
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="nome_completo" className="col-form-label">Nome Completo</Label>
                          <AvField
                            id="nome_completo"
                            name="nome_completo"
                            type="text"
                            className="form-control"
                            value={ name }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="email" className="col-form-label">E-mail</Label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            value={ email }
                          />
                        </FormGroup>
                        <div className="form-group">
                          <Label htmlFor="telefone" className="col-form-label">Telefone</Label>
                          <AvField
                            value={ telefone }
                            name="telefone"
                            className="form-control"
                            placeholder="Digite o telefone"
                            type="text"
                            mask="(99) 9999-99999"
                            maskChar=""
                            tag={ [Input, InputMask] }
                          />
                        </div>
                        <div className="form-group">
                          <Label htmlFor="senha" className="col-form-label">Senha</Label>
                          <AvField
                            name="senha"
                            type="password"
                            placeholder="Digite a senha"
                            onChange={ e => setPassword(e.target.value) }
                          />

                          <PasswordStrengthBar
                            password={ password }
                            onChangeScore={ e => setPasswordScore(e) }
                            scoreWords={ [
                              "Fraca",
                              "Fraca",
                              "Razoável",
                              "Boa",
                              "Forte"
                            ] }
                            shortScoreWord="Muito curta"
                          />
                        </div>

                        <div className="form-group">
                          <Label htmlFor="repetir_senha" className="col-form-label">Repetir Senha</Label>
                          <AvField
                            name="repetir_senha"
                            type="password"
                            placeholder="Digite a senha novamente"
                            validate={ { match: { value: "senha" } } }
                            required={ password }
                            errorMessage="Campo obrigatório."
                          />
                        </div>

                        { passwordScore < 3 && (
                          <div
                            className="alert alert-warning fade show"
                            role="alert"
                          >
                            O campo senha precisa possuir uma combinação de ao
                            menos 6 caracteres que contenha uma letra maíscula,
                            uma letra minúscula e um número.
                          </div>
                        ) }

                        <Label className="col-form-label">Avatar</Label>
                        <Dropzone
                          maxFiles={ 1 }
                          accept=".jpeg,.jpg,.png,.gif"
                          onDrop={ acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          } }
                        >
                          { ({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                { ...getRootProps() }
                              >
                                <input { ...getInputProps() } />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>
                                    Arraste uma imagem ou clique aqui para
                                    atualizar seu avatar.
                                  </h4>
                                </div>
                              </div>
                            </div>
                          ) }
                        </Dropzone>
                        <div className="dropzone-previews mt-3 mb-3" id="file-previews">
                          { selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={ i + "-file" }
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={ f.name }
                                        src={ f.preview }
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        { f.name }
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{ f.formattedSize }</strong>
                                      </p>

                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          }) }
                        </div>

                        { fileUploadError ?
                          <p className="mb-0">
                            <strong> { fileUploadError } </strong>
                          </p>
                          : null
                        }
                      </Col>
                    </Row>

                    <Button
                      type="submit"
                      color="primary"
                      className="me-1 waves-effect waves-light"
                      disabled={ password && passwordScore < 3 && usePermissionEdit }
                    >
                      Salvar Alterações
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Perfil.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  const { user } = state.Login
  return { error, success, user }
}

export default withRouter(connect(mapStatetoProps, { editProfile , navegarBreadCrumb})(Perfil))
