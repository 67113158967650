import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Badge, Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap"
import { connect } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"
import { get } from "../../helpers/api_helper"

//Date Picker
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CsvDownloader from 'react-csv-downloader';

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment"

import { isArray } from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import { buscarDRE, limparDRE, relatorioExecutar } from "store/auth/profile/actions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import {getCompanies, getCompaniesValuesByOptions, getCompanyOption} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import { navegarBreadCrumb } from "../../store/navigator/actions"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const Dre = props => {
  const [startYear, setStartYear] = useState(moment().startOf("year").format("YYYY-MM-DD"))
  const [endYear, setEndYear] = useState(moment().endOf("year").format("YYYY-MM-DD"))
  const [startYearPeriod, setStartYearPeriod] = useState([])
  const [endYearPeriod, setEndYearPeriod] = useState([])
  const [companies, setCompanies] = useState([])
  const [period, setPeriod] = useState("M")
  const [analizeType, setAnalizeType] = useState("")
  const [find, setFind] = useState(false)
  const location = useLocation();
  const [companiesOption, setCompaniesOption] = useState([])

  const [tableData, setTableData] = useState({
    columns: [
      { label: "Conta", field: "codigo_plano_conta", width: 150 },
      { label: "Descrição", field: "descricao_plano_conta", width: 270 },
      { label: "Total", field: "valor", width: 270 }
    ],
    rows: []
  })

  const isActualMonth = (month, year) => {
    if(period === 'M' && moment().month() === month && moment().year() === year) {
      return true
    }

    return false
  }

  useEffect(() => {
    const tmpStartYearPeriods = []
    for (let i = 0; i <= 12; i++) {
      tmpStartYearPeriods.push({
        label: moment().subtract(i, "year").format("YYYY"),
        value: moment().subtract(i, "year").startOf('year').format("YYYY-MM-DD")
      })
    }

    setStartYearPeriod(tmpStartYearPeriods)
    const yearDateStr = moment().startOf("year").format("YYYY-MM-DD")
    doSetEndYearPeriodByStart(yearDateStr)

    if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)
    props.navegarBreadCrumb( {title: `menu.label.dre`, link: location.pathname })
    return () => {
      setStartYearPeriod([])
    }
  }, [])

  useEffect(() => {
    if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
      let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
      setCompaniesOption(companiesOptions)
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOption(companiesOptions)
    }
    return () => {
      setCompaniesOption([])
    }
  }, [props.user, props.empresas])

  const doSetStartYearPeriod = (startYearDateStr) => {
    setStartYear(startYearDateStr)
    doSetEndYearPeriodByStart(startYearDateStr)
  }

  const doSetEndYearPeriodByStart = (startYearDateStr) => {
    const tmpEndYearPeriods = []
    const startYearMoment = moment(startYearDateStr)
    const now = moment().endOf("year")
    const diffYear = now.diff(startYearMoment, 'years')
    for (let i = 0; i <= diffYear; i++) {
      tmpEndYearPeriods.push({
        label: moment().subtract(i, "year").format("YYYY"),
        value: moment().subtract(i, "year").endOf('year').format("YYYY-MM-DD")
      })
    }

    setEndYear(tmpEndYearPeriods[0].value)
    setEndYearPeriod(tmpEndYearPeriods)
  }

  const getMoneyValue = (calc, value, withStyle) => {
    return withStyle ?
        (calc ?
          <div className="text-right">
            <Badge
              className={ "font-size-12 badge-soft-" + statusBadgeClass(value) }
              color={ statusBadgeClass(value) } pill>
              <div className="text-right font-weight-bold">
                { parseFloat(value || 0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }
              </div>
            </Badge>
          </div>
           : <div className="text-right">
            { parseFloat(value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            }) }
          </div>) :
            parseFloat(value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
  }

  const getPercValue = (calc, value, printArrow, withStyle) => {
    if(withStyle) {
      let arrow = ""
      if(printArrow && value && Math.round(value || 0) !== 0) {
        arrow = <i className={"mdi " + (Math.round(value || 0) > 0 ? "mdi-arrow-up text-success" : Math.round(value || 0) < 0 ? "mdi-arrow-down text-danger" : "") }></i>
      }

      return calc ?
        <div className="text-right font-weight-bold">
          { Math.round(value || 0).toLocaleString("pt-br", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) }%
          { arrow }
        </div> :
        <div className="text-right">
          { Math.round(value || 0).toLocaleString("pt-br", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) }%
          { arrow }
        </div>
    } else {
      return `${Math.round(value || 0).toLocaleString("pt-br", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}%`
    }
  }

  const createRow = (codigoPlanoConta, descricaoPlanoConta, item, verticalAnalize, horizontalAnalize, calc, withStyle) => {
    const row = {
      codigoPlanoConta: withStyle ? (calc ? <span className="font-weight-bold">{ codigoPlanoConta }</span>: codigoPlanoConta) : codigoPlanoConta,
      descricaoPlanoConta: withStyle ? (calc ? <Badge className={ "font-size-12 badge-soft-warning" } color="#556EE6" pill><span className="font-weight-bold" style={ { textTransform: "uppercase"} }>{ descricaoPlanoConta }</span></Badge>: descricaoPlanoConta) : descricaoPlanoConta,
    }

    for (const periodo of item.periodos) {
      row[`${ periodo.ano }_${ periodo.data }_valor`] = getMoneyValue(calc, periodo.valor, withStyle)

      if (verticalAnalize) {
        row[`${ periodo.ano }_${ periodo.data }_analiseVerticalPercentual`] = getPercValue(calc, periodo.analiseVerticalPercentual, true, withStyle)
      }
      if (horizontalAnalize) {
        row[`${ periodo.ano }_${ periodo.data }_analiseHorizontalPercentual`] = getPercValue(calc, periodo.analiseHorizontalPercentual, true, withStyle)
      }
    }

    row.valor = getMoneyValue(calc, item.valor, withStyle)

    if (verticalAnalize) {
      row.analiseVerticalPercentual = getPercValue(calc, item.analiseVerticalPercentual, true, withStyle)
    }
    return row
  }

  const statusBadgeClass = value => {
      return value < 0 ? "danger" :
             value > 0 ? "success" : "primary"
  }

  function getMonthStr(periodo) {
    switch (periodo) {
      case 1: return 'Jan'
      case 2: return 'Fev'
      case 3: return 'Mar'
      case 4: return 'Abr'
      case 5: return 'Mai'
      case 6: return 'Jun'
      case 7: return 'Jul'
      case 8: return 'Ago'
      case 9: return 'Set'
      case 10: return 'Out'
      case 11: return 'Nov'
      case 12: return 'Dev'
      default: return ''
    }
  }

  function getColumns(verticalAnalize, horizontalAnalize, withStyle) {
    const reportFirstLine = Array.isArray(props.dreReport) ? props.dreReport[0] : null;
    const columns = [
      { label: "Conta", field: "codigoPlanoConta", width: 100 },
      { label: "Descrição", field: "descricaoPlanoConta", width: 600 }
    ]
    if(!reportFirstLine) {
      return columns;
    }
    const periodos = reportFirstLine.periodos
    let label
    for (const periodo of periodos) {

      if (period === "M") {
        if(withStyle) {
          label = isActualMonth(periodo.data, periodo.ano) ?
                 <Badge className={ "font-size-12 badge-soft-warning" } color="#556EE6" pill>{ getMonthStr(periodo.data) }/{ periodo.ano }</Badge> :
                `${ getMonthStr(periodo.data) }/${ periodo.ano }`
        } else {
          label = `${ getMonthStr(periodo.data) }/${ periodo.ano }`
        }
      } else if (period === "B") {
        label = `${ periodo.data }º bim/${ periodo.ano }`
      } else if (period === "T") {
        label = `${ periodo.data }º tri/${ periodo.ano }`
      } else if (period === "S") {
        label = `${ periodo.data }º sem/${ periodo.ano }`
      } else if (period === "A") {
        label = `Ano ${ periodo.ano }`
      }

      columns.push({ label: label, field: `${ periodo.ano }_${ periodo.data }_valor`, width: 50 })
      if (verticalAnalize) {
        columns.push({ label: "AV%", field: `${ periodo.ano }_${ periodo.data }_analiseVerticalPercentual`, width: 50 })
      }
      if (horizontalAnalize) {
        columns.push({
          label: "AH%",
          field: `${ periodo.ano }_${ periodo.data }_analiseHorizontalPercentual`,
          width: 50
        })
      }
    }
    columns.push({ label: "Total", field: "valor", width: 50 })
    if (verticalAnalize) {
      columns.push({ label: "AV%", field: "analiseVerticalPercentual", width: 50 })
    }
    return columns
  }

  function getRows(verticalAnalize, horizontalAnalize, withStyle) {
    const dreCalcs = new Map()
    const dreReport = Array.isArray(props.dreReport) ? props.dreReport : [];
    if(dreReport) {
      for (let i = props.dreReport.length - 1; i >= 0; i--) {
        if (props.dreReport[i].codigo >= 100) { // Receita líquida
          dreCalcs.set(props.dreReport[i].codigo, props.dreReport[i])
        } else {
          break // calculos estão no final da lista
        }
      }
    }

    const rows = []
    let row
    let lastCode
    for (const item of dreReport) {
      const codigoPlanoConta = item.codigoPlanoConta
      const descricaoPlanoConta = item.descricaoPlanoConta

      if (!codigoPlanoConta && item.codigo < 100) {
        continue
      } else if (item.codigo >= 100) {
        break
      }

      if (lastCode === 2 && item.codigo === 3) { // adiciona receita líquida
        const receitaLiquida = dreCalcs.get(100)
        if(receitaLiquida) {
          row = createRow(null, receitaLiquida.descricao, receitaLiquida, verticalAnalize, horizontalAnalize, true, withStyle)
          rows.push(row)
        }
      }
      if (lastCode === 3 && item.codigo === 4) { // adiciona margem de contribuição
        const margemContribuicao = dreCalcs.get(101)
        if(margemContribuicao) {
          row = createRow(null, margemContribuicao.descricao, margemContribuicao, verticalAnalize, horizontalAnalize, true, withStyle)
          rows.push(row)
        }
      }

      lastCode = item.codigo
      row = createRow(codigoPlanoConta, descricaoPlanoConta, item, verticalAnalize, horizontalAnalize, false, withStyle)
      rows.push(row)
    }

    const resultado = dreCalcs.get(102) // adiciona resultado
    if(resultado) {
      row = createRow(null, resultado.descricao, resultado, verticalAnalize, horizontalAnalize, true, withStyle)
      rows.push(row)
    }

    const pontoEquilibrio = dreCalcs.get(103) // adiciona ponto de equilíbrio
    if(pontoEquilibrio) {
      row = createRow(null, pontoEquilibrio.descricao, pontoEquilibrio, verticalAnalize, horizontalAnalize, true, withStyle)
      rows.push(row)
    }
    return rows
  }

  useEffect(() => {
    if (isArray(props.dreReport) && props.dreReport.length > 0) {
      const verticalAnalize = analizeType === '' || analizeType === 'vertical'
      const horizontalAnalize = analizeType === '' || analizeType === 'horizontal'

      const columns = getColumns(verticalAnalize, horizontalAnalize, true)
      const rows = getRows(verticalAnalize, horizontalAnalize, true)

      setTableData({
        columns: columns,
        rows: rows
      })
    }
    return () => {
      setTableData({ columns: [], rows: [] })
    }
  }, [props.dreReport])

  const doUpdate = () => {
    setFind(true)
    props.buscarDRE(
      getCompaniesValuesByOptions(companies),
      startYear,
      endYear,
      analizeType === '' || analizeType === 'vertical',
      analizeType === '' || analizeType === 'horizontal',
      period,
    )
  }

  const csvDataHeader = () => {
    const verticalAnalize = analizeType === '' || analizeType === 'vertical'
    const horizontalAnalize = analizeType === '' || analizeType === 'horizontal'
    const columns = getColumns(verticalAnalize, horizontalAnalize, false)
    return columns.map(column => {
      return { id: column.field, displayName: column.label }
    });
  };

  const csvData = () => {
    const verticalAnalize = analizeType === '' || analizeType === 'vertical'
    const horizontalAnalize = analizeType === '' || analizeType === 'horizontal'
    const rows = getRows(verticalAnalize, horizontalAnalize, false)
    return Promise.resolve(rows.map(row => {
      return row
    }));
  };

  const printPDF = () => {
    let reportName;
    switch (period) {
      case 'B':
        reportName = 'dre_bimestral';
        break;
      case 'T':
        reportName = 'dre_trimestral';
        break;
      case 'S':
        reportName = 'dre_semestral';
        break;
      case 'A':
        reportName = 'dre_anual';
        break;
      case 'M':
      default:
        reportName = 'dre_mensal';
        break;
    }

    props.relatorioExecutar({
      company: getCompaniesValuesByOptions(companies),
      startYear,
      endYear,
      reportName
    });
  }

  useEffect(() => {
    const tableResponsive = document.getElementsByClassName('table-responsive')
    if(tableResponsive && tableResponsive.length === 1) {
      const tableResponsiveElement = tableResponsive[0]
      tableResponsiveElement.classList.add('table-scroll-bottom')
      const parentElement = tableResponsiveElement.parentElement

      const newTableResponsive = document.createElement("div");
      newTableResponsive.setAttribute('class', 'table-scroll-top table-responsive');
      const element = document.createElement("div")
      element.setAttribute('style', 'height: 1px;')
      newTableResponsive.appendChild(element)
      parentElement.insertBefore(newTableResponsive, parentElement.firstChild)

      tableResponsiveElement.addEventListener('scroll', () => {
        document.getElementsByClassName('table-scroll-top')[0].scrollLeft = document.getElementsByClassName('table-scroll-bottom')[0].scrollLeft
        document.getElementsByClassName('table-scroll-top')[0].children[0].style.width = document.getElementsByClassName('table-scroll-bottom')[0].children[0].clientWidth + 'px'
      })

      setTimeout(() => {
        document.getElementsByClassName('table-scroll-top')[0].scrollLeft = document.getElementsByClassName('table-scroll-bottom')[0].scrollLeft
        document.getElementsByClassName('table-scroll-top')[0].children[0].style.width = document.getElementsByClassName('table-scroll-bottom')[0].children[0].clientWidth + 'px'
      }, 500)

      newTableResponsive.addEventListener('scroll', () => {
        document.getElementsByClassName('table-scroll-bottom')[0].scrollLeft = document.getElementsByClassName('table-scroll-top')[0].scrollLeft
      })
    }
  }, [tableData])

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      { props.executaRelatorioLoading &&
        <div className="loader-container position-absolute">
        <Loader
          type="ThreeDots"
          color="#556EE6"
          height={ 80 }
          width={ 80 }
        />
        </div> }
      { !props.executaRelatorioLoading &&
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="breadcrumb.dre" />

            <Row>
              <Col lg="12">
                <div>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-3">Filtros</h4>

                      <Form>
                        <Row>
                          <div className="col-xl col-12 col-md-2">
                            <FormFilterCompany
                                attribute="companies"
                                value={companies}
                                description="Empresa(s)"
                                setValue={ value => setCompanies(value) }
                                options={companiesOption}
                                multiple={ true }
                                hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                            />
                          </div>

                          <div className="col-xl col-12 col-md-2">
                            <FormGroup className="mt-3 mb-0">
                              <Label>Período</Label>
                              <select className="form-select select2-search-disable" value={ period } onChange={ e => setPeriod(e.target.value) }>
                                <option value="M" defaultValue>Mensal</option>
                                <option value="B">Bimestral</option>
                                <option value="T">Trimestral</option>
                                <option value="S">Semestral</option>
                                <option value="A">Anual</option>
                              </select>
                            </FormGroup>
                          </div>

                          <div className="col-xl col-12 col-md-2">
                              <FormGroup className="mt-3 mb-0">
                                <Label>Ano inicial</Label>
                                <select className="form-select select2-search-disable" value={ startYear } onChange={ e => doSetStartYearPeriod(e.target.value) }>
                                  { startYearPeriod.map((year, key) => (
                                    <option key={ year.value } value={ year.value }>
                                      { year.label }
                                    </option>
                                  )) }
                                </select>
                              </FormGroup>
                          </div>
                          <div className="col-xl col-12 col-md-2">
                            <FormGroup className="mt-3 mb-0">
                              <Label>Ano final</Label>
                              <select className="form-select select2-search-disable" value={ endYear } onChange={ e => setEndYear(e.target.value) }>
                                { endYearPeriod.map((year, key) => (
                                  <option key={ year.value } value={ year.value }>
                                    { year.label }
                                  </option>
                                )) }
                              </select>
                            </FormGroup>
                          </div>

                          <div className="col-xl col-12 col-md-1">
                            <FormGroup className="mt-3 mb-0">
                              <Label>Análise</Label>
                              <select className="form-select select2-search-disable" value={ analizeType } onChange={ e => setAnalizeType(e.target.value) }>
                                <option value="" defaultValue>Todas</option>
                                <option value="vertical">Vertical</option>
                                <option value="horizontal">Horizontal</option>
                              </select>
                            </FormGroup>
                          </div>

                          <div className="col-12 col-md-3 align-self-start mt-4 pt-1 row">
                            <div className="mt-3 col-4">
                              <Button type="button" color="primary"onClick={ e => doUpdate() } >
                                Buscar
                              </Button>
                            </div>
                            { find && !props.dreReportLoading && !props.executaRelatorioLoading &&
                              <div className="mt-3 col-4">
                                <CsvDownloader
                                  filename="dre"
                                  extension=".csv"
                                  separator=";"
                                  wrapColumnChar=""
                                  columns={ csvDataHeader() }
                                  datas={ csvData() }>
                                    <Button type="button" color="light">
                                      <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                      CSV
                                    </Button>
                                </CsvDownloader>
                              </div> }
                            { !props.dreReportLoading && !props.executaRelatorioLoading &&
                              <div className="mt-3 col-4">
                                <Button type="button" color="light" onClick={ e => printPDF() }>
                                  <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                  PDF
                                </Button>
                              </div>
                            }
                          </div>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>

                  { find && <Card>
                    { props.dreReportLoading ? (
                      <div className="loader-container position-absolute">
                        <Loader
                          type="ThreeDots"
                          color="#556EE6"
                          height={ 80 }
                          width={ 80 }
                        />
                      </div>
                    ) : (
                      <CardBody>
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          data={ tableData }
                          entriesOptions={[50, 100, 200, 400]}
                          entriesLabel={ "Mostrar Registros" }
                          infoLabel={ ["Exibindo", "a", "de", "registros"] }
                          noRecordsFoundLabel={ "Nenhum registro encontrado" }
                          paginationLabel={ ["Anterior", "Próximo"] }
                          searchLabel={ "Pesquisar" }
                        />
                      </CardBody>
                    ) }
                  </Card> }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}

Dre.propTypes = {
  relatorioExecutar: PropTypes.func,
  buscarDRE: PropTypes.func,
  limparDRE: PropTypes.func
}

const mapStatetoProps = state => {
  const { dreReport, dreReportLoading, empresas, empresasLoading } = state.Profile
  const { executaRelatorioLoading } = state.Profile
  const { user, currentPeriod, dashboardFilters } = state.Login
  return {
    user,
    currentPeriod,
    dreReport,
    dreReportLoading,
    executaRelatorioLoading, dashboardFilters, empresas, empresasLoading
  }
}

export default connect(mapStatetoProps, {
  buscarDRE,
  relatorioExecutar,
  limparDRE,
  navegarBreadCrumb
})(withRouter(Dre))
