import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { connect } from "react-redux"
import { buscarNcm, buscarNCMCategorias, doEditNCM, ncm } from "store/auth/profile/actions"
import { navegarBreadCrumb } from "store/navigator/actions"
import PropTypes from "prop-types"
import Select from "react-select"
import DatePicker from "react-datepicker"
import Loader from "react-loader-spinner"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import { useLocation } from "react-router-dom"
import ButtonRedirect from "../../components/Common/button-redirect";

const NCM = (props) => {

  const [paramIdCloud, setParamIdCloud] = useState(undefined)
  const [ncmCategoriasArr, setNcmCategoriasArr] = useState()
  const [ncmTitle, setNcmTitle] = useState()
  const [descricao, setDescricao] = useState()
  const [vigenciaInicio, setVigenciaInicio] = useState(new Date())
  const [vigenciaFinal, setVigenciaFinal] = useState(new Date())
  const [uTrib, setUTrib] = useState()
  const [uTribDescricao, setUTribDescricao] = useState()
  const [IPI, setIPI] = useState()
  const [usuarioId, setUsuarioId] = useState()
  const [gtinProducao, setGtinProducao] = useState()
  const [gtinHomologacao, setGtinHomologacao] = useState()
  const [observacao, setObservacao] = useState(EditorState.createEmpty())
  const [dePara, setDePara] = useState()
  const [categoria, setCategoria] = useState()
  const location = useLocation();

  const handleValidSubmit = (e, v) => {
    props.ncm({
      id: props.ncmEdit ? props.ncmEdit.id : null,
      ncmTitle,
      descricao,
      vigencia_inicio: vigenciaInicio,
      vigencia_final: vigenciaFinal ? vigenciaFinal : null,
      uTrib,
      uTribDescricao,
      IPI: IPI ? IPI.replaceAll(',', '.') : IPI,
      usuario_id: usuarioId,
      gtin_producao: gtinProducao ? gtinProducao : null,
      gtin_homologacao: gtinHomologacao ? gtinHomologacao : null,
      observacao: JSON.stringify(convertToRaw(observacao.getCurrentContent())),
      de_para: dePara,
      categoria
    }, props.history)
  }

  const setIPIValue = (value) => {
    value = value.replace(/[^0-9]/g,'');
    if(value.length <= 10) {
      while(value.length < 3) {
        value = value.concat(0)
      }

      value = value.substr(0, value.length - 2) + ',' + value.substr(value.length - 2);
      setIPI(value)
    }
  }

  useEffect(() => {
    setNcmCategoriasArr(Array.isArray(props.ncmCategorias) ? props.ncmCategorias.map( ncmCategoria  => ({ label: ncmCategoria, value: ncmCategoria })): [])
    return () => { setNcmCategoriasArr([])}
  }, [props.ncmCategorias])

  useEffect(() => {
    props.buscarNCMCategorias();
  }, [])

  const getAndSearch = (idCloud)=>{
    props.buscarNcm({id: idCloud}, props.history)
    props.navegarBreadCrumb( {title: `breadcrumb.ncm`, link: location.pathname, id: idCloud})
  }

  useEffect(() => {
    if(paramIdCloud !== undefined) return

    const idCloud = props.match.params.hasOwnProperty('idCloud') ? props.match.params.idCloud : null
    if (idCloud) {
      getAndSearch(idCloud)
    } else {
      populaDados(null)
      props.navegarBreadCrumb({title: `breadcrumb.ncm.new`, link: location.pathname})
    }

    setParamIdCloud(idCloud)
  }, [props.match.params])

  useEffect(() => {
    if(paramIdCloud) {
      populaDados(props.ncmEdit)
    }
  }, [props.ncmEdit])

  const populaDados = (item)=>{
    setNcmTitle(item?.ncm);
    setDescricao(item?.descricao);
    setVigenciaInicio(item?.vigencia_inicio ? new Date(item?.vigencia_inicio) : "");
    setVigenciaFinal(item?.vigencia_final ? new Date(item?.vigencia_final) : "");
    setUTrib(item?.uTrib);
    setUTribDescricao(item?.uTribDescricao);
    setIPI(item?.IPI ? item?.IPI.replaceAll('.', ',') : item?.IPI);
    setUsuarioId(item?.usuario_id);
    setGtinProducao(item?.gtin_producao ? new Date(item?.gtin_producao) : "");
    setGtinHomologacao(item?.gtin_homologacao ? new Date(item?.gtin_homologacao) : "");
    setObservacao(
      item?.observacao ?
        EditorState.createWithContent(convertFromRaw(JSON.parse(item?.observacao))) :
        EditorState.createEmpty()
    );
    setDePara(item?.de_para);
    setCategoria(item?.categoria);
  }

  return  <React.Fragment> {props.ncmLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"breadcrumb.ncm.edit"}/>

          <StickerToolbarButtons>
            <ButtonRedirect path={"tabela-ncm"} history={props.history}></ButtonRedirect>

            { usePermission('tabelaNCMEdit_editar') && props.ncmEdit &&
                <Button type="submit" color="primary" onClick={ e => handleValidSubmit() }
                        disabled={ !ncmTitle || !categoria || !descricao
                            || !vigenciaInicio || !uTrib }>
                  Salvar NCM
                </Button>
           }
            { usePermission('tabelaNCMEdit_inserir') && !props.ncmEdit &&
                <Button type="submit" color="primary" onClick={ e => handleValidSubmit() }
                        disabled={ !ncmTitle || !categoria || !descricao
                            || !vigenciaInicio || !uTrib }>
                  Salvar NCM
                </Button>
            }
          </StickerToolbarButtons>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">NCM - Nomenclatura Comum do Mercosul</CardTitle>
                  <p className="card-title-desc">
                    A Nomenclatura Comum do Mercosul (NCM) é uma Nomenclatura regional para categorização de mercadorias adotada pelo Brasil, Argentina, Paraguai e Uruguai desde 1995, sendo utilizada em todas as operações de comércio exterior dos países do Mercosul.
                    Mais informações clique <a href="https://portalunico.siscomex.gov.br/classif/#/nomenclatura/avancada?perfil=publico" target="_blank" rel="noopener noreferrer">aqui</a>.
                  </p>

                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="ncm" className="col-form-label col-lg-2">
                          NCM
                        </Label>
                        <Col lg="10">
                          <Input
                            id="ncm"
                            name="ncm"
                            type="text"
                            className="form-control"
                            placeholder="Digite o título da ncm..."
                            required
                            maxLength={ 10 }
                            value={ ncmTitle }
                            onChange={ e => setNcmTitle(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label htmlFor="categoria" className="col-form-label col-lg-2">
                          Categoria
                        </label>
                        <div className="col-lg-10">
                          <Select
                            id="categoria"
                            name="categoria"
                            className="basic-single"
                            options={ ncmCategoriasArr }
                            placeholder="Digite a Categoria..."
                            isClearable={ true }
                            isSearchable={ true }
                            noOptionsMessage={ () => "Nenhuma categoria encontrada" }
                            onChange={ e => setCategoria(e?.value) }
                            value={ categoria ? ncmCategoriasArr.find(e => e.value === categoria) : undefined }
                            isDisabled={ !usePermission('tabelaNCMEdit_editar') }
                            required
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="descricao" className="col-form-label col-lg-2">
                          Descrição
                        </Label>
                        <Col lg="10">
                          <Input
                            id="descricao"
                            name="descricao"
                            type="text"
                            className="form-control"
                            placeholder="Digite a descrição da ncm..."
                            required
                            value={ descricao }
                            maxLength={ 1100 }
                            onChange={ e => setDescricao(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="vigenciaInicio" className="col-form-label col-lg-2">
                          Início da vigência
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="vigenciaInicio"
                            selected={ vigenciaInicio }
                            onChange={ date => {
                              setVigenciaInicio(date)
                              setVigenciaFinal(null)
                            } }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            required
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="vigenciaFinal" className="col-form-label col-lg-2">
                          Fim da vigência
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="vigenciaFinal"
                            selected={ vigenciaFinal }
                            onChange={ date => setVigenciaFinal(date) }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            minDate={ vigenciaInicio }
                            dateFormat="dd/MM/yyyy"
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="uTrib" className="col-form-label col-lg-2">
                          Unidade Tributável
                        </Label>
                        <Col lg="10">
                          <Input
                            id="uTrib"
                            name="uTrib"
                            type="text"
                            className="form-control"
                            placeholder="Digite a unidade..."
                            required
                            value={ uTrib }
                            maxLength={ 5 }
                            onChange={ e => setUTrib(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="uTribDescricao" className="col-form-label col-lg-2">
                          Descrição da Unidade
                        </Label>
                        <Col lg="10">
                          <Input
                            id="uTribDescricao"
                            name="uTribDescricao"
                            type="text"
                            className="form-control"
                            placeholder="Digite a descrição da unidade..."
                            value={ uTribDescricao }
                            maxLength={ 50 }
                            onChange={ e => setUTribDescricao(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="ipi" className="col-form-label col-lg-2">
                          IPI
                        </Label>
                        <Col lg="10">
                          <Input
                            id="ipi"
                            name="ipi"
                            type="text"
                            className="form-control"
                            value={ IPI }
                            onChange={ e => setIPIValue(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="gtinProducao" className="col-form-label col-lg-2">
                          GTIN Produção
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="gtinProducao"
                            selected={ gtinProducao }
                            onChange={ date => setGtinProducao(date) }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="gtinHomologacao" className="col-form-label col-lg-2">
                          GTIN Homologação
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="gtinHomologacao"
                            selected={ gtinHomologacao }
                            onChange={ date => setGtinHomologacao(date) }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="observacao" className="col-form-label col-lg-2">
                          Observação
                        </Label>
                        <Col lg="10">
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={ observacao }
                            onEditorStateChange={ e => setObservacao(e) }
                            placeholder="Insira observações sobre o NCM..."
                            readOnly={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="dePara" className="col-form-label col-lg-2">
                          Mudou para
                        </Label>
                        <Col lg="10">
                          <Input
                            id="dePara"
                            name="dePara"
                            type="text"
                            className="form-control"
                            placeholder="Informe a mudança..."
                            value={ dePara }
                            maxLength={ 10 }
                            onChange={ e => setDePara(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
  }
  </React.Fragment>
}

NCM.propTypes = {
  buscarNCMCategorias: PropTypes.func,
  ncm: PropTypes.func,
  ncmPageLoading: PropTypes.bool
}

const mapStatetoProps = state => {
  const { ncmCategorias, ncmPageLoading, ncmEdit, activeNcmEdit } = state.Profile
  const { user } = state.Login
  return { ncmCategorias, user, ncmPageLoading, ncmEdit, activeNcmEdit }
}

export default connect(mapStatetoProps, {
  buscarNCMCategorias,
  buscarNcm,
  ncm, doEditNCM, navegarBreadCrumb
})(NCM)
