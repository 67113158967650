import PropTypes from "prop-types"
import React from "react"
import {FormGroup, Input, Label} from "reactstrap"
import {NumericFormat} from "react-number-format"

const FormFilterText = props => {
    const {attribute, value, description, changeHandeler, minLength, maxLength} = props

    return (
        <React.Fragment>
            <FormGroup className="mt-3 mb-0">
                <Label>{description}</Label>
                <Input
                    id={ attribute + "-text"}
                    name={ attribute + "-text"}
                    type="text"
                    className={ "form-control" }
                    value={ value }
                    onChange={ e => {
                        changeHandeler(e.target.value)
                    } }
                    minLength={ minLength }
                    maxLength={ maxLength }
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormFilterText.propTypes = {
    attribute: PropTypes.string,
    value: PropTypes.any,
    description: PropTypes.string,
    changeHandeler: PropTypes.func,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
}

export default FormFilterText
