import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from "reactstrap"
import { connect } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"

//Date Picker
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import "react-phone-number-input/style.css"

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  buscarGrupoEstoque,
  cadastrarGrupoEstoque,
  cadastrarGrupoEstoqueMantemCadastro,
  editarGrupoEstoque,
  removerGrupoEstoque
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import {getCompanies, isEmailValid} from "helpers/utils"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import toastr from "toastr"
import FormInputNumber from "../../components/Common/form-input-number"
import FormSelectCompany from "../../components/Common/form-select-company"
import FormInputText from "../../components/Common/form-input-text"
import FormInputSwitch from "../../components/Common/form-input-switch"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import ButtonRedirect from "../../components/Common/button-redirect"
import Loader from "react-loader-spinner"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarGrupoEstoque = props => {

  const [paramIdCloud, setParamIdCloud] = useState(undefined)
  const usePermissionEdit = usePermission("gruposEstoque_editar")
  const usePermissionInsert = usePermission("gruposEstoque_inserir")
  const usePermissionRemove = usePermission("gruposEstoque_excluir")
  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

  const [clonado, setClonado] = useState(false)
  const [companies, setCompanies] = useState([{ label: "Todas", value: "" }])
  const [id, setId] = useState()
  const [empresa, setEmpresa] = useState()
  const [codigo, setCodigo] = useState()
  const [descricao, setDescricao] = useState()
  const [subgrupo, setSubgrupo] = useState()
  const location = useLocation()

  const getAndSearch = (idCloud)=>{
    props.buscarGrupoEstoque({ id: idCloud }, props.history)
    props.navegarBreadCrumb({ title: `breadcrumb.stock.group`, link: location.pathname, id: idCloud })
  }

  useEffect(() => {
    if(paramIdCloud !== undefined) return

    const idCloud = props.match.params.hasOwnProperty('idCloud') ? props.match.params.idCloud : null
    if (idCloud) {
      getAndSearch(idCloud)
    } else {
      setId(null)
      setEmpresa(companies[0].value)
      props.navegarBreadCrumb({ title: `breadcrumb.stock.group.new`, link: location.pathname })
    }
    setParamIdCloud(idCloud)
    setClonado(false)
  }, [props.match.params])

  const preencherFormulario = (grupoEstoque) => {
    setId(grupoEstoque?.id)
    setEmpresa(grupoEstoque?.idEmpresa)
    setCodigo(grupoEstoque?.Codigo)
    setSubgrupo(grupoEstoque?.subgrupo)
    setDescricao(grupoEstoque?.descricao)
  }

  useEffect(() => {
    preencherFormulario(props.grupoEstoque)
  }, [props.grupoEstoque])

  useEffect(() => {
    if (props.permanecerCadastro === true) {
      setTimeout(() => {
        preencherFormulario(null)

        setId(null)
        setEmpresa(companies[0].value)

        setClonado(false)
      }, 1000)
    }
  }, [props.permanecerCadastro])

  const excluirGrupoEstoque = () => {

    props.removerGrupoEstoque({ id }, props.history)
  }

  const clonarGrupoEstoque = () => {
    setId(null)
    setCodigo(null)
    setDescricao(`${descricao} (cópia)`)
    setEmpresa(companies[0].value)
    props.grupoEstoque.id = null
    props.grupoEstoque.codigo = null
    props.grupoEstoque.iCodigo = null // caso seja clonado não deverá haver o iCodigo
    setClonado(true)
    window.scrollTo(0, 0)
  }

  const gravarGrupoEstoque = (e, cadastrarOutroGrupoEstoque) => {
    let valid = true
    valid = valid && (empresa ? true : false)
    valid = valid && (codigo ? true : false)
    valid = valid && (descricao && descricao !== "" ? true : false)

    if (valid) {
      salvarGrupoEstoque(cadastrarOutroGrupoEstoque)
    } else {
      const divs = document.getElementsByName("validate-required")
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.display = "block"
      }

      window.scrollTo(0, 0)

      toastr.error("Há campos inválidos no formulário!", "Validação de informações")
      return
    }
  }

  const salvarGrupoEstoque = (cadastrarOutroGrupoEstoque) => {
    const grupoEstoque = props.grupoEstoque ? Object.assign({}, props.grupoEstoque) : {}
    grupoEstoque.idEmpresa = empresa
    grupoEstoque.Codigo = codigo
    grupoEstoque.descricao = descricao
    grupoEstoque.subgrupo = subgrupo ? "S" : "N"

    if (grupoEstoque.id) {
      props.editarGrupoEstoque(grupoEstoque, props.history)
    } else {
      if (cadastrarOutroGrupoEstoque) {
        props.cadastrarGrupoEstoqueMantemCadastro(grupoEstoque, props.history)
      } else {
        props.cadastrarGrupoEstoque(grupoEstoque, props.history)
      }
    }
  }


  useEffect(() => {
    const tmpCompanies = getCompanies(props.user)
    setCompanies(tmpCompanies)
    setEmpresa(tmpCompanies[0].value)

    return () => {
      setCompanies([])
    }
  }, [])

  if (
    !props.user ||
    !possuiVinculo
  ) {
    return (
      <AlertaSemVinculo />
    )
  }

  return (
    <React.Fragment>
      {props.grupoEstoqueLoading ? (<div className="loader-container position-absolute">
          <Loader
            type="ThreeDots"
            color="#556EE6"
            height={80}
            width={80}
          />
        </div>) :
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={props.editingCategory ? "breadcrumb.stock.group.edit" : "breadcrumb.stock.group.new"}
            />
            <StickerToolbarButtons>
              <ButtonRedirect path={"grupos-estoque"} history={props.history}></ButtonRedirect>
              {id && <>
                <Button
                  type="submit"
                  color="primary"
                  className="waves-effect waves-light me-2"
                  disabled={!empresa || !((id && usePermissionEdit) || (!id && usePermissionInsert) || props.grupoEstoque?.iCodigo)
                  }
                  onClick={e => gravarGrupoEstoque(e)}>
                  {id ? "Salvar Grupo de Estoque" : "Cadastrar Grupo de Estoque"}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className="waves-effect waves-light me-2"
                  disabled={!(id && usePermissionInsert)}
                  onClick={e => clonarGrupoEstoque(e)}>
                  {"Clonar Grupo de Estoque"}
                </Button>
                <Button
                  type="submit"
                  color="danger"
                  className="waves-effect waves-light me-2"
                  disabled={!(id && usePermissionRemove) || props.grupoEstoque?.iCodigo}
                  onClick={e => excluirGrupoEstoque(e)}>
                  {"Remover Grupo de Estoque"}
                </Button>
              </>
              }

              {!id && !clonado &&
                <>
                  <Button
                    type="submit"
                    color="primary"
                    className="waves-effect waves-light me-2"
                    disabled={!empresa || !usePermissionInsert}
                    onClick={e => gravarGrupoEstoque(e)}>
                    {"Cadastrar Grupo de Estoque"}
                  </Button>

                  <Button
                    type="submit"
                    color="secondary"
                    className="waves-effect waves-light me-2 btn-secondary"
                    disabled={!empresa || !usePermissionInsert}
                    onClick={e => gravarGrupoEstoque(e, true)}>
                    {"Cadastrar e Preencher novo Grupo de Estoque"}
                  </Button>
                </>
              }

              {clonado && <Button
                type="submit"
                color="primary"
                className="waves-effect waves-light me-2"
                disabled={!empresa || !usePermissionInsert}
                onClick={e => gravarGrupoEstoque(e)}>
                {"Cadastrar Grupo de Estoque"}
              </Button>}

            </StickerToolbarButtons>
            <Row>
              <Col lg="12">
                <div>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="outer">
                          <div className="outer">
                            {id && <FormGroup className="mb-4" row>
                              <Label htmlFor="empresa"
                                     className="col-form-label col-lg-2">
                                Identificador
                              </Label>
                              <Col lg="10" className="col-form-label">
                                {id}
                              </Col>
                            </FormGroup>}

                            {id && <FormGroup className="mb-4" row>
                              <Label htmlFor="codigo" className="col-form-label col-lg-2">
                                Código
                              </Label>
                              <Col lg="10" className="col-form-label">
                                {codigo}
                              </Col>
                            </FormGroup>}

                            {!id && <FormInputNumber
                              attribute="codigo"
                              description="Código"
                              value={codigo}
                              setValue={setCodigo}
                              allowNegative={false}
                              max={9999999}
                              required={true}
                            />}

                            <FormSelectCompany
                              attribute="empresa"
                              value={empresa}
                              setValue={setEmpresa}
                              options={companies.filter(c => c.value)}
                              required={true}
                              disabled={(props.grupoEstoque?.id !== null && props.grupoEstoque?.id !== undefined) || false}
                            />

                            <FormInputText
                              attribute="descricao"
                              description="Descrição"
                              value={descricao}
                              setValue={setDescricao}
                              required={true}
                              maxLength={50}
                            />

                            <FormInputSwitch
                              attribute="subgrupo"
                              description="Subgrupo"
                              value={subgrupo}
                              setValue={setSubgrupo}
                            />
                          </div>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}

CadastrarGrupoEstoque.propTypes = {
  grupoEstoque: PropTypes.any,
  grupoEstoqueAtivo: PropTypes.any,
  grupoEstoqueLoading: PropTypes.bool,
  cadastrarGrupoEstoque: PropTypes.func,
  cadastrarGrupoEstoqueMantemCadastro: PropTypes.func,
  editarGrupoEstoque: PropTypes.func,
  removerGrupoEstoque: PropTypes.func
}

const mapStatetoProps = state => {
  const { grupoEstoqueAtivo, grupoEstoque, grupoEstoqueLoading, permanecerCadastro } = state.Profile
  const { user } = state.Login
  return { user, grupoEstoqueAtivo, grupoEstoque, grupoEstoqueLoading, permanecerCadastro }
}

export default connect(mapStatetoProps, {
  cadastrarGrupoEstoque,
  cadastrarGrupoEstoqueMantemCadastro,
  removerGrupoEstoque,
  editarGrupoEstoque,
  buscarGrupoEstoque,
  navegarBreadCrumb
})(withRouter(CadastrarGrupoEstoque))
