import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Filters } from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

import { buscarSinc } from "../../store/auth/profile/actions"
import Loader from "react-loader-spinner"
import SincronismoDadosTable from "./sincronismo-dados-table"

import FormFilterCompany from "../../components/Common/form-filter-company"
import FormFilterPeriod from "../../components/Common/form-filter-period"

import {
  getCompanies,
  getCompaniesValuesByOptions,
  getCompanyOption,
  getDatasPeloPeriodo,
  getPeriodos
} from "../../helpers/utils"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import moment from "moment/dist/moment"
import "moment/dist/locale/pt-br"
import ptBR from "date-fns/locale/pt-BR"
import { withTranslation } from "react-i18next"

moment.locale("pt-br")
registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const SincronismoDadosLista = props => {

  const ref = useRef()
  const [periods, setPeriods] = useState([])
  const [period, setPeriod] = useState(null)
  const [companies, setCompanies] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([{ label: "Todas", value: "" }])


  const [startDate, setStartDate] = useState(moment().subtract(30, "days").toDate())
  const [endDate, setEndDate] = useState(new Date())

  const [filters, setFilters] = useState(null)
  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
  const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador

  useEffect(() => {
    const { primeiroPeriodo, tmpPeriods } = getPeriodos()
    setPeriod(primeiroPeriodo)
    setPeriods(tmpPeriods)

    if (props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)

    if (possuiVinculo) {
      populateFiltros(primeiroPeriodo, props.dashboardFilters.companiesSelected)
    }

  }, [])


  useEffect(() => {
    if (isAdmin && Array.isArray(props.empresas)) {
      let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa))
      setCompaniesOptions(companiesOptions)
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOptions(companiesOptions)
    }
    return () => {
      setCompaniesOptions([])
    }
  }, [props.user, props.empresas])


  const populateFiltros = (periodoSelecionado, companiesSelected) => {
    const filters = []

    if (companiesSelected) {
      filters.push({ id: "idEmpresa", value: getCompaniesValuesByOptions(companiesSelected) })
    } else if (companies.length > 0) {
      filters.push({ id: "idEmpresa", value: getCompaniesValuesByOptions(companies) })
    }

    let periodo = period || periodoSelecionado
    let datasPeloPeriodo = getDatasPeloPeriodo(periodo, startDate, endDate)
    if (datasPeloPeriodo) {
      filters.push({ id: "UltimaAtualizacao", value: datasPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo })
      filters.push({ id: "UltimaAtualizacao", value: datasPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo })
    }


    setFilters(filters)
  }

  const pesquisar = () => {
    populateFiltros()
    setTimeout(() => ref.current?.search(), 300)
  }

  const onPickStartDate = date => {
    setStartDate(date)
    setEndDate(date)
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
      <AlertaSemVinculo />
    )
  }

  return (
    <React.Fragment>
      {props.sincLoading ? (<div className="loader-container position-absolute">
        <Loader
          type="ThreeDots"
          color="#556EE6"
          height={80}
          width={80}
        />
      </div>) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="menu.label.synchronization" />

            <Row>
              <Col lg="12">
                <div>
                  <Card>
                    <CardBody>
                      <Form>
                        <Row>
                          <div className="col-md-2 col-sm-12">
                            <FormFilterCompany
                              attribute="companies"
                              value={companies}
                              setValue={value => setCompanies(value)}
                              options={companiesOptions}
                              multiple={true}
                              hasSelectAll={ !(isAdmin) }
                            />
                          </div>


                          <div className="col-md-2 col-sm-12 filter-period-col">
                            <FormFilterPeriod
                              periodValue={period || ''}
                              periodChangeHandeler={e => setPeriod(e)}
                              options={periods}
                              startDateValue={startDate}
                              startDateChangeHandeler={e => onPickStartDate(e)}
                              endDateValue={endDate}
                              endDateChangeHandeler={e => setEndDate(e)}
                              t={props.t} />
                          </div>

                          <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                            <div className="mt-3 col col-auto">
                              <Button color="primary" className="w-md"
                                      onClick={e => pesquisar()}>
                                {props.t('label.search')}
                              </Button>
                            </div>
                          </div>

                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                  {filters && <Card>
                    <CardBody>
                      <SincronismoDadosTable ref={ref} filters={filters}
                                             history={props.history}
                                             user={props.user} t={props.t}>
                      </SincronismoDadosTable>
                    </CardBody>
                  </Card>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>)}


    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { empresas } = state.Profile
  const { user, dashboardFilters, sincLoading } = state.Login
  return { user, dashboardFilters, sincLoading, empresas }
}

export default withTranslation()(connect(mapStatetoProps, {
  buscarSinc
})(withRouter(SincronismoDadosLista)))
