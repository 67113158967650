import {
    ALTERAR_PERFIL,
    ALTERAR_PERFIL_RESULT,
    ALTERAR_TEMPLATE_EMAIL,
    ALTERAR_TEMPLATE_EMAIL_RESULT,
    ALTERAR_VINCULOS,
    ALTERAR_VINCULOS_RESULT,
    BUSCAR_CAIXAS_OPERADORES,
    BUSCAR_CODIGOS_BARRAS,
    BUSCAR_CODIGOS_BARRAS_POR_PRODUTO,
    BUSCAR_CODIGOS_BARRAS_POR_PRODUTO_RESULT,
    BUSCAR_CODIGOS_BARRAS_RESULT,
    BUSCAR_CONTAS_PAGAR,
    BUSCAR_CONTAS_PAGAR_ATRASADOS,
    BUSCAR_CONTAS_PAGAR_ATRASADOS_RESULT,
    BUSCAR_CONTAS_PAGAR_HOJE,
    BUSCAR_CONTAS_PAGAR_HOJE_RESULT,
    BUSCAR_CONTAS_PAGAR_MES,
    BUSCAR_CONTAS_PAGAR_MES_RESULT,
    BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES,
    BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES_RESULT,
    BUSCAR_CONTAS_PAGAR_RESULT,
    BUSCAR_DISPOSITIVO_LOGS,
    BUSCAR_DISPOSITIVOS,
    BUSCAR_DRE,
    BUSCAR_DRE_RESULT,
    BUSCAR_EMPRESA,
    BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA,
    BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA_RESULT,
    BUSCAR_EMPRESA_POR_USUARIO,
    BUSCAR_EMPRESA_POR_USUARIO_RESULT,
    BUSCAR_EMPRESA_RESULT,
    BUSCAR_ESTOQUE,
    BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE,
    BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE_RESULT,
    BUSCAR_ESTOQUE_RESULT,
    BUSCAR_ESTOQUES,
    BUSCAR_ESTOQUES_RESULT,
    BUSCAR_EVOLUCAO_CONTAS_PAGAR,
    BUSCAR_EVOLUCAO_CONTAS_PAGAR_RESULT,
    BUSCAR_EVOLUCAO_FISCAL,
    BUSCAR_EVOLUCAO_FISCAL_RESULT,
    BUSCAR_FORNECEDOR,
    BUSCAR_FORNECEDOR_RESULT,
    BUSCAR_FORNECEDORES,
    BUSCAR_FORNECEDORES_NOME_CNPJ,
    BUSCAR_FORNECEDORES_NOME_CNPJ_RESULT,
    BUSCAR_FORNECEDORES_RESULT,
    BUSCAR_GRUPO_CARDAPIO,
    BUSCAR_GRUPO_CARDAPIO_ITEM,
    BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT,
    BUSCAR_GRUPO_CARDAPIO_OBSERVACAO,
    BUSCAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    BUSCAR_GRUPO_CARDAPIO_RESULT,
    BUSCAR_GRUPO_ESTOQUE,
    BUSCAR_GRUPO_ESTOQUE_RESULT,
    BUSCAR_GRUPO_PRODUTO,
    BUSCAR_GRUPO_PRODUTO_RESULT,
    BUSCAR_GRUPOS_CARDAPIO,
    BUSCAR_GRUPOS_CARDAPIO_ITEM,
    BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO,
    BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO_RESULT,
    BUSCAR_GRUPOS_CARDAPIO_ITEM_RESULT,
    BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO,
    BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO,
    BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO_RESULT,
    BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_RESULT,
    BUSCAR_GRUPOS_CARDAPIO_RESULT,
    BUSCAR_GRUPOS_ESTOQUE,
    BUSCAR_GRUPOS_ESTOQUE_RESULT,
    BUSCAR_GRUPOS_PRODUTO,
    BUSCAR_GRUPOS_PRODUTO_POR_NOME,
    BUSCAR_GRUPOS_PRODUTO_POR_NOME_RESULT,
    BUSCAR_GRUPOS_PRODUTO_RESULT,
    BUSCAR_IMAGEM_PRODUTO,
    BUSCAR_IMAGEM_PRODUTO_RESULT,
    BUSCAR_MOVIMENTACAO_CAIXAS,
    BUSCAR_NCM,
    BUSCAR_NCM_RESULT,
    BUSCAR_NCM_CATEGORIAS,
    BUSCAR_NCMS,
    BUSCAR_NCMS_DESCRICAO,
    BUSCAR_NOTA,
    BUSCAR_NOTAS,
    BUSCAR_NOTAS_SITUACOES,
    BUSCAR_NOTIFICACAO,
    BUSCAR_NOTIFICACAO_RESULT,
    BUSCAR_NOTIFICACOES,
    BUSCAR_NOTIFICACOES_RESULT,
    BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA,
    BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA_RESULT,
    BUSCAR_NOTIFICACOES_USUARIO,
    BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS,
    BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS_RESULT,
    BUSCAR_NOTIFICACOES_USUARIO_RESULT,
    BUSCAR_PERFIL,
    BUSCAR_PERFIL_RESULT,
    BUSCAR_PERFIS,
    BUSCAR_PERFIS_RESULT,
    BUSCAR_PERMISSAO_PERFIL,
    BUSCAR_PERMISSAO_PERFIL_RESULT,
    BUSCAR_PLANO_CONTAS,
    BUSCAR_PLANO_CONTAS_RESULT,
    BUSCAR_PRODUTO,
    BUSCAR_PRODUTO_RESULT,
    BUSCAR_PRODUTOS,
    BUSCAR_PRODUTOS_COMPOSICAO,
    BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO,
    BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO_RESULT,
    BUSCAR_PRODUTOS_COMPOSICAO_RESULT,
    BUSCAR_PRODUTOS_REFERENCIA,
    BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO,
    BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO_RESULT,
    BUSCAR_PRODUTOS_REFERENCIA_RESULT,
    BUSCAR_PRODUTOS_RESULT,
    BUSCAR_QUANTIDADE_FORNECEDORES,
    BUSCAR_QUANTIDADE_FORNECEDORES_RESULT,
    BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO,
    BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO_RESULT,
    BUSCAR_QUANTIDADE_GRUPOS_PRODUTO,
    BUSCAR_QUANTIDADE_GRUPOS_PRODUTO_RESULT,
    BUSCAR_QUANTIDADE_PRODUTOS,
    BUSCAR_QUANTIDADE_PRODUTOS_RESULT,
    BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES,
    BUSCAR_SUGESTOES,
    BUSCAR_SUGESTOES_PRODUTOS,
    BUSCAR_SUGESTOES_STATUS,
    BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO,
    BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO_RESULT,
    BUSCAR_TAREFAS_POR_EMPRESA,
    BUSCAR_TAREFAS_POR_EMPRESA_RESULT,
    BUSCAR_TEMPLATES_EMAIL,
    BUSCAR_USUARIOS_POR_EMPRESA,
    BUSCAR_VENDAS_ANO,
    BUSCAR_VENDAS_ANO_ANTERIOR,
    BUSCAR_VENDAS_ANO_ANTERIOR_RESULT,
    BUSCAR_VENDAS_ANO_EVOLUCAO,
    BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO,
    BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO_RESULT,
    BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS,
    BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS_RESULT,
    BUSCAR_VENDAS_ANO_EVOLUCAO_RESULT,
    BUSCAR_VENDAS_ANO_RESULT,
    BUSCAR_VENDAS_DIA,
    BUSCAR_VENDAS_DIA_RESULT,
    BUSCAR_VENDAS_MES,
    BUSCAR_VENDAS_MES_ANTERIOR,
    BUSCAR_VENDAS_MES_ANTERIOR_RESULT,
    BUSCAR_VENDAS_MES_RESULT,
    BUSCAR_VENDAS_SEMANA,
    BUSCAR_VENDAS_SEMANA_RESULT,
    BUSCAR_VINCULOS_POR_USUARIO,
    BUSCAR_VINCULOS_POR_USUARIO_RESULT,
    CADASTRAR_CATEGORIA,
    CADASTRAR_CATEGORIA_RESULT,
    CADASTRAR_LANCAMENTO,
    CADASTRAR_LANCAMENTO_RESULT,
    CRIAR_NOTIFICACAO_RESULT,
    CADASTRAR_PERFIL,
    CADASTRAR_PERFIL_RESULT,
    CADASTRAR_PERGUNTA,
    CADASTRAR_PERGUNTA_RESULT,
    CAIXAS_OPERADORES,
    CREATE_NCM,
    CREATE_PERFIL,
    CREATE_SUGGESTION,
    CRIAR_CODIGO_BARRAS,
    CRIAR_CODIGO_BARRAS_RESULT,
    CRIAR_ESTOQUE,
    CRIAR_ESTOQUE_MANTEM_CADASTRO,
    CRIAR_ESTOQUE_MANTEM_CADASTRO_RESULT,
    CRIAR_ESTOQUE_RESULT,
    CRIAR_FORNECEDOR,
    CRIAR_FORNECEDOR_RESULT,
    CRIAR_GRUPO_CARDAPIO,
    CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO,
    CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO_RESULT,
    CRIAR_GRUPO_CARDAPIO_OBSERVACAO,
    CRIAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    CRIAR_GRUPO_CARDAPIO_RESULT,
    CRIAR_GRUPO_ESTOQUE,
    CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO,
    CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO_RESULT,
    CRIAR_GRUPO_ESTOQUE_RESULT,
    CRIAR_GRUPO_PRODUTO,
    CRIAR_GRUPO_PRODUTO_RESULT,
    CRIAR_NOTIFICACAO,
    CRIAR_PRODUTO,
    CRIAR_PRODUTO_COMPOSICAO,
    CRIAR_PRODUTO_COMPOSICAO_RESULT,
    CRIAR_PRODUTO_MANTEM_CADASTRO,
    CRIAR_PRODUTO_MANTEM_CADASTRO_RESULT,
    CRIAR_PRODUTO_REFERENCIA,
    CRIAR_PRODUTO_REFERENCIA_RESULT,
    CRIAR_PRODUTO_RESULT,
    DISPOSITIVO_LOGS,
    DISPOSITIVOS,
    DO_EDIT_CATEGORY,
    DO_EDIT_QUESTION,
    EDIT_NCM,
    EDIT_PERFIL,
    EDIT_PROFILE,
    EDIT_SUGGESTION,
    EDITAR_CODIGO_BARRAS,
    EDITAR_CODIGO_BARRAS_RESULT,
    EDITAR_EMPRESA,
    EDITAR_EMPRESA_RESULT,
    EDITAR_ESTOQUE,
    EDITAR_ESTOQUE_RESULT,
    EDITAR_FORNECEDOR,
    EDITAR_FORNECEDOR_RESULT,
    EDITAR_GRUPO_CARDAPIO,
    EDITAR_GRUPO_CARDAPIO_OBSERVACAO,
    EDITAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    EDITAR_GRUPO_CARDAPIO_RESULT,
    EDITAR_GRUPO_ESTOQUE,
    EDITAR_GRUPO_ESTOQUE_RESULT,
    EDITAR_GRUPO_PRODUTO,
    EDITAR_GRUPO_PRODUTO_RESULT,
    EDITAR_LANCAMENTO,
    EDITAR_LANCAMENTO_RESULT,
    EDITAR_NOTIFICACAO,
    EDITAR_NOTIFICACAO_RESULT,
    EDITAR_PRODUTO,
    EDITAR_PRODUTO_COMPOSICAO,
    EDITAR_PRODUTO_COMPOSICAO_RESULT,
    EDITAR_PRODUTO_REFERENCIA,
    EDITAR_PRODUTO_REFERENCIA_RESULT,
    EDITAR_PRODUTO_RESULT,
    ESTORNAR_PAGAMENTO_LANCAMENTO,
    ESTORNAR_PAGAMENTO_LANCAMENTO_RESULT,
    EXPORTAR_USUARIO_CSV,
    EXPORTAR_USUARIO_CSV_RESULT,
    LIMPAR_DRE,
    LIMPAR_DRE_RESULT,
    LISTAR_FAQS,
    LISTAR_FAQS_RESULT,
    MARCAR_NOTIFICACAO_USUARIO_LIDA,
    MARCAR_NOTIFICACAO_USUARIO_LIDA_RESULT,
    MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA,
    MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA_RESULT,
    MOVIMENTACAO_CAIXAS,
    NAVEGAR_EDITAR_EMPRESA,
    NAVEGAR_EDITAR_ESTOQUE,
    NAVEGAR_EDITAR_FORNECEDOR,
    NAVEGAR_EDITAR_GRUPO_CARDAPIO,
    NAVEGAR_EDITAR_GRUPO_ESTOQUE,
    NAVEGAR_EDITAR_GRUPO_PRODUTO,
    NAVEGAR_EDITAR_NOTIFICACAO,
    NAVEGAR_EDITAR_PRODUTO,
    NAVEGAR_LISTAR_ESTOQUE,
    NCM_CATEGORIAS,
    NCM_RESULT,
    NCMS,
    NOTA,
    NOTAS,
    NOTAS_SITUACOES,
    NOTIFICACAO_USUARIO_WEBSOCKET,
    NOTIFICACAO_USUARIO_WEBSOCKET_RESULT,
    PAGAR_LANCAMENTO,
    PAGAR_LANCAMENTO_RESULT,
    PERSISTIR_PERMISSOES,
    PERSISTIR_PERMISSOES_RESULT,
    PROFILE_ERROR,
    PROFILE_SUCCESS,
    QUANTIDADE_SOLICITACOES_PENDENTES,
    REJEITAR_VINCULOS,
    REJEITAR_VINCULOS_RESULT,
    RELATORIO_EXECUTAR,
    RELATORIO_EXECUTAR_RESULT,
    REMOVE_NCM,
    REMOVER_CODIGO_BARRAS,
    REMOVER_CODIGO_BARRAS_RESULT,
    REMOVER_ESTOQUE,
    REMOVER_ESTOQUE_RESULT,
    REMOVER_FORNECEDOR,
    REMOVER_FORNECEDOR_RESULT,
    REMOVER_GRUPO_CARDAPIO,
    REMOVER_GRUPO_CARDAPIO_OBSERVACAO,
    REMOVER_GRUPO_CARDAPIO_OBSERVACAO_RESULT,
    REMOVER_GRUPO_CARDAPIO_RESULT,
    REMOVER_GRUPO_ESTOQUE,
    REMOVER_GRUPO_ESTOQUE_RESULT,
    REMOVER_GRUPO_PRODUTO,
    REMOVER_GRUPO_PRODUTO_RESULT,
    REMOVER_LANCAMENTO,
    REMOVER_LANCAMENTO_RESULT,
    REMOVER_NOTIFICACAO,
    REMOVER_NOTIFICACAO_RESULT,
    REMOVER_PERFIL,
    REMOVER_PERFIL_RESULT,
    REMOVER_PRODUTO,
    REMOVER_PRODUTO_COMPOSICAO,
    REMOVER_PRODUTO_COMPOSICAO_RESULT,
    REMOVER_PRODUTO_REFERENCIA,
    REMOVER_PRODUTO_REFERENCIA_RESULT,
    REMOVER_PRODUTO_RESULT,
    SET_USER_PREFERENCE,
    SUGESTAO,
    SUGESTAO_RESULT,
    SUGESTOES,
    SUGESTOES_PRODUTOS,
    SUGESTOES_STATUS,
    TEMPLATES_EMAIL,
    USUARIOS_POR_EMPRESA,
    VIEW_PERFIL,
    VIEW_SUGGESTION,
    EDITAR_EMPRESA_CERTIFICADO_DIGITAL_RESULT,
    EDITAR_EMPRESA_CERTIFICADO_DIGITAL,
    REMOVER_EMPRESA_CERTIFICADO_DIGITAL,
    REMOVER_EMPRESA_CERTIFICADO_DIGITAL_RESULT,
    EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO,
    EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO_RESULT,
    BUSCAR_PERGUNTA,
    BUSCAR_PERGUNTA_RESULT,
    BUSCAR_CATEGORIA_RESULT,
    BUSCAR_CATEGORIA,
    BUSCAR_SUGESTAO_RESULT,
    BUSCAR_SUGESTAO,
    BUSCAR_SINC,
    BUSCAR_SINC_RESULT,
    BUSCAR_TIPOS_EMPRESA,
    BUSCAR_TIPOS_EMPRESA_RESULT,
    BUSCAR_CONFIG_IFOOD_POR_EMPRESA,
    BUSCAR_CONFIG_IFOOD_POR_EMPRESA_RESULT,
    EDITAR_CONFIG_IFOOD,
    EDITAR_CONFIG_IFOOD_RESULT,
    CRIAR_CONFIG_IFOOD,
    CRIAR_CONFIG_IFOOD_RESULT
} from "./actionTypes"

import {API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS} from "../login/actionTypes"
import NCM from "pages/Tabelas/NCM"

const initialState = {
    error: "",
    success: "",
    loading: false,
    faqs: [],
    editingCategory: null,
    categoriesLoading: true,
    editingQuestion: null,
    editingSuggestion: null,
    notasSituacoes: [],
    notasLoading: false,
    notas: [],
    notaLoading: null,
    nota: [],
    sugestoesLoading: false,
    sugestoes: [],
    sugestoesStatusLoading: false,
    sugestoesStatus: [],
    sugestoesProdutosLoading: false,
    sugestoesProdutos: [],
    activeSuggestion: null,
    suggestionMode: null,
    sugestaoLoading: false,
    movimentacaoCaixas: [],
    movimentacaoCaixasLoading: false,
    ncms: [],
    ncmsLoading: false,
    caixasOperadores: [],

    notificationMode: null,
    activeNotification: null,
    notificacaoLoading: false,
    notificacoes: [],
    notificacoesLoading: false,

    usuariosEmpresa: [],
    usuariosEmpresaLoading: false,

    dreReport: [],
    dreReportLoading: false,

    dre: null,
    executaRelatorioLoading: false,

    contasPagar: [],
    contasPagarLoading: false,

    contasPagarHoje: [],
    contasPagarHojeLoading: false,

    contasPagarMes: [],
    contasPagarMesLoading: false,

    contasPagarProximosMeses: [],
    contasPagarProximosMesesLoading: false,

    contasPagarAtrasados: [],
    contasPagarAtrasadosLoading: false,

    evolucaoContaPagar: [],
    evolucaoContaPagarLoading: false,

    planoContas: [],
    planoContasLoading: false,

    fornecedores: [],
    quantidadeFornecedores: {},
    fornecedoresLoading: false,

    empresaCertificadoLoading: false
}

const profile = (state = initialState, action) => {
    console.debug("[Profile][Reducer]", {state, action})

    let pendingNotificacoesUsuario
    let data
    let exists

    switch (action.type) {
        case EDIT_PROFILE:
            state = {...state}
            break
        case PROFILE_SUCCESS:
            state = {...state, success: action.payload}
            break
        case PROFILE_ERROR:
            state = {...state, error: action.payload}
            break
        case ALTERAR_VINCULOS:
            state = {...state, ...{vinculosLoading: true}}
            break
        case ALTERAR_VINCULOS_RESULT:
            state = { ...state, ...{ vinculosLoading: false } }
            break
        case API_ERROR: {
            state.loading = false
            state.loadingLogin = false
            state.usuariosEmpresaLoading = false
            state.notificacoesLoading = false
            state.notificacaoLoading = false
            state.ncmsLoading = false
            state.sugestaoLoading = false
            state.sugestoesProdutosLoading = false
            state.sugestoesStatusLoading = false
            state.sugestoesLoading = false
            state.notaLoading = false
            state.notasLoading = false
            state.notasLoading = false
            break
        }
        case BUSCAR_CATEGORIA: {
            state = {...state, ...{editingCategory: null, categoriesLoading: true}}
            break
        }
        case BUSCAR_CATEGORIA_RESULT:
            state = {
                ...state,
                ...{
                    editingCategory: action.payload.data,
                    categoriesLoading: false
                }
            }
            break
        case CADASTRAR_CATEGORIA: {
            state = {...state, ...{loading: true}}
            break
        }
        case CADASTRAR_CATEGORIA_RESULT: {
            state = {...state, ...{loading: false}}
            break
        }
        case BUSCAR_PERGUNTA: {
            state = {...state, ...{questionLoading: true}}
            break
        }
        case BUSCAR_PERGUNTA_RESULT:
            state = {
                ...state,
                ...{
                    editingQuestion: action.payload.data,
                    questionLoading: false
                }
            }
            break
        case CADASTRAR_PERGUNTA: {
            state = {...state, ...{loading: true}}
            break
        }
        case CADASTRAR_PERGUNTA_RESULT: {
            state = {...state, ...{loading: false}}
            break
        }
        case LISTAR_FAQS: {
            state = {...state, ...{loading: true}}
            break
        }
        case LISTAR_FAQS_RESULT: {
            state = {
                ...state,
                ...{
                    loading: false,
                    faqs: action.payload.data
                }
            }
            break
        }
        case DO_EDIT_CATEGORY:
            state = {
                ...state,
                ...{
                    editingCategory: action.payload.faqCategoria
                }
            }
            break
        case DO_EDIT_QUESTION:
            state = {
                ...state,
                ...{
                    editingQuestion: action.payload.faqPergunta
                }
            }
            break
        case BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES:
            state = {
                ...state,
                ...{
                    quantidadeSolicitacoesPendentes: null
                }
            }
            break
        case QUANTIDADE_SOLICITACOES_PENDENTES:
            state = {
                ...state,
                ...{
                    quantidadeSolicitacoesPendentes: action.payload.data
                }
            }
            break
        case BUSCAR_NOTAS_SITUACOES:
            state = {
                ...state,
                ...{
                    notasSituacoes: []
                }
            }
            break
        case NOTAS_SITUACOES:
            state = {
                ...state,
                ...{
                    notasSituacoes: action.payload.data
                }
            }
            break
        case BUSCAR_CAIXAS_OPERADORES:
            state = {
                ...state,
                ...{
                    caixasOperadores: []
                }
            }
            break
        case CAIXAS_OPERADORES:
            state = {
                ...state,
                ...{
                    caixasOperadores: action.payload.data
                }
            }
            break
        case BUSCAR_NOTAS:
            state = {
                ...state,
                ...{
                    notas: [],
                    notasLoading: true
                }
            }
            break
        case NOTAS:
            state = {
                ...state,
                ...{
                    notas: action.payload.data,
                    notasLoading: false
                }
            }
            break
        case BUSCAR_MOVIMENTACAO_CAIXAS:
            state = {
                ...state,
                ...{
                    movimentacaoCaixas: [],
                    movimentacaoCaixasLoading: true
                }
            }
            break
        case MOVIMENTACAO_CAIXAS:
            state = {
                ...state,
                ...{
                    movimentacaoCaixas: action.payload.data,
                    movimentacaoCaixasLoading: false
                }
            }
            break
        case BUSCAR_NCM:
            state = {
                ...state,
                ...{
                    ncmEdit: null,
                    ncmPageLoading: true
                }
            }
            break
        case BUSCAR_NCM_RESULT:
            state = {
                ...state,
                ...{
                    ncmEdit: action.payload.data,
                    ncmPageLoading: false
                }
            }
            break
        case BUSCAR_NCMS:
            state = {
                ...state,
                ...{
                    ncms: [],
                    ncmsLoading: true
                }
            }
            break
        case BUSCAR_NCMS_DESCRICAO:
            state = {
                ...state,
                ...{
                    ncms: [],
                    ncmsLoading: true
                }
            }
            break
        case NCMS:
            state = {
                ...state,
                ...{
                    ncms: action.payload.data,
                    ncmsLoading: false
                }
            }
            break
        case BUSCAR_NCM_CATEGORIAS:
            state = {
                ...state,
                ...{
                    ncmCategorias: [],
                    ncmCategoriasLoading: true
                }
            }
            break
        case NCM_CATEGORIAS:
            state = {
                ...state,
                ...{
                    ncmCategorias: action.payload.data,
                    ncmCategoriasLoading: false
                }
            }
            break
        case EDIT_NCM:
            state = {
                ...state,
                ...{
                    activeNcm: action.payload.data
                }
            }
            break
        case CREATE_NCM:
            state = {
                ...state,
                ...{
                    activeNcm: {}
                }
            }
            break
        case REMOVE_NCM:
            state = {
                ...state,
                ...{
                    activeNcm: null
                }
            }
            break
        case NCM:
            state = {
                ...state,
                ...{
                    ncmLoading: true
                }
            }
            break
        case NCM_RESULT:
            state = {
                ...state,
                ...{
                    ncmLoading: false
                }
            }
            break
        case BUSCAR_NOTA:
            state = {
                ...state,
                ...{
                    nota: [],
                    notaLoading: true
                }
            }
            break
        case NOTA:
            state = {
                ...state,
                ...{
                    nota: action.payload.data,
                    notaLoading: false
                }
            }
            break
        case SUGESTOES:
            state = {
                ...state,
                ...{
                    sugestoes: action.payload.data,
                    sugestoesLoading: false
                }
            }
            break
        case BUSCAR_SUGESTOES:
            state = {
                ...state,
                ...{
                    sugestoes: [],
                    sugestoesLoading: true
                }
            }
            break
        case SUGESTOES_STATUS:
            state = {
                ...state,
                ...{
                    sugestoesStatus: action.payload.data,
                    sugestoesStatusLoading: false
                }
            }
            break
        case BUSCAR_SUGESTOES_STATUS:
            state = {
                ...state,
                ...{
                    sugestoesStatus: [],
                    sugestoesStatusLoading: true
                }
            }
            break
        case BUSCAR_SUGESTOES_PRODUTOS:
            state = {
                ...state,
                ...{
                    sugestoesProdutos: [],
                    sugestoesProdutosLoading: true
                }
            }
            break
        case SUGESTOES_PRODUTOS:
            state = {
                ...state,
                ...{
                    sugestoesProdutos: action.payload.data,
                    sugestoesProdutosLoading: false
                }
            }
            break
        case VIEW_SUGGESTION:
            state = {
                ...state,
                ...{
                    activeSuggestion: action.payload.suggestion,
                    suggestionMode: "view"
                }
            }
            break
        case EDIT_SUGGESTION:
            state = {
                ...state,
                ...{
                    activeSuggestion: action.payload.suggestion,
                    suggestionMode: "edit"
                }
            }
            break
        case CREATE_SUGGESTION:
            state = {
                ...state,
                ...{
                    activeSuggestion: null,
                    suggestionMode: ""
                }
            }
            break
        case BUSCAR_SUGESTAO: {
            state = {...state, ...{sugestaoLoading: true}}
            break
        }
        case BUSCAR_SUGESTAO_RESULT:
            state = {
                ...state,
                ...{
                    editingSuggestion: action.payload.data,
                    sugestaoLoading: false
                }
            }
            break
        case SUGESTAO:
            state = {
                ...state,
                ...{
                    sugestaoLoading: true
                }
            }
            break
        case SUGESTAO_RESULT:
            state = {
                ...state,
                ...{
                    sugestaoLoading: false
                }
            }
            break
        case SET_USER_PREFERENCE:
            const tmp = {}
            tmp[action.payload.key] = action.payload.value

            state = {
                ...state,
                ...{
                    preferences: {...state.preferences, ...tmp}
                }
            }
            break
        case BUSCAR_NOTIFICACOES:
            state = {
                ...state,
                ...{
                    notificacoes: [],
                    notificacoesLoading: true
                }
            }
            break
        case BUSCAR_NOTIFICACOES_RESULT:
            state = {
                ...state,
                ...{
                    notificacoes: action.payload.data,
                    notificacoesLoading: false
                }
            }
            break
        case BUSCAR_NOTIFICACAO:
            state = {
                ...state,
                ...{
                    notificacao: null,
                    notificacaoLoading: true
                }
            }
            break
        case BUSCAR_NOTIFICACAO_RESULT:
            state = {
                ...state,
                ...{
                    notificacao: action.payload.data,
                    notificacaoLoading: false
                }
            }
            break
        case CRIAR_NOTIFICACAO:
            state = {
                ...state,
                ...{
                    notificacaoLoading: true,
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_NOTIFICACAO_RESULT:
            state = {
                ...state,
                ...{
                    notificacaoLoading: false,
                    permanecerCadastro: false
                }
            }
            break
        case EDITAR_NOTIFICACAO:
            state = {
                ...state,
                ...{
                    notificacaoLoading: true
                }
            }
            break
        case EDITAR_NOTIFICACAO_RESULT:
            state = {
                ...state,
                ...{
                    notificacaoLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_NOTIFICACAO:
            state = {
                ...state,
                ...{
                    notificacaoAtivo: action.payload.data,
                    permanecerCadastro: null
                }
            }

            state.notificacao = null
            break
        case REMOVER_NOTIFICACAO:
            state = {
                ...state,
                ...{
                    notificacaoRemovido: false,
                    notificacaoLoading: true
                }
            }
            break
        case REMOVER_NOTIFICACAO_RESULT:
            state = {
                ...state,
                ...{
                    notificacaoRemovido: true,
                    notificacaoLoading: false
                }
            }
            break
        case BUSCAR_USUARIOS_POR_EMPRESA:
            state = {
                ...state,
                ...{
                    usuariosEmpresa: [],
                    usuariosEmpresaLoading: true
                }
            }
            break
        case USUARIOS_POR_EMPRESA:
            state = {
                ...state,
                ...{
                    usuariosEmpresa: action.payload.data,
                    usuariosEmpresaLoading: false
                }
            }
            break
        case BUSCAR_NOTIFICACOES_USUARIO:
            state = {
                ...state,
                ...{
                    notificacoesUsuario: [],
                    notificacoesUsuarioLoading: true
                }
            }
            break
        case BUSCAR_NOTIFICACOES_USUARIO_RESULT:
            state = {
                ...state,
                ...{
                    notificacoesUsuario: action.payload.data,
                    notificacoesUsuarioLoading: false
                }
            }
            break
        case BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS:
            state = {
                ...state,
                ...{
                    notificacoesUsuarioNaoLidas: [],
                    notificacoesUsuarioLoading: true,
                    pendingNotificacoesUsuario: []
                }
            }
            break
        case BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS_RESULT:
            state = {
                ...state,
                ...{
                    notificacoesUsuarioNaoLidas: Array.isArray(action.payload.data)
                        ? action.payload.data
                        : [],
                    notificacoesUsuarioLoading: false,
                    pendingNotificacoesUsuario: Array.isArray(action.payload.data)
                        ? action.payload.data.sort((one, other) => other.id - one.id)
                        : []
                }
            }
            break
        case MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA:
            state = {
                ...state,
                ...{
                    notificacoesUsuarioAlteracaoLoading: true
                }
            }
            break
        case MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA_RESULT:
            pendingNotificacoesUsuario = Array.isArray(
                state.pendingNotificacoesUsuario
            )
                ? state.pendingNotificacoesUsuario
                : []
            if (action.payload.data.data) {
                data = action.payload.data.data
                exists = pendingNotificacoesUsuario.some(
                    notificacoes => notificacoes.id === data.id
                )
                if (!exists) {
                    pendingNotificacoesUsuario.push(data)
                    pendingNotificacoesUsuario = pendingNotificacoesUsuario.sort(
                        (one, other) => other.id - one.id
                    )
                }
            }

            state = {
                ...state,
                ...{
                    notificacoesUsuarioAlteracaoLoading: false,
                    pendingNotificacoesUsuario: pendingNotificacoesUsuario
                }
            }
            break
        case MARCAR_NOTIFICACAO_USUARIO_LIDA:
            state = {
                ...state,
                ...{
                    notificacoesUsuarioAlteracaoLoading: true
                }
            }
            break
        case MARCAR_NOTIFICACAO_USUARIO_LIDA_RESULT:
            pendingNotificacoesUsuario = Array.isArray(
                state.pendingNotificacoesUsuario
            )
                ? state.pendingNotificacoesUsuario
                : []
            if (action.payload.data.data) {
                data = action.payload.data.data
                pendingNotificacoesUsuario = pendingNotificacoesUsuario.filter(
                    notificacoes => notificacoes.id != data.id
                )
            }

            state = {
                ...state,
                ...{
                    notificacoesUsuarioAlteracaoLoading: false,
                    pendingNotificacoesUsuario: pendingNotificacoesUsuario
                }
            }
            break

        case NOTIFICACAO_USUARIO_WEBSOCKET:
            state = {
                ...state,
                ...{
                    loadingValueSocket: true
                }
            }
            break
        case NOTIFICACAO_USUARIO_WEBSOCKET_RESULT:
            pendingNotificacoesUsuario = Array.isArray(
                state.pendingNotificacoesUsuario
            )
                ? state.pendingNotificacoesUsuario
                : []
            if (action.payload.data) {
                data = action.payload.data
                exists = pendingNotificacoesUsuario.some(
                    notificacoes => notificacoes.id === data.id
                )
                if (!exists) {
                    pendingNotificacoesUsuario.push(data)
                    pendingNotificacoesUsuario = pendingNotificacoesUsuario.sort(
                        (one, other) => other.id - one.id
                    )
                }
            }

            state = {
                ...state,
                ...{
                    loadingValueSocket: false,
                    pendingNotificacoesUsuario: pendingNotificacoesUsuario
                }
            }
            break
        case BUSCAR_DISPOSITIVOS:
            state = {
                ...state,
                ...{
                    dispositivos: [],
                    dispositivosLoading: true
                }
            }
            break
        case DISPOSITIVOS:
            state = {
                ...state,
                ...{
                    dispositivos: action.payload.data,
                    dispositivosLoading: false
                }
            }
            break
        case BUSCAR_DISPOSITIVO_LOGS:
            state = {
                ...state,
                ...{
                    dispositivoLogs: [],
                    dispositivoLogsLoading: true
                }
            }
            break
        case DISPOSITIVO_LOGS:
            state = {
                ...state,
                ...{
                    dispositivoLogs: action.payload.data,
                    dispositivoLogsLoading: false
                }
            }
            break
        case BUSCAR_TEMPLATES_EMAIL:
            state = {
                ...state,
                ...{
                    templatesEmail: [],
                    templatesEmailLoading: true
                }
            }
            break
        case TEMPLATES_EMAIL:
            state = {
                ...state,
                ...{
                    templatesEmail: action.payload.data,
                    templatesEmailLoading: false
                }
            }
            break
        case ALTERAR_TEMPLATE_EMAIL:
            state = {
                ...state,
                ...{
                    templateEmail: action.payload.data,
                    loading: true
                }
            }
            break
        case ALTERAR_TEMPLATE_EMAIL_RESULT:
            state = {
                ...state,
                ...{loading: false}
            }
            break
        case BUSCAR_DRE:
            state = {
                ...state,
                ...{
                    dreReport: [],
                    dreReportLoading: true
                }
            }
            break
        case BUSCAR_DRE_RESULT:
            state = {
                ...state,
                ...{
                    dreReport: action.payload.data,
                    dreReportLoading: false
                }
            }
            break
        case LIMPAR_DRE_RESULT:
            state = {
                ...state,
                ...{
                    dreReport: [],
                    dreReportLoading: false
                }
            }
            break
        case LIMPAR_DRE:
            state = {
                ...state,
                ...{
                    dreReport: [],
                    dreReportLoading: true
                }
            }
            break
        case RELATORIO_EXECUTAR:
            state = {
                ...state,
                ...{
                    url: null,
                    executaRelatorioLoading: true
                }
            }
            break
        case RELATORIO_EXECUTAR_RESULT:
            state = {
                ...state,
                ...{
                    url: action.payload.data.url,
                    executaRelatorioLoading: false
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR:
            state = {
                ...state,
                ...{
                    contasPagar: [],
                    contasPagarLoading: true
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_RESULT:
            state = {
                ...state,
                ...{
                    contasPagar: action.payload.data,
                    contasPagarLoading: false
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_HOJE:
            state = {
                ...state,
                ...{
                    contasPagarHoje: [],
                    contasPagarHojeLoading: true
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_HOJE_RESULT:
            state = {
                ...state,
                ...{
                    contasPagarHoje: action.payload.data,
                    contasPagarHojeLoading: false
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_MES:
            state = {
                ...state,
                ...{
                    contasPagarMes: [],
                    contasPagarMesLoading: true
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_MES_RESULT:
            state = {
                ...state,
                ...{
                    contasPagarMes: action.payload.data,
                    contasPagarMesLoading: false
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES:
            state = {
                ...state,
                ...{
                    contasPagarProximosMeses: [],
                    contasPagarProximosMesesLoading: true
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES_RESULT:
            state = {
                ...state,
                ...{
                    contasPagarProximosMeses: action.payload.data,
                    contasPagarProximosMesesLoading: false
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_ATRASADOS:
            state = {
                ...state,
                ...{
                    contasPagarAtrasados: [],
                    contasPagarAtrasadosLoading: true
                }
            }
            break
        case BUSCAR_CONTAS_PAGAR_ATRASADOS_RESULT:
            state = {
                ...state,
                ...{
                    contasPagarAtrasados: action.payload.data,
                    contasPagarAtrasadosLoading: false
                }
            }
            break
        case BUSCAR_EVOLUCAO_CONTAS_PAGAR:
            state = {
                ...state,
                ...{
                    evolucaoContaPagar: [],
                    evolucaoContaPagarLoading: true
                }
            }
            break
        case BUSCAR_EVOLUCAO_CONTAS_PAGAR_RESULT:
            state = {
                ...state,
                ...{
                    evolucaoContaPagar: action.payload.data,
                    evolucaoContaPagarLoading: false
                }
            }
            break
        case BUSCAR_PLANO_CONTAS:
            state = {
                ...state,
                ...{
                    planoContas: [],
                    planoContasLoading: true
                }
            }
            break
        case BUSCAR_PLANO_CONTAS_RESULT:
            state = {
                ...state,
                ...{
                    planoContas: action.payload.data,
                    planoContasLoading: false
                }
            }
            break
        case BUSCAR_FORNECEDORES_NOME_CNPJ:
            state = {
                ...state,
                ...{
                    fornecedores: [],
                    fornecedoresLoading: true
                }
            }
            break
        case BUSCAR_FORNECEDORES_NOME_CNPJ_RESULT:
            state = {
                ...state,
                ...{
                    fornecedores: action.payload.data,
                    fornecedoresLoading: false
                }
            }
            break
        case BUSCAR_FORNECEDORES:
            state = {
                ...state,
                ...{
                    fornecedores: [],
                    fornecedoresLoading: true
                }
            }
            break
        case BUSCAR_FORNECEDORES_RESULT:
            state = {
                ...state,
                ...{
                    fornecedores: action.payload.data,
                    fornecedoresLoading: false
                }
            }
            break
        case BUSCAR_FORNECEDOR:
            state = {
                ...state,
                ...{
                    fornecedor: null,
                    fornecedorLoading: true
                }
            }
            break
        case BUSCAR_FORNECEDOR_RESULT:
            state = {
                ...state,
                ...{
                    fornecedor: action.payload.data,
                    fornecedorLoading: false
                }
            }
            break
        case BUSCAR_QUANTIDADE_FORNECEDORES:
            state = {
                ...state,
                ...{
                    quantidadeFornecedores: {},
                    fornecedoresLoading: true
                }
            }
            break
        case BUSCAR_QUANTIDADE_FORNECEDORES_RESULT:
            state = {
                ...state,
                ...{
                    quantidadeFornecedores: action.payload.data,
                    fornecedoresLoading: false
                }
            }
            break
        case CRIAR_FORNECEDOR:
            state = {
                ...state,
                ...{
                    fornecedorLoading: true
                }
            }
            break
        case CRIAR_FORNECEDOR_RESULT:
            state = {
                ...state,
                ...{
                    fornecedorLoading: false
                }
            }
            break
        case EDITAR_FORNECEDOR:
            state = {
                ...state,
                ...{
                    fornecedorLoading: true
                }
            }
            break
        case EDITAR_FORNECEDOR_RESULT:
            state = {
                ...state,
                ...{
                    fornecedorLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_FORNECEDOR:
            state = {
                ...state,
                ...{
                    fornecedorAtivo: action.payload.data
                }
            }

            state.fornecedor = null
            break
        case REMOVER_FORNECEDOR:
            state = {
                ...state,
                ...{
                    fornecedorLoading: true
                }
            }
            break
        case REMOVER_FORNECEDOR_RESULT:
            state = {
                ...state,
                ...{
                    fornecedorLoading: false
                }
            }
            break
        case CADASTRAR_LANCAMENTO:
            state = {...state, ...{loading: true}}
            break
        case CADASTRAR_LANCAMENTO_RESULT:
            state = {...state, ...{loading: false}}
            break
        case EDITAR_LANCAMENTO:
            state = {...state, ...{loading: true}}
            break
        case EDITAR_LANCAMENTO_RESULT:
            state = {...state, ...{loading: false}}
            break
        case REMOVER_LANCAMENTO:
            state = {...state, ...{loading: true}}
            break
        case REMOVER_LANCAMENTO_RESULT:
            state = {...state, ...{loading: false}}
            break
        case PAGAR_LANCAMENTO:
            state = {...state, ...{loading: true}}
            break
        case PAGAR_LANCAMENTO_RESULT:
            state = {...state, ...{loading: false}}
            break
        case ESTORNAR_PAGAMENTO_LANCAMENTO:
            state = {...state, ...{loading: true}}
            break
        case ESTORNAR_PAGAMENTO_LANCAMENTO_RESULT:
            state = {...state, ...{loading: false}}
            break
        case BUSCAR_PERFIS:
            state = {...state, loading: true, perfis: []}
            break

        case BUSCAR_PERFIS_RESULT:
            state = {
                ...state,
                loading: false,
                perfis: Array.isArray(action.payload.data)
                    ? action.payload.data
                    : []
            }
            break
        case BUSCAR_PERFIL:
            state = {...state, loading: true, perfil: undefined}
            break
        case BUSCAR_PERFIL_RESULT:
            state = {
                ...state,
                loading: false,
                perfil: action.payload.data
            }
            break
        case VIEW_PERFIL:
            state = {
                ...state,
                ...{
                    activePerfil: action.payload.data,
                    perfilMode: "view",
                    perfil: null,
                    permissoes: [],
                    loading: true
                }
            }
            break
        case EDIT_PERFIL:
            state = {
                ...state,
                ...{
                    activePerfil: action.payload.data,
                    perfilMode: "edit",
                    perfil: null,
                    permissoes: [],
                    loading: true
                }
            }
            break
        case CREATE_PERFIL:
            state = {
                ...state,
                ...{
                    activePerfil: null,
                    perfilMode: "",
                    perfil: null,
                    permissoes: [],
                    loading: true
                }
            }
            break
        case CADASTRAR_PERFIL:
            state = {...state, ...{loading: true}}
            break
        case CADASTRAR_PERFIL_RESULT:
            state = {...state, ...{loading: false}}
            break
        case ALTERAR_PERFIL:
            state = {...state, ...{loading: true}}
            break
        case ALTERAR_PERFIL_RESULT:
            state = {...state, ...{loading: false}}
            break
        case REMOVER_PERFIL:
            state = {...state, ...{loading: true}}
            break
        case REMOVER_PERFIL_RESULT:
            state = {...state, ...{loading: false}}
            break
        case BUSCAR_PERMISSAO_PERFIL:
            state = {...state, ...{permissoes: [], loading: true}}
            break
        case BUSCAR_PERMISSAO_PERFIL_RESULT:
            state = {
                ...state, ...{
                    permissoes: Array.isArray(action.payload.data) ? action.payload.data : [],
                    loading: false
                }
            }
            break
        case PERSISTIR_PERMISSOES:
            state = {...state, ...{loading: true}}
            break
        case PERSISTIR_PERMISSOES_RESULT:
            state = {...state, ...{loading: false}}
            break
        case BUSCAR_GRUPOS_PRODUTO:
            state = {
                ...state,
                ...{
                    gruposProduto: [],
                    gruposProdutoLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    gruposProduto: action.payload.data,
                    gruposProdutoLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_PRODUTO_POR_NOME:
            state = {
                ...state,
                ...{
                    gruposProduto: [],
                    gruposProdutoLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_PRODUTO_POR_NOME_RESULT:
            state = {
                ...state,
                ...{
                    gruposProduto: action.payload.data,
                    gruposProdutoLoading: false
                }
            }
            break
        case BUSCAR_GRUPO_PRODUTO:
            state = {
                ...state,
                ...{
                    grupoProduto: null,
                    grupoProdutoLoading: true
                }
            }
            break
        case BUSCAR_GRUPO_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    grupoProduto: action.payload.data,
                    grupoProdutoLoading: false
                }
            }
            break
        case BUSCAR_QUANTIDADE_GRUPOS_PRODUTO:
            state = {
                ...state,
                ...{
                    quantidadeGruposProduto: {},
                    gruposProdutoLoading: true
                }
            }
            break
        case BUSCAR_QUANTIDADE_GRUPOS_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    quantidadeGruposProduto: action.payload.data,
                    gruposProdutoLoading: false
                }
            }
            break
        case CRIAR_GRUPO_PRODUTO:
            state = {
                ...state,
                ...{
                    grupoProdutoLoading: true
                }
            }
            break
        case CRIAR_GRUPO_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    grupoProdutoLoading: false
                }
            }
            break
        case EDITAR_GRUPO_PRODUTO:
            state = {
                ...state,
                ...{
                    grupoProdutoLoading: true
                }
            }
            break
        case EDITAR_GRUPO_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    grupoProdutoLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_GRUPO_PRODUTO:
            state = {
                ...state,
                ...{
                    grupoProdutoAtivo: action.payload.data
                }
            }

            state.grupoProduto = null
            break
        case REMOVER_GRUPO_PRODUTO:
            state = {
                ...state,
                ...{
                    grupoProdutoLoading: true
                }
            }
            break
        case REMOVER_GRUPO_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    grupoProdutoLoading: false
                }
            }
            break
        case BUSCAR_PRODUTOS:
            state = {
                ...state,
                ...{
                    produtos: [],
                    produtosLoading: true
                }
            }
            break
        case BUSCAR_PRODUTOS_RESULT:
            state = {
                ...state,
                ...{
                    produtos: action.payload.data,
                    produtosLoading: false
                }
            }
            break
        case BUSCAR_PRODUTO:
            state = {
                ...state,
                ...{
                    produto: null,
                    produtoLoading: true
                }
            }
            break
        case BUSCAR_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    produto: action.payload.data,
                    produtoLoading: false
                }
            }
            break
        case BUSCAR_QUANTIDADE_PRODUTOS:
            state = {
                ...state,
                ...{
                    quantidadeProdutos: {},
                }
            }
            break
        case BUSCAR_QUANTIDADE_PRODUTOS_RESULT:
            state = {
                ...state,
                ...{
                    quantidadeProdutos: action.payload.data,
                }
            }
            break
        case CRIAR_PRODUTO:
            state = {
                ...state,
                ...{
                    produtoLoading: true,
                }
            }
            break
        case CRIAR_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    produtoLoading: false,
                }
            }
            break
        case CRIAR_PRODUTO_MANTEM_CADASTRO:
            state = {
                ...state,
                ...{
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_PRODUTO_MANTEM_CADASTRO_RESULT:
            state = {
                ...state,
                ...{
                    permanecerCadastro: action.payload.data
                }
            }
            break
        case EDITAR_PRODUTO:
            state = {
                ...state,
                ...{
                    produtoLoading: true
                }
            }
            break
        case EDITAR_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    produtoLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_PRODUTO:
            state = {
                ...state,
                ...{
                    produtoAtivo: action.payload.data,
                    permanecerCadastro: null
                }
            }

            state.produto = null
            break
        case REMOVER_PRODUTO:
            state = {
                ...state,
                ...{
                    produtoLoading: true
                }
            }
            break
        case REMOVER_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    produtoLoading: false
                }
            }
            break
        case BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO:
            state = {
                ...state,
                ...{
                    tabelasUnidade: [],
                    tabelasUnidadeLoading: true
                }
            }
            break
        case BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO_RESULT:
            state = {
                ...state,
                ...{
                    tabelasUnidade: action.payload.data,
                    tabelasUnidadeLoading: false
                }
            }
            break
        case BUSCAR_CODIGOS_BARRAS_POR_PRODUTO:
            state = {
                ...state,
                ...{
                    codigosBarras: null,
                    codigosBarrasLoading: true
                }
            }
            break
        case BUSCAR_CODIGOS_BARRAS_POR_PRODUTO_RESULT:
            let codigosBarras = []
            if (Array.isArray(action.payload.data)) {
                codigosBarras = [...action.payload.data]
                codigosBarras.sort((um, outro) => um.inc_data <= outro.inc_data ? (um.inc_data < outro.inc_data ? -1 : 0) : 1)
            }

            state = {
                ...state,
                ...{
                    codigosBarras: codigosBarras,
                    codigosBarrasLoading: false
                }
            }
            break
        case BUSCAR_CODIGOS_BARRAS:
            state = {
                ...state,
                ...{
                    codigosBarras: null,
                    codigosBarrasLoading: true
                }
            }
            break
        case BUSCAR_CODIGOS_BARRAS_RESULT:
            state = {
                ...state,
                ...{
                    codigosBarras: action.payload.data,
                    codigosBarrasLoading: false
                }
            }
            break
        case CRIAR_CODIGO_BARRAS:
            state = {
                ...state,
                ...{
                    novoCodigoBarras: null,
                    codigoBarrasLoading: true
                }
            }
            break
        case CRIAR_CODIGO_BARRAS_RESULT:
            state = {
                ...state,
                ...{
                    novoCodigoBarras: action?.payload?.data,
                    codigoBarrasLoading: false
                }
            }
            break
        case EDITAR_CODIGO_BARRAS:
            state = {
                ...state,
                ...{
                    codigoBarrasLoading: true
                }
            }
            break
        case EDITAR_CODIGO_BARRAS_RESULT:
            state = {
                ...state,
                ...{
                    codigoBarrasLoading: false
                }
            }
            break
        case REMOVER_CODIGO_BARRAS:
            state = {
                ...state,
                ...{
                    codigoBarrasRemovido: false,
                    codigoBarrasLoading: true
                }
            }
            break
        case REMOVER_CODIGO_BARRAS_RESULT:
            state = {
                ...state,
                ...{
                    codigoBarrasRemovido: true,
                    codigoBarrasLoading: false
                }
            }
            break
        case BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO:
            state = {
                ...state,
                ...{
                    produtosReferencia: null,
                    produtosReferenciaLoading: true
                }
            }
            break
        case BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO_RESULT:
            let produtosReferencia = []
            if (Array.isArray(action.payload.data)) {
                produtosReferencia = [...action.payload.data]
                produtosReferencia.sort((um, outro) => um.referencia.localeCompare(outro.referencia))
            }

            state = {
                ...state,
                ...{
                    produtosReferencia: produtosReferencia,
                    produtosReferenciaLoading: false
                }
            }
            break
        case BUSCAR_PRODUTOS_REFERENCIA:
            state = {
                ...state,
                ...{
                    produtosReferencia: null,
                    produtosReferenciaLoading: true
                }
            }
            break
        case BUSCAR_PRODUTOS_REFERENCIA_RESULT:
            state = {
                ...state,
                ...{
                    produtosReferencia: action.payload.data,
                    produtosReferenciaLoading: false
                }
            }
            break
        case CRIAR_PRODUTO_REFERENCIA:
            state = {
                ...state,
                ...{
                    novoProdutoReferencia: null,
                    produtoReferenciaLoading: true
                }
            }
            break
        case CRIAR_PRODUTO_REFERENCIA_RESULT:
            state = {
                ...state,
                ...{
                    novoProdutoReferencia: action?.payload?.data,
                    produtoReferenciaLoading: false
                }
            }
            break
        case EDITAR_PRODUTO_REFERENCIA:
            state = {
                ...state,
                ...{
                    produtoReferenciaLoading: true
                }
            }
            break
        case EDITAR_PRODUTO_REFERENCIA_RESULT:
            state = {
                ...state,
                ...{
                    produtoReferenciaLoading: false
                }
            }
            break
        case REMOVER_PRODUTO_REFERENCIA:
            state = {
                ...state,
                ...{
                    produtoReferenciaRemovido: false,
                    produtoReferenciaLoading: true
                }
            }
            break
        case REMOVER_PRODUTO_REFERENCIA_RESULT:
            state = {
                ...state,
                ...{
                    produtoReferenciaRemovido: true,
                    produtoReferenciaLoading: false
                }
            }
            break
        case BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO:
            state = {
                ...state,
                ...{
                    produtosComposicao: null,
                    produtosComposicaoLoading: true
                }
            }
            break
        case BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO_RESULT:
            let produtosComposicao = []
            if (Array.isArray(action.payload.data)) {
                produtosComposicao = [...action.payload.data]
                produtosComposicao.sort((um, outro) => um.produto.Descricao.localeCompare(outro.produto.Descricao))
            }

            state = {
                ...state,
                ...{
                    produtosComposicao: produtosComposicao,
                    produtosComposicaoLoading: false
                }
            }
            break
        case BUSCAR_PRODUTOS_COMPOSICAO:
            state = {
                ...state,
                ...{
                    produtosComposicao: null,
                    produtosComposicaoLoading: true
                }
            }
            break
        case BUSCAR_PRODUTOS_COMPOSICAO_RESULT:
            state = {
                ...state,
                ...{
                    produtosComposicao: action.payload.data,
                    produtosComposicaoLoading: false
                }
            }
            break
        case CRIAR_PRODUTO_COMPOSICAO:
            state = {
                ...state,
                ...{
                    novoProdutoComposicao: null,
                    produtoComposicaoLoading: true
                }
            }
            break
        case CRIAR_PRODUTO_COMPOSICAO_RESULT:
            state = {
                ...state,
                ...{
                    novoProdutoComposicao: action?.payload?.data,
                    produtoComposicaoLoading: false
                }
            }
            break
        case EDITAR_PRODUTO_COMPOSICAO:
            state = {
                ...state,
                ...{
                    produtoComposicaoLoading: true
                }
            }
            break
        case EDITAR_PRODUTO_COMPOSICAO_RESULT:
            state = {
                ...state,
                ...{
                    produtoComposicaoLoading: false
                }
            }
            break
        case REMOVER_PRODUTO_COMPOSICAO:
            state = {
                ...state,
                ...{
                    produtoComposicaoRemovido: false,
                    produtoComposicaoLoading: true
                }
            }
            break
        case REMOVER_PRODUTO_COMPOSICAO_RESULT:
            state = {
                ...state,
                ...{
                    produtoComposicaoRemovido: true,
                    produtoComposicaoLoading: false
                }
            }
            break
        case BUSCAR_IMAGEM_PRODUTO:
            state = {
                ...state,
                ...{
                    imagemProduto: [],
                    imagemProdutoLoading: true
                }
            }
            break
        case BUSCAR_IMAGEM_PRODUTO_RESULT:
            state = {
                ...state,
                ...{
                    imagemProduto: action.payload.data,
                    imagemProdutoLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO:
            state = {
                ...state,
                ...{
                    gruposCardapio: [],
                    gruposCardapioLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_RESULT:
            state = {
                ...state,
                ...{
                    gruposCardapio: action.payload.data,
                    gruposCardapioLoading: false
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO:
            state = {
                ...state,
                ...{
                    grupoCardapio: null,
                    grupoCardapioLoading: true
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapio: action.payload.data,
                    grupoCardapioLoading: false
                }
            }
            break
        case BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO:
            state = {
                ...state,
                ...{
                    quantidadeGruposCardapio: {},
                }
            }
            break
        case BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO_RESULT:
            state = {
                ...state,
                ...{
                    quantidadeGruposCardapio: action.payload.data,
                }
            }
            break
        case CRIAR_GRUPO_CARDAPIO:
            state = {
                ...state,
                ...{
                    grupoCardapioLoading: true,
                }
            }
            break
        case CRIAR_GRUPO_CARDAPIO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioLoading: false,
                }
            }
            break
        case CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO:
            state = {
                ...state,
                ...{
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO_RESULT:
            state = {
                ...state,
                ...{
                    permanecerCadastro: action.payload.data
                }
            }
            break
        case EDITAR_GRUPO_CARDAPIO:
            state = {
                ...state,
                ...{
                    grupoCardapioLoading: true
                }
            }
            break
        case EDITAR_GRUPO_CARDAPIO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_GRUPO_CARDAPIO:
            state = {
                ...state,
                ...{
                    grupoCardapioAtivo: action.payload.data,
                    permanecerCadastro: null
                }
            }

            state.grupoCardapio = null
            break
        case REMOVER_GRUPO_CARDAPIO:
            state = {
                ...state,
                ...{
                    grupoCardapioRemovido: false,
                    grupoCardapioLoading: true
                }
            }
            break
        case REMOVER_GRUPO_CARDAPIO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioRemovido: true,
                    grupoCardapioLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO:
            state = {
                ...state,
                ...{
                    gruposCardapioObservacao: null,
                    gruposCardapioObservacaoLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO_RESULT:
            state = {
                ...state,
                ...{
                    gruposCardapioObservacao: action.payload.data,
                    gruposCardapioObservacaoLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO:
            state = {
                ...state,
                ...{
                    gruposCardapioObservacao: null,
                    gruposCardapioObservacaoLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_RESULT:
            state = {
                ...state,
                ...{
                    gruposCardapioObservacao: action.payload.data,
                    gruposCardapioObservacaoLoading: false
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_OBSERVACAO:
            state = {
                ...state,
                ...{
                    grupoCardapioObservacao: null,
                    grupoCardapioObservacaoLoading: true
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioObservacao: action.payload.data,
                    grupoCardapioObservacaoLoading: false
                }
            }
            break
        case CRIAR_GRUPO_CARDAPIO_OBSERVACAO:
            state = {
                ...state,
                ...{
                    novoGrupoCardapioObservacao: null,
                    grupoCardapioObservacaoLoading: true
                }
            }
            break
        case CRIAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT:
            state = {
                ...state,
                ...{
                    novoGrupoCardapioObservacao: action?.payload?.data,
                    grupoCardapioObservacaoLoading: false
                }
            }
            break
        case EDITAR_GRUPO_CARDAPIO_OBSERVACAO:
            state = {
                ...state,
                ...{
                    grupoCardapioObservacaoEditado: false,
                    grupoCardapioObservacaoLoading: true
                }
            }
            break
        case EDITAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioObservacaoEditado: true,
                    grupoCardapioObservacaoLoading: false
                }
            }
            break
        case REMOVER_GRUPO_CARDAPIO_OBSERVACAO:
            state = {
                ...state,
                ...{
                    grupoCardapioObservacaoRemovido: false,
                    grupoCardapioObservacaoLoading: true
                }
            }
            break
        case REMOVER_GRUPO_CARDAPIO_OBSERVACAO_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioObservacaoRemovido: true,
                    grupoCardapioObservacaoLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO:
            state = {
                ...state,
                ...{
                    gruposCardapioItem: null,
                    gruposCardapioItemLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO_RESULT:
            state = {
                ...state,
                ...{
                    gruposCardapioItem: action.payload.data,
                    gruposCardapioItemLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_ITEM:
            state = {
                ...state,
                ...{
                    gruposCardapioItem: null,
                    gruposCardapioItemLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_CARDAPIO_ITEM_RESULT:
            state = {
                ...state,
                ...{
                    gruposCardapioItem: action.payload.data,
                    gruposCardapioItemLoading: false
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_ITEM:
            state = {
                ...state,
                ...{
                    grupoCardapioItem: null,
                    grupoCardapioItemLoading: true
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioItem: action.payload.data,
                    grupoCardapioItemLoading: false
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_ITEM:
            state = {
                ...state,
                ...{
                    grupoCardapioItem: null,
                    grupoCardapioItemLoading: true
                }
            }
            break
        case BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT:
            state = {
                ...state,
                ...{
                    grupoCardapioItem: action.payload.data,
                    grupoCardapioItemLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_DIA:
            state = {
                ...state,
                ...{
                    vendasDia: null,
                    vendasDiaLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_DIA_RESULT:
            state = {
                ...state,
                ...{
                    vendasDia: action.payload.data,
                    vendasDiaLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_SEMANA:
            state = {
                ...state,
                ...{
                    vendasSemana: null,
                    vendasSemanaLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_SEMANA_RESULT:
            state = {
                ...state,
                ...{
                    vendasSemana: action.payload.data,
                    vendasSemanaLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_MES:
            state = {
                ...state,
                ...{
                    vendasMes: null,
                    vendasMesLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_MES_RESULT:
            state = {
                ...state,
                ...{
                    vendasMes: action.payload.data,
                    vendasMesLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_MES_ANTERIOR:
            state = {
                ...state,
                ...{
                    vendasMesAnterior: null,
                    vendasMesAnteriorLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_MES_ANTERIOR_RESULT:
            state = {
                ...state,
                ...{
                    vendasMesAnterior: action.payload.data,
                    vendasMesAnteriorLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_ANO:
            state = {
                ...state,
                ...{
                    vendasAno: null,
                    vendasAnoLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_ANO_RESULT:
            state = {
                ...state,
                ...{
                    vendasAno: action.payload.data,
                    vendasAnoLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_ANO_ANTERIOR:
            state = {
                ...state,
                ...{
                    vendasAnoAnterior: null,
                    vendasAnoAnteriorLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_ANO_ANTERIOR_RESULT:
            state = {
                ...state,
                ...{
                    vendasAnoAnterior: action.payload.data,
                    vendasAnoAnteriorLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_ANO_EVOLUCAO:
            state = {
                ...state,
                ...{
                    vendasAnoEvolucao: null,
                    vendasAnoEvolucaoLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_ANO_EVOLUCAO_RESULT:
            state = {
                ...state,
                ...{
                    vendasAnoEvolucao: action.payload.data,
                    vendasAnoEvolucaoLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO:
            state = {
                ...state,
                ...{
                    vendasAnoEvolucaoPorAtendimento: null,
                    vendasAnoEvolucaoPorAtendimentoLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO_RESULT:
            state = {
                ...state,
                ...{
                    vendasAnoEvolucaoPorAtendimento: action.payload.data,
                    vendasAnoEvolucaoPorAtendimentoLoading: false
                }
            }
            break
        case BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS:
            state = {
                ...state,
                ...{
                    vendasAnoEvolucaoPorStatus: null,
                    vendasAnoEvolucaoPorStatusLoading: true
                }
            }
            break
        case BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS_RESULT:
            state = {
                ...state,
                ...{
                    vendasAnoEvolucaoPorStatus: action.payload.data,
                    vendasAnoEvolucaoPorStatusLoading: false
                }
            }
            break
        case BUSCAR_EMPRESA:
            state = {
                ...state,
                ...{
                    empresa: null,
                    empresaLoading: true
                }
            }
            break
        case BUSCAR_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    empresa: action.payload.data,
                    empresaLoading: false
                }
            }
            break
        case BUSCAR_EMPRESA_POR_USUARIO:
            state = {
                ...state,
                ...{
                    empresas: null,
                    empresasLoading: true
                }
            }
            break
        case BUSCAR_EMPRESA_POR_USUARIO_RESULT:
            state = {
                ...state,
                ...{
                    empresas: action.payload.data,
                    empresasLoading: false
                }
            }
            break
        case EDITAR_EMPRESA:
            state = {
                ...state,
                ...{
                    empresaLoading: true
                }
            }
            break
        case EDITAR_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    empresaLoading: false
                }
            }
            break
        case EDITAR_EMPRESA_CERTIFICADO_DIGITAL:
            state = {
                ...state,
                ...{
                    empresaCertificadoLoading: true
                }
            }
            break
        case EDITAR_EMPRESA_CERTIFICADO_DIGITAL_RESULT:
            state = {
                ...state,
                ...{
                    empresaCertificadoLoading: false
                }
            }
            break
        case EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO:
            state = {
                ...state,
                ...{
                    empresaCertificadoLoading: true
                }
            }
            break
        case EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO_RESULT:
            state = {
                ...state,
                ...{
                    empresaCertificadoLoading: false
                }
            }
            break
        case REMOVER_EMPRESA_CERTIFICADO_DIGITAL:
            state = {
                ...state,
                ...{
                    empresaCertificadoLoading: true
                }
            }
            break
        case REMOVER_EMPRESA_CERTIFICADO_DIGITAL_RESULT:
            state = {
                ...state,
                ...{
                    empresaCertificadoLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_EMPRESA:
            state = {
                ...state,
                ...{
                    empresaAtivo: action.payload.data
                }
            }

            state.empresa = null
            break
        case BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA:
            state = {
                ...state,
                ...{
                    licencas: null,
                    licencasLoading: true
                }
            }
            break
        case BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    licencas: action.payload.data,
                    licencasLoading: false
                }
            }
            break
        case BUSCAR_TAREFAS_POR_EMPRESA:
            state = {
                ...state,
                ...{
                    tarefas: null,
                    tarefasLoading: true
                }
            }
            break
        case BUSCAR_TAREFAS_POR_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    tarefas: action.payload.data,
                    tarefasLoading: false
                }
            }
            break
        case BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA:
            state = {
                ...state,
                ...{
                    notificacoesSistema: null,
                    notificacoesSistemaLoading: true
                }
            }
            break
        case BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    notificacoesSistema: action.payload.data,
                    notificacoesSistemaLoading: false
                }
            }
            break
        case BUSCAR_VINCULOS_POR_USUARIO:
            state = {
                ...state,
                ...{
                    vinculos: null,
                    vinculosLoading: true
                }
            }
            break
        case BUSCAR_VINCULOS_POR_USUARIO_RESULT:
            state = {
                ...state,
                ...{
                    vinculos: action.payload.data,
                    vinculosLoading: false
                }
            }
            break
        case REJEITAR_VINCULOS:
            state = {
                ...state,
                ...{
                    loading: true
                }
            }
            break
        case REJEITAR_VINCULOS_RESULT:
            state = {
                ...state,
                ...{
                    loading: false
                }
            }
            break
        case EXPORTAR_USUARIO_CSV:
            state = {
                ...state,
                ...{
                    exportarCSVLoading: true
                }
            }
            break
        case EXPORTAR_USUARIO_CSV_RESULT:
            state = {
                ...state,
                ...{
                    exportarCSVLoading: false
                }
            }
            break
        case BUSCAR_GRUPOS_ESTOQUE:
            state = {
                ...state,
                ...{
                    gruposEstoque: [],
                    gruposEstoqueLoading: true
                }
            }
            break
        case BUSCAR_GRUPOS_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    gruposEstoque: action.payload.data,
                    gruposEstoqueLoading: false
                }
            }
            break
        case BUSCAR_GRUPO_ESTOQUE:
            state = {
                ...state,
                ...{
                    grupoEstoque: null,
                    grupoEstoqueLoading: true
                }
            }
            break
        case BUSCAR_GRUPO_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    grupoEstoque: action.payload.data,
                    grupoEstoqueLoading: false
                }
            }
            break
        case CRIAR_GRUPO_ESTOQUE:
            state = {
                ...state,
                ...{
                    grupoEstoqueLoading: true,
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_GRUPO_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    grupoEstoqueLoading: false,
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO:
            state = {
                ...state,
                ...{
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO_RESULT:
            state = {
                ...state,
                ...{
                    permanecerCadastro: action.payload.data
                }
            }
            break
        case EDITAR_GRUPO_ESTOQUE:
            state = {
                ...state,
                ...{
                    grupoEstoqueLoading: true
                }
            }
            break
        case EDITAR_GRUPO_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    grupoEstoqueLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_GRUPO_ESTOQUE:
            state = {
                ...state,
                ...{
                    grupoEstoqueAtivo: action.payload.data,
                    permanecerCadastro: null
                }
            }

            state.grupoEstoque = null
            break
        case NAVEGAR_LISTAR_ESTOQUE:
            state = {
                ...state,
                ...{
                    grupoEstoqueAtivo: action.payload?.grupo,
                    company: action.payload?.company,
                }
            }

            state.grupoEstoque = null
            break
        case REMOVER_GRUPO_ESTOQUE:
            state = {
                ...state,
                ...{
                    grupoEstoqueRemovido: false,
                    grupoEstoqueLoading: true
                }
            }
            break
        case REMOVER_GRUPO_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    grupoEstoqueRemovido: true,
                    grupoEstoqueLoading: false
                }
            }
            break
        case BUSCAR_ESTOQUES:
            state = {
                ...state,
                ...{
                    estoques: [],
                    estoquesLoading: true
                }
            }
            break
        case BUSCAR_ESTOQUES_RESULT:
            state = {
                ...state,
                ...{
                    estoques: action.payload.data,
                    estoquesLoading: false
                }
            }
            break
        case BUSCAR_ESTOQUE:
            state = {
                ...state,
                ...{
                    estoque: null,
                    estoqueLoading: true
                }
            }
            break
        case BUSCAR_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    estoque: action.payload.data,
                    estoqueLoading: false
                }
            }
            break
        case CRIAR_ESTOQUE:
            state = {
                ...state,
                ...{
                    estoqueLoading: true,
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    estoqueLoading: false,
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_ESTOQUE_MANTEM_CADASTRO:
            state = {
                ...state,
                ...{
                    permanecerCadastro: false
                }
            }
            break
        case CRIAR_ESTOQUE_MANTEM_CADASTRO_RESULT:
            state = {
                ...state,
                ...{
                    permanecerCadastro: action.payload.data
                }
            }
            break
        case EDITAR_ESTOQUE:
            state = {
                ...state,
                ...{
                    estoqueLoading: true
                }
            }
            break
        case EDITAR_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    estoqueLoading: false
                }
            }
            break
        case NAVEGAR_EDITAR_ESTOQUE:
            state = {
                ...state,
                ...{
                    estoqueAtivo: action.payload.data,
                    permanecerCadastro: null
                }
            }

            state.estoque = null
            break
        case REMOVER_ESTOQUE:
            state = {
                ...state,
                ...{
                    estoqueRemovido: false,
                    estoqueLoading: true
                }
            }
            break
        case REMOVER_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    estoqueRemovido: true,
                    estoqueLoading: false
                }
            }
            break
        case BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE:
            state = {
                ...state,
                ...{
                    listaEstoqueFilial: null,
                    listaEstoqueFilialLoading: true
                }
            }
            break
        case BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE_RESULT:
            state = {
                ...state,
                ...{
                    listaEstoqueFilial: action.payload.data,
                    listaEstoqueFilialLoading: false
                }
            }
            break
        case BUSCAR_EVOLUCAO_FISCAL:
            state = {
                ...state,
                ...{
                    evolucaoFiscal: null,
                    evolucaoFiscalLoading: true
                }
            }
            break
        case BUSCAR_EVOLUCAO_FISCAL_RESULT:
            state = {
                ...state,
                ...{
                    evolucaoFiscal: action.payload.data,
                    evolucaoFiscalLoading: false
                }
            }
            break
        case BUSCAR_SINC:
            state = {
                ...state,
                ...{
                    sinc: null,
                    sincLoading: false
                }
            }
            break
        case BUSCAR_SINC_RESULT:
            state = {
                ...state,
                ...{
                    sinc: action.payload.data,
                    sincLoading: false
                }
            }
            break
        case BUSCAR_TIPOS_EMPRESA:
            state = {
                ...state,
                ...{
                    tiposEmpresa: null,
                    tiposEmpresaLoading: false
                }
            }
            break
        case BUSCAR_TIPOS_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    tiposEmpresa: action.payload.data,
                    tiposEmpresaLoading: false
                }
            }
            break
        case BUSCAR_CONFIG_IFOOD_POR_EMPRESA:
            state = {
                ...state,
                ...{
                    configIfood: null,
                    configIfoodLoading: true
                }
            }
            break
        case BUSCAR_CONFIG_IFOOD_POR_EMPRESA_RESULT:
            state = {
                ...state,
                ...{
                    configIfood: action.payload.data,
                    configIfoodLoading: false
                }
            }
            break
        case CRIAR_CONFIG_IFOOD:
            state = {
                ...state,
                ...{
                    configIfoodLoading: true
                }
            }
            break
        case CRIAR_CONFIG_IFOOD_RESULT:
            state = {
                ...state,
                ...{
                    configIfoodLoading: false
                }
            }
            break
        case EDITAR_CONFIG_IFOOD:
            state = {
                ...state,
                ...{
                    configIfoodLoading: true
                }
            }
            break
        case EDITAR_CONFIG_IFOOD_RESULT:
            state = {
                ...state,
                ...{
                    configIfoodLoading: false
                }
            }
            break
        case LOGOUT_USER:
            state = { ...state }
            break
        case LOGOUT_USER_SUCCESS:
            state = { ...initialState }
            break
        default:
            return state
    }
    return state
}

export default profile
