import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { apiBuscarSincronismo } from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { describeDateTime } from "../../helpers/utils"


registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const SincronismoDadosTable = forwardRef((props, refFather) => {

  const ref = useRef()

  const { filters, columns, t } = props

  const [data, setData] = useState([])

  useImperativeHandle(refFather, () => ({
    search() {
      ref?.current?.search()
    }
  }))

  const colunas = useMemo(() => columns ||
    [
      { header: t("label.column.id.cloud"), accessorKey: "id", size: 20, enableHiding: false },
      {
        header: t("breadcrumb.company.title"),
        accessorKey: "nomeEmpresa",
        size: 120,
        enableColumnFilter: false,
        filterFn: "contains"
      },
      { header: t("label.column.name"), accessorKey: "Nome", size: 80, filterFn: "contains" },
      { header: t("label.column.table"), accessorKey: "Tabela", size: 80 },
      {
        header: t("label.column.last.synchronization"),
        accessorKey: "UltimaAtualizacao",
        size: 80,
        Cell: ({ cell }) => describeDateTime(cell?.getValue()),
        enableColumnFilter: false
      }


    ], [])

  return (
    <React.Fragment>
      <ListDataTable
        ref={ref}
        searchFn={params => apiBuscarSincronismo(params)}
        advancedFilters={filters}
        columns={colunas}
        showColumnFilters={false}
        data={data}
        setData={setData}
        columnVisibility={{}}
        enableRowActions={false}
        defaultSorting={[{
          id: "id",
          desc: true
        }]}
        enableRowSelection={false}
      />
    </React.Fragment>
  )
})

export default SincronismoDadosTable

SincronismoDadosTable.propTypes = {
  filters: PropTypes.array,
  columns: PropTypes.array,
  usePermissionEdit: PropTypes.bool,
  navegarEditarEmpresa: PropTypes.func,
  user: PropTypes.object,
  t: PropTypes.func
}
