import React, { Component } from "react"
import { Button, Card, CardBody, Col } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import ApexCharts from 'apexcharts';
import Loader from "react-loader-spinner"
import { connect } from "react-redux"
import moment from "moment"
import { buscarEvolucaoContasPagar } from "store/auth/profile/actions"
import {getCompaniesValuesByOptions} from "../../helpers/utils"
import PropTypes from "prop-types";

class OverView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeM: false,
      active6M: false,
      activeY: true,
    }
    this.updateChartMonthly = this.updateChartMonthly.bind(this)
    this.updateChartSixMonth.bind(this)
    this.updateChartYearly.bind(this)

  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.company?.length || 0) !== (this.props.company?.length || 0)) {
      this.props.buscarEvolucaoContasPagar({ company: getCompaniesValuesByOptions(this.props.company) });
    }
  }

  static abbreviateNumber(num, fixed) {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0) {
      return "0"
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k] // append power
    return e
  }

  static getDerivedStateFromProps(props, current_state) {
    if (Array.isArray(props.evolucaoContaPagar)) {
      return {
        ...current_state,
        ...{
          series: [
            {
              name: "Total",
              data: props.evolucaoContaPagar.map(e => [
                new Date(e.grupo).getTime(),
                e.valor
              ])
            }
          ],
          options: {
            chart: {
              id: 'evolucao-contas',
              toolbar: "false",
              locales: [
                {
                  name: "pt-br",
                  options: {
                    months: [
                      "Janeiro",
                      "Fevereiro",
                      "Março",
                      "Abril",
                      "Maio",
                      "Junho",
                      "Julho",
                      "Agosto",
                      "Setembro",
                      "Outubro",
                      "Novembro",
                      "Dezembro"
                    ],
                    shortMonths: [
                      "Jan",
                      "Fev",
                      "Mar",
                      "Abr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Ago",
                      "Set",
                      "Out",
                      "Nov",
                      "Dez"
                    ],
                    days: [
                      "Domingo",
                      "Segunda",
                      "Terça",
                      "Quarta",
                      "Quinta",
                      "Sexta",
                      "Sábado"
                    ],
                    shortDays: [
                      "Dom",
                      "Seg",
                      "Ter",
                      "Qua",
                      "Qui",
                      "Sex",
                      "Sab"
                    ]
                  }
                }
              ],
              defaultLocale: "pt-br",
              type: 'area',
              height: 240,
              zoom: {
                autoScaleYaxis: true
              }
            },
            dataLabels: {enabled: false },
            stroke: { curve: "smooth", width: 2 },
            markers: { size: 0, style: "hollow" },
            xaxis: {
              type: "datetime",
            },
            tooltip: { x: { format: "dd MMM yyyy" } },
            colors: ["#f1b44c"],
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.05,
                stops: [42, 100, 100, 100]
              }
            },
            yaxis: {
              title: {
                text: "Valor"
              },
              labels: {
                formatter: val => {
                  return OverView.abbreviateNumber(val)
                }
              }
            }
          }
        }
      }
    }

    return { ...current_state, ...props }
  }

  updateChartMonthly() {
    this.setState({
      activeM: true,
      active6M: false,
      activeY: false,
    })

    ApexCharts.exec(
        'evolucao-contas',
        'zoomX',
        moment().subtract(1, "month").toDate().getTime(),
        new Date().getTime()
    )
  }

  updateChartSixMonth() {
    this.setState({
      activeM: false,
      active6M: true,
      activeY: false,
    })

    ApexCharts.exec(
        'evolucao-contas',
        'zoomX',
        moment().subtract(6, "month").toDate().getTime(),
        new Date().getTime()
    )
  }

  updateChartYearly() {
    this.setState({
      activeM: false,
      active6M: false,
      activeY: true,
    })

    ApexCharts.exec(
        'evolucao-contas',
        'zoomX',
        moment().subtract(1, "year").toDate().getTime(),
        new Date().getTime()
    )
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="4">
          <Card>
            <CardBody>
              <div>
                <h4 className="card-title mb-3">Evolução Contas Pagar</h4>
                <div className="ms-auto">
                  <div className="d-flex flex-wrap gap-2 justify-content-center toolbar">
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={ this.state.activeM ? "active" : "" }
                      onClick={ () => this.updateChartMonthly() }
                      id="one_month"
                    >
                      1M
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={ this.state.active6M ? "active" : "" }
                      onClick={ () => this.updateChartSixMonth() }
                      id="six_months"
                    >
                      6M
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={ this.state.activeY ? "active" : "" }
                      onClick={ () => this.updateChartYearly() }
                      id="one_year"
                    >
                      1A
                    </Button>
                  </div>
                </div>
              </div>
              { !this.state.series ||
              this.props.loadingEvolucaoContaPagar ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : <></> }
              <div id="overview-chart-timeline">
                { this.state.options && <ReactApexChart
                    options={ this.state.options }
                    series={ this.state.series }
                    type="area"
                    height={ 240 }
                /> }
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

OverView.propTypes = {
  company: PropTypes.any,
}

const mapStatetoProps = state => {
  const { loadingEvolucaoContaPagar, evolucaoContaPagar } = state.Profile
  return { loadingEvolucaoContaPagar, evolucaoContaPagar }
}

export default connect(mapStatetoProps, {
  buscarEvolucaoContasPagar
})(OverView)
