import React, {useState} from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip} from "reactstrap";
import {isEmpty, map} from "lodash";
import {Link} from "react-router-dom";
import {AvForm} from "availity-reactstrap-validation";
import {describeDateTime} from "../../helpers/utils";
import FormInputText from "../../components/Common/form-input-text";
import usePermission from "../../helpers/permission/usePermission";
import LoaderList from "../../components/Common/loaderList";
import FormInputNumber from "../../components/Common/form-input-number";
import FormInputAsyncSelect from "../../components/Common/form-input-async-select";
import {apiBuscarFornecedoresPesquisa, apiBuscarProdutosPesquisa} from "../../helpers/backend_helper";

const ProdutoComposicaoLista = props => {

    const [quantidade , setQuantidade] = useState() // Composição do produto - tb_pro_cont - Qtd
    const [codigoEstoque, setCodigoEstoque] = useState() // Composição do produto - tb_pro_cont - Codigo_estoque
    const [modalRemove, setModalRemove] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState()
    const [cadastrar, setCadastrar] = useState()

    const usePermissionInsert = usePermission("produtoComposicao_inserir")
    const usePermissionRemove = usePermission("produtoComposicao_excluir")

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const removerProdutoComposicao = () => {
        props.removerProdutoComposicao(itemSelecionado)
        setModalRemove(false)
    }

    const cadastrarProdutoComposicao = () => {
        props.cadastrarProdutoComposicao({ codigoEstoque: codigoEstoque.value, quantidade })
        setQuantidade(null)
        setCodigoEstoque(null)
        setCadastrar(false)
    }

    const buildProduto = (Codigo, Descricao) => {
        return {label: `${Codigo} - ${Descricao}`, value: Codigo}
    }

    const buscarProdutos = (inputValue, callback) => {
        apiBuscarProdutosPesquisa({empresaId: props.empresa, argumento: inputValue})
            .then(results => {
                const options = (results || []).filter(produto =>
                    produto.Codigo !== props.codigoProdutoPai &&
                    !props.listaProdutoComposicao.some(produtoComposicao => produtoComposicao.Codigo_estoque === produto.Codigo)
                ).map(({Codigo, Descricao}) => {
                    return buildProduto(Codigo, Descricao)
                })
                callback(options)
            })
    }

    return <>
        { props.loading ? <LoaderList></LoaderList> :
            <>
                <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                        <thead>
                            <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Descrição</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Preço de custo</th>
                                <th scope="col">Preço de venda</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {( isEmpty(props.listaProdutoComposicao) &&
                                (<tr>
                                    <td colSpan={ 6 }>
                                        <h5 className="text-truncate font-size-14 m-0">
                                            Não há nenhum produtos de referência
                                        </h5>
                                    </td>
                                    </tr>)) ||
                            map(props.listaProdutoComposicao, (item, i) => (<tr key={i}>
                            <td>
                                <div>
                                    <p className="text-muted mb-0">{ item.Codigo_estoque}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="text-muted mb-0">{item.produto.Descricao}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="text-muted mb-0">{(item.produto.Anterior || 0) + (item.produto.Aquisicao || 0) - (item.produto.Saida || 0)}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="text-muted mb-0">
                                    { parseFloat((item.produto.Custo || 0)).toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL"}) }
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="text-muted mb-0">
                                        { parseFloat((item.produto.Venda || 0)).toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL"}) }
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="text-muted mb-0">{item.Qtd}</p>
                                </div>
                            </td>
                            <td>
                                { usePermissionRemove && !props.loading && !item.idId && <Link to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                                       onClick={() => showConfirmDelete(item)} title="Remover"/>
                                    </Link>
                                }
                            </td>
                        </tr>))}
                        </tbody>
                    </Table>
                </div>

                { cadastrar && <FormInputAsyncSelect
                    dependencyKey={props.empresa}
                    attribute="codigoEstoque"
                    description="Produto"
                    value={codigoEstoque}
                    setValue={setCodigoEstoque}
                    loadOptions={buscarProdutos}
                /> }

                { cadastrar && <FormInputNumber
                    attribute="quantidade"
                    description="Quantidade"
                    value={quantidade}
                    setValue={setQuantidade}
                    allowNegative={false}
                    max={9999999}
                /> }

                <div className="d-flex justify-content-start">
                    { !cadastrar && <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        onClick={ e => setCadastrar(true) }
                        disabled={ !usePermissionInsert || props.loading }>
                        { "Incluir nova composição" }
                    </Button> }

                    { cadastrar && <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        disabled={ !codigoEstoque || !quantidade }
                        onClick={ e => cadastrarProdutoComposicao(e) }>
                        { "Salvar nova composição" }
                    </Button> }

                    { cadastrar && <Button
                        type="submit"
                        color="danger"
                        className="waves-effect waves-light me-2"
                        onClick={ e => setCadastrar(false) }>
                        { "Cancelar" }
                    </Button> }
                </div>

                <Modal isOpen={modalRemove}>
                    <ModalHeader tag="h4">Confirmação</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={(e, v) => { removerProdutoComposicao() }} >
                            <Row form>
                                <Col className="col-12">
                                    Deseja realmente remover esse item?
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 10}}>
                                <Col>
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-success save-event">
                                            Confirmar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-light ms-2"
                                            onClick={e => setModalRemove(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </>
        }
    </>
}

ProdutoComposicaoLista.propTypes = {
    listaProdutoComposicao: PropTypes.array,
    cadastrarProdutoComposicao: PropTypes.any,
    removerProdutoComposicao: PropTypes.any,
    loading: PropTypes.bool,
    codigoProdutoPai: PropTypes.any,
    empresa: PropTypes.any
}

export default ProdutoComposicaoLista