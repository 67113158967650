import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Input, Label,  } from "reactstrap"
import {withTranslation} from "react-i18next";
import Tooltip from "./tooltip";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from "draft-js";
import {Editor} from "react-draft-wysiwyg";

const FormInputEditor = props => {
  const { attribute, description, value, setValue, required, disabled, changeHandeler, afterChange, keyTranslate, hiddenLabel } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        { !hiddenLabel && <Label htmlFor={ attribute + "-editor" } className="col-form-label col-lg-2">
          { keyTranslate ? props.t(keyTranslate + '.label') : description }
          { keyTranslate && <Tooltip id={ attribute + "-title"} tooltip={ props.t(keyTranslate + '.title') }></Tooltip>}
        </Label> }
        <Col lg={ hiddenLabel ? "12" : "10" }>
          { !disabled && <Editor
              id={attribute + "-editor"}
              name={attribute + "-editor"}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={value}
              required={required}
              onEditorStateChange={e => {
                setValue(e)

                if (required) {
                  if (changeHandeler !== undefined) {
                    changeHandeler(e.target.value, `validate-required-${attribute}-editor`)
                  } else {
                    changeHandelerInput(e.target.value, `validate-required-${attribute}-editor`)
                  }
                }

                if (afterChange) afterChange(e.target.value)
              }}
          /> }

          {disabled &&
              <span dangerouslySetInnerHTML={ { __html: draftToHtml(convertToRaw(value.getCurrentContent())) } }>
              </span>
          }

          {required &&
              <div className={value ? "" : "invalid-tooltip"}
                   name="validate-required" id={`validate-required-${attribute}-editor`}>
                {value ? "" : `${description} é obrigatório(a)`}
              </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputEditor.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  changeHandeler: PropTypes.func,
  afterChange: PropTypes.func,
  keyTranslate: PropTypes.string,
}

export default withTranslation()(FormInputEditor)
