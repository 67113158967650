import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash"
import { Link, useLocation, withRouter } from "react-router-dom"
import classNames from "classnames"
import { Button, Card, CardBody, CardTitle, Col, Container, Row, UncontrolledTooltip } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Images

import { statusClasses } from "common/data/definitions"
import {
  buscarSugestoes,
  doCreateSuggestion,
  doEditSuggestion,
  doViewSuggestion
} from "../../store/auth/profile/actions"
import moment from "moment"
import Loader from "react-loader-spinner"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import { navegarBreadCrumb } from "../../store/navigator/actions"

const SugestoesLista = props => {
  const { sugestoes } = props

  const usePermissionEdit = usePermission('sugestoes_editar')
  const usePermissionInsert = usePermission('sugestoes_inserir')
  const isInforangra = props.user?.usuario_info
  const location = useLocation();

  useEffect(() => {
    props.buscarSugestoes()
    props.navegarBreadCrumb({title: `menu.label.suggestion`, link: location.pathname})
  }, [])

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }

    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const goToSuggestion = item => {
    (usePermissionEdit && props.doEditSuggestion(item)) || props.doViewSuggestion(item)
    props.history.push("/sugestao"+(item?`/${item.id}`: ""));
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return props.sugestoesLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="breadcrumb.suggestion.title" />
          {/* Render Breadcrumbs */ }
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Suas Sugestões</CardTitle>

                  <div className="button-items my-3">
                    <Link to="/sugestao">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light"
                        onClick={ e => props.doCreateSuggestion() }
                      >
                        <i className="bx bx-plus" /> Cadastrar
                      </Button>
                    </Link>
                  </div>

                  <div className="table-responsive">
                    <table
                      className="dataTable table table-bordered table-striped table-nowrap table-centered mb-0 table-hover">
                      <thead>
                      <tr>
                        <th>Sugestão</th>
                        <th>Produto</th>
                        { isInforangra && <th>Usuario</th> }
                        <th>Registrado em</th>
                        <th>Status</th>
                        <th>Ações</th>
                      </tr>
                      </thead>

                      <tbody>
                      { (isEmpty(sugestoes) && (
                        <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              Você não criou nenhuma sugestão até o momento,
                              crie a primeira agora mesmo!
                            </h5>
                          </td>
                        </tr>
                      )) ||
                      map(sugestoes, (item, i) => (
                        <tr key={ i } onClick={ e => goToSuggestion(item) }>
                          <td>
                            <Link to="#" className="text-truncate text-dark" >
                              { item.conteudo }
                            </Link>
                          </td>
                          <td>
                            <Link to="#" className="text-truncate text-dark">
                              { item.produto }
                            </Link>
                          </td>
                          { isInforangra && (
                            <td>
                              <Link to="#" className="text-truncate text-dark">
                                { item.usuario?.nome_completo }
                              </Link>
                            </td>
                          ) }
                          <td>
                            <Link to="#" className="text-truncate text-dark">
                              { describeDateTime(item.created_at) }
                            </Link>
                          </td>
                          <td>
                                <span className={ classNames("badge badge-pill font-size-11", statusClasses[
                                      (
                                        (item.status && item.status.nome) ||
                                        "N/D"
                                      ).toLowerCase()
                                      ]
                                  ) }>
                                  { (item.status && item.status.nome) || "N/D" }
                                </span>
                          </td>
                          <td>
                            { (usePermissionInsert || usePermissionEdit) &&
                              <Link to="#" className="me-3 text-primary">
                                <i className="mdi mdi-pencil font-size-18 me-3" id="edittooltip" title="Editar"/>
                              </Link>
                            }
                          </td>
                        </tr>
                      )) }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SugestoesLista.propTypes = {
  sugestoes: PropTypes.array,
  sugestoesLoading: PropTypes.bool,
  buscarSugestoes: PropTypes.func
}

const mapStatetoProps = state => {
  const { sugestoes, sugestoesLoading } = state.Profile
  const { user } = state.Login
  return {
    sugestoes,
    sugestoesLoading,
    user
  }
}

export default connect(mapStatetoProps, {
  buscarSugestoes,
  doViewSuggestion,
  doEditSuggestion,
  doCreateSuggestion,
  navegarBreadCrumb
})(withRouter(SugestoesLista))
