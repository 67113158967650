import React, { Component, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CardUser from "./card-user"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import UltimasNotasEmitidas from "./ultimas-notas-emitidas"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../store/auth/register/actions"
import { apiError } from "../../store/auth/login/actions"
import Loader from "react-loader-spinner"
import VendasHora from "./vendas-hora"
import VendasDia from "./vendas-dias"
import TiposPagamentos from "./tipos-pagamentos"
import GruposMaisVendas from "./grupos-mais-vendas"
import {buscarQuantidadeSolicitacoesPendentes, logoutUser} from "../../store/actions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import UltimosMovimentoCaixaLista from "./ultimos-movimento-caixa-lista";
import {withTranslation} from "react-i18next";
class Dashboard extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.buscarQuantidadeSolicitacoesPendentes()
  }

  render() {

    if (
      !this.props.user ||
      !this.props.user.vinculos?.length ||
      !this.props.user.vinculos.filter(e =>
        ["A", "T"].find(status => status == e.vinculo_status)
      ).length
    ) {
      return (
          <AlertaSemVinculo/>
      )
    }

      return this.props.loadingDashboard ? (
        <div className="loader-container">
          <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
        </div>
      ) : (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */ }
              <Breadcrumbs title="breadcrumb.dashboard" />
              {/* Card User */ }
              <CardUser
                { ...{
                  user: this.props.user,
                  dashboardData: this.props.dashboardData,
                  quantidadeSolicitacoesPendentes: this.props.quantidadeSolicitacoesPendentes,
                  history: this.props.history
                } }
              />

              <Row>
                {/* Vendas */ }
                <Earning { ...{ dashboardData: this.props.dashboardData } } />

                {/* Produtos Top 10 */ }
                <SalesAnalytics />
              </Row>

              <Row>
                <VendasHora></VendasHora>
                <VendasDia></VendasDia>
                <TiposPagamentos></TiposPagamentos>
                <GruposMaisVendas></GruposMaisVendas>
              </Row>

              <Row>
                <UltimosMovimentoCaixaLista />
                <UltimasNotasEmitidas { ...{ dashboardData: this.props.dashboardData } }
                />
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )
    }
}

Dashboard.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
  vinculos: PropTypes.any,
  dashboardData: PropTypes.any,
  logoutUser: PropTypes.func
}

const mapStatetoProps = state => {
  const { quantidadeSolicitacoesPendentes } = state.Profile
  const {
    user,
    registrationError,
    loadingDashboard,
    dashboardData
  } = state.Login
  return {
    user,
    registrationError,
    loadingDashboard,
    dashboardData,
    quantidadeSolicitacoesPendentes
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  buscarQuantidadeSolicitacoesPendentes,
  logoutUser
})(Dashboard))
