import React from "react"
import PropTypes from "prop-types"
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Col, Row } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Link from '@mui/material/Link';
import { withTranslation } from "react-i18next"
import { checkIsMobile } from "../../helpers/utils"


const Breadcrumb = props => {

  const isMobile = checkIsMobile()

  const onClickLink = (item) =>{

    props.history.push(item.link)

  }

  return (
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{props.t(props.title)}</h4>
              {!isMobile && (<div role="presentation" className="page-title-right">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/"
                        sx={{ display: 'flex', alignItems: 'center', color: 'rgb(102, 179, 255)', fontSize: '13px' }}>
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {props.t('breadcrumb.home')}
                  </Link>

                  {props.heap && props.heap.map((item, index) => {
                    return item.link ? (
                        <Link key={index} underline="hover" color="inherit" onClick={() => onClickLink(item)}
                              sx={{ display: 'flex', alignItems: 'center', color: 'rgb(102, 179, 255)',  fontSize: '13px' }}>
                          {item.id? props.t(item.title, {id: item.id}) : props.t(item.title)}</Link>)
                      : (<Typography color="text.primary">{item.title}</Typography>)
                  })}

                </Breadcrumbs>
              </div>)}
            </div>
          </Col>
        </Row>
  )
}

const mapStateToProps = state => {
  const { error, success , heap } = state.Navigator

  return { error, success, heap }
}

export default withTranslation()(connect(mapStateToProps, { })(withRouter(Breadcrumb)))
