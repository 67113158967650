import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import "react-datepicker/dist/react-datepicker.css"

import "assets/scss/datatables.scss"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { buscarNota } from "../../store/auth/profile/actions"
import Loader from "react-loader-spinner"
import { matchPath } from "react-router"
import XMLViewer from "react-xml-viewer"
import { saveAs } from "file-saver"
import { navegarBreadCrumb } from "../../store/navigator/actions"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const VerNota = props => {
  const [downloaded, setDownloaded] = useState(false)

  useEffect(() => {
    props.buscarNota(
      matchPath(props.history.location.pathname, {
        path: "/notas/ver/:idCloud/:action",
        exact: true,
        strict: false
      }).params
    )
  }, [])

  useEffect(() => {
    if (props.nota && props.nota[0]) {
      const match = matchPath(props.history.location.pathname, {
        path: "/notas/ver/:idCloud/:action",
        exact: true,
        strict: false
      })
      if (
        match.params.action === "download" &&
        props.nota[0].id == match.params.idCloud &&
        !downloaded &&
        props.nota[0].xml
      ) {
        setTimeout(() => {
          saveAs(
            new Blob([Buffer.from(props.nota[0].xml)]),
            `${ props.nota[0].chave }.xml`
          )
        }, 500)
        setDownloaded(true)
      }
      props.navegarBreadCrumb({title: `breadcrumb.note`, link: `/notas/ver/${props.nota[0].id}/visualizar`, editObject: props.notas[0]})
    }
    return () => setDownloaded(false)
  }, [props.nota])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */ }
          <Breadcrumbs title="breadcrumb.invoices"/>

          <Row>
            <Col lg="12">
              { props.notaLoading || !props.nota || !props.nota[0] ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : (
                props.nota[0].xml && <XMLViewer xml={ Buffer.from(props.nota[0].xml).toString() } />
              ) }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VerNota.propTypes = {}

const mapStatetoProps = state => {
  const { notaLoading, nota } = state.Profile
  const { user } = state.Login
  return { notaLoading, nota, user }
}

export default connect(mapStatetoProps, { buscarNota, navegarBreadCrumb })(withRouter(VerNota))
