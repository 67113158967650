import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Badge, Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import { buscarDispositivos } from "../../store/auth/profile/actions"
import moment from "moment"
import Loader from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"
import DispositivoLogsModal from "pages/CardapioDigital/dispositivo-logs"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { navegarBreadCrumb } from "../../store/navigator/actions"

const CardapioDispositivosLista = props => {
  const [dataDispositivos, setDataDispositivos] = useState({
    columns: [
      { label: "Registro", field: "Registro", sort: "asc", width: 150 },
      { label: "Empresa", field: "Empresa", sort: "asc", width: 150 },
      {
        label: "Data inclusão",
        field: "Data inclusão",
        sort: "asc",
        width: 270
      },
      {
        label: "Data última atualização",
        field: "Data última atualização",
        sort: "asc",
        width: 270
      },
      {
        label: "ID do dispositivo Android",
        field: "ID do dispositivo Android",
        sort: "asc",
        width: 200
      },
      {
        label: "Versão Android",
        field: "Versão Android",
        sort: "asc",
        width: 150
      },
      { label: "Versão APP", field: "Versão APP", sort: "asc", width: 100 },
      { label: "Bateria", field: "Bateria", sort: "disabled", width: 100 },
      {
        label: "Visualizar Logs",
        field: "Visualizar Logs",
        sort: "disabled",
        width: 150
      }
    ],
    rows: []
  })

  useEffect(() => {
    props.buscarDispositivos()
    props.navegarBreadCrumb({title: `breadcrumb.menu.devices`, link: location.pathname})
  }, [])

  useEffect(() => {
    if(Array.isArray(props.dispositivos)) {
      const rows = props.dispositivos?.map(dispositivo => ({
          Registro: dispositivo.id,
          Empresa: dispositivo.empresa?.Fantasia,
          "Data inclusão": describeDateTime(dispositivo.created_at),
          "Data última atualização": describeDateTime(dispositivo.updated_at),
          "ID do dispositivo Android": dispositivo.imei,
          "Versão Android": dispositivo.versao_android,
          "Versão APP": dispositivo.versao_app,
          Bateria: (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                describePercentualBaterial(dispositivo.percentual_bateria)
              }
              color={ describePercentualBaterial(dispositivo.percentual_bateria) }
              pill
            >
              { dispositivo.percentual_bateria }%
            </Badge>
          ),
          "Visualizar Logs": (
            <DispositivoLogsModal dispositivoId={ dispositivo.id } />
          )
        }))

      const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
      const columns = isAdmin ? dataDispositivos.columns : dataDispositivos.columns.filter(column => column.field !== "Empresa")

      setDataDispositivos({
        columns: columns,
        rows: rows
      })
    }
  }, [props.user, props.dispositivos])

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const describePercentualBaterial = percentual => {
    if (percentual > 50) {
      return "success"
    } else if (percentual <= 50 && percentual >= 20) {
      return "warning"
    } else {
      return "danger"
    }
  }

  return props.dispositivosLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="breadcrumb.menu.devices" />
        <Row className="justify-content-center">
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Lista de Dispositivos</CardTitle>
                <MDBDataTable
                  responsive
                  striped
                  bordered
                  data={ dataDispositivos }
                  entriesLabel={ "Mostrar Registros" }
                  infoLabel={ ["Exibindo", "a", "de", "registros"] }
                  noRecordsFoundLabel={ "Nenhum registro encontrado" }
                  paginationLabel={ ["Anterior", "Próximo"] }
                  searchLabel={ "Pesquisar" }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CardapioDispositivosLista.propTypes = {
  dispositivos: PropTypes.array,
  dispositivosLoading: PropTypes.bool,
  buscarDispositivos: PropTypes.func
}

const mapStatetoProps = state => {
  const { dispositivos, dispositivosLoading } = state.Profile
  const { user } = state.Login
  return { dispositivos, dispositivosLoading, user }
}

export default connect(mapStatetoProps, { buscarDispositivos, navegarBreadCrumb })(
  withRouter(CardapioDispositivosLista)
)
