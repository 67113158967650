import React, {useEffect, useRef, useState} from "react"
import {Button, Card, CardBody, Col, Container, Form, Row} from "reactstrap"
import {connect} from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import {buscarCaixasOperadores} from "../../store/auth/profile/actions"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import MovimentoCaixaTable from "./movimento-caixa-table"
import FormFilterSelect from "../../components/Common/form-filter-select"
import FormFilterPeriod from "../../components/Common/form-filter-period"
import moment from "moment"
import {
    getCompanies,
    getCompaniesValuesByOptions,
    getCompanyOption,
    getDatasPeloPeriodo,
    getPeriodos
} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import usePermission from "../../helpers/permission/usePermission";

import "moment/locale/pt-br"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import { withTranslation } from "react-i18next"

moment.locale("pt-br")
registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const MovimentoCaixaLista = props => {

    const ref = useRef()

    const [filters, setFilters] = useState(null)
    const [companies, setCompanies] = useState([])
    const [companiesOptions, setCompaniesOptions] = useState([{label: "Todas", value: ""}])
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [periods, setPeriods] = useState([])
    const [period, setPeriod] = useState(null)
    const [startDate, setStartDate] = useState(moment().subtract(30, "days").toDate())
    const [endDate, setEndDate] = useState(new Date())

    const [situation, setSituation] = useState("")
    const [operator, setOperator] = useState("")
    const [cashier, setCashier] = useState("")
    const [turn, setTurn] = useState("")

    const [cashiers, setCashiers] = useState([])
    const [operators, setOperators] = useState([])
    const location = useLocation();

    useEffect(() => {
        const { primeiroPeriodo, tmpPeriods } = getPeriodos()

        setPeriod(primeiroPeriodo)
        setPeriods(tmpPeriods)

        if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)

        if (possuiVinculo) {
            props.buscarCaixasOperadores()
            populateFiltros(primeiroPeriodo, props.dashboardFilters.companiesSelected)
        }

        cashiers.length = 0
        cashiers.push({value: "", label: "Qualquer", defaultValue: true})
        for (let i = 1; i <= 99; i++) {
            cashiers.push({label: i, value: i})
            setCashiers(cashiers)
        }

        props.navegarBreadCrumb({title: `breadcrumb.cash.transactions`, link: location.pathname})

        return () => {
            setCashiers([])
        }
    }, [])

    useEffect(() => {
        if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
            let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
            setCompaniesOptions(companiesOptions)
        } else {
            let companiesOptions = getCompanies(props.user)
            setCompaniesOptions(companiesOptions)
        }
        return () => {
            setCompaniesOptions([])
        }
    }, [props.user, props.empresas])

    const updateOperators = (companies = null) => {
        if (Array.isArray(props.caixasOperadores)) {
            operators.length = 0
            operators.push({label: "Qualquer", value: ""})
            props.caixasOperadores.map(operador => {
                if (Array.isArray(companies) && (companies.length === 0 || companies.find(company => company.value === operador.idEmpresa))) {
                    operators.push({label: operador.nome, value: operador.id})
                }
            })
            setOperators(operators)

            if (operators) {
                const possuiValor = operators.find(operador => operador.value === operador)
                if (possuiValor) setOperator(null)
            }
        }
    }

    useEffect(() => {
        if (Array.isArray(props.caixasOperadores) && props.caixasOperadores.length > 0) {
            updateOperators(companies)
        }
    }, [props.caixasOperadores])

    const populateFiltros = (periodoSelecionado, companiesSelected) => {
        const filters = []

        if (companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        } else if (companies) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies)})
        }

        let periodo = period || periodoSelecionado
        let datasPeloPeriodo = getDatasPeloPeriodo(periodo, startDate, endDate)
        if(datasPeloPeriodo) {
            filters.push({id: 'Data_Abertura', value: datasPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo})
            filters.push({id: 'Data_Abertura', value: datasPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo})
        }

        if (situation) filters.push({id: 'Status', value: situation})
        if (operator) filters.push({id: 'Func_Caixa', value: operator})
        if (cashier) filters.push({id: 'Caixa', value: cashier})
        if (turn) filters.push({id: 'Periodo', value: turn})

        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => ref?.current?.search(), 300)
    }

    const onPickStartDate = date => {
        setStartDate(date)
        setEndDate(date)
    }

    if (!props.user || !props.user.vinculos.length || !(props.user.vinculos || []).filter(e => ["A", "T"].find(status => status == e.vinculo_status)).length) {
        return (<AlertaSemVinculo/>)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="breadcrumb.cash.transactions"/>
                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Movimento de caixa</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-md-2 col-sm-12">
                                                    <FormFilterCompany
                                                        attribute="companies"
                                                        value={companies}
                                                        description="Empresa(s)"
                                                        setValue={value => {
                                                            setCompanies(value)
                                                            updateOperators(value)
                                                        }}
                                                        options={companiesOptions}
                                                        multiple={true}
                                                        hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                                                    />
                                                </div>

                                                <FormFilterPeriod
                                                    periodValue={period}
                                                    periodChangeHandeler={e => setPeriod(e)}
                                                    options={periods}
                                                    startDateValue={startDate}
                                                    startDateChangeHandeler={e => onPickStartDate(e)}
                                                    endDateValue={endDate}
                                                    endDateChangeHandeler={e => setEndDate(e)}
                                                    t={props.t}
                                                />

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="situacao"
                                                        value={situation}
                                                        description="Situação"
                                                        changeHandeler={value => setSituation(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "A", label: "Aberto"},
                                                            {value: "F", label: "Fechado"},
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="operator"
                                                        value={operator}
                                                        description="Operador"
                                                        changeHandeler={value => setOperator(value)}
                                                        options={operators}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="cashier"
                                                        value={cashier}
                                                        description="Caixa"
                                                        changeHandeler={value => setCashier(value)}
                                                        options={cashiers}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="turn"
                                                        value={turn}
                                                        description="Turno"
                                                        changeHandeler={value => setTurn(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "1", label: "Manhã"},
                                                            {value: "2", label: "Tarde"},
                                                            {value: "3", label: "Noite"},
                                                            {value: "4", label: "Madrugada"},
                                                        ]}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}
                                                                disabled={!usePermission('movimentoCaixa_listar')}>
                                                            Buscar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && <Card>
                                    <CardBody>
                                        <MovimentoCaixaTable ref={ref} filters={filters} history={props.history}>
                                        </MovimentoCaixaTable>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

MovimentoCaixaLista.propTypes = {}

const mapStatetoProps = state => {
    const {caixasOperadores, empresas, empresasLoading} = state.Profile
    const {user, dashboardFilters} = state.Login
    return {user, dashboardFilters, caixasOperadores, empresas, empresasLoading}
}

export default withTranslation()(connect(mapStatetoProps, {
    buscarCaixasOperadores, navegarBreadCrumb
})(withRouter(MovimentoCaixaLista)))
