import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup, Input,
  Label, Modal, ModalBody, ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import { connect } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

//Date Picker
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import 'react-phone-number-input/style.css'

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  buscarFornecedor, cadastrarFornecedor, editarFornecedor, navegarEditarFornecedor, removerFornecedor
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import Select from "react-select"
import InputPhoneNumber, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input/input'
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import {getCompanies, isEmailValid} from 'helpers/utils';
import InputMask from "react-input-mask"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import Loader from "react-loader-spinner"
import { navegarBreadCrumb } from "../../store/navigator/actions"

import ButtonRedirect from "../../components/Common/button-redirect"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarFornecedor = props => {

  const [paramIdCloud, setParamIdCloud] = useState(undefined)
  const usePermissionEdit = usePermission("fornecedores_editar")
  const usePermissionInsert = usePermission("fornecedores_inserir")
  const usePermissionRemove = usePermission("fornecedores_excluir")
  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

  const [companies, setCompanies] = useState([{ label: "Todas", value: "" }])
  const [id, setId] = useState()
  const [empresa, setEmpresa] = useState()
  const [codigo, setCodigo] = useState()
  const [razaoSocial, setRazaoSocial] = useState()
  const [fantasia, setFantasia] = useState()
  const [cnpj, setCnpj] = useState()
  const [ie, setIe] = useState()
  const [im, setIm] = useState()
  const [endereco, setEndereco] = useState()
  const [bairro, setBairro] = useState()
  const [cep, setCep] = useState()
  const [cidade, setCidade] = useState()
  const [estado, setEstado] = useState()
  const [telefone, setTelefone] = useState()
  const [fax, setFax] = useState()
  const [telefone2, setTelefone2] = useState()
  const [contato, setContato] = useState()
  const [observacao, setObservacao] = useState()
  const [email, setEmail] = useState()
  const [numero, setNumero] = useState()
  const [status, setStatus] = useState()
  const location = useLocation();

  useEffect(() => {
    if(paramIdCloud !== undefined) return

    const idCloud = props.match.params.hasOwnProperty('idCloud') ? props.match.params.idCloud : null
    if (idCloud) {
      getAndSearch(idCloud)
    } else {
      populaDados(null)
      setId(null)
      setEmpresa(companies[0].value)
      setStatus('A')
      props.navegarBreadCrumb({ title: `breadcrumb.supplier.new`, link: location.pathname })
    }

    setParamIdCloud(idCloud)
  }, [props.match.params])

  const getAndSearch = (idCloud)=> {
    props.buscarFornecedor({id: idCloud}, props.history)
    props.navegarBreadCrumb( {title: `breadcrumb.supplier`, link: location.pathname, id: idCloud})
  }

  useEffect(() => {
    populaDados(props.fornecedor)
  }, [props.fornecedor])

  const populaDados = (item)=> {
    setId(item?.id)
    setEmpresa(item?.idEmpresa)
    setCodigo(item?.codigo)
    setRazaoSocial(item?.razaosocial)
    setFantasia(item?.fantasia)
    setCnpj(item?.cnpj)
    setIe(item?.ie)
    setIm(item?.im)
    setEndereco(item?.endereco)
    setBairro(item?.bairro)
    setCep(item?.cep)
    setCidade(item?.cidade)
    setEstado(item?.estado)
    setTelefone(item?.telefone)
    setFax(item?.fax)
    setTelefone2(item?.telefone2)
    setContato(item?.contato)
    setObservacao(item?.obs)
    setEmail(item?.email)
    setNumero(item?.numero)
    setStatus(item?.status)
  }

  const excluirFornecedor = () => {
    props.removerFornecedor({ id }, props.history)
  }

  const clonarFornecedor = () => {
    setId(null)
    setCodigo(null)
    setRazaoSocial(`${razaoSocial} (cópia)`)
    setEmpresa(companies[0].value)
    setCnpj(props.fornecedor.cnpj)
    setEndereco(props.fornecedor.endereco)
    props.fornecedor.id = null
    props.fornecedor.codigo = null
    props.fornecedor.iCodigo = null // caso seja clonado não deverá haver o iCodigo
    window.scrollTo(0, 0)
  }

  const gravarFornecedor = () => {
    let valid = true
    valid = valid && (empresa ? true : false)
    valid = valid && (codigo ? true : false)
    valid = valid && (razaoSocial && razaoSocial !== '' ? true : false)

    let valorCNPJ = cnpj
    if(valorCNPJ) {
      valorCNPJ = cnpjValidator.strip(valorCNPJ)
    }
    const cnpjValid = valorCNPJ && valorCNPJ !== '' ?  cnpjValidator.isValid(cnpj) : true;
    valid = valid && cnpjValid
    if(!cnpjValid) {
      document.getElementsByName("formatterValidateCnpj")[0].style.display = "block"
    }

    const emailValid = email && email !== '' ? isEmailValid(email) : true
    if(!emailValid) {
      document.getElementsByName("formatterValidateEmail")[0].style.display = "block"
    }
    valid = valid && emailValid

    if(valid) {
      salvarFornecedor()
    } else {
      const divs = document.getElementsByName("validate-required")
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.display = "block"
      }
    }
  }

  const changeHandeler = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "") {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  const salvarFornecedor = () => {
    const fornecedor = props.fornecedor ? Object.assign({}, props.fornecedor) : {}
    fornecedor.idEmpresa = empresa
    fornecedor.codigo = codigo
    fornecedor.razaosocial = razaoSocial
    fornecedor.fantasia = fantasia
    fornecedor.cnpj = cnpj
    fornecedor.ie = ie
    fornecedor.im = im
    fornecedor.endereco = endereco
    fornecedor.bairro = bairro
    fornecedor.cep = cep
    fornecedor.cidade = cidade
    fornecedor.estado = estado
    fornecedor.telefone = telefone && telefone !== '' ? formatPhoneNumber(telefone) : null
    fornecedor.fax = fax && fax !== '' ? formatPhoneNumber(fax) : null
    fornecedor.telefone2 = telefone2 && telefone2 !== '' ? formatPhoneNumber(telefone2) : null
    fornecedor.contato = contato
    fornecedor.obs = observacao
    fornecedor.email = email
    fornecedor.numero = numero

    if(fornecedor.id) {
      fornecedor.status = status
      props.editarFornecedor(fornecedor, props.history)
    } else {
      fornecedor.status = 'A'
      props.cadastrarFornecedor(fornecedor, props.history)
    }
  }


  useEffect(() => {
    const tmpCompanies = getCompanies(props.user)
    setCompanies(tmpCompanies)
    setEmpresa(tmpCompanies[0].value)

    return () => {
      setCompanies([])
    }
  }, [])

  if (
    !props.user ||
    !possuiVinculo
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      {props.fornecedorLoading && id ? (<div className="loader-container position-absolute">
          <Loader
            type="ThreeDots"
            color="#556EE6"
            height={80}
            width={80}
          />
        </div>) :
        (<div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="breadcrumb.supplier.title"
          />
          <StickerToolbarButtons>
            <ButtonRedirect path={"fornecedores"} history={props.history}></ButtonRedirect>
            <Button
                type="submit"
                color="primary"
                className="waves-effect waves-light me-2"
                disabled={ !empresa || !((id && usePermissionEdit) || (!id && usePermissionInsert) || props.fornecedor?.iCodigo)
                }
                onClick={ e => gravarFornecedor(e) }>
              { id ? "Salvar Fornecedor" : "Cadastrar Fornecedor" }
            </Button>
            <Button
                type="submit"
                color="primary"
                className="waves-effect waves-light me-2"
                disabled={ !(id && usePermissionInsert) }
                onClick={ e => clonarFornecedor(e) }>
              { "Clonar Fornecedor" }
            </Button>
            <Button
                type="submit"
                color="danger"
                className="waves-effect waves-light me-2"
                disabled={ !(id && usePermissionRemove) || props.fornecedor?.iCodigo }
                onClick={ e => excluirFornecedor(e) }>
              { "Remover Fornecedor" }
            </Button>
          </StickerToolbarButtons>
          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <Form>
                      <div className="outer">
                        <div className="outer">
                          { id && <FormGroup className="mb-4" row>
                            <Label htmlFor="empresa" className="col-form-label col-lg-2">
                              Identificador
                            </Label>
                            <Col lg="10" className="col-form-label">
                              { id }
                            </Col>
                          </FormGroup> }

                          { id && <FormGroup className="mb-4" row>
                            <Label htmlFor="status" className="col-form-label col-lg-2">
                              Ativo
                            </Label>
                            <Col lg="10">
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="status-switch"
                                  switch="none"
                                  checked={ status === 'A' ? true : false }
                                  onChange={ () => setStatus(status === 'A' ? 'I' : 'A' ) }
                                />
                                <label
                                  htmlFor="status-switch"
                                  data-on-label="Sim"
                                  data-off-label="Não"
                                />
                              </div>
                            </Col>
                          </FormGroup> }

                          { id && <FormGroup className="mb-4" row>
                            <Label htmlFor="codigo" className="col-form-label col-lg-2">
                              Código
                            </Label>
                            <Col lg="10" className="col-form-label">
                              { codigo }
                            </Col>
                          </FormGroup> }

                          { !id && <FormGroup className="mb-4" row>
                            <Label htmlFor="codigo" className="col-form-label col-lg-2">
                              Código
                            </Label>
                            <Col lg="10">
                              <Input
                                id="codigo"
                                name="codigo"
                                type="number"
                                className="form-control"
                                value={ codigo }
                                onChange={ e => {
                                  setCodigo(e.target.value)
                                  changeHandeler(e.target.value, "validateCodigo")
                                } }
                                placeholder="Digite o Código"
                                disabled={ id }
                                required
                              />
                              <div className={ codigo ? "" : "invalid-tooltip" } name="validate" id="validateCodigo">
                                { codigo ? "" : "Código é obrigatório"}
                              </div>
                            </Col>
                          </FormGroup> }

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="empresa" className="col-form-label col-lg-2">
                              Empresa
                            </Label>
                            <Col lg="10">
                              <Select
                                id="empresa"
                                name="empresa"
                                className="basic-single"
                                options={ companies.filter(c => c.value) }
                                placeholder="Digite a empresa..."
                                isClearable={ true }
                                isSearchable={ true }
                                noOptionsMessage={ () => "Nenhuma empresa encontrada" }
                                onChange={ e => {
                                  setEmpresa(e?.value)
                                  changeHandeler(e?.value, "validateCompany")
                                } }
                                value={ empresa ? companies.find(e => e.value === empresa) : undefined }
                                required
                                isDisabled={ props.fornecedor?.id }
                              />
                              <div className={ empresa ? "" : "invalid-tooltip" }
                                   name="validate" id="validateCompany">
                                { empresa ? "" : "Empresa é obrigatório"}
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="cnpj" className="col-form-label col-lg-2">
                              CNPJ
                            </Label>
                            <Col lg="10">
                              <CpfCnpj
                                id="cnpj"
                                name="cnpj"
                                type="text"
                                className="form-control"
                                value={ cnpj }
                                onChange={(e, type) => {
                                  setCnpj(e.target.value)
                                  changeHandeler(e.target.value, "validateCnpj", e.target.value ? cnpjValidator.isValid(e.target.value, true) : undefined)
                                }}
                                placeholder="Digite o CNPJ"
                                required
                                maxLength={18}
                              />
                              <div className={ "invalid-tooltip" } name="formatterValidateCnpj" id="validateCnpj">
                                { "CNPJ é inválido" }
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="razaoSocial" className="col-form-label col-lg-2">
                              Razão social
                            </Label>
                            <Col lg="10">
                              <Input
                                id="razaoSocial"
                                name="razaoSocial"
                                type="text"
                                className="form-control"
                                value={ razaoSocial }
                                onChange={ e => {
                                  setRazaoSocial(e.target.value)
                                  changeHandeler(e.target.value, "validateRazaoSocial")
                                } }
                                placeholder="Digite a Razão social"
                                required
                                maxLength={70}
                              />
                              <div className={ razaoSocial ? "" : "invalid-tooltip" }
                                   name="validate" id="validateRazaoSocial">
                                { razaoSocial ? "" : "Razão social é obrigatório"}
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="fantasia" className="col-form-label col-lg-2">
                              Nome fantasia
                            </Label>
                            <Col lg="10">
                              <Input
                                id="fantasia"
                                name="fantasia"
                                type="text"
                                className="form-control"
                                value={ fantasia }
                                onChange={ e => {
                                  setFantasia(e.target.value)
                                } }
                                placeholder="Digite o Nome fantasia"
                                maxLength={70}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="ie" className="col-form-label col-lg-2">
                              Inscrição estadual
                            </Label>
                            <Col lg="10">
                              <Input
                                id="ie"
                                name="ie"
                                type="text"
                                className="form-control"
                                value={ ie }
                                onChange={ e => {
                                  setIe(e.target.value)
                                } }
                                placeholder="Digite a Inscrição estadual"
                                maxLength={18}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="im" className="col-form-label col-lg-2">
                              Inscrição municipal
                            </Label>
                            <Col lg="10">
                              <Input
                                id="im"
                                name="im"
                                type="text"
                                className="form-control"
                                value={ im }
                                onChange={ e => {
                                  setIm(e.target.value)
                                } }
                                placeholder="Digite a Inscrição municipal"
                                maxLength={18}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="endereco" className="col-form-label col-lg-2">
                              Endereço
                            </Label>
                            <Col lg="10">
                              <Input
                                id="endereco"
                                name="endereco"
                                type="text"
                                className="form-control"
                                value={ endereco }
                                onChange={ e => {
                                  setEndereco(e.target.value)
                                } }
                                placeholder="Digite o endereço"
                                maxLength={70}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="numero" className="col-form-label col-lg-2">
                              Número
                            </Label>
                            <Col lg="10">
                              <Input
                                id="numero"
                                name="numero"
                                type="number"
                                className="form-control"
                                value={ numero }
                                onChange={ e => {
                                  setNumero(e.target.value)
                                } }
                                placeholder="Digite o número"
                                maxLength={15}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="bairro" className="col-form-label col-lg-2">
                              Bairro
                            </Label>
                            <Col lg="10">
                              <Input
                                id="bairro"
                                name="bairro"
                                type="text"
                                className="form-control"
                                value={ bairro }
                                onChange={ e => {
                                  setBairro(e.target.value)
                                } }
                                placeholder="Digite o barro"
                                maxLength={70}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="cep" className="col-form-label col-lg-2">
                              CEP
                            </Label>
                            <Col lg="10">
                              <InputMask
                                  mask="99999-999"
                                  maskChar={null}
                                  value={ cep }
                                  name="cep"
                                  label="cep"
                                  className="form-control"
                                  placeholder="Digite o CEP"
                                  onChange={ e => {
                                    setCep(e.target.value)
                                  } }
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="cidade" className="col-form-label col-lg-2">
                              Cidade
                            </Label>
                            <Col lg="10">
                              <Input
                                id="cidade"
                                name="cidade"
                                type="text"
                                className="form-control"
                                value={ cidade }
                                onChange={ e => {
                                  setCidade(e.target.value)
                                } }
                                placeholder="Digite a cidade"
                                maxLength={70}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="cidade" className="col-form-label col-lg-2">
                              Estado
                            </Label>
                            <Col lg="10">
                              <select className="form-select select2-search-disable" value={ estado } onChange={ e => setEstado(e.target.value) }>
                                <option key="" value="">Informa a sigla do estado</option>
                                <option key="AC" value="AC">AC</option>
                                <option key="AL" value="AL">AL</option>
                                <option key="AP" value="AP">AP</option>
                                <option key="AM" value="AM">AM</option>
                                <option key="BA" value="BA">BA</option>
                                <option key="CE" value="CE">CE</option>
                                <option key="DF" value="DF">DF</option>
                                <option key="ES" value="ES">ES</option>
                                <option key="GO" value="GO">GO</option>
                                <option key="MA" value="MA">MA</option>
                                <option key="MT" value="MT">MT</option>
                                <option key="MS" value="MS">MS</option>
                                <option key="MG" value="MG">MG</option>
                                <option key="PA" value="PA">PA</option>
                                <option key="PB" value="PB">PB</option>
                                <option key="PR" value="PR">PR</option>
                                <option key="PE" value="PE">PE</option>
                                <option key="PI" value="PI">PI</option>
                                <option key="RJ" value="RJ">RJ</option>
                                <option key="RN" value="RN">RN</option>
                                <option key="RS" value="RS">RS</option>
                                <option key="RO" value="RO">RO</option>
                                <option key="RR" value="RR">RR</option>
                                <option key="SC" value="SC">SC</option>
                                <option key="SP" value="SP">SP</option>
                                <option key="SE" value="SE">SE</option>
                                <option key="TO" value="TO">TO</option>
                              </select>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="telefone" className="col-form-label col-lg-2">
                              Telefone
                            </Label>
                            <Col lg="10">
                              <InputPhoneNumber
                                id="telefone"
                                name="telefone"
                                type="text"
                                className="form-control"
                                withCountryCallingCode
                                country="BR"
                                value={ telefone }
                                onChange={ e => {
                                  setTelefone(e)
                                  changeHandeler(e, "validateTelefone", e ? isValidPhoneNumber(e) : undefined)
                                } }
                                placeholder="Digite o telefone"
                              />
                              <div className={ "invalid-tooltip" } name="formatterValidate" id="validateTelefone">
                                { "Telefone é inválido" }
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="fax" className="col-form-label col-lg-2">
                              Fax
                            </Label>
                            <Col lg="10">
                              <InputPhoneNumber
                                id="fax"
                                name="fax"
                                type="text"
                                className="form-control"
                                country="BR"
                                value={ fax }
                                onChange={ e => {
                                  setFax(e)
                                  changeHandeler(e, "validateFax", e ? isValidPhoneNumber(e) : undefined)
                                } }
                                placeholder="Digite o Fax"
                              />
                              <div className={ "invalid-tooltip" } name="formatterValidate" id="validateFax">
                                { "Fax é inválido" }
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="telefone2" className="col-form-label col-lg-2">
                              Outro telefone
                            </Label>
                            <Col lg="10">
                              <InputPhoneNumber
                                id="telefone2"
                                name="telefone2"
                                type="text"
                                className="form-control"
                                country="BR"
                                value={ telefone2 }
                                onChange={ e => {
                                  setTelefone2(e)
                                  changeHandeler(e, "validateTelefone2", e ? isValidPhoneNumber(e) : undefined)
                                } }
                                placeholder="Digite o Outro telefone"
                              />
                              <div className={ "invalid-tooltip" } name="formatterValidate" id="validateTelefone2">
                                { "Outro telefone é inválido" }
                              </div>

                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="contato" className="col-form-label col-lg-2">
                              Contato
                            </Label>
                            <Col lg="10">
                              <Input
                                id="contato"
                                name="contato"
                                type="text"
                                className="form-control"
                                value={ contato }
                                onChange={ e => {
                                  setContato(e.target.value)
                                } }
                                placeholder="Digite o Contato"
                                maxLength={70}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="email" className="col-form-label col-lg-2">
                              E-mail
                            </Label>
                            <Col lg="10">
                              <Input
                                id="email"
                                name="email"
                                type="email"
                                className="form-control"
                                value={ email }
                                onChange={ e => {
                                  setEmail(e.target.value)
                                  changeHandeler(e.target.value, "validateEmail", e.target.value && e.target.value !== "" ? isEmailValid(e.target.value) : undefined)
                                } }
                                placeholder="Digite o E-mail"
                                maxLength={200}
                              />
                              <div className={ "invalid-tooltip" } name="formatterValidateEmail" id="validateEmail">
                                { "E-mail é inválido" }
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="observacao" className="col-form-label col-lg-2">
                              Observação
                            </Label>
                            <Col lg="10">
                              <Input
                                id="observacao"
                                name="observacao"
                                type="text"
                                className="form-control"
                                value={ observacao }
                                onChange={ e => setObservacao(e.target.value) }
                                placeholder="Digite a observação"
                                maxLength={400}
                              />
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>)}
    </React.Fragment>
  )
}

CadastrarFornecedor.propTypes = {
  fornecedor: PropTypes.any,
  fornecedorAtivo: PropTypes.any,
  fornecedorLoading: PropTypes.bool,
  cadastrarFornecedor: PropTypes.func,
  editarFornecedor: PropTypes.func,
  removerFornecedor: PropTypes.func,
}

const mapStatetoProps = state => {
  const { fornecedorAtivo, fornecedor, fornecedorLoading } = state.Profile
  const { user } = state.Login
  return { user, fornecedorAtivo, fornecedor, fornecedorLoading }
}

export default connect(mapStatetoProps, {
  cadastrarFornecedor,
  removerFornecedor,
  editarFornecedor,
  buscarFornecedor,
  navegarBreadCrumb
})(withRouter(CadastrarFornecedor))
