import React, { Component } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

import ReactApexChart from "react-apexcharts"
import moment from "moment"

import "moment/locale/pt-br"
import { getDashboardData } from "../../store/dashboard/actions"
import { dashboardSync } from "../../store/auth/login/actions"
import { connect } from "react-redux"
import Loader from "react-loader-spinner"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";
import { describeDateTime, getCompaniesValuesByOptions, isNotEmpty } from "../../helpers/utils"

moment.locale("pt-br")

class Earning extends Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [
        {
          name: "series1",
          data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76]
        }
      ],
      options: {
        chart: {
          toolbar: "false",
          dropShadow: {
            enabled: !0,
            color: "#000",
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2
          }
        },
        dataLabels: {
          enabled: !1
        },
        colors: ["#556ee6"],
        stroke: {
          curve: "smooth",
          width: 3
        }
      },
      chart_options: {
        tooltip: {
          trigger: "axis"
        },
        grid: {
          zlevel: 0,
          x: 50,
          x2: 50,
          y: 30,
          y2: 30,
          borderWidth: 0
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLable: {
            color: "#ffffff"
          },
          axisLine: {
            lineStyle: {
              color: "#74788d"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLable: {
            color: "#ffffff"
          },
          axisLine: {
            lineStyle: {
              color: "#74788d"
            }
          }
        },
        series: [
          {
            data: [620, 832, 750, 934, 1290, 1330, 1400],
            type: "line"
          }
        ],
        color: ["#556ee6"],
        textStyle: {
          color: ["#74788d"]
        }
      },
      currentPeriod: moment().format("MMM/YY"),
      previousPeriod: moment().subtract(1, "month").format("MMM/YY"),
      previousPeriodData: null,
      previousPercentage: 0,
      dataDifference: 0,
      averageLastMonths: 0
    }
  }

  static abbreviateNumber(num, fixed) {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0 || num === undefined) {
      return "0"
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k] // append power
    return e
  }

  static getDerivedStateFromProps(props, current_state) {
    console.debug("[earning][getDerivedStateFromPops]", {
      props,
      current_state
    })

    const monthName = month => {
      return [
        null,
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ][month]
    }

    if (props.dashboardVendasMes) {
      const previousPeriodDataTmp =
          isNotEmpty(props.dashboardVendasMes.mes) &&
          isNotEmpty(props.dashboardVendasMes.mes[0])
        ? props.dashboardVendasMes.meses[0].find(
          e =>
            e.month ==
            moment(
              moment(props.dashboardVendasMes.mes[0][0]?.period).subtract(
                1,
                "month"
              )
            ).format("M")
        )
        : null
      const A =
        props.dashboardVendasMes.mes &&
        isNotEmpty(props.dashboardVendasMes.mes) &&
        isNotEmpty(props.dashboardVendasMes.mes[0])
          ? props.dashboardVendasMes.mes[0][0].Valor_Total
          : 0
      const B = previousPeriodDataTmp ? previousPeriodDataTmp.Valor_Total : 0

      return {
        series: [
          {
            name: props.t('dashboard.lista.evolucaovendas.label'),
            data: ((props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.meses) ? props.dashboardVendasMes.meses[0] : []).map(e =>
              e.Valor_Total.toFixed(2)
            ))
          },
          {
            name: props.t('dashboard.lista.evolucaovendas.anterior.label'),
            data:
              ((props.dashboardVendasMes && props.dashboardVendasMes.mesesAnterioes && props.dashboardVendasMes.mesesAnterioes.length ? props.dashboardVendasMes.mesesAnterioes[0] : []).map(e =>
                  e.Valor_Total.toFixed(2)
                )) ||
              []
          }
        ],
        options: {
          xaxis: {
            categories:
                (props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.meses) ? props.dashboardVendasMes.meses[0] : []).map(
              e => `${ monthName(e.month) }/${ e.year.toString().slice(-2) }`
            )
          },
          yaxis: {
            labels: {
              /**
               * Allows users to apply a custom formatter function to yaxis labels.
               *
               * @param { String } value - The generated value of the y-axis tick
               * @param { index } index of the tick / currently executing iteration in yaxis labels array
               */
              formatter: function(val, index) {
                return Earning.abbreviateNumber(val)
              }
            }
          },
          chart: {
            toolbar: "false",
            dropShadow: {
              enabled: !0,
              color: "#000",
              top: 18,
              left: 7,
              blur: 8,
              opacity: 0.2
            }
          },
          dataLabels: {
            enabled: !1
          },
          colors: ["#556ee6", "#34c38f"],
          stroke: {
            curve: "smooth",
            width: 3
          },
          tooltip: {
            x: {
              show: true,
              format: "dd MMM",
              formatter: function(value, options) {
                const yearMod = options.seriesIndex
                const e = props.dashboardVendasMes.meses[0][value - 1]
                return (
                  (e &&
                    `${ monthName(e.month) }/${ (e.year - yearMod)
                      .toString()
                      .slice(-2) }`) ||
                  "N/D"
                )
              }
            }
            // y: {
            //   formatter: undefined,
            //   title: {
            //     formatter: (seriesName) => seriesName,
            //   },
            // },
            // z: {
            //   formatter: undefined,
            //   title: 'Size: '
            // },
          }
        },
        //
        chart_options: {
          tooltip: {
            trigger: "axis"
          },
          grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0
          },
          xAxis: {
            type: "category",
            data: (props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.mes) ? props.dashboardVendasMes.meses[0] : []).map(e => e.mes),
            axisLable: {
              color: "#ffffff"
            },
            axisLine: {
              lineStyle: {
                color: "#74788d"
              }
            }
          },
          yAxis: {
            type: "value",
            axisLable: {
              color: "#ffffff"
            },
            axisLine: {
              lineStyle: {
                color: "#74788d"
              }
            }
          },
          series: [
            {
              data: (props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.mes) ? props.dashboardVendasMes.meses[0] : []).map(e =>
                e.Valor_Total.toFixed(2)
              ),
              type: "line"
            },
            {
              data:
                (props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.mesesAnterioes) ? props.dashboardVendasMes.mesesAnterioes[0] : []).map(e =>
                    e.Valor_Total.toFixed(2)
                  ),
              type: "line"
            }
          ],
          color: ["#556ee6", "#34c38f"],
          textStyle: {
            color: ["#74788d"]
          }
        },
        currentPeriod: props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.mes) && isNotEmpty(props.dashboardVendasMes.mes[0])
          ? moment(props.dashboardVendasMes.mes[0][0].period).format("MMM/YY")
          : null,
        previousPeriod: props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.mes) && isNotEmpty(props.dashboardVendasMes.mes[0])
          ? moment(props.dashboardVendasMes.mes[0][0].period)
            .subtract(1, "month")
            .format("MMM/YY")
          : null,
        previousPeriodData: previousPeriodDataTmp,
        previousPercentage:
          A && B ? (100 * Math.abs((B - A) / B)).toFixed(2) : 0,
        dataDifference: A - B,
        averageLastMonths: props.dashboardVendasMes && isNotEmpty(props.dashboardVendasMes.mes)
          ? props.dashboardVendasMes.meses[0].reduce(
          (p, c) => p + c.Valor_Total || 0,
          0
        ) / props.dashboardVendasMes.meses[0].length
          : 0
      }
    }

    // if (current_state.value !== props.value) {
    //   return {
    //     value: props.value
    //     // computed_prop: heavy_computation(props.value)
    //   }
    // }
    return null
  }

  componentDidMount() {
    this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "vendasMes")
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dashboardFilters !== this.props.dashboardFilters) {
      let company = getCompaniesValuesByOptions(this.props.dashboardFilters.companiesSelected)

      this.props.dashboardSync({ company: company, tabela: "TB_VENDAS" }, 'TB_VENDAS')
    }
  }

  render() {
    const doUpdateDashboard = () => {
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "vendasHora"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "vendasDias"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "tiposPagamentos"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "gruposMaisVendas"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "vendasMes"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "maisVendidos"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "vendas"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "notas"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "notasPeriodo"
      )
      this.props.getDashboardData(
        this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId,
        "notasHoje"
      )
    }

    return (
      <React.Fragment>
        <Col xl="6">
          <Card>

              {this.props.loadingDashboardVendasMes ||
              !this.props.dashboardVendasMes ||
                this.props.loadingDashboardSyncTB_VENDAS
                ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : (
                <CardBody>
                <div className="clearfix">
                  <h4 className="card-title mb-4">{this.props.t("dashboard.lista.evolucaovendas.label")}
                    <Tooltip id="evolucaovendas" tooltip={this.props.t("dashboard.lista.evolucaovendas.tooltip")}></Tooltip>
                    <p className="card-sync"> {(this.props.dashboardSyncTB_VENDAS?.error ? this.props.t('dashboard.lista.sinc.tooltip.error'):
                      this.props.t('dashboard.lista.sinc.tooltip', { data: describeDateTime(this.props.dashboardSyncTB_VENDAS) }))}</p>
                  </h4>
                </div>

                <Row>
                  <Col lg="4">
                    <div className="text-muted">
                      <div className="mb-4">
                        <p>Este mês { this.state.currentPeriod }</p>
                        <h4>
                          { (this.props.dashboardVendasMes && isNotEmpty(this.props.dashboardVendasMes.mes) && isNotEmpty(this.props.dashboardVendasMes.mes[0])
                              ? this.props.dashboardVendasMes.mes[0][0]
                                .Valor_Total
                              : 0
                          ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                          }) }
                        </h4>
                        <div>
                          { (this.state.dataDifference >= 0 && (
                            <span className="badge badge-soft-success font-size-12 me-1">
                              { " " }
                              + { this.state.previousPercentage }%{ " " }
                            </span>
                          )) || (
                            <span className="badge badge-soft-danger font-size-12 me-1">
                              { " " }
                              - { this.state.previousPercentage }%{ " " }
                            </span>
                          ) }{ " " }
                          Do período anterior
                        </div>
                      </div>

                      <div>
                        <Link
                          to="#"
                          className="btn btn-primary waves-effect waves-light btn-sm"
                          onClick={ e => doUpdateDashboard() }
                        >
                          Atualizar Dados{ " " }
                          <i className="mdi mdi-chevron-right ms-1"></i>
                        </Link>
                      </div>

                      { this.state.previousPeriod && (
                        <div className="mt-4">
                          <p className="mb-2">
                            Mês passado ({ this.state.previousPeriod })
                          </p>
                          <h5>
                            { (this.state.previousPeriodData
                                ? this.state.previousPeriodData.Valor_Total
                                : 0
                            ).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL"
                            }) }
                          </h5>
                        </div>
                      ) }

                      <div className="mt-4">
                        <p className="mb-2">{this.props.t('dashboard.lista.evolucaovendas.media.label')}</p>
                        <h5>
                          { this.state.averageLastMonths.toLocaleString(
                            "pt-br",
                            {
                              style: "currency",
                              currency: "BRL"
                            }
                          ) }
                        </h5>
                      </div>
                    </div>
                  </Col>

                  <Col lg="8">
                    <div id="line-chart" className="apex-charts" dir="ltr">
                      <ReactApexChart
                        series={ this.state.series }
                        options={ this.state.options }
                        type="line"
                        height={ 320 }
                      />
                    </div>
                  </Col>
                </Row>
               </CardBody>
              ) }

          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

Earning.propTypes = {
  loadingDashboardVendasMes: PropTypes.any,
  dashboards: PropTypes.any,
  getDashboardData: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboards,
    loadingDashboardVendasMes,
    dashboardVendasMes,
    dashboardFilters,
    loadingDashboardSyncTB_VENDAS,
    dashboardSyncTB_VENDAS
  } = state.Login
  return {
    dashboardData,
    dashboards,
    loadingDashboardVendasMes,
    dashboardVendasMes,
    dashboardFilters,
    loadingDashboardSyncTB_VENDAS,
    dashboardSyncTB_VENDAS
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  getDashboardData,
  dashboardSync
})(Earning))
