import { DASHBOARD } from "./actionTypes"
import { call, put, takeEvery } from "redux-saga/effects"
import { apiError } from "../actions"
import { dashboardData } from "./actions"
import { apiGetDashboard } from "../../helpers/backend_helper"


function* doGetDashboardData({payload: {periodStart, periodEnd, companiesId, type}}) {
  try {
    const response = yield call(apiGetDashboard, {
      periodStart,
      periodEnd,
      companiesId,
      type
    })
    yield put(dashboardData(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* DashboardSaga() {
  yield takeEvery(DASHBOARD, doGetDashboardData)
}

export default DashboardSaga
