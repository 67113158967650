import PropTypes from "prop-types"
import React from "react"
import {Button} from "reactstrap"
import { withTranslation } from "react-i18next"

const ButtonRedirect = props => {
  const { history, path, keyTranslate } = props

  const redirect = () => {
    history.push(`/${path}`)
  }
  return (
    <React.Fragment>
      <Button
        type="submit"
        color="secondary"
        onClick={ e => redirect() }
        className="waves-effect waves-light me-2"
      >
        { keyTranslate ? props.t(keyTranslate) : props.t('button.return')}
      </Button>
    </React.Fragment>
  )
}

export default withTranslation()(ButtonRedirect)
