import React, { useEffect, useState } from "react"
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import { describeDateTime } from "../../helpers/utils"
import FormInputDate from "../../components/Common/form-input-date"
import FormInputText from "../../components/Common/form-input-text"
import FormInputSelect from "../../components/Common/form-input-select"
import FormInputTypeNumber from "../../components/Common/form-input-type-number"
import FormInputNumber from "../../components/Common/form-input-number"
import FormInputPassword from "../../components/Common/form-input-password"
import { withTranslation } from "react-i18next"

const ConfigIfoodModal = props => {
  const { configIfood, isOpen, toggle, saveConfig, isEditing } = props
  const [configIfoodModal, setConfigIfoodModal] = useState({
    id: null,
    Contrato: null,
    LiberadoAte: null,
    merchant_id: null,
    merchant_uuid: null,
    auth_tipo: "D",
    polling_interval: 30,
    clientid: "",
    clientsecret: ""
  })
  const tipoOptions = [
    { value: "D", label: props.t("config-ifood.auth.tipo.D") },
    { value: "C", label: props.t("config-ifood.auth.tipo.C") }
  ]

  useEffect(() => {
    if (configIfood) {
      setConfigIfoodModal(configIfood)
    }
  }, [configIfood])


  const save = () => {
    saveConfig(configIfoodModal)
  }

  return (
    <React.Fragment>
      {configIfoodModal && (

        <Modal
          isOpen={isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          tabIndex="-1"
          scrollable={true}
          size="xl"
          toggle={toggle}
        >
          <div className="modal-content">

            <ModalHeader toggle={toggle}>{props.t("config-ifood.title")}</ModalHeader>
            <ModalBody>

              <div className="outer">
                <div className="outer">
                  <Form>
                    {isEditing && (
                      <FormInputText
                        attribute="id"
                        description={props.t("config-ifood.id")}
                        value={configIfoodModal.id}
                        readOnly={true}
                        disabled={true}
                      >
                      </FormInputText>
                    )}

                    <FormInputNumber
                      attribute="idContrato"
                      description={props.t("config-ifood.contrato")}
                      value={configIfoodModal.idContrato}
                      setValue={e => setConfigIfoodModal({ ...configIfoodModal, idContrato: e })}
                      min={0}
                    >
                    </FormInputNumber>

                    <FormInputDate
                      attribute="liberadoAte"
                      description={props.t("config-ifood.liberado.ate")}
                      value={new Date(configIfoodModal.LiberadoAte)}
                      setValue={(e) => setConfigIfoodModal({ ...configIfoodModal, LiberadoAte: e })}
                      dateFormat={"dd/MM/yyyy HH:mm"}
                      withHour={true}
                    />


                    <FormInputNumber
                      attribute="merchantId"
                      description={props.t("config-ifood.merchant.id")}
                      value={configIfoodModal.merchant_id}
                      setValue={e => setConfigIfoodModal({ ...configIfoodModal, merchant_id: e })}
                      min={0}
                    >
                    </FormInputNumber>

                    <FormInputText
                      attribute="merchantUUID"
                      description={props.t("config-ifood.merchant.uuid")}
                      value={configIfoodModal.merchant_uuid}
                      setValue={e => setConfigIfoodModal({ ...configIfoodModal, merchant_uuid: e })}
                    >
                    </FormInputText>

                    {/*
                      Used input decoy to prevent browser autocomplete
                    */}

                    <input type="text"
                           style={{
                             position: "absolute",
                             top: 0,
                             left: 0,
                             opacity: 0,
                             zIndex: -1,
                             caretColor: "transparent",
                             backgroundColor: "transparent"
                           }}>

                    </input>


                    <input type={"password"}
                           style={{
                             position: "absolute",
                             top: 0,
                             left: 0,
                             opacity: 0,
                             zIndex: -1,
                             caretColor: "transparent",
                             backgroundColor: "transparent"
                           }}>
                    </input>

                    <FormInputPassword
                      attribute="clientId"
                      description={props.t("config-ifood.client.id")}
                      value={configIfoodModal.clientid}
                      setValue={(e) => setConfigIfoodModal({...configIfoodModal, clientid: e })}
                    />

                    <FormInputPassword
                      attribute="clientSecret"
                      description={props.t("config-ifood.client.secret")}
                      value={configIfoodModal.clientsecret}
                      setValue={(e) => setConfigIfoodModal({...configIfoodModal, clientsecret: e })}
                    />

                    <FormInputSelect
                      attribute="authTipo"
                      description={props.t("config-ifood.auth.tipo")}
                      value={configIfoodModal.auth_tipo}
                      setValue={(e) => setConfigIfoodModal({ ...configIfoodModal, auth_tipo: e })}
                      options={tipoOptions}
                    />

                    <FormInputTypeNumber
                      attribute="polling_interval"
                      description={props.t("config-ifood.polling")}
                      value={configIfoodModal.polling_interval}
                      setValue={(e) => setConfigIfoodModal({ ...configIfoodModal, polling_interval: e })}
                      maxLength={70}
                      validacao={configIfoodModal.polling_interval >= 30 && configIfoodModal.polling_interval <= 120}
                      validacaoMensagem={"Polling está inválido"}
                    />

                    {isEditing && (
                      <>
                        <FormInputText
                          attribute="refreshToken"
                          description={props.t("config-ifood.refresh.token")}
                          value={configIfoodModal.refresh_token}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>

                        <FormInputText
                          attribute="refreshTokenData"
                          description={props.t("config-ifood.refresh.token.data")}
                          value={describeDateTime(configIfoodModal.refresh_token_data)}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>

                        <FormInputText
                          attribute="merchantStatus"
                          description={props.t("config-ifood.merchant.status")}
                          value={configIfoodModal.merchant_status}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>

                        <FormInputText
                          attribute="merchantStatusMotivo"
                          description={props.t("config-ifood.merchant.status.motivo")}
                          value={configIfoodModal.merchant_status_motivo}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>

                        <FormInputText
                          attribute="merchantStatusData"
                          description={props.t("config-ifood.merchant.status.data")}
                          value={describeDateTime(configIfoodModal.merchant_status_data)}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>

                        <FormInputText
                          attribute="merchantStatusAltFunc"
                          description={props.t("config-ifood.merchant.status.altfunc")}
                          value={configIfoodModal.merchant_status_altfunc}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>

                        <FormInputText
                          attribute="merchantName"
                          description={props.t("config-ifood.merchant.name")}
                          value={configIfoodModal.merchant_name}
                          readOnly={true}
                          disabled={true}>
                        </FormInputText>
                      </>
                    )}
                  </Form>

                </div>
              </div>

            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary"
                      disabled={(!isEditing && (!configIfoodModal.clientid || !configIfoodModal.clientsecret))
                        || !(configIfoodModal.LiberadoAte && configIfoodModal.merchant_id != null && configIfoodModal.merchant_uuid && configIfoodModal.auth_tipo
                          && configIfoodModal.polling_interval)
                      }
                      onClick={e => save()}>
                {props.t("config-ifood.modal.save")}
              </Button>
              <Button type="button" color="secondary" className="me-2" onClick={toggle}>
                {props.t("config-ifood.modal.close")}
              </Button>
            </ModalFooter>

          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}

ConfigIfoodModal.propTypes = {
  toggle: PropTypes.func,
  saveTemplate: PropTypes.func,
  isOpen: PropTypes.bool,
  templateEmail: PropTypes.object,
  isEditing: PropTypes.bool
}

export default withTranslation()(ConfigIfoodModal)
