import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form, FormGroup, Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap"
import { connect } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import usePermission from "helpers/permission/usePermission"
import { Filters } from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import UsuariosTable from "./usuarios-table"
import {
  buscarEmpresaPorUsuario,
  buscarVinculosPorUsuario,
  exportarUsuarioCSV,
  rejeitarVinculos
} from "../../store/auth/profile/actions"
import { isEmpty, map } from "lodash"
import PropTypes from "prop-types"
import FormFilterNumber from "../../components/Common/form-filter-number"
import moment from "moment"
import FormFilterCompany from "../../components/Common/form-filter-company"
import { getCompanies, getCompaniesValuesByOptions } from "../../helpers/utils"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import FormInputCpfCnpj from "../../components/Common/form-input-cpf-cnpj"
import FormFilterText from "../../components/Common/form-filter-text"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const UsuariosLista = props => {

  const ref = useRef()
  const location = useLocation()
  const [filters, setFilters] = useState(null)
  const [companiesOption, setCompaniesOption] = useState([])
  const empresasVinculoId = getCompanies(props.user, false).map(empresa => empresa.value)
  const isInforangra = props.user?.usuario_info !== null
  const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
  const usePermissionEdit = usePermission("empresas_editar")
  const [itemSelecionado, setItemSelecionado] = useState()
  const [modalVisualizar, setModalVisualizar] = useState(false)
  const [modalCancelar, setModalCancelar] = useState(false)
  const [usuarioSemAcessoDias, setUsuarioSemAcessoDias] = useState()
  const [companies, setCompanies] = useState([])
  const [filterObj, setFilterObj] = useState({ codigo: null, cnpj: "", razaoSocial: "", fantasia: "", email: "" })

  useEffect(() => {
    if (props.user?.usuario_info) {
      props.buscarEmpresaPorUsuario()
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOption(companiesOptions)
    }
    props.navegarBreadCrumb({ title: `menu.label.users`, link: location.pathname })

  }, [])

  useEffect(() => {
    if (props.user?.usuario_info && Array.isArray(props.empresas)) {
      setCompaniesOption(props.empresas.map(empresa => {
        return {
          label: empresa.Fantasia,
          value: empresa.idEmpresa
        }
      }))
    }

    return () => {
      setCompaniesOption([])
    }
  }, [props.empresas])

  useEffect(() => {
    if (props.dashboardFilters.companiesSelected && !isInforangra) {
      setCompanies(props.dashboardFilters.companiesSelected)
    }
    populateFiltros(isInforangra ? null : props.dashboardFilters.companiesSelected)

  }, [companiesOption])

  const populateFiltros = (companiesSelected) => {
    const filters = []

    if (filterObj.codigo) {
      filters.push({ id: "vinculos.idEmpresa", value: [filterObj.codigo] })
    } else if (companiesSelected) {
      filters.push({ id: "vinculos.idEmpresa", value: getCompaniesValuesByOptions(companiesSelected) })
    } else if (companies) {
      filters.push({ id: "vinculos.idEmpresa", value: getCompaniesValuesByOptions(companies) })
    }

    if (isInforangra) filters.push({ id: "ignoreCompany", value: true, condition: Filters.equals })

    if (usuarioSemAcessoDias !== undefined && usuarioSemAcessoDias !== null) {
      const usuarioSemAcessoDiasStr = moment().subtract(usuarioSemAcessoDias, "days").format("YYYY-MM-DD 00:00:00")
      filters.push({ id: "data_ultimo_login", value: usuarioSemAcessoDiasStr, condition: Filters.lessThanOrEqualTo })
    }

    if (filterObj.cnpj) {
      filters.push({ id: "vinculos.empresa.CNPJ", value: filterObj.cnpj, condition: Filters.equals })
    }

    if (filterObj.razaoSocial) {
      filters.push({ id: "vinculos.empresa.RazaoSocial", value: filterObj.razaoSocial, condition: Filters.contains })
    }

    if (filterObj.fantasia) {
      filters.push({ id: "vinculos.empresa.Fantasia", value: filterObj.fantasia, condition: Filters.contains })
    }

    if (filterObj.email) {
      filters.push({ id: "email", value: filterObj.email, condition: Filters.contains })
    }

    setFilters(filters)
  }

  const pesquisar = () => {
    populateFiltros()
    setTimeout(() => ref?.current?.search(), 300)
  }

  const exportarCSV = () => {
    populateFiltros()
    setTimeout(() => {
      let paramsExport = ref.current.getParamsExport()
      props.exportarUsuarioCSV({ name: "usuario", paramsExport })
    }, 300)
  }

  const visualizarVinculos = (usuario) => {
    props.buscarVinculosPorUsuario({ ignoreCompany: isAdmin, usuarioId: usuario.id })
    setModalVisualizar(true)
  }

  const cancelarVinculos = (usuario) => {
    setItemSelecionado(usuario)
    setModalCancelar(true)
  }

  const removerVinculos = () => {
    // C = Cancelado (ele será gravado como rejeitado mas precisa da diferenciação devido o tipo do e-mail
    props.rejeitarVinculos({ id: itemSelecionado.id, status: "C" }, props.history)
    setModalCancelar(false)
  }

  const describeStatusColor = vinculo_status => {
    switch (vinculo_status) {
      case "A":
      case "T":
        return "success"
      case "R":
        return "danger"
      case "P":
      case "E":
        return "warning"
      default:
        return ""
    }
  }

  const describeStatus = vinculo_status => {
    switch (vinculo_status) {
      case "A":
        return "Aceito"
      case "R":
        return "Rejeitado"
      case "P":
        return "Pendente"
      case "T":
        return "Aceite Temporário"
      case "E":
        return "Expirado"
      default:
        return "N/D"
    }
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
      <AlertaSemVinculo />
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="menu.label.users" />

          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <Form>
                      <Row>
                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormGroup className="mt-3 mb-0">
                            <FormFilterCompany
                              attribute="companies"
                              value={companies}
                              description="Empresa(s)"
                              setValue={value => setCompanies(value)}
                              options={companiesOption}
                              multiple={true}
                              hasSelectAll={!props.user?.usuario_info}
                            />
                          </FormGroup>
                        </div>

                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormFilterNumber
                            classNameFormGrupo="mt-3 mb-0"
                            attribute="usuarioSemAcessoDias"
                            description="Usuários sem acesso a X dias"
                            value={usuarioSemAcessoDias}
                            changeHandeler={setUsuarioSemAcessoDias}
                            allowNegative={false}
                            max={9999999}
                          />
                        </div>
                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormFilterText
                            description="E-mail do usuário"
                            attribute="filterObj.email"
                            value={filterObj.email}
                            changeHandeler={value => setFilterObj({
                              ...filterObj,
                              email: value
                            })}
                          >
                          </FormFilterText>
                        </div>
                      </Row>
                      <Row>
                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormFilterText
                            description="Código da empresa"
                            attribute="filterObj.codigo"
                            value={filterObj.codigo}
                            changeHandeler={value => setFilterObj({
                              ...filterObj,
                              codigo: value
                            })}
                          >
                          </FormFilterText>
                        </div>

                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormGroup className="mt-3 mb-0">
                            <Label>CNPJ da empresa</Label>
                            <FormInputCpfCnpj
                              placeholder={'  '}
                              className='form-control'
                              attribute="cnpj"
                              value={filterObj.cnpj}
                              setValue={e => setFilterObj({
                                ...filterObj,
                                cnpj: e
                              })}
                            />
                          </FormGroup>
                        </div>

                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormFilterText
                            description="Razão Social da empresa"
                            attribute="filterObj.razaoSocial"
                            value={filterObj.razaoSocial}
                            changeHandeler={value => setFilterObj({
                              ...filterObj,
                              razaoSocial: value
                            })}
                          >
                          </FormFilterText>
                        </div>

                        <div className="col col-12 col-md-3 col-lg-2">
                          <FormFilterText
                            description="Fantasia da empresa"
                            attribute="filterObj.razaoSocial"
                            value={filterObj.fantasia}
                            changeHandeler={value => setFilterObj({
                              ...filterObj,
                              fantasia: value
                            })}
                          >
                          </FormFilterText>

                        </div>

                        {!props.exportarCSVLoading &&
                          <div className="col col-auto align-self-start mt-md-4 pt-1">
                            <div className="mt-3 col col-auto">
                              <Button color="primary" className="w-md"
                                      onClick={e => exportarCSV()}>
                                <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                CSV
                              </Button>
                            </div>
                          </div>}
                        <div className="col col-auto align-self-start mt-md-4 pt-1">
                          <div className="mt-3 col col-auto">
                            <Button color="primary" className="w-md"
                                    onClick={e => pesquisar()}>
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                {filters && <Card>
                  <CardBody>
                    <UsuariosTable ref={ref} filters={filters}
                                   history={props.history}
                                   usePermissionEdit={usePermissionEdit}
                                   visualizarVinculos={usuario => visualizarVinculos(usuario)}
                                   cancelarVinculos={usuario => cancelarVinculos(usuario)}
                                   user={props.user}
                                   companies={getCompanies(props.user, false).map(empresa => empresa.value)}
                                   isInforangra={props.user?.usuario_info !== null}
                                   isInforangraAdmin={props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador}>
                    </UsuariosTable>
                    <Modal isOpen={modalVisualizar} role="dialog"
                           autoFocus={true} centered={true}
                           tabIndex="-1" scrollable={true} size="xl"
                           onExit={() => setModalVisualizar(false)}>
                      <div className="modal-content">
                        <ModalHeader tag="h4">Vinculo(s) do usuário</ModalHeader>
                        <ModalBody>
                          <div className="table-responsive">
                            <Table className="table table-centered table-nowrap">
                              <thead>
                              <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Empresa</th>
                                <th scope="col">Perfil</th>
                                <th scope="col">Status</th>
                              </tr>
                              </thead>
                              <tbody>
                              {(props.vinculosLoading) || (isEmpty(props.vinculos) && (
                                  <tr>
                                    <td>
                                      <h5 className="text-truncate font-size-14 m-0">
                                        Não há nenhum vínculo para este usuário
                                      </h5>
                                    </td>
                                  </tr>
                                )) ||
                                map(props.vinculos, (item, i) => (
                                  <tr key={i}>
                                    <td>
                                      <div>
                                        <p className="text-muted mb-0">{item.id}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p className="text-muted mb-0">
                                          {item.empresa_RazaoSocial}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p className="text-muted mb-0">
                                          {item.perfil_Nome}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <Badge
                                          className={"font-size-12 badge-soft-" + describeStatusColor(item.vinculo_status)}
                                          color={describeStatusColor(item.vinculo_status)}
                                          pill
                                        >
                                          {describeStatus(item.vinculo_status)}
                                        </Badge>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button type="button" color="secondary" onClick={() => setModalVisualizar(false)}>
                            Fechar
                          </Button>
                        </ModalFooter>
                      </div>
                    </Modal>
                    <Modal isOpen={modalCancelar}>
                      <ModalHeader tag="h4">Confirmação</ModalHeader>
                      <ModalBody>
                        <Row form>
                          <Col className="col-12">
                            {props.user?.id !== itemSelecionado?.id ? "Deseja realmente remover os vínculos deste usuário" : "Você não pode remover seus próprios vínculos"}
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        {props.user?.id !== itemSelecionado?.id &&
                          <Button type="submit" color="danger" onClick={e => removerVinculos()}>
                            Remover
                          </Button>}
                        <Button type="button" color="secondary" className="ms-2" onClick={e => setModalCancelar(false)}>
                          Cancelar
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </CardBody>
                </Card>}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UsuariosLista.propTypes = {
  buscarVinculosPorUsuario: PropTypes.func,
  rejeitarVinculos: PropTypes.func,
  vinculos: PropTypes.array,
  vinculosLoading: PropTypes.bool,
  loading: PropTypes.bool,
  user: PropTypes.any
}

const mapStatetoProps = state => {
  const { vinculos, vinculosLoading, loading, exportarCSVLoading, empresas, empresasLoading } = state.Profile
  const { user, dashboardFilters } = state.Login
  return { user, vinculos, vinculosLoading, loading, dashboardFilters, exportarCSVLoading, empresas, empresasLoading }
}

export default connect(mapStatetoProps, {
  buscarVinculosPorUsuario,
  rejeitarVinculos,
  exportarUsuarioCSV,
  buscarEmpresaPorUsuario,
  navegarBreadCrumb
})(withRouter(UsuariosLista))
