import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { apiBuscarNCMs } from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import { Link } from "react-router-dom"
import { Box, TextField } from "@mui/material"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import { ptBR as ptBRDatePickers } from "@mui/x-date-pickers"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { describeDateTime, removeHour } from "../../helpers/utils"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const NCMTable = forwardRef((props, refFather) => {

  const ref = useRef()

  const {
    filters,
    columns,
    usePermissionEdit,
    navegarEditarNCM,
    isUsuarioInforAngra,
    usePermissionRemove,
    showConfirmDeleteNCM,
    showObservation
  } = props

  const [data, setData] = useState([])

  useImperativeHandle(refFather, () => ({
    search() {
      ref?.current?.search()
    }
  }))

  const colunas = useMemo(() => columns ||
    [
      {
        header: "ID Cloud", accessorKey: "id", size: 50, enableHiding: false, muiTableBodyCellProps: { align: "center" }
      },
      { header: "Registro", accessorKey: "ncm", size: 80, filterFn: "contains" },
      { header: "Descrição", accessorKey: "descricao", size: 120, filterFn: "contains" },
      { header: "Categoria", accessorKey: "categoria", size: 120, filterFn: "contains" },
      {
        header: "Início de vigência",
        accessorKey: "vigencia_inicio",
        size: 170,
        Cell: ({ cell }) => describeDateTime(cell?.getValue()),
        filterFn: "greaterThanOrEqualTo",
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
            <DatePicker
              onChange={(newValue) => {
                if (newValue) {
                  newValue = removeHour(newValue, false)
                }
                column.setFilterValue(newValue)
              }}
              inputFormat="dd/MM/yyyy"
              componentsProps={{
                actionBar: {
                  actions: ["accept", "clear"]
                }
              }}
              value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
              views={["year", "month", "day"]}
              renderInput={(props) => <TextField {...props} variant="standard" />}
            />
          </LocalizationProvider>
        )
      },
      {
        header: "Fim de vigência",
        accessorKey: "vigencia_final",
        size: 150,
        Cell: ({ cell }) => describeDateTime(cell?.getValue()),
        filterFn: "lessThanOrEqualTo",
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
            <DatePicker
              onChange={(newValue) => {
                if (newValue) {
                  newValue = removeHour(newValue, false)
                }
                column.setFilterValue(newValue)
              }}
              inputFormat="dd/MM/yyyy"
              componentsProps={{
                actionBar: {
                  actions: ["accept", "clear"]
                }
              }}
              value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
              views={["year", "month", "day"]}
              renderInput={(props) => <TextField {...props} variant="standard" />}
            />
          </LocalizationProvider>
        )
      },
      { header: "Unidade Trib.", accessorKey: "uTrib", size: 140, filterFn: "contains" },
      { header: "Descrição Unidade", accessorKey: "uTribDescricao", size: 180, filterFn: "contains" },
      { header: "IPI %", accessorKey: "IPI", size: 50, enableColumnFilter: false },
      {
        header: "Data inclusão",
        accessorKey: "IncData",
        size: 160,
        enableColumnFilter: false,
        Cell: ({ cell }) => describeDateTime(cell?.getValue())
      },
      {
        header: "Data alteração",
        accessorKey: "AltData",
        size: 160,
        enableColumnFilter: false,
        Cell: ({ cell }) => describeDateTime(cell?.getValue())
      },
      {
        header: "Observação",
        accessorKey: "observacao",
        size: 130,
        enableColumnFilter: false,
        Cell: ({ cell }) => <Link to="#" className="text-primary">
          <i className="mdi mdi-pencil font-size-18 me-3" id="infotooltip"
             onClick={() => {
               if (cell?.getValue()) {
                 showObservation(cell?.getValue())
               }
             }}
             title="Informações"
          />
        </Link>
      },
      { header: "Novo NCM", accessorKey: "de_para", size: 80, filterFn: "equal" }
    ], [])

  return (
    <React.Fragment>
      <ListDataTable
        ref={ref}
        searchFn={params => apiBuscarNCMs(params)}
        advancedFilters={filters}
        columns={colunas}
        data={data}
        setData={setData}
        columnVisibility={{
          "observacao": false
        }}
        enableRowActions={true}
        defaultSorting={[{
          id: "id",
          desc: false
        }]}
        renderRowActions={(row, table) =>
          (<Box sx={{ display: "flex", gap: "1rem" }}>
            {isUsuarioInforAngra && usePermissionEdit && <Link to="#" className="text-primary">
              <i className="mdi mdi-pencil font-size-18 ms-1" id="edittooltip"
                 onClick={() => navegarEditarNCM(row.original)}
                 title="Editar"
              />
            </Link>}

            {isUsuarioInforAngra && usePermissionRemove &&
              <Link to="#" className="text-danger">
                <i className="mdi mdi-delete font-size-18 me-3" id="deletetooltip"
                   onClick={() => showConfirmDeleteNCM(row.original)}
                   title="Remover" />
              </Link>}
          </Box>)
        }
      />
    </React.Fragment>
  )
})

export default NCMTable

NCMTable.propTypes = {
  filters: PropTypes.array,
  columns: PropTypes.array,
  isUsuarioInforAngra: PropTypes.object,
  usePermissionEdit: PropTypes.bool,
  usePermissionRemove: PropTypes.bool,
  navegarEditarNCM: PropTypes.func,
  showConfirmDeleteNCM: PropTypes.func,
  showObservation: PropTypes.func
}
