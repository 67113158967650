import PropTypes from "prop-types"
import React from "react"
import { FormGroup, Label } from "reactstrap"
import {isArray} from "leaflet/src/core/Util"

const FormFilterSwitch = props => {
  const { attribute, value, description, changeHandeler, trueValue, falseValue, disabled } = props

  return (
    <React.Fragment>
      <FormGroup className="mt-3 mb-0">
        <Label>{description}</Label>
          <div>
              <input
                  type="checkbox"
                  id={ attribute + "-switch" }
                  name={ attribute + "-switch"}
                  switch="none"
                  checked={ value === (trueValue || 'true') ? true : false }
                  onChange={ () => {
                      changeHandeler(value === (trueValue || 'true') ? (falseValue || 'false') : (trueValue || 'true') )
                  } }
                  disabled={disabled}
              />
              <label
                  htmlFor={ attribute + "-switch"}
                  data-on-label="Sim"
                  data-off-label="Não"
              />
          </div>

      </FormGroup>
    </React.Fragment>
  )
}

FormFilterSwitch.propTypes = {
  attribute: PropTypes.string,
  value: PropTypes.any,
  description: PropTypes.string,
  changeHandeler: PropTypes.func,
  options: PropTypes.any,
  disabled: PropTypes.bool
}

export default FormFilterSwitch
