import {
  API_ERROR,
  DASHBOARD_DATA,
  DASHBOARD_FILTERS, DASHBOARD_SYNC, DASHBOARD_SYNC_RESULT,
  GET_DASHBOARD_DATA,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS, SOCIAL_LOGIN_TOKEN, SOCIAL_LOGIN_TOKEN_RESULT
} from "./actionTypes"
import {
  ALTERAR_VINCULOS,
  ALTERAR_VINCULOS_RESULT,
  BUSCAR_PERFIS,
  BUSCAR_PERFIS_RESULT,
  PROFILE_SUCCESS
} from "../profile/actionTypes"
import { SOLICITAR_VINCULO_SUCCESSFUL } from "../register/actionTypes"

const initialState = {
  error: "",
  loading: false,
  solicitacoes: [],
  vinculos: [],
  perfis: [],
  preferencias: null,
  dashboards: {},
  dashboardData: null,
  dashboardFilters: {},
  dashboardInicial: 'Padrão Food Service',
  currentPeriod: new Date()
}

const login = (state = initialState, action) => {
  console.debug("[Login][Reducer]", { state, action })

  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loadingLogin: true
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loadingLogin: false,
        user: action.payload.data?.user,
        token: action.payload.data?.token,
        preferences: action.payload.data?.preferences ? JSON.parse(action.payload.data?.preferences) : null,
        dashboardData: null,
        dashboards: {},
        dashboardFilters: {},
        error: null
      }

      state.user.permissoes = Array.isArray(action.payload.data?.permissoes) ? action.payload.data.permissoes : []
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...initialState }
      break
    case GET_DASHBOARD_DATA:
      if (action.payload.type) {
        const tmp = {}
        tmp[`loadingDashboard${ action.payload.type }`] = true
        tmp[`dashboard${ action.payload.type }`] = null
        state = { ...state, ...tmp }
      } else {
        state = { ...state, ...{ loadingDashboard: true } }
      }

      state = {
        ...state,
        ...{ currentPeriod: action.payload.period || new Date() }
      }
      break
    case DASHBOARD_DATA:
      if (action.payload.type) {
        const tmp = {}
        tmp[`loadingDashboard${ action.payload.type }`] = false
        tmp[`dashboard${ action.payload.type }`] = action.payload.data
        state = { ...state, ...tmp }
      } else {
        state = {
          ...state,
          ...{
            dashboardData: Object.keys(action.payload).length
              ? action.payload
              : null,
            loadingDashboard: false
          }
        }

        if (state.dashboardData) {
          state.dashboardData.top_produtos[0].map(top_produto => {
            top_produto.qtd_total = parseInt(top_produto.qtd_total)
            return top_produto
          })
        }
      }
      break

    case DASHBOARD_FILTERS:
      const companiesSelectedDashboard = action.payload.filters.companiesSelected.find(cs => cs.dashboardInicial)
      state = {
        ...state,
        dashboardFilters: {
          ...state.dashboardFilters,
          ...action.payload.filters
        },
        dashboardInicial: companiesSelectedDashboard ? companiesSelectedDashboard.dashboardInicial: 'Padrão Food Service'
      }
      break
    case DASHBOARD_SYNC:
      let tmpSync = {}
      tmpSync[`loadingDashboardSync${ action.payload.type }`] = true
      state = {
        ...state,
        ...tmpSync
      }
      break
    case DASHBOARD_SYNC_RESULT:
      let tmpSyncResult = {}
      tmpSyncResult[`loadingDashboardSync${ action.payload.type }`] = false
      tmpSyncResult[`dashboardSync${ action.payload.type }`] = action.payload.data
      state = {
        ...state,
        ...tmpSyncResult
      }
      break
    case ALTERAR_VINCULOS:
      state = {...state, ...{vinculosLoading: true}}
      break

    case ALTERAR_VINCULOS_RESULT:
      if (action.payload.data.success) {
        state.user.vinculos = state.user.vinculos.map(e => {
          const vinculo = action.payload.data.vinculos.find(
            vinculo => vinculo.id == e.id
          )
          return vinculo || e
        })
      }

      state = { ...state, ...{ vinculosLoading: false } }
      break

    case BUSCAR_PERFIS:
      state = { ...state, loading: true, perfis: [] }
      break

    case BUSCAR_PERFIS_RESULT:
      state = {
        ...state,
        loading: false,
        perfis: Array.isArray(action.payload.data)
          ? action.payload.data
          : []
      }
      break

    case SOLICITAR_VINCULO_SUCCESSFUL:
      state.user.vinculos.push(action.payload)

      state = {
        ...state
      }
      break
    case SOCIAL_LOGIN_TOKEN:
      state = {
        ...state,
        socialLoginTokenLoading: true,
        socialLoginTokenObj: null
      }
      break
    case SOCIAL_LOGIN_TOKEN_RESULT:
      state = {
        ...state,
        socialLoginTokenLoading: false,
        socialLoginTokenObj: action.payload.data
      }
      break
    case API_ERROR:
      const error =
        action.payload &&
        action.payload.response &&
        action.payload.response.data &&
        Array.isArray(action.payload.response.data)
          ? action.payload.response.data.map(e => e.message).join("<br/>")
          : null

      state = { ...state, error: error || action.payload, loading: false, loadingLogin: false }
      break
    default:
      return state
  }
  return state
}

export default login
