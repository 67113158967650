import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {apiBuscarNotificacoes} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDate, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {Badge, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {Link} from "react-router-dom"
import {AvForm} from "availity-reactstrap-validation"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const NotificacoesTable = forwardRef((props, refFather) => {

    const ref = useRef()

    const { filters, columns, usePermissionRemove, usePermissionEdit, removerNotificacao, navegarEditarNotificacao } = props
    const [data, setData] = useState([])
    const [modalRemove, setModalRemove] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState()

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        }
    }));

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const remover = () => {
        removerNotificacao(itemSelecionado)
        setModalRemove(false)
    }

    const getBadgeElement = (cell) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (cell.getValue() === 'E' ? 'primary higher' : 'soft-dark')}
            color={cell.getValue() === 'E' ? 'primary higher' : 'soft-dark'}
            pill
        >
            {cell.getValue() === 'E' ? 'Entregue' : 'Não entregue'}
        </Badge>
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "ID Cloud", accessorKey: "id", size: 80, enableHiding: false, muiTableBodyCellProps: { align: 'right', }, },
            {header: "Título", accessorKey: "titulo", size: 120},
            {header: "Status", accessorKey: "status_entregue", size: 150, Cell: ({cell}) => getBadgeElement(cell), enableColumnFilter: false },
            {header: "Data entrega", accessorKey: "data_entrega", size: 130,
                Cell: ({cell}) => describeDate(cell?.getValue()),
                accessorFn: (row) => row.data_entrega ? new Date(removeHour(row.data_entrega)) : null,
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Empresa", accessorKey: "nomeEmpresa", size: 120, Cell: ({cell}) => (cell?.getValue() || 'Todas empresas'), enableColumnFilter: false, filterFn: 'contains'},
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarNotificacoes(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
                enableRowActions={true}
                renderRowActions={(row, table) =>
                    (<Box sx={{display: 'flex', gap: '1rem'}}>
                        <Link to="#" className="text-primary">
                            <i className="mdi mdi-eye font-size-18 ms-1" id="viewtooltip"
                               onClick={e => navegarEditarNotificacao(row.original)}
                               title="Visualizar"
                            />
                        </Link>
                        {usePermissionEdit && <Link to="#" className="text-primary">
                            <i className="mdi mdi-pencil font-size-18 ms-1" id="edittooltip"
                               onClick={e => navegarEditarNotificacao(row.original)}
                               title="Editar"
                            />
                        </Link>}
                        {usePermissionRemove && <Link to="#" className="text-danger">
                            <i className="mdi mdi-delete font-size-18" id="btnDelete"
                               onClick={() => showConfirmDelete(row.original)}
                                title="Remover"/>
                        </Link>}
                    </Box>)
                }
            />
            <Modal isOpen={modalRemove}>
                <ModalHeader tag="h4">Confirmação</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={(e, v) => remover()}>
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente remover esse registro?
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div className="text-right">
                                    <button type="submit" className="btn btn-success save-event">
                                        Confirmar
                                    </button>
                                    <button type="button" className="btn btn-light ms-2" onClick={e => setModalRemove(false)}>
                                        Cancelar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
})

export default NotificacoesTable

NotificacoesTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
    usePermissionRemove: PropTypes.bool,
    usePermissionEdit: PropTypes.bool,
    history: PropTypes.any,
    navegarEditarNotificacao: PropTypes.func,
    removerNotificacao: PropTypes.func,
}
