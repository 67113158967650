import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, Col, Container, Form, Row} from "reactstrap"
import {connect} from "react-redux"
import { useLocation, withRouter } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import {buscarNotificacoes, editarNotificacao, navegarEditarNotificacao, removerNotificacao} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import {getCompanies, getCompaniesValuesByOptions, getCompanyOption} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import FormFilterSelect from "../../components/Common/form-filter-select"
import NotificacoesTable from "./notificacoes-table";
import FormFilterSwitch from "../../components/Common/form-filter-switch";


registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const NotificacaoLista = props => {


  const location = useLocation();

  useEffect(() => {
    props.buscarNotificacoes()
    props.navegarBreadCrumb({title: `menu.label.notification`, link: location.pathname})
  }, [])
    const ref = useRef();

    const [filters, setFilters] = useState(null)

    const [companies, setCompanies] = useState([])
    const [companiesOption, setCompaniesOption] = useState([])
    const [situation, setSituation] = useState()
    const [semEmpresa, setSemEmpresa] = useState()

    const usePermissionRemove = usePermission("notificacoes_excluir")
    const usePermissionInsert = usePermission("notificacoes_inserir")
    const usePermissionEdit = usePermission("notificacoes_editar")
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
    const inforangraAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador

    useEffect(() => {
        if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)

        if (possuiVinculo) {
            populateFiltros(props.dashboardFilters.companiesSelected)
        }
    }, [])

    useEffect(() => {
        if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
            let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
            setCompaniesOption(companiesOptions)
        } else {
            let companiesOptions = getCompanies(props.user)
            setCompaniesOption(companiesOptions)
        }
        return () => {
            setCompaniesOption([])
        }
    }, [props.user, props.empresas])

    useEffect(() => {
        if(props.notificacaoRemovido) {
            pesquisar()
        }
    }, [props.notificacaoRemovido])

    const populateFiltros = (companiesSelected, grupoFiltro) => {
        const filters = []

        if(semEmpresa === "true") {
            filters.push({id: 'semEmpresa', value: true})
        } else {
            if(companiesSelected) {
                filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
            } else if(companies) {
                filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies)})
            }
        }

        if(situation) {
            filters.push({id: 'status_entregue', value: situation})
        }
        setFilters(filters)
    }

    const pesquisar = (idEmpresaFiltro, grupoFiltro) => {
        populateFiltros(idEmpresaFiltro, grupoFiltro)
        setTimeout(() => ref?.current?.search(), 300)
    }

    const goToNotificacao = item => {
      props.navegarEditarNotificacao(item)
      props.history.push("/notificacao"+(item?`/${item.id}`: ""))
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return ( <AlertaSemVinculo/> )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="menu.label.notification"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <div className="col-md-3 col-sm-12">
                                                    <FormFilterCompany
                                                        attribute="companies"
                                                        value={companies}
                                                        description="Empresa(s)"
                                                        setValue={(value) => {
                                                            setCompanies(value)
                                                            setSemEmpresa(false)
                                                        }}
                                                        options={companiesOption}
                                                        multiple={true}
                                                        hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                                                    />
                                                </div>

                                                { inforangraAdmin && <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterSwitch
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="semEmpresa"
                                                        description="Todas empresas"
                                                        value={semEmpresa}
                                                        changeHandeler={(value) => {
                                                            setSemEmpresa(value)
                                                            if(value || value === 'true') {
                                                                setCompanies([])
                                                            }
                                                        }}
                                                    />
                                                </div> }

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="status_entregue"
                                                        value={situation}
                                                        description="Situação"
                                                        changeHandeler={value => setSituation(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "E", label: "Entregue"},
                                                            {value: "N", label: "Não entregue"},
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>

                                                    <div className="mt-3 col col-auto">
                                                        <Button
                                                            type="button"
                                                            color="success"
                                                            disabled={!(usePermissionEdit || usePermissionInsert)}
                                                            onClick={e => goToNotificacao(null)}>
                                                            <i className="bx bx-plus"/> Cadastrar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && <Card>
                                    <CardBody>
                                        <NotificacoesTable ref={ref} filters={filters}
                                                           history={props.history}
                                                           usePermissionRemove={usePermissionRemove}
                                                           usePermissionEdit={usePermissionEdit}
                                                           navegarEditarNotificacao={goToNotificacao}
                                                           removerNotificacao={(data) => props.removerNotificacao({id: data.id}, props.history)}>
                                        </NotificacoesTable>
                                    </CardBody>
                                </Card> }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
  }



NotificacaoLista.propTypes = {
    notificacaoRemovido: PropTypes.any,
    company: PropTypes.any,
    removerNotificacao: PropTypes.func,
    editarNotificacao: PropTypes.func
}

const mapStatetoProps = state => {
    const { notificacaoRemovido, company, empresas, empresasLoading } = state.Profile
    const { user, dashboardFilters } = state.Login
    return { user, notificacaoRemovido, dashboardFilters, company, empresas, empresasLoading }
}

export default connect(mapStatetoProps, {
  buscarNotificacoes,
  navegarEditarNotificacao,
  removerNotificacao,
  editarNotificacao,
  navegarBreadCrumb
})(withRouter(NotificacaoLista))
