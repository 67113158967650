import PropTypes from "prop-types"
import React from "react"
import { FormGroup, Label } from "reactstrap"
import {isArray} from "leaflet/src/core/Util"
import {withTranslation} from "react-i18next";

const FormFilterSelect = props => {
  const { attribute, value, description, changeHandeler, options, keyTranslate } = props

  return (
    <React.Fragment>
      <FormGroup className="mt-3 mb-0">
        <Label>
          { keyTranslate ? props.t(keyTranslate + '.label') : description }
        </Label>
        <select id={attribute} className="form-select select2-search-disable" value={value} onChange={e => changeHandeler(e.target.value)} >
          {isArray(options) && options.map((item, key) => (
              <option value={item.value}
                      key={item.value}
                      defaultValue={item.defaultValue}>
                {item.label}
              </option>
          ))}
        </select>
      </FormGroup>
    </React.Fragment>
  )
}

FormFilterSelect.propTypes = {
  attribute: PropTypes.string,
  keyTranslate: PropTypes.string,
  value: PropTypes.any,
  description: PropTypes.string,
  changeHandeler: PropTypes.func,
  options: PropTypes.any
}

export default withTranslation()(FormFilterSelect)
