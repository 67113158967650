import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Badge, Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {connect} from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import momentTimezone from "moment-timezone"
import {
    editarGrupoCardapio,
    navegarEditarGrupoCardapio, relatorioExecutar,
    removerGrupoCardapio
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import GruposTable from "./grupos-table"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {getCompanies, getCompaniesValuesByOptions, getCompanyOption} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import { navegarBreadCrumb } from "../../store/navigator/actions"

const CardapioDigitalGrupoLista = props => {

    const ref = useRef();

    const [filters, setFilters] = useState(null)

    const [companies, setCompanies] = useState([])
    const [companiesOption, setCompaniesOption] = useState([])
    const location = useLocation();
    const usePermissionRemove = usePermission("gruposCardapio_excluir")
    const usePermissionInsert = usePermission("gruposCardapio_inserir")
    const usePermissionEdit = usePermission("gruposCardapio_editar")
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    useEffect(() => {
        if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)
        if (possuiVinculo) {
            populateFiltros(props.dashboardFilters.companiesSelected)
        }
        props.navegarBreadCrumb( {title: `breadcrumb.digital.menu.title`, link: location.pathname })
    }, [])

    useEffect(() => {
        if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
            let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
            setCompaniesOption(companiesOptions)
        } else {
            let companiesOptions = getCompanies(props.user)
            setCompaniesOption(companiesOptions)
        }
        return () => {
            setCompaniesOption([])
        }
    }, [props.user, props.empresas])

    useEffect(() => {
        if(props.grupoCardapioRemovido) {
            pesquisar()
        }
    }, [props.grupoCardapioRemovido])

    const populateFiltros = (companiesSelected) => {
        const filters = []

        if(companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        } else if(companies) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies)})
        }

        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => ref?.current?.search(), 300)
    }

    const goToGrupo = item => {
        props.navegarEditarGrupoCardapio(item)
        props.history.push( "/cardapio-digital-grupo"+(item?`/${item.id}`: ""))
    }

    const printPDF = () => {
        let reportName = 'grupo_cardapio';
        props.relatorioExecutar({
            company: getCompaniesValuesByOptions(companies),
            reportName
        });
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="breadcrumb.group.categories"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Grupos de cardápio</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-md-4 col-sm-12">
                                                    <FormFilterCompany
                                                        attribute="companies"
                                                        value={companies}
                                                        description="Empresa(s)"
                                                        setValue={ value => setCompanies(value) }
                                                        options={companiesOption}
                                                        multiple={ true }
                                                        hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                                                    />
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md" onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>

                                                    <div className="mt-3 col col-auto">
                                                        <Button
                                                            type="button"
                                                            color="success"
                                                            disabled={ !(usePermissionEdit || usePermissionInsert) }
                                                            onClick={ e => goToGrupo(null) }>
                                                            <i className="bx bx-plus" /> Cadastrar
                                                        </Button>
                                                    </div>

                                                    { !props.executaRelatorioLoading &&
                                                        <div className="mt-3 col col-auto">
                                                            <Button type="button" color="light" onClick={ e => printPDF() }>
                                                                <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                                                PDF
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                { filters && <Card>
                                    <CardBody>
                                        <GruposTable ref={ref} filters={filters}
                                                     history={props.history}
                                                     usePermissionRemove={ usePermissionRemove }
                                                     usePermissionEdit={ usePermissionEdit }
                                                     navegarEditarGrupoCardapio={ goToGrupo }
                                                     removerGrupoCardapio={ (data) => props.removerGrupoCardapio({ id : data.id }, props.history)}>
                                        </GruposTable>
                                    </CardBody>
                                </Card> }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

CardapioDigitalGrupoLista.propTypes = {
    grupoCardapioRemovido: PropTypes.any,
    removerGrupoCardapio: PropTypes.func,
    editarGrupoCardapio: PropTypes.func,
    relatorioExecutar: PropTypes.func,
    navegarEditarGrupoCardapio: PropTypes.func,
}

const mapStatetoProps = state => {
    const { grupoCardapioRemovido, empresas, empresasLoading } = state.Profile
    const { user, dashboardFilters } = state.Login
    return { user, grupoCardapioRemovido, dashboardFilters, empresas, empresasLoading }
}

export default connect(mapStatetoProps, {
    navegarEditarGrupoCardapio,
    removerGrupoCardapio,
    editarGrupoCardapio,
    relatorioExecutar,
    navegarBreadCrumb
})(withRouter(CardapioDigitalGrupoLista))
