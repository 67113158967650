import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, Col, Container, Form, Row} from "reactstrap"
import {connect} from "react-redux"
import {useLocation, withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import {marcarNotificacaoUsuarioLida, marcarNotificacaoUsuarioNaoLida} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import FormFilterSelect from "../../components/Common/form-filter-select"
import PainelNotificacoesTable from "./painel-notificacoes-table";

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const PainelNotificacoesLista = props => {

    const ref = useRef();

    const [filters, setFilters] = useState([])

    const [tipoNotificacao, setTipoNotificacao] = useState()
    const [statusLeitura, setStatusLeitura] = useState("")

    const usePermissionEdit = usePermission("notificacoes_editar")

    useEffect(() => {
        if(props.location?.state && props.location?.state?.id) {
            pesquisar(props.location.state.id)
        }
    }, [props.location.state])

    useEffect(() => {
        if(!props.location?.state && !props.location?.state?.id && props.notificacoesUsuarioAlteracaoLoading) {
            pesquisar()
        }
    }, [props.notificacoesUsuarioAlteracaoLoading])

    const populateFiltros = (noticacaoUserId) => {
        const filters = []

        if(noticacaoUserId) {
            filters.push({id: 'id', value: noticacaoUserId})
        }
        if(statusLeitura) {
            filters.push({id: 'lido', value: statusLeitura === "L"})
        }

        if(tipoNotificacao) {
            filters.push({id: 'tipo_notificacao', value: tipoNotificacao})
        }

        setFilters(filters)
    }

    const pesquisar = (noticacaoUserId) => {
        populateFiltros(noticacaoUserId)
        setTimeout(() => ref?.current?.search(), 300)
    }

    const marcarNotificacaoComoLido = (e, notificacoesUsuarioSelected, exibeMensagem) => {
        if (Array.isArray(notificacoesUsuarioSelected) && notificacoesUsuarioSelected.length > 0) {
            for (const selecionado of notificacoesUsuarioSelected) {
                props.marcarNotificacaoUsuarioLida({ id: selecionado }, exibeMensagem)
            }
        }
    }

    const marcarNotificacaoComoNaoLido = (e, notificacoesUsuarioSelected) => {
        if (Array.isArray(notificacoesUsuarioSelected) && notificacoesUsuarioSelected.length > 0) {
            for (const selecionado of notificacoesUsuarioSelected) {
                props.marcarNotificacaoUsuarioNaoLida({ id: selecionado })
            }
        }
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return ( <AlertaSemVinculo/> )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Notificações" breadcrumbItem="Painel de Notificações"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Painel de Notificações</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-md-3 col-sm-12">
                                                    <FormFilterSelect
                                                        attribute="statusLeitura"
                                                        value={statusLeitura}
                                                        keyTranslate="painelnotificacao.lista.statusleitura"
                                                        changeHandeler={value => setStatusLeitura(value)}
                                                        options={[
                                                            {value: "", label: "Todos(as)", defaultValue: true},
                                                            {value: "L", label: "Somente lidas"},
                                                            {value: "N", label: "Somente não lidas"},
                                                        ]}
                                                    />
                                                </div>
                                                <div className="col-md-3 col-sm-12">
                                                    <FormFilterSelect
                                                        attribute="tipoNotificacao"
                                                        value={tipoNotificacao}
                                                        keyTranslate="painelnotificacao.lista.tiponotificacao"
                                                        changeHandeler={value => setTipoNotificacao(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "S", label: "Sistema"},
                                                            {value: "P", label: "Push Web"},
                                                            {value: "E", label: "E-mail"},
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <PainelNotificacoesTable ref={ref} filters={filters} user={props.user}
                                                                 usePermissionEdit={usePermissionEdit}
                                                                 marcarNotificacaoComoLido={marcarNotificacaoComoLido}
                                                                 marcarNotificacaoComoNaoLido={marcarNotificacaoComoNaoLido}>
                                        </PainelNotificacoesTable>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

PainelNotificacoesLista.propTypes = {
    notificacoesUsuarioLoading: PropTypes.bool,
    notificacoesUsuarioAlteracaoLoading: PropTypes.bool,
    history: PropTypes.object,
}

const mapStatetoProps = state => {
    const { notificacoesUsuarioAlteracaoLoading } = state.Profile
    const { user, dashboardFilters } = state.Login
    return { notificacoesUsuarioAlteracaoLoading, user, dashboardFilters }
}

export default connect(mapStatetoProps, {
    marcarNotificacaoUsuarioLida,
    marcarNotificacaoUsuarioNaoLida
})(withRouter(PainelNotificacoesLista))
