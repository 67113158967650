import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardTitle, Col, Collapse, Container, Form, FormGroup, Label, Row,} from "reactstrap"
import {connect} from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import 'react-phone-number-input/style.css'

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment-timezone"
import {
    buscarEstoque,
    buscarEstoqueFilialPorEstoque,
    cadastrarEstoque,
    cadastrarEstoqueMantemCadastro,
    editarEstoque,
    removerEstoque,
    navegarEditarEstoque
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import toastr from "toastr"
import FormInputNumber from "../../components/Common/form-input-number"
import FormSelectCompany from "../../components/Common/form-select-company"
import FormInputText from "../../components/Common/form-input-text"
import FormInputSelect from "../../components/Common/form-input-select"
import FormInputAsyncSelect from "../../components/Common/form-input-async-select"
import {
    apiBuscarFornecedoresPesquisa,
    apiBuscarGrupoEstoquePesquisa,
    apiBuscarGruposCardapioItemPesquisa,
    apiBuscarNCMsPesquisa,
    apiBuscarNfeImpostoPesquisa,
    apiBuscarTabelaCestPesquisa,
    apiBuscarTabelaUnidadesPesquisa
} from "../../helpers/backend_helper"
import FormInputSwitch from "../../components/Common/form-input-switch"
import FormInputMoney from "../../components/Common/form-input-money"
import FormInputDate from "../../components/Common/form-input-date"
import EstoqueFilialLista from "./estoque-filial-lista"
import Loader from "react-loader-spinner"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import ButtonRedirect from "../../components/Common/button-redirect"
import {getCompanies} from "../../helpers/utils";

const brazilMoment = (...args) => moment(...args)

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarEstoque = props => {

    const [paramIdCloud, setParamIdCloud] = useState(undefined)
    const usePermissionEdit = usePermission("estoques_editar")
    const usePermissionInsert = usePermission("estoques_inserir")
    const usePermissionRemove = usePermission("estoques_excluir")
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [clonado, setClonado] = useState(false)
    const [loading, setLoading] = useState(false)

    const [companies, setCompanies] = useState([{label: "Todas", value: ""}])
    const [id, setId] = useState()
    const [empresa, setEmpresa] = useState()
    const [codigo, setCodigo] = useState()

    const [iCodigo, setICodigo] = useState(0)
    const [tipo, setTipo] = useState() // Tipo - tipo2
    const [codigoBarra, setCodigoBarra] = useState() // Código EAN - codigo_barra
    const [descricao, setDescricao] = useState() // Descrição - Descricao
    const [descricaoOpcional, setDescricaoOpcional] = useState() // Descrição Resumida - desc_opcional
    const [unidade, setUnidade] = useState() // Unidade - UM
    const [ativo, setAtivo] = useState() // Ativo - ativo
    const [grupo, setGrupo] = useState() // Grupo - grupo
    const [subgrupo, setSubgrupo] = useState() // Sub grupo - subgrupo
    const [custo, setCusto] = useState() // Custo - custo
    const [diferencaCustoVenda, setDiferencaCustoVenda] = useState() // Diferença custo/venda - dcv
    const [venda, setVenda] = useState() // Venda - venda
    const [fornecedor, setFornecedor] = useState() // Últ. Fornecedor - Fornec

    // Dados de Estoque
    const [estoqueAtual, setEstoqueAtual] = useState() // Est. Atual - est_atual
    const [estoqueMinimo, setEstoqueMinimo] = useState() // Est. Mínimo - est_minimo
    const [estoqueMaximo, setEstoqueMaximo] = useState() // Est. Máximo - est_maximo
    const [embalagem, setEmbalagem] = useState() // Qtd. Embalagem Padrão Compra - embalagem
    const [ultimoEntrada, setUltimoEntrada] = useState() // Últ. Data Entrada - ult_entrada
    const [ultimoSaida, setUltimoSaida] = useState() // Últ. Data Saída - ult_saida
    const [ultimoBalanco, setUltimoBalanco] = useState() // Últ. Inventário Realizado - ult_balanco
    const [ultimaProducao, setUltimaProducao] = useState() // Últ. Ordem de Produção - ult_producao
    const [ultimaSaidaProducao, setUltimaSaidaProducao] = useState() // Últ. Saída para Produção - ult_saida_producao
    const [ultimoPreco, setUltimoPreco] = useState() // Últ. Ajuste de Preço - ult_preco
    // Vinculo com cardápio
    const [codigoCardapio, setCodigoCardapio] = useState() // Código do cardápio - cod_cardapio
    // Impostos
    const [imposto, setImposto] = useState() // Imposto de saída - imp_id
    const [ncm, setNcm] = useState() // Código NCM - cod_ncm
    const [cest, setCest] = useState() // Código CEST - cest
    const [contaEntrada, setContaEntrada] = useState() // Conta de entrada - conta_entrada
    const [contaSaida, setContaSaida] = useState() // Conta de saída - conta_saida
    // Dados para Balança de Peso
    const [tempoUtil, setTempoUtil] = useState() // Tempo de validade em dias - tempo_util
    const [validade, setValidade] = useState()  // Última data de validade informada - validade
    const [balanca, setBalanca] = useState() // Envia para balança - balanca
    const [balancaTecla, setBalancaTecla] = useState() // Número da tecla na balança - balanca_tecla
    const [balancaValidade, setBalancaValidade] = useState() // Tempo de validade em dias - balanca_validade
    // Dados adicionais
    const [dataAlteracao, setDataAlteracao] = useState() // Alteração do cadastro - altfunc
    const [usuarioAlteracao, setUsuarioAlteracao] = useState() // Usuário que alterou - altdata

    // accordions
    const [accordionEstoque, setAccordionEstoque] = useState(false)
    const [accordionVinculo, setAccordionVinculo] = useState(false)
    const [accordionImpostos, setAccordionImpostos] = useState(false)
    const [accordionBalanca, setAccordionBalanca] = useState(false)
    const [accordionDadosAdicionais, setAccordionDadosAdicionais] = useState(false)
    const [accordionFiliais, setAccordionFiliais] = useState(false)

    const tipoOptions = [{value: "00", label: "Mercadoria para Revenda"},
        {value: "01", label: "Matéria-Prima"},
        {value: "02", label: "Embalagem"},
        {value: "03", label: "Produto em Processo"},
        {value: "04", label: "Produto Acabado"},
        {value: "05", label: "Subproduto"},
        {value: "06", label: "Produto Intermediário"},
        {value: "07", label: "Material de Uso e Consumo"},
        {value: "08", label: "Ativo Imobilizado"},
        {value: "09", label: "Serviços"},
        {value: "10", label: "Outros insumos"},
        {value: "99", label: "Outras"}]

    const location = useLocation();

    const getAndSearch = (idCloud)=>{
        props.buscarEstoque({id: idCloud}, props.history)
        props.navegarBreadCrumb( {title: `breadcrumb.stock`, link: location.pathname, id: idCloud})
    }


    useEffect(() => {
        if(paramIdCloud !== undefined) return

        const idCloud = props.match.params.hasOwnProperty('idCloud') ? props.match.params.idCloud : null
        if (idCloud) {
            getAndSearch(idCloud)
        } else {
            setId(null)
            setEmpresa(companies[0].value)
            setAtivo('A')

            setAccordionEstoque(true)
            setAccordionVinculo(true)
            setAccordionImpostos(true)
            setAccordionBalanca(true)
            setAccordionDadosAdicionais(false)
            setAccordionFiliais(false)
            props.navegarBreadCrumb({title: `breadcrumb.stock.new`, link: location.pathname})
        }

        setClonado(false)
        setParamIdCloud(idCloud)
    }, [props.match.params])

    useEffect(() => {
        if (props.permanecerCadastro === true) {
            setLoading(true)

            setTimeout(() => {
                preencherFormulario(null)

                setId(null)
                setEmpresa(companies[0].value)
                setAtivo('A')

                setClonado(false)
                setLoading(false)


                setAccordionEstoque(true)
                setAccordionVinculo(true)
                setAccordionImpostos(true)
                setAccordionBalanca(true)
                setAccordionDadosAdicionais(false)
                setAccordionFiliais(false)
            }, 1000)
        }
    }, [props.permanecerCadastro])

    useEffect(() => {
        if (props.estoque && props.estoque?.id) {
            preencherFormulario(props.estoque)

            props.buscarEstoqueFilialPorEstoque({
                company: props.estoque?.idEmpresa, estoque: props.estoque?.codigo
            })
        }

    }, [props.estoque])

    const preencherFormulario = (estoque) => {
        setId(estoque?.id)
        setEmpresa(estoque?.idEmpresa)
        setCodigo(estoque?.codigo)
        setICodigo(estoque?.iCodigo)
        setTipo(estoque?.tipo2)
        setCodigoBarra(estoque?.codigo_barra)
        setDescricao(estoque?.descricao)
        setDescricaoOpcional(estoque?.desc_opcional)
        setUnidade(estoque?.tabelaUnidade ? buildTabelaUnidade(estoque?.tabelaUnidade.unidade, estoque?.tabelaUnidade.descricao) : undefined)
        setAtivo(estoque?.ativo === 'A' ? estoque?.ativo : 'I')
        setGrupo(estoque?.grupo ? buildGrupoEstoque(estoque?.grupo.Codigo, estoque?.grupo.descricao) : undefined)
        setSubgrupo(estoque?.subgrupo ? buildGrupoEstoque(estoque?.subgrupo.Codigo, estoque?.subgrupo.descricao) : undefined)
        setCusto(estoque?.custo)
        setDiferencaCustoVenda(estoque?.dcv)
        setVenda(estoque?.venda)
        setFornecedor(estoque?.fornecedor ? buildFornecedor(estoque?.fornecedor.codigo, estoque?.fornecedor.cnpj, estoque?.fornecedor.razaosocial) : undefined)
        setEstoqueAtual(estoque?.est_atual)
        setEstoqueMinimo(estoque?.est_minimo)
        setEstoqueMaximo(estoque?.est_maximo)
        setEmbalagem(estoque?.embalagem)
        setUltimoEntrada(estoque?.ult_entrada ? brazilMoment(estoque?.ult_entrada, "YYYY-MM-DD").toDate() : null)
        setUltimoSaida(estoque?.ult_saida ? brazilMoment(estoque?.ult_saida, "YYYY-MM-DD").toDate() : null)
        setUltimoBalanco(estoque?.ult_balanco ? brazilMoment(estoque?.ult_balanco, "YYYY-MM-DD").toDate() : null)
        setUltimaProducao(estoque?.ult_producao ? brazilMoment(estoque?.ult_producao, "YYYY-MM-DD").toDate() : null)
        setUltimaSaidaProducao(estoque?.ult_saida_producao ? brazilMoment(estoque?.ult_saida_producao, "YYYY-MM-DD").toDate() : null)
        setUltimoPreco(estoque?.ult_preco ? brazilMoment(estoque?.ult_preco, "YYYY-MM-DD").toDate() : null)

        setCodigoCardapio(estoque?.cardapio ? buildGruposCardapioItem(estoque?.cardapio.Codigo, estoque?.cardapio.Descricao) : undefined)
        setImposto(estoque?.tabelaImposto ? buildRegraImpostoSaida(estoque?.tabelaImposto.idId, estoque?.tabelaImposto.descricao) : undefined)
        setNcm(estoque?.ncm ? buildNCM(estoque?.ncm.ncm, estoque?.ncm.descricao) : undefined)
        setCest(estoque?.cest ? buildTabelaCest(estoque?.cest.cest, estoque?.cest.descricao) : undefined)

        setContaEntrada(estoque?.conta_entrada)
        setContaSaida(estoque?.conta_saida)
        setTempoUtil(estoque?.tempo_util)
        setValidade(estoque?.validade ? brazilMoment(estoque?.validade, "YYYY-MM-DD").toDate() : null)
        setBalanca(estoque?.balanca ? estoque?.balanca : 'N')
        setBalancaTecla(estoque?.balanca_tecla)
        setBalancaValidade(estoque?.balanca_validade)
        setDataAlteracao(estoque?.altdata ? brazilMoment(estoque?.altdata, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setUsuarioAlteracao(`${estoque?.altfunc} - ${estoque?.altfunc_Nome}`)
    }

    const excluirEstoque = () => {
        props.removerEstoque({id}, props.history)
    }

    const clonarEstoque = () => {
        setTimeout(() => {
            setId(null)
            setCodigo(null)
            setDescricao(`${descricao} (cópia)`)
            setEmpresa(companies[0].value)
            setDataAlteracao(null)
            setUsuarioAlteracao(null)
            setUltimoEntrada(null)
            setUltimoSaida(null)
            setUltimoBalanco(null)
            setUltimaProducao(null)
            setUltimaSaidaProducao(null)
            setUltimoPreco(null)
            setValidade(null)

            props.estoque.id = null
            props.estoque.codigo = null
            props.estoque.iCodigo = null // caso seja clonado não deverá haver o iCodigo
            props.estoque.altdata = null
            props.estoque.altfunc = null
            props.estoque.ult_entrada = null
            props.estoque.ult_saida = null
            props.estoque.ult_balanco = null
            props.estoque.ult_producao = null
            props.estoque.ult_saida_producao = null
            props.estoque.ult_preco = null
            props.estoque.validade = null

            setClonado(true)
            window.scrollTo(0, 0)
        }, 1000)
    }

    const gravarEstoque = (e, cadastrarOutroEstoque) => {
        let valid = true
        valid = valid && (!!empresa)
        valid = valid && (!!codigo)
        valid = valid && (!!tipo)
        valid = valid && (!!descricao)
        valid = valid && (!!unidade)
        valid = valid && (!!grupo)
        valid = valid && (!!custo)
        valid = valid && (!!venda)
        valid = valid && (!!estoqueAtual)
        valid = valid && (!!estoqueMinimo)
        valid = valid && (!!estoqueMaximo)
        valid = valid && (!!embalagem)
        valid = valid && (!!codigoCardapio)
        valid = valid && (!!cest)
        valid = valid && (!!imposto)

        if (valid) {
            salvarEstoque(cadastrarOutroEstoque)
        } else {
            const divs = document.getElementsByName("validate-required")
            for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "block"
            }

            window.scrollTo(0, 0)

            toastr.error('Há campos inválidos no formulário!', 'Validação de informações')
            return
        }
    }

    const salvarEstoque = (cadastrarOutroEstoque) => {
        const estoque = props.estoque ? Object.assign({}, props.estoque) : {}
        estoque.idEmpresa = empresa
        estoque.codigo = codigo
        estoque.tipo2 = tipo
        estoque.codigo_barra = codigoBarra
        estoque.descricao = descricao
        estoque.desc_opcional = descricaoOpcional
        estoque.UM = unidade ? unidade.value : null
        estoque.ativo = ativo || 'A'
        estoque.grupo = grupo ? grupo.value : null
        estoque.subgrupo = subgrupo ? subgrupo.value : null
        estoque.custo = custo
        estoque.dcv = diferencaCustoVenda
        estoque.venda = venda
        estoque.fornec = fornecedor ? fornecedor.value : null
        estoque.est_atual = estoqueAtual
        estoque.est_minimo = estoqueMinimo
        estoque.est_maximo = estoqueMaximo
        estoque.embalagem = embalagem
        estoque.ult_entrada = ultimoEntrada
        estoque.ult_saida = ultimoSaida
        estoque.ult_balanco = ultimoBalanco
        estoque.ult_producao = ultimaProducao
        estoque.ult_saida_producao = ultimaSaidaProducao
        estoque.ult_preco = ultimoPreco
        estoque.cod_cardapio = codigoCardapio ? codigoCardapio.value : null
        estoque.imp_id = imposto ? imposto.value : null
        estoque.cod_ncm = ncm ? ncm.value : null
        estoque.cest = cest ? cest.value : null
        estoque.conta_entrada = contaEntrada
        estoque.conta_saida = contaSaida
        estoque.tempo_util = tempoUtil
        estoque.validade = validade
        estoque.balanca = balanca
        estoque.balanca_tecla = balancaTecla
        estoque.balanca_validade = balancaValidade
        estoque.altfunc = dataAlteracao
        estoque.altdata = usuarioAlteracao

        if (estoque.id) {
            props.editarEstoque(estoque, props.history)
        } else {
            if (cadastrarOutroEstoque) {
                props.cadastrarEstoqueMantemCadastro(estoque, props.history)
            } else {
                props.cadastrarEstoque(estoque, props.history)
            }
        }
    }

    const buildTabelaUnidade = (unidade, descricao) => {
        return {label: `${unidade} - ${descricao}`, value: unidade}
    }

    const buscarTabelaUnidade = (inputValue, callback) => {
        apiBuscarTabelaUnidadesPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({unidade, descricao}) => {
                    return buildTabelaUnidade(unidade, descricao)
                })

                callback(options)
            })
    }

    const buildGrupoEstoque = (Codigo, descricao) => {
        return {label: `${Codigo} - ${descricao}`, value: Codigo}
    }

    const buscarGrupoEstoque = (inputValue, callback) => {
        apiBuscarGrupoEstoquePesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({Codigo, descricao}) => {
                    return buildGrupoEstoque(Codigo, descricao)
                })

                callback(options)
            })
    }

    const buscarSubGrupoEstoque = (inputValue, callback) => {
        apiBuscarGrupoEstoquePesquisa({empresaId: empresa, argumento: inputValue, subgrupo: true})
            .then(results => {
                const options = results.map(({Codigo, descricao}) => {
                    return buildGrupoEstoque(Codigo, descricao)
                })

                callback(options)
            })
    }

    const buildRegraImpostoSaida = (idId, descricao) => {
        return {label: `${idId} - ${descricao}`, value: idId}
    }

    const buscarRegraImpostoSaida = (inputValue, callback) => {
        apiBuscarNfeImpostoPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({idId, descricao}) => {
                    return buildRegraImpostoSaida(idId, descricao)
                })

                callback(options)
            })
    }

    const buildTabelaCest = (cest, descricao) => {
        return {label: `${cest} - ${descricao}`, value: cest}
    }

    const buscarTabelaCest = (inputValue, callback) => {
        apiBuscarTabelaCestPesquisa({argumento: inputValue})
            .then(results => {
                const options = results.map(({cest, descricao}) => {
                    return buildTabelaCest(cest, descricao)
                })

                callback(options)
            })
    }

    const buildNCM = (ncm, descricao) => {
        return {label: `${ncm} - ${descricao}`, value: ncm}
    }

    const buscarNCM = (inputValue, callback) => {
        apiBuscarNCMsPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({ncm, descricao}) => {
                    return buildNCM(ncm, descricao)
                })

                callback(options)
            })
    }

    const buildFornecedor = (codigo, cnpj, razaosocial) => {
        return {label: `${cnpj} - ${razaosocial}`, value: codigo}
    }

    const buscarFornecedores = (inputValue, callback) => {
        apiBuscarFornecedoresPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({codigo, cnpj, razaosocial}) => {
                    return buildFornecedor(codigo, cnpj, razaosocial)
                })
                callback(options)
            })
    }

    const buildGruposCardapioItem = (Codigo, Descricao) => {
        return {label: `${Codigo} - ${Descricao}`, value: Codigo}
    }

    const buscarGruposCardapioItem = (inputValue, callback) => {
        apiBuscarGruposCardapioItemPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({Codigo, Descricao}) => {
                    return buildGruposCardapioItem(Codigo, Descricao)
                })
                callback(options)
            })
    }

    useEffect(() => {
        const tmpCompanies = getCompanies(props.user)
        setCompanies(tmpCompanies)
        setEmpresa(tmpCompanies[0].value)

        return () => {
            setCompanies([])
        }
    }, [])

    const atualizarDiferencaCustoVenda = (custo, venda) => {
        let diferenca = 0
        if(custo && venda) {
            diferenca = custo / venda
        }
        setDiferencaCustoVenda(diferenca)
    }

    if (!props.user || !possuiVinculo) {
        return (<AlertaSemVinculo/>)
    }

    return (
        <React.Fragment>
            {props.estoqueLoading || loading ? (<div className="loader-container position-absolute">
                    <Loader
                        type="ThreeDots"
                        color="#556EE6"
                        height={80}
                        width={80}
                    />
                </div>) :
                (<div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="breadcrumb.stock.title"
                        />
                        <StickerToolbarButtons>
                            <ButtonRedirect path={"estoques"} history={props.history}></ButtonRedirect>
                            {id && <>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="waves-effect waves-light me-2"
                                    disabled={!empresa || !((id && usePermissionEdit) || (!id && usePermissionInsert) || props.estoque?.iCodigo)
                                    }
                                    onClick={e => gravarEstoque(e)}>
                                    {id ? "Salvar Estoque" : "Cadastrar Estoque"}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="waves-effect waves-light me-2"
                                    disabled={!(id && usePermissionInsert)}
                                    onClick={e => clonarEstoque(e)}>
                                    {"Clonar Estoque"}
                                </Button>
                                <Button
                                    type="submit"
                                    color="danger"
                                    className="waves-effect waves-light me-2"
                                    disabled={!(id && usePermissionRemove) || props.estoque?.iCodigo}
                                    onClick={e => excluirEstoque(e)}>
                                    {"Remover Estoque"}
                                </Button>
                            </>
                            }

                            {!id && !clonado &&
                                <>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="waves-effect waves-light me-2"
                                        disabled={!empresa || !usePermissionInsert}
                                        onClick={e => gravarEstoque(e)}>
                                        {"Cadastrar Estoque"}
                                    </Button>

                                    <Button
                                        type="submit"
                                        color="secondary"
                                        className="waves-effect waves-light me-2 btn-secondary"
                                        disabled={!empresa || !usePermissionInsert}
                                        onClick={e => gravarEstoque(e, true)}>
                                        {"Cadastrar e Preencher novo Estoque"}
                                    </Button>
                                </>
                            }

                            {clonado && <Button
                                type="submit"
                                color="primary"
                                className="waves-effect waves-light me-2"
                                disabled={!empresa || !usePermissionInsert}
                                onClick={e => gravarEstoque(e)}>
                                {"Cadastrar Estoque"}
                            </Button>}

                        </StickerToolbarButtons>
                        <Row>
                            <Col lg="12">
                                <div>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="outer">
                                                    <div className="outer">
                                                        {id && <FormGroup className="mb-4" row>
                                                            <Label htmlFor="empresa"
                                                                   className="col-form-label col-lg-2">
                                                                Identificador
                                                            </Label>
                                                            <Col lg="10" className="col-form-label">
                                                                {id}
                                                            </Col>
                                                        </FormGroup>}

                                                        {id && <FormGroup className="mb-4" row>
                                                            <Label htmlFor="codigo" className="col-form-label col-lg-2">
                                                                Código
                                                            </Label>
                                                            <Col lg="10" className="col-form-label">
                                                                {codigo}
                                                            </Col>
                                                        </FormGroup>}

                                                        {!id && <FormInputNumber
                                                            attribute="codigo"
                                                            description="Código"
                                                            value={codigo}
                                                            setValue={setCodigo}
                                                            allowNegative={false}
                                                            max={9999999}
                                                            required={true}
                                                        />}

                                                        {id && <FormInputSwitch
                                                            attribute="ativo"
                                                            description="Ativo"
                                                            value={ativo}
                                                            setValue={setAtivo}
                                                            trueValue={'A'}
                                                            falseValue={'I'}
                                                            required={true}
                                                        />}

                                                        <FormSelectCompany
                                                            attribute="empresa"
                                                            value={empresa}
                                                            setValue={setEmpresa}
                                                            options={companies.filter(c => c.value)}
                                                            required={true}
                                                            disabled={(props.estoque?.id !== null && props.estoque?.id !== undefined) || false}
                                                        />

                                                        <FormInputSelect
                                                            attribute="tipo"
                                                            description="Tipo"
                                                            value={tipo}
                                                            setValue={setTipo}
                                                            options={tipoOptions}
                                                            required={true}
                                                        />

                                                        <FormInputText
                                                            attribute="codigoBarra"
                                                            description="Código EAN"
                                                            value={codigoBarra}
                                                            setValue={setCodigoBarra}
                                                            maxLength={14}
                                                        />

                                                        <FormInputText
                                                            attribute="descricao"
                                                            description="Descrição"
                                                            value={descricao}
                                                            setValue={setDescricao}
                                                            required={true}
                                                            maxLength={50}
                                                        />

                                                        <FormInputText
                                                            attribute="descricaoOpcional"
                                                            description="Descrição Resumida"
                                                            value={descricaoOpcional}
                                                            setValue={setDescricaoOpcional}
                                                            maxLength={50}
                                                        />

                                                        <FormInputAsyncSelect
                                                            dependencyKey={empresa}
                                                            attribute="unidade"
                                                            description="Unidade"
                                                            value={unidade}
                                                            setValue={setUnidade}
                                                            loadOptions={buscarTabelaUnidade}
                                                            required={true}
                                                        />

                                                        <FormInputAsyncSelect
                                                            dependencyKey={empresa}
                                                            attribute="grupo"
                                                            description="Grupo"
                                                            value={grupo}
                                                            setValue={setGrupo}
                                                            loadOptions={buscarGrupoEstoque}
                                                            required={true}
                                                        />

                                                        <FormInputAsyncSelect
                                                            dependencyKey={empresa}
                                                            attribute="subgrupo"
                                                            description="Subgrupo"
                                                            value={subgrupo}
                                                            setValue={setSubgrupo}
                                                            loadOptions={buscarSubGrupoEstoque}
                                                        />

                                                        <FormInputMoney
                                                            attribute="custo"
                                                            description="Custo"
                                                            value={custo}
                                                            setValue={ (valor) => {
                                                                setCusto(valor)
                                                                atualizarDiferencaCustoVenda(valor, venda)
                                                            }}
                                                            required={true}
                                                        />

                                                        <FormInputMoney
                                                            attribute="venda"
                                                            description="Venda"
                                                            value={venda}
                                                            setValue={ (valor) => {
                                                                setVenda(valor)
                                                                atualizarDiferencaCustoVenda(custo, valor)
                                                            }}
                                                            required={true}
                                                        />

                                                        <FormInputNumber
                                                            attribute="diferencaCustoVenda"
                                                            description="Diferença custo/venda"
                                                            value={diferencaCustoVenda}
                                                            decimalScale={2}
                                                            suffix="%"
                                                            disabled={true}
                                                        />

                                                        <FormInputAsyncSelect
                                                            dependencyKey={empresa}
                                                            attribute="fornecedor"
                                                            description="Fornecedor"
                                                            value={fornecedor}
                                                            setValue={setFornecedor}
                                                            loadOptions={buscarFornecedores}
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Form>

                                            { <div className="hstack gap-3 mb-2">
                                                <Label className="col-form-label">Quadros de informações</Label>
                                                <button type="button" className="btn btn-soft-primary btn-sm"
                                                        onClick={(e) => {
                                                            setAccordionEstoque(true)
                                                            setAccordionVinculo(true)
                                                            setAccordionImpostos(true)
                                                            setAccordionBalanca(true)
                                                            setAccordionDadosAdicionais(true)
                                                            setAccordionFiliais(true)
                                                        }}>Exibir todos
                                                </button>
                                                <button type="button" className="btn btn-soft-secondary btn-sm"
                                                        onClick={(e) => {
                                                            setAccordionEstoque(false)
                                                            setAccordionVinculo(false)
                                                            setAccordionImpostos(false)
                                                            setAccordionBalanca(false)
                                                            setAccordionDadosAdicionais(false)
                                                            setAccordionFiliais(false)
                                                        }}>Não exibir
                                                </button>
                                            </div>}

                                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                                <label
                                                    className={accordionEstoque ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                    <input type="checkbox" className="btn-check" onClick={() => {
                                                        setAccordionEstoque(!accordionEstoque)
                                                    }}/>{" "}
                                                    Dados de Estoque
                                                </label>
                                                <label
                                                    className={accordionVinculo ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                    <input type="checkbox" className="btn-check" onClick={() => {
                                                        setAccordionVinculo(!accordionVinculo)
                                                    }}/>{" "}
                                                    Vinculo com cardápio
                                                </label>
                                                <label
                                                    className={accordionImpostos ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                    <input type="checkbox" className="btn-check" onClick={() => {
                                                        setAccordionImpostos(!accordionImpostos)
                                                    }}/>{" "}
                                                    Impostos
                                                </label>
                                                <label
                                                    className={accordionBalanca ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                    <input type="checkbox" className="btn-check" onClick={() => {
                                                        setAccordionBalanca(!accordionBalanca)
                                                    }}/>{" "}
                                                    Dados para Balança de Peso
                                                </label>
                                                { id && <label
                                                    className={accordionDadosAdicionais ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                    <input type="checkbox" className="btn-check" onClick={() => {
                                                        setAccordionDadosAdicionais(!accordionDadosAdicionais)
                                                    }}/>{" "}
                                                    Dados adicionais
                                                </label> }
                                                { id && <label
                                                    className={accordionFiliais ? "btn btn-secondary active" : "btn btn-secondary"}>
                                                    <input type="checkbox" className="btn-check" onClick={() => {
                                                        setAccordionFiliais(!accordionFiliais)
                                                    }}/>{" "}
                                                    Filiais
                                                </label> }
                                            </div>

                                        </CardBody>
                                    </Card>

                                    <Collapse isOpen={accordionEstoque}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Dados de Estoque</CardTitle>
                                                <Form>
                                                    <div className="outer">
                                                        <div className="outer">

                                                            <FormInputNumber
                                                                attribute="estoqueAtual"
                                                                description="Estoque Atual"
                                                                value={estoqueAtual}
                                                                setValue={setEstoqueAtual}
                                                                allowNegative={false}
                                                                required={true}
                                                                max={9999999}
                                                            />

                                                            <FormInputNumber
                                                                attribute="estoqueMinimo"
                                                                description="Estoque Mínimo"
                                                                value={estoqueMinimo}
                                                                setValue={setEstoqueMinimo}
                                                                allowNegative={false}
                                                                required={true}
                                                                max={9999999}
                                                            />

                                                            <FormInputNumber
                                                                attribute="estoqueMaximo"
                                                                description="Estoque Máximo"
                                                                value={estoqueMaximo}
                                                                setValue={setEstoqueMaximo}
                                                                allowNegative={false}
                                                                required={true}
                                                                max={9999999}
                                                            />

                                                            <FormInputNumber
                                                                attribute="embalagem"
                                                                description="Qtd. Embalagem Padrão Compra"
                                                                value={embalagem}
                                                                setValue={setEmbalagem}
                                                                allowNegative={false}
                                                                required={true}
                                                                max={9999999}
                                                            />

                                                            <FormInputDate
                                                                attribute="ultimoEntrada"
                                                                description="Últ. Data Entrada"
                                                                value={ultimoEntrada}
                                                                setValue={setUltimoEntrada}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputDate
                                                                attribute="ultimoSaida"
                                                                description="Últ. Data Saída"
                                                                value={ultimoSaida}
                                                                setValue={setUltimoSaida}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputDate
                                                                attribute="ultimoBalanco"
                                                                description="Últ. Inventário Realizado"
                                                                value={ultimoBalanco}
                                                                setValue={setUltimoBalanco}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputDate
                                                                attribute="ultimaProducao"
                                                                description="Últ. Ordem de Produção"
                                                                value={ultimaProducao}
                                                                setValue={setUltimaProducao}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputDate
                                                                attribute="ultimaSaidaProducao"
                                                                description="Últ. Saída para Produção"
                                                                value={ultimaSaidaProducao}
                                                                setValue={setUltimaSaidaProducao}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputDate
                                                                attribute="ultimoPreco"
                                                                description="Últ. Ajuste de Preço"
                                                                value={ultimoPreco}
                                                                setValue={setUltimoPreco}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                        </div>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>

                                    <Collapse isOpen={accordionVinculo}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Vinculo com cardápio</CardTitle>
                                                <Form>
                                                    <div className="outer">
                                                        <div className="outer">

                                                            <FormInputAsyncSelect
                                                                dependencyKey={empresa}
                                                                attribute="codigoCardapio"
                                                                description="Cardápio"
                                                                value={codigoCardapio}
                                                                setValue={setCodigoCardapio}
                                                                loadOptions={buscarGruposCardapioItem}
                                                                required={true}
                                                            />

                                                        </div>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>

                                    <Collapse isOpen={accordionImpostos}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Impostos</CardTitle>
                                                <Form>
                                                    <div className="outer">
                                                        <div className="outer">

                                                            <FormInputAsyncSelect
                                                                dependencyKey={empresa}
                                                                attribute="imposto"
                                                                description="Regra para Imposto de saída"
                                                                value={imposto}
                                                                setValue={setImposto}
                                                                loadOptions={buscarRegraImpostoSaida}
                                                                required={true}
                                                            />

                                                            <FormInputAsyncSelect
                                                                dependencyKey={empresa}
                                                                attribute="ncm"
                                                                description="Código NCM"
                                                                value={ncm}
                                                                setValue={setNcm}
                                                                loadOptions={buscarNCM}
                                                            />

                                                            <FormInputAsyncSelect
                                                                attribute="cest"
                                                                description="Código CEST"
                                                                value={cest}
                                                                setValue={setCest}
                                                                loadOptions={buscarTabelaCest}
                                                                required={true}
                                                            />

                                                            <FormInputText
                                                                attribute="contaEntrada"
                                                                description="Conta de entrada"
                                                                value={contaEntrada}
                                                                setValue={setContaEntrada}
                                                            />

                                                            <FormInputText
                                                                attribute="contaSaida"
                                                                description="Conta de saída"
                                                                value={contaSaida}
                                                                setValue={setContaSaida}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>

                                    <Collapse isOpen={accordionBalanca}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Dados para Balança de Peso</CardTitle>
                                                <Form>
                                                    <div className="outer">
                                                        <div className="outer">

                                                            <FormInputNumber
                                                                attribute="tempoUtil"
                                                                description="Tempo de validade em dias"
                                                                value={tempoUtil}
                                                                setValue={setTempoUtil}
                                                                allowNegative={false}
                                                                max={9999999}
                                                                min={0}
                                                            />

                                                            <FormInputDate
                                                                attribute="validade"
                                                                description="Última data de validade informada"
                                                                value={validade}
                                                                setValue={setValidade}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputSwitch
                                                                attribute="balanca"
                                                                description="Envia para balança"
                                                                value={balanca}
                                                                setValue={setBalanca}
                                                                trueValue={'S'}
                                                                falseValue={'N'}
                                                            />

                                                            <FormInputNumber
                                                                attribute="balancaTecla"
                                                                description="Número da tecla na balança"
                                                                value={balancaTecla}
                                                                setValue={setBalancaTecla}
                                                                allowNegative={false}
                                                                max={9999999}
                                                                min={0}
                                                            />

                                                            <FormInputNumber
                                                                attribute="balancaValidade"
                                                                description="Tempo de validade em dias"
                                                                value={balancaValidade}
                                                                setValue={setBalancaValidade}
                                                                allowNegative={false}
                                                                max={9999999}
                                                                min={0}
                                                            />

                                                        </div>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>

                                    {id && <Collapse isOpen={accordionDadosAdicionais}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Dados adicionais</CardTitle>
                                                <Form>
                                                    <div className="outer">
                                                        <div className="outer">
                                                            <FormInputDate
                                                                attribute="dataAlteracao"
                                                                description="Alteração do cadastro"
                                                                value={dataAlteracao}
                                                                disabled={true}
                                                                dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                                withHour={true}
                                                            />

                                                            <FormInputText
                                                                attribute="usuarioAlteracao"
                                                                description="Usuário que alterou"
                                                                value={usuarioAlteracao}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>}

                                    {id && <Collapse isOpen={accordionFiliais}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Filiais</CardTitle>
                                                <EstoqueFilialLista
                                                    listaEstoqueFilial={props.listaEstoqueFilial}
                                                    loading={props.listaEstoqueFilialLoading}>
                                                </EstoqueFilialLista>
                                            </CardBody>
                                        </Card>
                                    </Collapse>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>)}
        </React.Fragment>
    )
}

CadastrarEstoque.propTypes = {
    estoque: PropTypes.any,
    estoqueAtivo: PropTypes.any,
    estoqueLoading: PropTypes.bool,
    cadastrarEstoque: PropTypes.func,
    cadastrarEstoqueMantemCadastro: PropTypes.func,
    editarEstoque: PropTypes.func,
    removerEstoque: PropTypes.func,
    listaEstoqueFilial: PropTypes.any,
    listaEstoqueFilialLoading: PropTypes.bool,
    navegarEditarEstoque: PropTypes.func
}

const mapStatetoProps = state => {
    const {estoqueAtivo, estoque, estoqueLoading, permanecerCadastro, listaEstoqueFilial, listaEstoqueFilialLoading} = state.Profile
    const {user} = state.Login
    return {user, estoqueAtivo, estoque, estoqueLoading, permanecerCadastro, listaEstoqueFilial, listaEstoqueFilialLoading}
}

export default connect(mapStatetoProps, {
    cadastrarEstoque,
    cadastrarEstoqueMantemCadastro,
    removerEstoque,
    editarEstoque,
    buscarEstoque,
    buscarEstoqueFilialPorEstoque,
    navegarBreadCrumb,
    navegarEditarEstoque
})(withRouter(CadastrarEstoque))
