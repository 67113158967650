import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {apiBuscarNotificacoesUsuario} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {Badge, Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {AvForm} from "availity-reactstrap-validation";
import FormInputEditor from "../../components/Common/form-input-editor";
import {convertFromRaw, EditorState} from "draft-js";
import {Link} from "react-router-dom";
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import {stateFromHTML} from "draft-js-import-html";

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const PainelNotificacoesTable = forwardRef((props, refFather) => {

    const ref = useRef()

    const {filters, columns, usePermissionEdit, marcarNotificacaoComoLido, marcarNotificacaoComoNaoLido} = props
    const [data, setData] = useState([])
    const inforangraAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
    const [notificacoesUsuarioSelected, setNotificacoesUsuarioSelected] = useState({})
    const [modal, setModal] = useState(false)
    const [notificacaoState, setNotificacaoState] = useState(EditorState.createEmpty())

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        }
    }));

    const getBadgeElement = (cell) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (cell.getValue() === 'E' ? 'primary higher' : 'soft-dark')}
            color={cell.getValue() === 'E' ? 'primary higher' : 'soft-dark'}
            pill
        >
            {cell.getValue() === 'E' ? 'Entregue' : 'Não entregue'}
        </Badge>
    }

    const describeType = tipo => {
        let tipoStr = "N/D"
        switch (tipo) {
            case "S":
                tipoStr = "Sistema"
                break
            case "P":
                tipoStr = "Push Web"
                break
            case "E":
                tipoStr = "E-mail"
                break
        }

        return <Badge
            className={"font-size-12 badge-soft-" + (tipo === 'S' ? 'secondary' : tipo === 'P' ? 'warning' : tipo === 'E' ? 'success' : 'soft-dark')}
            color={tipo === 'S' ? 'secondary' : tipo === 'P' ? 'warning' : tipo === 'E' ? 'success' : 'soft-dark'}
            pill>
            {tipoStr}
        </Badge>
    }

    const getBadgeElementLido = (cell) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (cell.getValue() ? 'primary higher' : 'soft-dark')}
            color={cell.getValue() ? 'primary higher' : 'soft-dark'}
            pill
        >
            {cell.getValue() ? 'Sim' : 'Não'}
        </Badge>
    }

    const colunas = useMemo(() => {
        if (columns) {
            return columns
        } else {
            const colunas =
                [{
                    header: "ID Cloud",
                    accessorKey: "id",
                    size: 80,
                    enableHiding: false,
                    muiTableBodyCellProps: {align: 'right',},
                },
                    {
                        header: "Data de envio", accessorKey: "created_at", size: 130,
                        Cell: ({cell}) => describeDateTime(cell?.getValue()),
                        accessorFn: (row) => row.created_at ? new Date(removeHour(row.created_at)) : null,
                        sortingFn: 'datetime',
                        Filter: ({column}) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}
                                                  localeText={ptBRLocale}>
                                <DatePicker
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            newValue = removeHour(newValue, false)
                                        }
                                        column.setFilterValue(newValue)
                                    }}
                                    inputFormat="dd/MM/yyyy"
                                    componentsProps={{
                                        actionBar: {
                                            actions: ['accept', 'clear'],
                                        },
                                    }}
                                    value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                                    views={['year', 'month', 'day']}
                                    renderInput={(props) => <TextField {...props} variant="standard"/>}
                                />
                            </LocalizationProvider>
                        ),
                    }]
            if (inforangraAdmin) {
                colunas.push({
                    header: "Destinatário",
                    accessorKey: "usuario.nome_completo",
                    size: 120,
                    filterFn: 'contains'
                })
            }

            colunas.push(...[
                {header: "Título", accessorKey: "notificacao.titulo", size: 120, filterFn: 'contains'},
                {
                    header: "Tipo",
                    accessorKey: "tipo_notificacao",
                    size: 150,
                    Cell: ({cell}) => describeType(cell?.getValue()),
                    enableColumnFilter: false
                },
                {
                    header: "Lido", accessorKey: "lido", Cell: ({cell}) => (
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                            {getBadgeElementLido(cell)}
                        </Box>), size: 120, enableColumnFilter: false
                },
                {
                    header: "Data leitura", accessorKey: "data_lida", size: 130,
                    Cell: ({cell}) => describeDateTime(cell?.getValue()),
                    accessorFn: (row) => row.created_at ? new Date(removeHour(row.created_at)) : null,
                    sortingFn: 'datetime',
                    Filter: ({column}) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                            <DatePicker
                                onChange={(newValue) => {
                                    if (newValue) {
                                        newValue = removeHour(newValue, false)
                                    }
                                    column.setFilterValue(newValue)
                                }}
                                inputFormat="dd/MM/yyyy"
                                componentsProps={{
                                    actionBar: {
                                        actions: ['accept', 'clear'],
                                    },
                                }}
                                value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                                views={['year', 'month', 'day']}
                                renderInput={(props) => <TextField {...props} variant="standard"/>}
                            />
                        </LocalizationProvider>
                    ),
                },
                {
                    header: "Notificação",
                    accessorKey: "notificacao.notificacao",
                    size: 150,
                    Cell: ({cell}) =>
                        <Link to="#" className="text-primary">
                            <i className="mdi mdi-eye font-size-18 ms-1" id="edittooltip"
                               onClick={e => openModal(e, cell?.row?.original, cell?.getValue())}
                               title="Visualizar notificação"
                            />
                        </Link>,
                enableColumnFilter
        :
            false
                },
            ])

            return colunas
        }
    }, [])

    const onRowSelectionChange = (rowSelection) => {
        setNotificacoesUsuarioSelected(Object.keys(rowSelection))
    }

    const openModal = (e, notificacao, texto) => {
        let contentState = stateFromHTML(texto)
        setNotificacaoState(prevState => EditorState.createWithContent(contentState))
        setModal(true)

        if(!notificacao.lido) {
            marcarNotificacaoComoLido(e, [notificacao.id], false)
        }
    }

    return (
        <React.Fragment>
            <StickerToolbarButtons top={300}>
                <Button
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light me-2"
                    onClick={e => marcarNotificacaoComoLido(e, notificacoesUsuarioSelected)}
                    disabled={
                        Object.keys(notificacoesUsuarioSelected)?.length <= 0 ||
                        !usePermissionEdit
                    }
                >
                    Marcar como lida
                </Button>

                <Button
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light"
                    onClick={e => marcarNotificacaoComoNaoLido(e, notificacoesUsuarioSelected)}
                    disabled={
                        Object.keys(notificacoesUsuarioSelected).length <= 0 ||
                        !usePermissionEdit
                    }
                >
                    Marcar como não lida
                </Button>
            </StickerToolbarButtons>

            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarNotificacoesUsuario(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
                enableRowSelection={(row) => {
                    return row.original.tipo_notificacao !== "E" &&
                        props.user?.id === row.original.usuario?.id
                }}
                getRowId={(row) => row.id}
                onRowSelectionChange={(row) => onRowSelectionChange(row)}
            />

            <Modal isOpen={modal}>
                <ModalHeader tag="h4">Notificação</ModalHeader>
                <ModalBody>
                    <Row form>
                        <FormInputEditor
                            attribute="notificacao"
                            keyTranslate="notificacao.edicao.notificacao"
                            value={notificacaoState}
                            setValue={setNotificacaoState}
                            disabled={true}
                            hiddenLabel={true}
                        />
                    </Row>
                    <Row style={ { paddingTop: 10 } }>
                        <Col>
                            <div className="text-right">
                                <button
                                    type="button"
                                    className="btn btn-light ms-2"
                                    onClick={e => setModal(false)}>
                                    Fechar
                                </button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
})

export default PainelNotificacoesTable

PainelNotificacoesTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
    marcarNotificacaoComoLido: PropTypes.func,
    marcarNotificacaoComoNaoLido: PropTypes.func,
    history: PropTypes.object,
}
