import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Alert, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"
// actions
import { apiError, loginUser, socialLogin, socialLoginToken } from "store/actions"

// import images

//Import config
import { facebook, google, recaptcha } from "../../config"
import ReCAPTCHA from "react-google-recaptcha"
import Loader from "react-loader-spinner"

import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import { decodeToken, isExpired } from "react-jwt"

const Login = props => {
  const [authed, setAuthed] = useState(true)
  const [recaptchaValidated, setRecaptchaValidated] = useState(false)
  const [keepConnected, setKeepConnected] = useState(false)
  const [errorLoginGoogle, setErrorLoginGoogle] = useState()
  const [disableLoginGoogle, setDisableLoginGoogle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)

  let captcha

  useEffect(() => {
    setRecaptchaValidated(false)
  }, [])

  useEffect(() => {
    if(submit) {
      setLoading(props.loadingLogin)
    }

    return () => setLoading(false)
  }, [props.loadingLogin])

  useEffect(() => {
    if(props.socialLoginTokenObj && !authed) {
      signIn(props.socialLoginTokenObj, "google")
    }

  }, [props.socialLoginTokenObj])

  useEffect(() => {
    if(props.user) {
      try {
        let token = localStorage.getItem("authToken")
        if(token) {
          const tokenValid = !isExpired(token) && decodeToken(token) !== null
          if(tokenValid)  {
            setAuthed(true)
            return
          }
        }
      } catch (error) {
      }
    }

    setAuthed(false)
  }, [props.user])


  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    values.keepConnected = keepConnected
    setSubmit(true)
    props.loginUser(values, props.history)
    captcha.reset()
    setRecaptchaValidated(false)
  }

  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        idToken: res.tokenId
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = async (response) => {
    setErrorLoginGoogle(null)
    const { socialLoginToken } = props
    socialLoginToken({token: response.credential})
  }

  //handleGoogleLoginResponse
  const googleErrorResponse = response => {
    if (response && response.error) {
      if ("idpiframe_initialization_failed" === response.error) {
        setErrorLoginGoogle(
          "Para continua com o Google é necessário configurar seu navegador para permitir inserção de cookies de terceiros!"
        )
        setDisableLoginGoogle(true)
      } else if ("popup_closed_by_user" === response.error) {
        setErrorLoginGoogle(
          "Não foi possível efetuar a autenticação devido ter fechado o janela antes de efetuar a autenticação!"
        )
      } else if ("immediate_failed" === response.error) {
        setErrorLoginGoogle(
          "Não foi possível efetuar a autenticação sem abrir a janela de autenticação!"
        )
      } /*if("access_denied" === response.error)*/ else {
        setErrorLoginGoogle("Não foi possível efetuar a autenticação!")
      }
    }
    console.log("[Login][googleErrorResponse] Exception", response)
  }

  const onRecaptchaChange = response => {
    console.debug("[onRecaptchaChange]", { response })
    setRecaptchaValidated(true)
  }

  const setCaptchaRef = ref => {
    if (ref) {
      return (captcha = ref)
    }
  }

  // return authed ? <></> : (
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={ 4 }>
              <div className="auth-full-page-content px-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={ logodark }
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={ logolight }
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Seja bem-vindo !</h5>
                        <p className="text-muted">
                          Realize seu acesso para continuar.
                        </p>
                      </div>

                      <div className="mt-4">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={ (e, v) => {
                            handleValidSubmit(e, v)
                          } }
                        >
                          { props.error && typeof props.error === "string" ? (
                            <Alert color="danger">{ props.error }</Alert>
                          ) : null }
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="E-mail"
                              value=""
                              className="form-control"
                              placeholder="Digite o e-mail"
                              type="text"
                              required
                              errorMessage="Campo obrigatório."
                              tabIndex={ 1 }
                              autoFocus
                            />
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Esqueceu sua
                                senha?
                              </Link>
                            </div>
                            <AvField
                              name="senha"
                              label="Senha"
                              value=""
                              type="password"
                              required
                              errorMessage="Campo obrigatório."
                              placeholder="Digite a senha"
                              tabIndex={ 2 }
                            />
                          </div>

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                              onChange={ e => setKeepConnected(e.target.checked) }
                              checked={ keepConnected }
                              tabIndex={ 3 }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Manter conectado
                            </label>
                          </div>

                          <div className="mt-2">
                            <ReCAPTCHA
                              ref={ r => setCaptchaRef(r) }
                              sitekey={ recaptcha.siteKey }
                              onChange={ onRecaptchaChange }
                              onErrored={ e => setRecaptchaValidated(false) }
                              onExpired={ e => setRecaptchaValidated(false) }
                              tabIndex={ 4 }
                            />
                          </div>

                          <div className="mt-3 d-grid">
                            { loading ? (
                              <div className="loader-container">
                                <Loader
                                  type="ThreeDots"
                                  color="#556EE6"
                                  height={ 80 }
                                  width={ 80 }
                                />
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={ !recaptchaValidated || loading }
                                tabIndex={ 5 }
                              >
                                Acessar
                              </button>
                            ) }
                          </div>

                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">Acessar com</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <div>
                                  <GoogleOAuthProvider clientId={google.CLIENT_ID}>
                                    <GoogleLogin
                                      onSuccess={googleResponse}
                                      onError={googleErrorResponse}
                                      auto_select={false}
                                      text={"continue_with"}
                                      context="signin"
                                      prompt="select_account"
                                      use_fedcm_for_prompt={true}
                                    />
                                  </GoogleOAuthProvider>
                                </div>
                              </li>
                            </ul>
                          </div>

                          { errorLoginGoogle && (
                            <Alert color="danger">{ errorLoginGoogle }</Alert>
                          ) }
                        </AvForm>
                        <div className="mt-5 text-center">
                          <p>
                            Não possui um cadastro?{ " " }
                            <Link
                              to="register"
                              className="font-weight-medium text-primary"
                            >
                              { " " }
                              Cadastre-se agora{ " " }
                            </Link>{ " " }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © { new Date().getFullYear() } Inforangra.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loadingLogin, user, socialLoginTokenLoading, socialLoginTokenObj } = state.Login
  return { error, loadingLogin, user, socialLoginTokenLoading, socialLoginTokenObj }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin, socialLoginToken })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}
