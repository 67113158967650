import moment from "moment/moment";
import 'moment/locale/pt-br'
moment.locale('pt-br')

export const isEmailValid = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

export const describeDateTime = dateStr => {
    if (!dateStr) {
        return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm:ss")
}

export const describeDate = dateStr => {
    if (!dateStr) {
        return "N/D"
    }

    return moment(dateStr).format("DD/MM/YY")
}

export const describeMoney = (value) => {
    return `R$ ${parseFloat(value || 0).toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
}

export const removeHour = (date, utc = false) => {
    let dateStr = moment(date).format('YYYY/MM/DD')
    dateStr = utc ? moment.utc(dateStr).toISOString(false) : moment(dateStr).toISOString(false)
    return dateStr
}

export const isNotEmpty = (collection) => {
    return Array.isArray(collection) && collection.length > 0
}

export const clearCollection = (colletion) => {
    if (Array.isArray(colletion)) {
        while (colletion.length > 0) colletion.pop();
    }
}

export const getCompanyOption = (empresa) => {
    return {
        label: empresa.Fantasia ? empresa.Fantasia : "N/D",
        value: empresa.idEmpresa,
        id: empresa.id,
        CNPJ: empresa.CNPJ,
        RazaoSocial: empresa.RazaoSocial,
        dashboardInicial: empresa.tipo ? empresa.tipo.dashboard_inicial: 'Padrão Food Service'
    }
}

export const getVinculoCompanyOption = (vinculo) => {
    return getCompanyOption(vinculo.empresa)
}

export const getCompanies = (user, opcaoTodas) => {
    const tmpCompanies = opcaoTodas === true ? [{label: "Todas", value: ""}] : []
    if (user) {
        for (const vinculo of (user.vinculos || []).filter(e => ["A", "T"].find(status => status == e.vinculo_status))) {
            tmpCompanies.push(getVinculoCompanyOption(vinculo))
        }
    }
    return tmpCompanies
}

export const getCompaniesValuesByOptions = (options) => {
    return (options || []).map(company => company.value)
}

export const describeDiff = (A, B, numberOnly = false) => {
    A = parseFloat(A)
    B = parseFloat(B)

    const diff = A && B ? (100 * Math.abs((B - A) / B)).toFixed(2) : 0

    if (numberOnly) {
        return parseInt(diff)
    }

    return A >= B ? `+ ${diff} %` : `- ${diff} %`
}

export const describePercentage = (A, B, numberOnly = false) => {
    A = parseFloat(A)
    B = parseFloat(B)

    const diff = A && B ? (100 * Math.abs(A / B)).toFixed(2) : 0

    if (numberOnly) {
        return parseInt(diff)
    }

    return A >= B ? `+ ${diff} %` : `- ${diff} %`
}

export const describeDiffColor = (A, B) => {
    A = parseFloat(A)
    B = parseFloat(B)

    return A >= B ? "success" : "warning"
}

export const abbreviateNumber = (num, fixed) => {
    if (num === null) {
        return null
    } // terminate early
    if (num === 0 || num === undefined) {
        return "0"
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c =
            k < 1
                ? num.toFixed(0 + fixed)
                : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ["", "K", "M", "B", "T"][k] // append power
    return e
}

export const isCollectionEmpty = (collection) => {
    return !Array.isArray(collection) || collection.length === 0
}

export const getDatasPeloPeriodo = (periodo, inicio, fim, formatado = true) => {
    if (periodo && periodo !== "Selecione") {
        let dateStartStr
        let dateEndStr
        if (periodo !== 'period') {
            if (periodo === 'lastDays') {
                let momentInicio = moment().subtract(6, "day");
                let momentoFim = moment();
                dateStartStr = formatado ? momentInicio.format('YYYY-MM-DD 00:00:00') : momentInicio
                dateEndStr = formatado ? momentoFim.format('YYYY-MM-DD 23:59:59') : momentoFim
            }
            else if (periodo === 'lastWeek') {
                let momentInicio = moment().startOf('week');
                let momentoFim = moment().endOf('week');
                dateStartStr = formatado ? momentInicio.format('YYYY-MM-DD 00:00:00') : momentInicio
                dateEndStr = formatado ? momentoFim.format('YYYY-MM-DD 23:59:59') : momentoFim
            }
            else {
                let momentInicio = moment(periodo).startOf('month');
                let momentoFim = moment(periodo).endOf('month');
                dateStartStr = formatado ? momentInicio.format('YYYY-MM-DD 00:00:00') : momentInicio
                dateEndStr = formatado ? momentoFim.format('YYYY-MM-DD 23:59:59') : momentoFim
            }
        } else {
            dateStartStr = inicio ? (formatado ? moment(inicio).format('YYYY-MM-DD 00:00:00') : moment(inicio)): null
            dateEndStr = fim ? (formatado ? moment(fim).format('YYYY-MM-DD 23:59:59') : moment(fim)) : null
        }

        if (dateStartStr && dateEndStr) {
            return {inicio: dateStartStr, fim: dateEndStr}
        }
    }

    return null
}

export const checkIsMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const getMonths = () => {
    const monthsOptions = []
    for (let i = 0; i <= 11; i++) {
        monthsOptions.push({
            label: moment().subtract(i, "month").format("MMMM/YY"),
            value: moment().subtract(i, "month").format("YYYY-MM-DD")
        })
    }
    return monthsOptions
}

export const getPeriodos = (selecione = true, retornaPrimeiroPeriodo = true,  last7Days = false, lastWeek = false, months = true ) => {
    const tmpPeriods = []

    if (months) {
        const months = getMonths();
        tmpPeriods.push(...months)
    } else {
        tmpPeriods.unshift({ label: "Mês", value: "month" })
    }

    let primeiroPeriodo = tmpPeriods[0].value

    if (last7Days) tmpPeriods.unshift({ label: "Últ. 7 dias", value: 'lastDays' })
    if (lastWeek) tmpPeriods.unshift({ label: "Últ. semana", value: 'lastWeek' })

    if (selecione)  tmpPeriods.unshift({ label: "Selecione", value: null })

    tmpPeriods.push({ label: "Personalizado", value: "period" })

    return retornaPrimeiroPeriodo ? { primeiroPeriodo, tmpPeriods }: {tmpPeriods}
}

export const getPeriodsStartEndDateByType = (periodType) => {
    let periodStart, periodEnd
    if (periodType === "month") {
        periodStart = moment().startOf("month")
        periodEnd = moment().endOf("month")
    } else if (periodType === "period") {
        periodStart = moment().subtract(1, "month")
        periodEnd = moment()
    } else if (periodType === "lastDays") {
        periodStart = moment().subtract(6, "day")
        periodEnd = moment()
    } else if (periodType === "lastWeek") {
        periodStart = moment().startOf("week")
        periodEnd = moment().endOf("week")
    } else if (periodType === "today") {
        periodStart = moment()
        periodEnd = moment()
    } else if (periodType === "yesterday") {
        periodStart = moment().subtract(1, "day")
        periodEnd = moment().subtract(1, "day")
    }
    return {periodStart, periodEnd}
}
