import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {connect} from "react-redux"
import {buscarNotificacao, cadastrarNotificacao, editarNotificacao} from "../../store/auth/profile/actions"
import Loader from "react-loader-spinner"
import ToggleButton from "react-bootstrap/ToggleButton"
import {buscarUsuariosPorEmpresa} from "store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import {getCompanies} from "../../helpers/utils"
import {ToggleButtonGroup} from "react-bootstrap";
import FormInputNumber from "../../components/Common/form-input-number";
import FormInputText from "../../components/Common/form-input-text";
import FormSelectCompany from "../../components/Common/form-select-company";
import { useLocation } from "react-router-dom"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import ButtonRedirect from "../../components/Common/button-redirect"
import toastr from "toastr";
import FormInputDate from "../../components/Common/form-input-date";
import FormInputSelect from "../../components/Common/form-input-select";
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";
import {EditorState} from "draft-js";
import FormInputEditor from "../../components/Common/form-input-editor";
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";

const CadastrarNotificacao = props => {

    const [paramIdCloud, setParamIdCloud] = useState(undefined)
    const [id, setId] = useState()
    const [titulo, setTitulo] = useState()
    const [notificacaoState, setNotificacaoState] = useState(EditorState.createEmpty())
    const [dataEntrega, setDataEntrega] = useState()
    const [status, setStatus] = useState()
    const [empresa, setEmpresa] = useState(null)
    const [usuario, setUsuario] = useState(null)
    const [diasSemAcesso, setDiasSemAcesso] = useState(null)
    const [diasCertificadoVencer, setDiasCertificadoVencer] = useState(null)
    const [usuarioSemVinculo, setUsuarioSemVinculo] = useState(false)
    const [usuarioComVinculo, setUsuarioComVinculo] = useState(false)
    const [usuarioComVinculoPendente, setUsuarioComVinculoPendente] = useState(false)
    const [semCertificado, setSemCertificado] = useState(false)
    const [certificadoDigitalVencido, setCertificadoDigitalVencido] = useState(false)
    const [certificadoDigitalVencimentoProximo, setCertificadoDigitalVencimentoProximo] = useState(false)

    const [notificaEmail, setNotificaEmail] = useState(true)
    const [notificaSistema, setNotificaSistema] = useState(false)
    const [notificaPush, setNotificaPush] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const [companies, setCompanies] = useState()
    const [usuariosEmpresa, setUsuariosEmpresa] = useState([])

    const usePermissionEdit = usePermission('notificacoesEdit_editar')
    const usePermissionInsert = usePermission('notificacoesEdit_inserir')
    const usePermissionRemove = usePermission('notificacoesEdit_excluir')
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const location = useLocation();

    const getAndSearch = (idCloud)=>{
        props.buscarNotificacao({id: idCloud}, props.history)
        props.navegarBreadCrumb( {title: `breadcrumb.notification`, link: location.pathname, id: idCloud})
    }

    useEffect(() => {
        if (paramIdCloud !== undefined) return

        const idCloud = props.match.params.hasOwnProperty('idCloud') ? props.match.params.idCloud : null
        if (idCloud) {
            getAndSearch(idCloud)
        } else {
            props.navegarBreadCrumb({title: `breadcrumb.notification.new`, link: location.pathname})
        }
        setLoaded(true)
        setParamIdCloud(idCloud)
    }, [props.match.params])


    useEffect(() => {
        const tmpCompanies = getCompanies(props.user)
        setCompanies(tmpCompanies)
        setEmpresa(tmpCompanies[0].value)

        return () => {
            setCompanies([])
        }
    }, [])

    useEffect(() => {
        if (props.notificacao && props.notificacao?.id) {
            preencherFormulario(props.notificacao)
        } else {
            setId(null)
            setDataEntrega(new Date())
            setTitulo(null)
            setStatus('N') //E: ENTREGUE, N: NÃO ENTREGUE
            setNotificacaoState(prevState => EditorState.createEmpty())
            setUsuario(null)
            setDiasCertificadoVencer(null)
            setUsuarioSemVinculo(false)
            setUsuarioComVinculo(false)
            setUsuarioComVinculoPendente(null)
            setNotificaEmail(false)
            setNotificaSistema(false)
            setNotificaPush(false)
            setDiasCertificadoVencer(null)
            setSemCertificado(false)
            setCertificadoDigitalVencido(false)
            setCertificadoDigitalVencimentoProximo(false)
        }

    }, [props.notificacao])

    const preencherFormulario = (notificacao) => {
        setId(notificacao.id)
        setTitulo(notificacao.titulo)
        let contentState = stateFromHTML(notificacao.notificacao)
        setNotificacaoState(prevState => EditorState.createWithContent(contentState))
        setDataEntrega(new Date(notificacao.data_entrega))
        setStatus(notificacao.status_entregue) //E: ENTREGUE, N: NÃO ENTREGUE
        alterarEmpresa(notificacao.idEmpresa)
        setUsuario(notificacao.usuario_id)
        setDiasSemAcesso(notificacao.dias_sem_acesso)
        setUsuarioSemVinculo(notificacao.usuario_sem_vinculo)
        setUsuarioComVinculo(notificacao.usuario_com_vinculo)
        setUsuarioComVinculoPendente(notificacao.usuario_com_vinculo_pendente)
        setNotificaEmail(notificacao.notifica_email)
        setNotificaSistema(notificacao.notifica_sistema)
        setNotificaPush(notificacao.notifica_push)
        setDiasCertificadoVencer(notificacao.dias_certificado_vencer)
        setSemCertificado(notificacao.sem_certificado)
        setCertificadoDigitalVencido(notificacao.certificado_digital_vencido)
        setCertificadoDigitalVencimentoProximo(notificacao.certificado_digital_vencimento_proximo)
    }

    useEffect(() => {
        setUsuariosEmpresa(Array.isArray(props.usuariosEmpresa) ? props.usuariosEmpresa.map(e => ({
            label: e.nome_completo,
            value: e.id
        })) : [])

        return () => setUsuariosEmpresa([])
    }, [props.usuariosEmpresa])

    const gravarNotificacao = (e) => {
        let valid = true
        valid = valid && (!!titulo)
        valid = valid && (!!notificacaoState)
        valid = valid && (!!dataEntrega)

        if (valid) {
            salvarNotificacao()
        } else {
            const divs = document.getElementsByName("validate-required")
            for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "block"
            }

            window.scrollTo(0, 0)

            toastr.error('Há campos inválidos no formulário!', 'Validação de informações')
        }
    }

    const salvarNotificacao = () => {
        const estoque = props.estoque ? Object.assign({}, props.estoque) : {}
        estoque.id = id
        estoque.titulo = titulo
        estoque.notificacao = stateToHTML(notificacaoState.getCurrentContent())
        estoque.data_entrega = dataEntrega
        estoque.idEmpresa = empresa ? empresa : null
        estoque.usuario_id = usuario ? usuario : null
        estoque.dias_sem_acesso = diasSemAcesso ? diasSemAcesso : null
        estoque.usuario_sem_vinculo = usuarioSemVinculo
        estoque.usuario_com_vinculo = usuarioComVinculo
        estoque.usuario_com_vinculo_pendente = usuarioComVinculoPendente
        estoque.notifica_email = notificaEmail
        estoque.notifica_sistema = notificaSistema
        estoque.notifica_push = notificaPush
        estoque.dias_certificado_vencer = diasCertificadoVencer ? diasCertificadoVencer : null
        estoque.sem_certificado = semCertificado
        estoque.certificado_digital_vencido = certificadoDigitalVencido
        estoque.certificado_digital_vencimento_proxim = certificadoDigitalVencimentoProximo

        if (estoque.id) {
            props.editarNotificacao(estoque, props.history)
        } else {
            props.cadastrarNotificacao(estoque, props.history)
        }
    }

    const alterarEmpresa = value => {
        const empresaId = value ? value : null
        setEmpresa(empresaId)
        alterarUsuario(null)
        if (value) {
            props.buscarUsuariosPorEmpresa({empresaId: value})
        }

        alteraMotivoNotificacao(null)
    }

    const alterarUsuario = value => {
        setUsuario(value)
        alteraMotivoNotificacao(null)
    }

    const alteraMotivoNotificacao = value => {
        setUsuarioSemVinculo(false)
        setUsuarioComVinculo(false)
        setUsuarioComVinculoPendente(false)
        setSemCertificado(false)
        setCertificadoDigitalVencido(false)
        setCertificadoDigitalVencimentoProximo(false)
        setDiasSemAcesso(0)
        setDiasCertificadoVencer(0)

        if (!value) return

        switch (value) {
            case 'usuarioSemVinculo':
                setUsuarioSemVinculo(true);
                break;
            case 'usuarioComVinculo':
                setUsuarioComVinculo(true);
                break;
            case 'usuarioComVinculoPendente':
                setUsuarioComVinculoPendente(true);
                break;
            case 'semCertificado':
                setSemCertificado(true);
                break;
            case 'certificadoDigitalVencido':
                setCertificadoDigitalVencido(true);
                break;
            case 'certificadoDigitalVencimentoProximo':
                setCertificadoDigitalVencimentoProximo(true);
                break;
            default:
                break;
        }
    }

    const alterarTipoNotificacao = value => {
        let notificaEmail = false
        let notificaSistema = false
        let notificaPush = false

        if (value === 'notificaEmail') {
            notificaEmail = true
        } else if (value === 'notificaSistema') {
            notificaSistema = true
        } else if (value === 'notificaPush') {
            notificaPush = true
        }

        setNotificaEmail(notificaEmail)
        setNotificaSistema(notificaSistema)
        setNotificaPush(notificaPush)
    }

    return (
        <>
            {props.notificacaoLoading ? (<div className="loader-container position-absolute">
                <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={80}
                    width={80}
                />
            </div>) : (
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="menu.label.notification"/>
                        <StickerToolbarButtons>
                            <ButtonRedirect path={"notificacoes"} history={props.history}></ButtonRedirect>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={status === 'E' || !((id && usePermissionEdit) || (!id && usePermissionInsert))}
                                onClick={e => gravarNotificacao()}>
                                {id ? "Salvar Notificação" : "Cadastrar Notificação"}
                            </Button>
                        </StickerToolbarButtons>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Cadastro</CardTitle>
                                        <form>
                                            <div className="outer">
                                                <div className="outer">

                                                    {id && <FormGroup className="mb-4" row>
                                                        <Label htmlFor="codigo" className="col-form-label col-lg-2">
                                                            Código
                                                        </Label>
                                                        <Col lg="10" className="col-form-label">
                                                            {id}
                                                        </Col>
                                                    </FormGroup>}

                                                    <FormInputText
                                                        attribute="titulo"
                                                        keyTranslate="notificacao.edicao.titulo"
                                                        value={titulo}
                                                        setValue={setTitulo}
                                                        maxLength={100}
                                                    />

                                                    <FormInputText
                                                        attribute="status"
                                                        keyTranslate="notificacao.edicao.status"
                                                        value={props.t('notificacao.edicao.status.' + status)}
                                                        readonly={true}
                                                    />

                                                    <FormInputEditor
                                                        attribute="notificacao"
                                                        keyTranslate="notificacao.edicao.notificacao"
                                                        value={notificacaoState}
                                                        setValue={setNotificacaoState}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataEntrega"
                                                        keyTranslate="notificacao.edicao.dataentrega"
                                                        value={dataEntrega}
                                                        setValue={setDataEntrega}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                    <FormSelectCompany
                                                        attribute="empresaId"
                                                        keyTranslate="notificacao.edicao.empresa"
                                                        value={empresa}
                                                        setValue={alterarEmpresa}
                                                        options={companies}
                                                    />

                                                    {empresa > 0 && (
                                                        <FormInputSelect
                                                            attribute="ippt"
                                                            keyTranslate="notificacao.edicao.usuario"
                                                            value={usuario}
                                                            setValue={alterarUsuario}
                                                            options={usuariosEmpresa}
                                                        />
                                                    )}

                                                    {(!usuario) && (
                                                        <FormGroup className="mb-4" row>
                                                            <Label className="col-form-label col-lg-2">
                                                                {props.t('notificacao.edicao.motivo.label')}
                                                                <Tooltip id="motivo-title"
                                                                         tooltip={props.t('notificacao.edicao.motivo.title')}></Tooltip>
                                                            </Label>
                                                            <Col lg="10">
                                                                <ToggleButtonGroup type="radio" className="mb-0"
                                                                                   name="motivo"
                                                                                   onChange={e => alteraMotivoNotificacao(e)}
                                                                                   value={usuarioSemVinculo ? 'usuarioSemVinculo' :
                                                                                       usuarioComVinculo ? 'usuarioComVinculo' :
                                                                                           usuarioComVinculoPendente ? 'usuarioComVinculoPendente' :
                                                                                               semCertificado ? 'semCertificado' :
                                                                                                   certificadoDigitalVencido ? 'certificadoDigitalVencido' :
                                                                                                       certificadoDigitalVencimentoProximo ? 'certificadoDigitalVencimentoProximo' : null}>
                                                                    {!empresa && <ToggleButton
                                                                        id="usuarioSemVinculo"
                                                                        variant="outline-primary"
                                                                        value="usuarioSemVinculo">
                                                                        Usuário sem vínculo
                                                                    </ToggleButton>}

                                                                    {empresa && <ToggleButton
                                                                        id="usuarioComVinculo"
                                                                        variant="outline-primary"
                                                                        value="usuarioComVinculo">
                                                                        Usuário com vínculo
                                                                    </ToggleButton>}

                                                                    {empresa && <ToggleButton
                                                                        id="usuarioComVinculoPendente"
                                                                        variant="outline-primary"
                                                                        value="usuarioComVinculoPendente">
                                                                        Usuário com vínculo pendente
                                                                    </ToggleButton>}

                                                                    <ToggleButton
                                                                        id="semCertificado"
                                                                        variant="outline-primary"
                                                                        value="semCertificado">
                                                                        Empresa sem certificado digital
                                                                    </ToggleButton>

                                                                    <ToggleButton
                                                                        id="certificadoDigitalVencido"
                                                                        variant="outline-primary"
                                                                        value="certificadoDigitalVencido">
                                                                        Empresa com certificado digital vencido
                                                                    </ToggleButton>

                                                                    <ToggleButton
                                                                        id="certificadoDigitalVencimentoProximo"
                                                                        variant="outline-primary"
                                                                        value="certificadoDigitalVencimentoProximo">
                                                                        Empresa com certificado digital próximo ao
                                                                        vencimento
                                                                    </ToggleButton>

                                                                </ToggleButtonGroup>
                                                            </Col>
                                                        </FormGroup>
                                                    )}

                                                    {(usuario || usuarioSemVinculo || usuarioComVinculo || usuarioComVinculoPendente) &&
                                                        <FormInputNumber
                                                            attribute="diasSemAcesso"
                                                            keyTranslate="notificacao.edicao.diasacesso"
                                                            value={diasSemAcesso}
                                                            setValue={setDiasSemAcesso}
                                                            allowNegative={false}
                                                            max={365}
                                                            min={0}
                                                        />
                                                    }

                                                    {(certificadoDigitalVencimentoProximo) &&
                                                        <FormInputNumber
                                                            attribute="diasCertificadoVencer"
                                                            description="Dias para vencer o certificado digital"
                                                            keyTranslate="notificacao.edicao.diascertificadovencer"
                                                            value={diasCertificadoVencer}
                                                            setValue={setDiasCertificadoVencer}
                                                            allowNegative={false}
                                                            max={365}
                                                            min={0}
                                                        />
                                                    }

                                                    <FormGroup className="mb-4" row>
                                                        <Label className="col-form-label col-lg-2">
                                                            Tipo de envio
                                                            {props.t('notificacao.edicao.tipomotivo.label')}
                                                            <Tooltip id="notificacao"
                                                                     tooltip={props.t('notificacao.edicao.tipomotivo.title')}></Tooltip>
                                                        </Label>
                                                        <Col lg="10">
                                                            <ToggleButtonGroup type="radio" className="mb-2"
                                                                               name="notificacao"
                                                                               onChange={e => alterarTipoNotificacao(e)}
                                                                               value={notificaEmail ? 'notificaEmail' :
                                                                                   notificaSistema ? 'notificaSistema' :
                                                                                       notificaPush ? 'notificaPush' : null}>
                                                                <ToggleButton
                                                                    id="notificaEmail"
                                                                    variant="outline-primary"
                                                                    value="notificaEmail">
                                                                    E-mail
                                                                </ToggleButton>

                                                                <ToggleButton
                                                                    id="notificaSistema"
                                                                    variant="outline-primary"
                                                                    value="notificaSistema">
                                                                    Sistema
                                                                </ToggleButton>

                                                                <ToggleButton
                                                                    id="notificaPush"
                                                                    variant="outline-primary"
                                                                    value="notificaPush">
                                                                    Push Web
                                                                </ToggleButton>

                                                            </ToggleButtonGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    )
}

const mapStatetoProps = state => {
    const {notificacaoAtivo, notificacao, notificacaoLoading, usuariosEmpresa, usuariosEmpresaLoading} = state.Profile
    const {user} = state.Login
    return {user, notificacaoAtivo, notificacao, notificacaoLoading, usuariosEmpresa, usuariosEmpresaLoading}
}

export default withTranslation()(connect(mapStatetoProps, {
    buscarNotificacao,
    cadastrarNotificacao,
    editarNotificacao,
    buscarUsuariosPorEmpresa,
    navegarBreadCrumb
})(CadastrarNotificacao))
