import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Input, Label } from "reactstrap"
import {cnpj as cnpjValidator} from "cpf-cnpj-validator";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

const FormInputCpfCnpj = props => {
  const { attribute, description, value, setValue, changeHandeler, required, disabled, placeholder, afterChange } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        {description &&
          <Label htmlFor={ attribute + "-cnpj" } className="col-form-label col-lg-2">
            { description }
          </Label>
        }
        <Col lg="10">
          <CpfCnpj
              id={ attribute + "-cnpj"}
              name={ attribute + "-cnpj"}
              type="text"
              className="form-control"
              value={ value }
              onChange={ e => {
                setValue(e.target.value)

                if(required) {
                  if(changeHandeler !== undefined) {
                    changeHandeler(e.target.value, `validate-required-${ attribute }-cnpj`)
                  } else {
                    changeHandelerInput(e.target.value, `validate-required-${ attribute }-cnpj`)
                  }
                }

                if(afterChange) afterChange(e.target.value)
              } }
              placeholder= { disabled ? '' : (placeholder ? placeholder : `Digite a(o) ${description}`)}
              required={ required }
              disabled={ disabled }
          />
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-cnpj` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputCpfCnpj.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  changeHandeler: PropTypes.func,
  afterChange: PropTypes.func,
}

export default FormInputCpfCnpj
