import PropTypes from "prop-types"
import React, {useState} from "react"
import {Col, FormGroup, Label} from "reactstrap"
import DatePicker from "react-datepicker";
import Tooltip from "./tooltip";
import {withTranslation} from "react-i18next";

const FormInputDate = props => {
  const { attribute, description, value, setValue, changeHandeler, afterSetValue, required, dateFormat, validacao, validacaoMensagem, disabled, withHour, placeholder, title, keyTranslate } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao === false) {
      document.getElementById(elementId).style.display = 'block'
    } else if(validacao === true) {
      document.getElementById(elementId).style.display = 'none'
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }

    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-date" } className="col-form-label col-lg-2">
          { keyTranslate ? props.t(keyTranslate + '.label') : description }
          { keyTranslate && <Tooltip id={ attribute + "-title"} tooltip={ props.t(keyTranslate + '.title') }></Tooltip>}
        </Label>
        <Col lg="10">
          <DatePicker
              id={ attribute + "-date" }
              name={ attribute + "-date" }
              selected={ value }
              onChange={ date => {
                setValue(date)

                if(afterSetValue) {
                  afterSetValue(data)
                }

                if(required) {
                  if(changeHandeler !== undefined) {
                    changeHandeler(date, `validate-required-${ attribute }-date`)
                  } else {
                    changeHandelerInput(date, `validate-required-${ attribute }-date`)
                  }
                }

                if(validacao === true || validacao === false) {
                  if(changeHandeler !== undefined) {
                    changeHandeler(date, `validate-${ attribute }-date`, validacao)
                  } else {
                    changeHandelerInput(date, `validate-${ attribute }-date`, validacao)
                  }
                }

              } }
              className={ "form-control " + ((required && !value) || validacao === false ? "is-invalid" : "") }
              placeholder= { disabled ? '' : (keyTranslate ? props.t(keyTranslate + '.placeholder') : (placeholder ? placeholder : `Digite a(o) ${description}`))}
              locale="ptBR"
              dateFormat= { dateFormat || (withHour ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy') }
              required={ required }
              disabled={ disabled }
              showTimeSelect={ withHour }
              timeFormat={ withHour ? "HH:mm" : undefined }
              timeIntervals={ withHour ? 15 : undefined }
              timeCaption={ withHour ? "Tempo" : undefined }
              title={ keyTranslate ? props.t(keyTranslate + '.title') : title}
          />
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-date` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
          { validacaoMensagem && validacao === false &&
              <div className="invalid-tooltip"
                   name="validate" id={ `validate-${ attribute }-date` }>
                { validacaoMensagem }
              </div>
          }
          { validacaoMensagem &&
              <div className="invalid-tooltip" name="validate" id={`validate-${ attribute }-date`}
               style={ { display: validacao == false ? "block" : "none"} }>
            { validacaoMensagem }
          </div> }

        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputDate.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  withHour: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  changeHandeler: PropTypes.func,
  dateFormat: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  keyTranslate: PropTypes.string,
}

export default withTranslation()(FormInputDate)
