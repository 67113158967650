import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import SimpleBar from "simplebar-react"

import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../../store/auth/register/actions"
import { apiError } from "../../../store/auth/login/actions"
import { buscarNotificacoesUsuarioNaoLidas, notificacaoUsuarioWebsocket } from "store/auth/profile/actions"
import moment from "moment"
import { io } from "socket.io-client"
import { socket } from "../../../config"
import usePermission from "helpers/permission/usePermission"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [socketClient, setSocketClient] = useState()

  const usePermissionList = usePermission('notificacoes_listar')

  useEffect(() => {
    if(usePermissionList) {
      props.buscarNotificacoesUsuarioNaoLidas()
    }
  }, [])

  useEffect(() => {
    if(usePermissionList) {
      let token = localStorage.getItem("authToken")
      if(token) {
        let socketNotificacao
        if (!socketClient) {
          try {
            socketNotificacao = io(`${ socket.url }/notificacaoUsuarios`, {
              query: { "x-access-token": token },
              upgrade: false
            })
            setSocketClient(socketNotificacao)
            socketNotificacao.off("connected").on("connected", channel => {
              console.log(`Connected in socket /notificacaoUsuarios`)
              if (channel) {
                socketNotificacao.off(channel).on(channel, data => {
                  if (data) {
                    console.log(`Received notification [${ data.id }]`)
                    props.notificacaoUsuarioWebsocket({
                      notificacaoUsuario: data,
                      pendingNotificacoesUsuario:
                      props.pendingNotificacoesUsuario
                    })
                  }
                })
              }
            })
            return () => {
              socketNotificacao.off()
            }
          } catch (e) {
            console.log("[NotificationDropdown][socket] Exception", { e })
          }
        }
      }
    }
  }, [])

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm:ss")
  }

  const describeType = tipo => {
    switch (tipo) {
      case "S":
        return "Sistema"
      case "P":
        return "Push Web"
      case "E":
        return "E-mail"
      default:
        return "N/D"
    }
  }

  const navegarParaPainel = (notificacaoUserId) => {
    props.history.push('/painel-notificacao', { id: notificacaoUserId })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={ menu }
        toggle={ () => setMenu(!menu) }
        className="dropdown d-inline-block"
        tag="li"
        disabled={ !usePermissionList }
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            { !props.loadingValueSocket &&
            (Array.isArray(props.pendingNotificacoesUsuario)
              ? props.pendingNotificacoesUsuario.length
              : 0) }
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notificações</h6>
              </Col>
              <div className="col-auto">
                <a href="/painel-notificacao" className="small">
                  { " " }
                  Ver Todas
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={ { height: "230px" } }>
            { props.pendingNotificacoesUsuario &&
            props.pendingNotificacoesUsuario.length > 0 &&
            props.pendingNotificacoesUsuario
              .filter(notificacaoUsuario => notificacaoUsuario)
              .map((notificacaoUsuario, key) => (
                <div
                  key={ key }
                  onClick={() => navegarParaPainel(notificacaoUsuario.id)}
                  className="text-reset notification-item">
                  <div className="d-flex" >
                    <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          { notificacaoUsuario.notificacao && (
                            <i className="bx bx-bell" />
                          ) }
                          { notificacaoUsuario.usuarioVinculo && (
                            <i className="bx bxs-zap" />
                          ) }
                        </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        { notificacaoUsuario.notificacao?.titulo }
                        { notificacaoUsuario.usuarioVinculo &&
                        " - Solicitação de vínculo pendente" }
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          { notificacaoUsuario.usuarioVinculo &&
                          `Solicitante ${ notificacaoUsuario.usuarioVinculo?.usuario?.nome_completo }` }
                        </p>
                        <p className="mb-1">
                          Tipo:{ " " }
                          { describeType(notificacaoUsuario.tipo_notificacao) }
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          { describeDateTime(notificacaoUsuario.created_at) }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )) }
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/painel-notificacao">
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">Ver Todas</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const {
    notificacoesUsuarioNaoLidas,
    pendingNotificacoesUsuario,
    loadingValueSocket
  } = state.Profile
  const { user } = state.Login
  return {
    user,
    notificacoesUsuarioNaoLidas,
    pendingNotificacoesUsuario,
    loadingValueSocket
  }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  buscarNotificacoesUsuarioNaoLidas,
  notificacaoUsuarioWebsocket
})((NotificationDropdown))

NotificationDropdown.propTypes = {
  user: PropTypes.any,
  history: PropTypes.object,
  t: PropTypes.any,
  loadingValueSocket: PropTypes.bool,
  notificacoesUsuarioNaoLidas: PropTypes.array,
  pendingNotificacoesUsuario: PropTypes.array
}
