import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Label } from "reactstrap"
import {withTranslation} from "react-i18next";
import Tooltip from "./tooltip";

const FormInputSelect = props => {
  const { attribute, description, value, setValue, required, changeHandeler, options, title, keyTranslate } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-select" } className="col-form-label col-lg-2">
          { keyTranslate ? props.t(keyTranslate + '.label') : description }
          { keyTranslate && <Tooltip id={ attribute + "-title"} tooltip={ props.t(keyTranslate + '.title') }></Tooltip>}
        </Label>
        <Col lg="10">
          <select className={"form-select select2-search-disable " + (required && !value? "is-invalid" : "") }
                  id={ attribute + "-select" }
                  name={ attribute + "-select" }
                  value={ value }
                  title={ keyTranslate ? props.t(keyTranslate + '.title') : title}
                  onChange={ e => {
                    setValue(e.target.value)
                    if(required) {
                      if(changeHandeler !== undefined) {
                        changeHandeler(e.target.value, `validate-required-${ attribute }-select`)
                      } else {
                        changeHandelerInput(e.target.value, `validate-required-${ attribute }-select`)
                      }
                    }
                  } }
                  required={ required }>
            <option value="">Selecione...</option>
            { options.map(({ value, label }) => (
              <option key={ value } value={ value }>
                { label }
              </option>
            )) }
          </select>
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-select` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputSelect.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  options: PropTypes.any,
  title: PropTypes.string,
  keyTranslate: PropTypes.string
}

export default withTranslation()(FormInputSelect)
