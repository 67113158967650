import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Input, Label } from "reactstrap"
import {withTranslation} from "react-i18next";
import Tooltip from "./tooltip";

const FormInputText = props => {
  const { attribute, description, value, setValue, changeHandeler, required, minLength, maxLength, disabled, readonly, placeholder, afterChange, title, keyTranslate } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-text" } className="col-form-label col-lg-2">
          { keyTranslate ? props.t(keyTranslate + '.label') : description }
          { keyTranslate && <Tooltip id={ attribute + "-title"} tooltip={ props.t(keyTranslate + '.title') }></Tooltip>}
        </Label>
        <Col lg="10">
          { !readonly && <Input
            id={ attribute + "-text"}
            name={ attribute + "-text"}
            type="text"
            className={ "form-control " + (required && !value? "is-invalid" : "") }
            value={ value }
            onChange={ e => {
              setValue(e.target.value)

              if(required) {
                if(changeHandeler !== undefined) {
                  changeHandeler(e.target.value, `validate-required-${ attribute }-text`)
                } else {
                  changeHandelerInput(e.target.value, `validate-required-${ attribute }-text`)
                }
              }

              if(afterChange) afterChange(e.target.value)
            } }
            placeholder= { disabled ? '' : (keyTranslate ? props.t(keyTranslate + '.placeholder') : (placeholder ? placeholder : `Digite a(o) ${description}`))}
            required={ required }
            minLength={ minLength }
            maxLength={ maxLength }
            disabled={ disabled }
            title={ keyTranslate ? props.t(keyTranslate + '.title') : title}
          /> }
          { readonly &&
              <Label id={ attribute + "-text"}
                     name={ attribute + "-text"}
                     className="col-form-label col-lg-2">
                { value }
              </Label>
          }
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-text` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputText.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  changeHandeler: PropTypes.func,
  afterChange: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  title: PropTypes.string,
  keyTranslate: PropTypes.string,
}

export default withTranslation()(FormInputText)
