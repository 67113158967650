import axios from "axios"
import swal from "sweetalert"
import { api } from "../config"

const axiosApi = axios.create({})

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("authToken")
      localStorage.removeItem("keepConnected")
      localStorage.removeItem("keepConnectedLastRefresh")
      localStorage.removeItem("avatarUser")
      window.location.href = "/login"
    }
    console.error(error)

    return Promise.reject(error)
  }
)
axiosApi.interceptors.request.use(
  async config => {
    const token = config.headers.common["x-access-token"]
    const keepConnected = localStorage.getItem("keepConnected")
    if (
      keepConnected &&
      token &&
      !config.url.includes("usuarios/refresh_token") &&
      !config.url.includes("usuarios/auth")
    ) {
      let refreshToken = true
      const lastRefreshStr = localStorage.getItem("keepConnectedLastRefresh")
      if (lastRefreshStr) {
        const lastRefresh = new Date(lastRefreshStr)
        refreshToken = lastRefresh.getDate() < new Date().getDate()
      }
      if (refreshToken) {
        await post(`${ api.url }usuarios/refresh_token`, {}).then(data => {
          localStorage.setItem("authToken", data.token)
          localStorage.setItem(
            "keepConnectedLastRefresh",
            new Date().toISOString()
          )
        })
      }
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

const setHeaders = () => {
  let auth = localStorage.getItem("authToken")
  if (auth) {
    axiosApi.defaults.headers.common["x-access-token"] = auth
  }
  axiosApi.defaults.headers.common["Content-Type"] = 'application/json'
  axiosApi.defaults.headers.common["Accept"] = 'application/json'
}

export async function getJson(url, config = {}) {
  setHeaders()
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}


export async function get(url, config = {}) {
  setHeaders()
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  setHeaders()

  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
    .catch(e => {
        console.error('[API][Error]', {url, data, config, e})
        throw e
    } )
}

export async function put(url, data, config = {}) {
  setHeaders()
  return axiosApi
    .put(url, data, { ...config })
    .then(response => response.data)
    .catch(e => {
        console.error('[API][Error]', {url, data, config, e})
        throw e
    })
}

export async function del(url, config = {}) {
  setHeaders()
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(e => {
        console.error('[API][Error]', {url, config, e})
        throw e
    })
}
