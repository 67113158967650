import PropTypes from "prop-types"
import React, {useState} from "react"
import {Col, FormGroup, Label} from "reactstrap"
import AsyncSelect from "react-select/async"

const FormInputAsyncSelect = props => {
  const { attribute, description, value, changeHandeler, setValue, loadOptions, required, dependencyKey } = props

  const [inputValue, setInputValue] = useState() // utilizado no auto complete

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-async-select" } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          <AsyncSelect
            key={ dependencyKey }
            id={ attribute + "-async-select" }
            name={ attribute + "-async-select" }
            className={ "basic-single " + (required && !value? "is-invalid" : "")}
            placeholder={ `Digite para exibir ${description}...` }
            isClearable={ true }
            isSearchable={ true }
            value={ value }
            noOptionsMessage={ () => `Nenhum(a) ${description} encontrado(a)` }
            loadingMessage={ () => "Carregando" }
            onChange={ e => {
              setValue(e)
              if(required) {
                if(changeHandeler !== undefined) {
                  changeHandeler(e?.value, `validate-required-${ attribute }-async-select`)
                } else {
                  changeHandelerInput(e?.value, `validate-required-${ attribute }-async-select`)
                }
              }
            }}
            cacheOptions
            loadOptions={ loadOptions }
            defaultOptions
            required={ required }
            styles={{
              menu: (base) => ({
                ...base,
                'z-index': '6'
              }),
            }}
          />
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-async-select` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputAsyncSelect.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  loadOptions: PropTypes.func,
  dependencyKey: PropTypes.string,
}

export default FormInputAsyncSelect
