import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label, Modal, ModalBody, ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import { connect } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"

//Date Picker
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  buscarGruposProduto,
  buscarQuantidadeGruposProduto, editarGrupoProduto, navegarEditarGrupoProduto, relatorioExecutar,
  removerGrupoProduto
} from "../../store/auth/profile/actions"
import { isArray } from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import usePermission from "helpers/permission/usePermission"
import { map } from "lodash"
import { AvForm } from "availity-reactstrap-validation"
import MiniCards from "../../components/Common/mini-card"
import CsvDownloader from "react-csv-downloader";
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import {getCompanies, getCompaniesValuesByOptions, getCompanyOption} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import { navegarBreadCrumb } from "../../store/navigator/actions"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const GruposProdutoLista = props => {

  const [companies, setCompanies] = useState([])
  const [status, setStatus] = useState("")
  const [miniCards, setMiniCards] = useState([])
  const [companiesOption, setCompaniesOption] = useState([])
  const usePermissionRemove = usePermission("gruposProduto_excluir")
  const usePermissionInsert = usePermission("gruposProduto_inserir")
  const usePermissionEdit = usePermission("gruposProduto_editar")
  const [modalRemove, setModalRemove] = useState(false)
  const [itemSelecionado, setItemSelecionado] = useState()
  const [modalInativar, setModalInativar] = useState(false)
  const [modalAtivar, setModalAtivar] = useState(false)
  const location = useLocation();

  const getColumns = (exportRows) => {
    let columns = [
      { label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150 },
      { label: "Código", field: "Código", sort: "asc", width: 150 },
      { label: "Descrição", field: "Descrição", sort: "asc", width: 270 },
    ];
    if(!exportRows) {
      columns.push({ label: "Ações", field: "Ações", sort: "asc", width: 100 })
    }
    return columns;
  }

  const [tableData, setTableData] = useState({
    columns: getColumns(),
    rows: []
  })

  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

  useEffect(() => {
    if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)
    if (possuiVinculo) {
      props.buscarQuantidadeGruposProduto({ company: getCompaniesValuesByOptions(props.dashboardFilters.companiesSelected)} )
      doUpdate(props.dashboardFilters.companiesSelected)
    }

    return () => {
      setMiniCards({})
    }
  }, [])

  useEffect(() => {
    if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
      let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
      setCompaniesOption(companiesOptions)
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOption(companiesOptions)
    }
    props.navegarBreadCrumb({title: `breadcrumb.product.group.title`, link: location.pathname})
    return () => {
      setCompaniesOption([])
    }
  }, [props.user, props.empresas])

  const popularMiniCards = () => {
    setMiniCards([
      {
        title: "Quantidade de grupos",
        iconClass: "bx-data",
        text:
          props.quantidadeGruposProduto && props.quantidadeGruposProduto.total && props.quantidadeGruposProduto.total.quantidade
            ? new String(props.quantidadeGruposProduto.total.quantidade)
            : "0",
        loading: props.gruposProdutoLoading
      },
      ])
  }

  useEffect(() => {
    popularMiniCards();
    return () => setMiniCards([])
  }, [props.quantidadeGruposProduto, props.gruposProdutoLoading])

  useEffect(() => {
    if(props.grupoProdutoLoading === false) {
      doUpdate()
      props.buscarQuantidadeGruposProduto({ company: getCompaniesValuesByOptions(companies)} )
    }
  }, [props.grupoProdutoLoading])

  const popularTabela = () => {
    if (isArray(props.gruposProduto)) {
      setTableData({
        columns: getColumns(),
        rows: getRows(props.gruposProduto)
      })
    }
  }

  const getRows = (gruposProduto, exportRows) => {
    const rows = [];
    for (const item of (gruposProduto || [])) {
      const row = {
        "ID Cloud": item.id,
        "Código": item.codigo,
        "Descrição": item.Descricao,
      };
      if(!exportRows) {
        row["Ações"] = (
            <div>
              { usePermissionEdit && <Link to="#" className="me-3 text-primary">
                <i
                    className="mdi mdi-pencil font-size-18 me-3"
                    id="edittooltip"
                    onClick={ e => goToGrupoProduto(item) }
                    title="Editar"
                />
              </Link> }
              { usePermissionEdit && item.status === "A" && <Link to="#" className="text-warning">
                <i className="mdi mdi-bookmark-minus font-size-18 me-3" id="btnInativar"
                   onClick={ () => showConfirmInactivate(item) } title="Inativar"/>
              </Link> }
              { usePermissionEdit && item.status === "I" && <Link to="#" className="text-info">
                <i className="mdi mdi-bookmark-plus font-size-18 me-3" id="btnAtivar"
                   onClick={ () => showConfirmActivate(item) } title="Ativar"/>
              </Link> }
              { usePermissionRemove && <Link to="#" className="text-danger">
                <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                   onClick={ () => showConfirmDelete(item) } title="Remover" />
              </Link> }
            </div>
        )
      }
      rows.push(row)
    }

    return rows;
  }

  useEffect(() => {
    popularTabela()
    return () => setTableData({ columns: [], rows: [] })
  }, [props.gruposProduto, props.gruposProdutoLoading])

  const doUpdate = (companiesSelected) => {
    props.buscarGruposProduto({
      company: getCompaniesValuesByOptions(companiesSelected ? companiesSelected : companies),
      status
    })
  }

  const goToGrupoProduto = item => {
    props.navegarEditarGrupoProduto(item)
    props.history.push("/grupo-produto"+(item?`/${item.id}`: ""))
  }

  const showConfirmDelete = item => {
    setItemSelecionado(item)
    setModalRemove(true)
  }

  const showConfirmInactivate = item => {
    setItemSelecionado(item)
    setModalInativar(true)
  }

  const showConfirmActivate = item => {
    setItemSelecionado(item)
    setModalAtivar(true)
  }

  const removerGrupoProduto = () => {
    props.removerGrupoProduto(itemSelecionado)
    setModalRemove(false)
  }

  const inativarGrupoProduto = () => {
    props.editarGrupoProduto( { status: 'I', id: itemSelecionado.id })
    setModalInativar(false)
  }

  const ativarGrupoProduto = () => {
    props.editarGrupoProduto( { status: 'A', id: itemSelecionado.id })
    setModalAtivar(false)
  }

  const csvDataHeader = () => {
    const columns = getColumns(true)
    return columns.map(column => {
      return { id: column.field, displayName: column.label }
    });
  };

  const csvData = () => {
    const rows = getRows(props.gruposProduto, true)
    return Promise.resolve(rows.map(row => {
      return row
    }));
  };

  const printPDF = () => {
    let reportName = 'grupo_produtos';
    props.relatorioExecutar({
      company: getCompaniesValuesByOptions(companies),
      reportName
    })
  }

  if(
    !props.user ||
    !possuiVinculo
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="breadcrumb.product.group.title" />
          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Grupos de produto</h4>
                    <Form>
                      <Row>
                        { map(miniCards, (card, key) => (
                          <MiniCards
                            title={ card.title }
                            text={ card.text }
                            iconClass={ card.iconClass }
                            key={ "_card_" + key }
                            loading={ card.loading }
                          />
                        )) }
                      </Row>
                      <Row>
                        <div className="col col-auto">
                          <FormFilterCompany
                              attribute="companies"
                              value={companies}
                              description="Empresa(s)"
                              setValue={ value => setCompanies(value) }
                              options={companiesOption}
                              multiple={ true }
                              hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                          />
                        </div>

                        <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                          <div className="mt-3 col col-auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={ e => doUpdate() }
                              disabled={ !usePermission("gruposProduto_listar") }>
                              Buscar
                            </Button>
                          </div>

                          <div className="mt-3 col col-auto">
                            <Button
                              type="button"
                              color="success"
                              disabled={ !(usePermissionEdit || usePermissionInsert) }
                              onClick={ e => goToGrupoProduto(null) }>
                              <i className="bx bx-plus" /> Cadastrar
                            </Button>
                          </div>

                          { !props.executaRelatorioLoading && !props.gruposProdutoLoading &&
                              <div className="mt-3 col col-auto">
                                <CsvDownloader
                                    filename="grupo-produtos"
                                    extension=".csv"
                                    separator=";"
                                    wrapColumnChar=""
                                    columns={ csvDataHeader() }
                                    datas={ csvData() }>
                                  <Button type="button" color="light">
                                    <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                    CSV
                                  </Button>
                                </CsvDownloader>
                              </div> }
                          { !props.executaRelatorioLoading && !props.gruposProdutoLoading &&
                              <div className="mt-3 col col-auto">
                                <Button type="button" color="light" onClick={ e => printPDF() }>
                                  <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                  PDF
                                </Button>
                              </div>
                          }
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                <Card>
                  { props.gruposProdutoLoading || props.executaRelatorioLoading ? (
                    <div className="loader-container position-absolute">
                      <Loader
                        type="ThreeDots"
                        color="#556EE6"
                        height={ 80 }
                        width={ 80 }
                      />
                    </div>
                  ) : (
                    <CardBody>
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={ tableData }
                        entriesLabel={ "Mostrar Registros" }
                        infoLabel={ ["Exibindo", "a", "de", "registros"] }
                        noRecordsFoundLabel={ "Nenhum registro encontrado" }
                        paginationLabel={ ["Anterior", "Próximo"] }
                        searchLabel={ "Pesquisar" }
                      />
                    </CardBody>
                  ) }
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={ modalRemove }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              removerGrupoProduto()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover esse registro?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalRemove(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={ modalInativar }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              inativarGrupoProduto()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente inativar esse registro ?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalInativar(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={ modalAtivar }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              ativarGrupoProduto()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente ativar esse registro ?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalAtivar(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

GruposProdutoLista.propTypes = {
  gruposProduto: PropTypes.array,
  quantidadeGruposProduto: PropTypes.any,
  gruposProdutoLoading: PropTypes.bool,
  grupoProduto: PropTypes.any,
  grupoProdutoLoading: PropTypes.bool,
  buscarGruposProduto: PropTypes.func,
  removerGrupoProduto: PropTypes.func,
  editarGrupoProduto: PropTypes.func,
  buscarQuantidadeGruposProduto: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    gruposProdutoLoading,
    gruposProduto,
    grupoProduto,
    grupoProdutoLoading,
    quantidadeGruposProduto,
    executaRelatorioLoading,
    empresas, empresasLoading
  } = state.Profile
  const { user, dashboardFilters } = state.Login
  return {
    gruposProdutoLoading,
    gruposProduto,
    user,
    grupoProduto,
    grupoProdutoLoading,
    quantidadeGruposProduto,
    executaRelatorioLoading, dashboardFilters,
    empresas, empresasLoading
  }
}

export default connect(mapStatetoProps, {
  buscarGruposProduto,
  relatorioExecutar,
  removerGrupoProduto,
  editarGrupoProduto,
  navegarEditarGrupoProduto,
  buscarQuantidadeGruposProduto,
  navegarBreadCrumb
})(withRouter(GruposProdutoLista))
