import React, {Component} from "react"
import {Button, Card, CardBody, Col} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import ApexCharts from 'apexcharts';
import Loader from "react-loader-spinner"
import {connect} from "react-redux"
import moment from "moment"
import {describeMoney, getCompaniesValuesByOptions} from "../../helpers/utils";
import {buscarEvolucaoFiscal} from "../../store/auth/profile/actions";
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";
import PropTypes from "prop-types";

class OverView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeM: false,
      active6M: true,
      activeY: false,
    }
    this.updateChartMonthly = this.updateChartMonthly.bind(this)
    this.updateChartSixMonth.bind(this)
    this.updateChartYearly.bind(this)

  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.company?.length || 0) !== (this.props.company?.length || 0)) {
      const periodo = this.state.activeM ? '1M' : this.state.active6M ? '6M' : '1Y'
      this.props.buscarEvolucaoFiscal({ company: getCompaniesValuesByOptions(this.props.company), periodo: periodo })
    }
  }
  static getDerivedStateFromProps(props, current_state) {
    if (props.evolucaoFiscal && props.evolucaoFiscal) {

      // const mesesNome = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
      const seriePorMes = current_state.activeM === true
      const seriePorSemestre = current_state.active6M === true
      const seriePorAno = current_state.activeY === true
      // let mesesAnoNome = []

      let evolucaoFiscal = [];
      if(seriePorSemestre) {
        for (let i = 5; i >= 0; i--) {
          const data = moment().subtract(i, "month").startOf("month").format("YYYY-MM-DD")
          evolucaoFiscal.push({ x: data, y: 0 })
        }
      } else if(seriePorAno) {
        for (let i = 11; i >= 0; i--) {
          const data = moment().subtract(i, "month").startOf("month").format("YYYY-MM-DD")
          evolucaoFiscal.push({ x: data, y: 0 })
        }
      } else {
        for (let i = moment().daysInMonth(); i >= 0; i--) {
          const data = moment().subtract(i, "day").format("YYYY-MM-DD")
          evolucaoFiscal.push({ x: data, y: 0 })
        }
      }

      evolucaoFiscal.forEach(evolucao => {
        const evolucaoFiscalData = (props.evolucaoFiscal[0] || []).find(value => evolucao.x === value.data)
        evolucao.y = evolucaoFiscalData?.total_nf || evolucao.y
      })

      // 2023-10-01

      const type = seriePorMes ? { type: "datetime", labels: { format: 'dd MMM yyyy', } } :  { type: "datetime", labels: { format: 'MM/yyyy', } }
      const tooltip = seriePorMes ? {x: {format: "dd MMM yyyy"}} :  {x: {format: "MMM/yyyy"}}

      return {
        ...current_state,
        ...{
          series: [
            {
              name: "Total",
              data: evolucaoFiscal
            }
          ],
          options: {
            chart: {
              id: 'evolucao-fiscal',
              toolbar: "false",
              locales: [
                {
                  name: "pt-br",
                  options: {
                    months: [
                      "Janeiro",
                      "Fevereiro",
                      "Março",
                      "Abril",
                      "Maio",
                      "Junho",
                      "Julho",
                      "Agosto",
                      "Setembro",
                      "Outubro",
                      "Novembro",
                      "Dezembro"
                    ],
                    shortMonths: [
                      "Jan",
                      "Fev",
                      "Mar",
                      "Abr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Ago",
                      "Set",
                      "Out",
                      "Nov",
                      "Dez"
                    ],
                    days: [
                      "Domingo",
                      "Segunda",
                      "Terça",
                      "Quarta",
                      "Quinta",
                      "Sexta",
                      "Sábado"
                    ],
                    shortDays: [
                      "Dom",
                      "Seg",
                      "Ter",
                      "Qua",
                      "Qui",
                      "Sex",
                      "Sab"
                    ]
                  }
                }
              ],
              defaultLocale: "pt-br",
              type: 'area',
              height: 240,
              zoom: {
                autoScaleYaxis: true
              }
            },
            dataLabels: {enabled: false },
            stroke: {curve: "stepline", width: 2},
            markers: {size: 0, style: "hollow"},
            xaxis: type,
            tooltip: tooltip,
            colors: ["#f1b44c"],
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.05,
                stops: [42, 100, 100, 100]
              }
            },
            yaxis: {
              title: {
                text: "Valor"
              },
              labels: {
                formatter: val => {
                  return describeMoney(val)
                }
              }
            }
          }
        }
      }
    }
    return { ...current_state, ...props }
  }

  updateChartMonthly() {
    this.props.buscarEvolucaoFiscal({ company: getCompaniesValuesByOptions(this.props.company), periodo: '1M' })

    ApexCharts.exec(
        'evolucao-fiscal',
        'zoomX',
        moment().subtract(1, "month").toDate().getTime(),
        new Date().getTime()
    )

    this.setState({
      activeM: true,
      active6M: false,
      activeY: false,
    })
  }

  updateChartSixMonth() {
    this.props.buscarEvolucaoFiscal({ company: getCompaniesValuesByOptions(this.props.company), periodo: '6M' })

    ApexCharts.exec(
        'evolucao-fiscal',
        'zoomX',
        moment().subtract(6, "month").toDate().getTime(),
        new Date().getTime()
    )

    this.setState({
      activeM: false,
      active6M: true,
      activeY: false,
    })
  }

  updateChartYearly() {
    this.props.buscarEvolucaoFiscal({ company: getCompaniesValuesByOptions(this.props.company), periodo: '1Y' })

    ApexCharts.exec(
        'evolucao-fiscal',
        'zoomX',
        moment().subtract(12, "month").toDate().getTime(),
        new Date().getTime()
    )

    this.setState({
      activeM: false,
      active6M: false,
      activeY: true,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="4">
          <Card>
            <CardBody>
              <div>
                <h4 className="card-title mb-3">{ this.props.t('notasemitidas.lista.evolucaofiscal.label') }
                  <Tooltip id='evolucaofiscal' tooltip={ this.props.t('notasemitidas.lista.evolucaofiscal.label') }></Tooltip>
                </h4>
                <div className="ms-auto">
                  <div className="d-flex flex-wrap gap-2 justify-content-center toolbar">
                      <Button
                        color="light" size="sm" type="button" className={ this.state.activeM ? "active" : "" }
                        onClick={ () => this.updateChartMonthly() } id="one_month">
                        1M
                      </Button>
                      <Button
                        color="light" size="sm" type="button" className={ this.state.active6M ? "active" : "" }
                        onClick={ () => this.updateChartSixMonth() } id="six_months">
                        6M
                      </Button>
                      <Button
                        color="light" size="sm" type="button" className={ this.state.activeY ? "active" : "" }
                        onClick={ () => this.updateChartYearly() } id="one_year">
                        1A
                      </Button>
                  </div>
                </div>
              </div>
              { !this.state.series ||
                this.props.evolucaoFiscalLoading ? (
                  <div className="loader-container position-absolute">
                    <Loader
                      type="ThreeDots"
                      color="#556EE6"
                      height={ 80 }
                      width={ 80 }
                    />
                  </div>
              ) : <></> }
              <div id="overview-chart-timeline">
                { this.state.options && <ReactApexChart
                  options={ this.state.options }
                  series={ this.state.series }
                  type="area"
                  height={ 240 }
                /> }
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

OverView.propTypes = {
  company: PropTypes.any,
}

const mapStatetoProps = state => {
  const { evolucaoFiscal, evolucaoFiscalLoading } = state.Profile
  return { evolucaoFiscal, evolucaoFiscalLoading }
}

export default withTranslation()(connect(mapStatetoProps, {
  buscarEvolucaoFiscal
})(OverView))
