import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  doDeleteCategory,
  doDeleteQuestion,
  doEditCategory,
  doEditQuestion,
  listarFaqs
} from "../store/auth/profile/actions"

import { stateToHTML } from "draft-js-export-html"
import { convertFromRaw } from "draft-js"
import { isArray } from "leaflet/src/core/Util"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../components/Common/alerta-sem-vinculo"
import { navegarBreadCrumb } from "../store/navigator/actions"
import { useLocation } from "react-router-dom"

const PagesFaqs = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [modalCategory, setModalCategory] = useState(false)
  const [modalQuestion, setModalQuestion] = useState(false)
  const [deleteCategory, setDeleteCategory] = useState(null)
  const [deleteQuestion, setDeleteQuestion] = useState(null)
  const location = useLocation();
  const usePermissionEdit = usePermission('perguntas_editar')
  const usePermissionRemove = usePermission('perguntas_excluir')

  useEffect(() => {
    if (props.faqs.length) {
      setactiveTab(props.faqs[0].id)
    }
    return () => {
      setactiveTab(null)
    }
  }, [props.faqs])

  useEffect(() => {
    props.listarFaqs()
    props.navegarBreadCrumb({title: 'menu.label.faq', link: location.pathname })
  }, [])

  const isInforangra = props.user?.usuario_info

  const onEditCategory = faqCategoria => {
    props.history.push("/categoria/"+ (faqCategoria?faqCategoria.id:""))
    props.doEditCategory(faqCategoria)
  }

  const onDeleteCategory = faqCategoria => {
    setModalCategory(true)
    setDeleteCategory(faqCategoria)
  }

  const doDeleteCategory = () => {
    setModalCategory(false)
    props.doDeleteCategory(deleteCategory)
  }

  const onEditQuestion = faqQuestion => {
    props.history.push("/pergunta"+(faqQuestion?`/${faqQuestion.id}`: ""))
    props.doEditQuestion(faqQuestion)
  }

  const onDeleteQuestion = faqCategoria => {
    setModalQuestion(true)
    setDeleteQuestion(faqCategoria)
  }

  const doDeleteQuestion = () => {
    setModalQuestion(false)
    props.doDeleteQuestion(deleteQuestion)
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */ }
          <Breadcrumbs title="breadcrumb.faq" />
          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  { props.faqs &&
                  isArray(props.faqs) &&
                  props.faqs.map((faqCategoria, key) => (
                    <NavItem key={key}>
                      <NavLink className={ classnames({ active: activeTab === faqCategoria.id }) }
                        onClick={ () => { setactiveTab(faqCategoria.id) } } >
                        <i className={ faqCategoria.icone + " d-block check-nav-icon mt-4 mb-2" } />
                        <p className="font-weight-bold mb-4">
                          { faqCategoria.titulo }
                        </p>

                        { isInforangra && (
                          <div className="button-items my-3">
                            <Button
                              type="button"
                              color="success"
                              className="waves-effect waves-light me-2"
                              onClick={ e => onEditCategory(faqCategoria) }
                              disabled={ !usePermissionEdit }
                            >
                              <i className="bx bxs-edit-alt" />
                            </Button>
                            <Button
                              type="button"
                              color="danger"
                              className="waves-effect waves-light"
                              onClick={ e => onDeleteCategory(faqCategoria) }
                              disabled={ !usePermissionRemove }
                            >
                              <i className="bx bxs-trash" />
                            </Button>
                          </div>
                        ) }
                      </NavLink>
                    </NavItem>
                  )) }
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={ activeTab }>
                      { props.faqs &&
                      isArray(props.faqs) &&
                      props.faqs.map((faqCategoria, key) => (
                        <TabPane tabId={ faqCategoria.id } key={key}>
                          <CardTitle className="mb-5">
                            { faqCategoria.titulo }
                          </CardTitle>
                          { faqCategoria.perguntas &&
                          faqCategoria.perguntas.map((faqPergunta, key) => (
                            <Media className="faq-box mb-4 d-flex justify-content-start" key={key}>
                              <div className="faq-icon me-3">
                                <i
                                  className={
                                    faqPergunta.icone +
                                    " font-size-20 text-success"
                                  }
                                />
                              </div>
                              <Media body>
                                <h5 className="font-size-15">
                                  { faqPergunta.titulo }
                                </h5>
                                { isInforangra && (
                                  <div className="button-items my-3">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="waves-effect waves-light me-2"
                                      onClick={ e =>
                                        onEditQuestion(faqPergunta)
                                      }
                                      disabled={ !usePermissionEdit }
                                    >
                                      <i className="bx bxs-edit-alt" />
                                    </Button>
                                    <Button
                                      type="button"
                                      color="danger"
                                      className="waves-effect waves-light"
                                      onClick={ e =>
                                        onDeleteQuestion(faqPergunta)
                                      }
                                      disabled={ !usePermissionRemove }
                                    >
                                      <i className="bx bxs-trash" />
                                    </Button>
                                  </div>
                                ) }
                                <p
                                  className="text-muted"
                                  dangerouslySetInnerHTML={ {
                                    __html: stateToHTML(
                                      convertFromRaw(
                                        JSON.parse(faqPergunta.conteudo)
                                      )
                                    )
                                  } }
                                ></p>
                              </Media>
                            </Media>
                          )) }
                        </TabPane>
                      )) }
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Modal isOpen={ modalQuestion }>
        <ModalHeader tag="h4">Excluir Pergunta</ModalHeader>
        <ModalBody>
          <Row form>
            <Col className="col-12">
              <p>Você realmente deseja excluir esta pergunta?</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <button
                  type="danger"
                  className="btn btn-success save-event"
                  onClick={ e => doDeleteQuestion() }
                >
                  Sim, excluir
                </button>
                <button
                  type="button"
                  className="btn btn-light ms-2"
                  onClick={ e => setModalQuestion(false) }
                >
                  Cancelar
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal isOpen={ modalCategory }>
        <ModalHeader tag="h4">Excluir Categoria</ModalHeader>
        <ModalBody>
          <Row form>
            <Col className="col-12">
              <p>Você realmente deseja excluir esta categoria?</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <button
                  type="danger"
                  className="btn btn-success save-event"
                  onClick={ e => doDeleteCategory() }
                >
                  Sim, excluir
                </button>
                <button
                  type="button"
                  className="btn btn-light ms-2"
                  onClick={ e => setModalCategory(false) }
                >
                  Cancelar
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

PagesFaqs.propTypes = {
  listarFaqs: PropTypes.func
}

const mapStatetoProps = state => {
  const { faqs, editingCategory, editingQuestion } = state.Profile
  const { user } = state.Login
  return { faqs, user, editingCategory, editingQuestion }
}

export default connect(mapStatetoProps, {
  listarFaqs,
  doDeleteCategory,
  doEditCategory,
  doDeleteQuestion,
  doEditQuestion,
  navegarBreadCrumb
})(PagesFaqs)
