import { NAVEGAR_BREADCRUMB } from "./actionTypes"
import { LOGOUT_USER_SUCCESS } from "../auth/login/actionTypes"

const initialState = {
  error: "",
  success: "",
  heap: [],
  current: ""
}

const navigator = (state = initialState, action) => {

  switch (action.type) {
    case NAVEGAR_BREADCRUMB:
      console.debug("[Navigator][Reducer]", { state, action })

      let checkIfLastElementIsEqual = state.heap.length > 0 && state.heap?.at(-1).link == action.payload.heap?.link

      if (checkIfLastElementIsEqual) {
        break
      }
      const tmp = {}
      tmp["heap"] = state.heap
      tmp["heap"].push(action.payload.heap)
      if (tmp["heap"].length > 5) {
        tmp["heap"].shift()
      }
      state = { ...state, ...tmp }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...initialState }
      break
    default:
      return state
  }
  return state
}

export default navigator
