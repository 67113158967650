import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import {connect} from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"

//Date Picker
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import {MDBDataTable} from "mdbreact"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment"
import {
  buscarContasPagar,
  buscarContasPagarAtrasados,
  buscarContasPagarHoje,
  buscarContasPagarMes,
  buscarContasPagarProximosMeses,
  cadastrarLancamento,
  editarLancamento,
  estornarPagamentoLancamento,
  pagarLancamento,
  relatorioExecutar,
  removerLancamento
} from "../../store/auth/profile/actions"
import {isArray} from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import OverView from "./overview"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import usePermission from "helpers/permission/usePermission"
import CsvDownloader from "react-csv-downloader"
import LancamentoModal from "pages/ContasPagar/lancamento-modal"
import PagamentoLancamentoModal from "pages/ContasPagar/pagamento-lancamento-modal"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import { getCompanies, getCompaniesValuesByOptions, getCompanyOption, getPeriodos } from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import { navegarBreadCrumb } from "../../store/navigator/actions"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const brazilMoment = (...args) => moment(...args)

const ContasPagar = props => {

  const [despesa, setDespesa] = useState()
  const [modal, setModal] = useState(false)
  const [modalPagamento, setModalPagamento] = useState(false)
  const [modalExcluir, setModalExcluir] = useState(false)
  const [modalEstornar, setModalEstonar] = useState(false)
  const [emissaoStartDate, setEmissaoStartDate] = useState(moment().startOf('month').toDate())
  const [emissaoEndDate, setEmissaoEndDate] = useState(moment().endOf('month').toDate())

  const [vencimentoStartDate, setVencimentoStartDate] = useState(moment().startOf('month').toDate())
  const [vencimentoEndDate, setVencimentoEndDate] = useState(moment().endOf('month').toDate())

  const [periods, setPeriods] = useState([])
  const [emissaoPeriod, setEmissaoPeriod] = useState(null)
  const [vencimentoPeriod, setVencimentoPeriod] = useState(null)
  const location = useLocation();
  const [situation, setSituation] = useState(null)
  const [situations, setSituations] = useState([
      { label: "Todas", value: undefined },
      { label: "Atrasada", value: "atrasada" },
      { label: "A pagar", value: "pagar" },
      { label: "Paga", value: "pago" },
  ])

  const [companies, setCompanies] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  const [tipoConta, setTipoConta] = useState()
  const [codigoFornecedor, setCodigoFornecedor] = useState()
  const [find, setFind] = useState(false)
  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

  const [reports, setReports] = useState([
    {
      title: "Pagar Hoje",
      icon: "mdi mdi-note-multiple-outline",
      color: "warning",
      value: "",
      desc: "",
      loading: true
    },
    {
      title: "Pagar no Mês",
      icon: "mdi mdi-note-multiple-outline",
      color: "primary",
      value: "",
      desc: "",
      loading: true
    },
    {
      title: "Pagar nos 3 próximos meses",
      icon: "mdi mdi-note-multiple-outline",
      color: "info",
      value: "",
      desc: "",
      loading: true
    },
    {
      title: "Atrasados",
      icon: "mdi mdi-note-multiple-outline",
      color: "danger",
      value: "",
      desc: "",
      loading: true
    }
  ])

  const usePermissionInsert = usePermission('contasPagar_inserir')
  const usePermissionRemove = usePermission('contasPagar_excluir')
  const usePermissionEdit = usePermission('contasPagar_editar')

  const [tableData, setTableData] = useState({
    columns: [
      { label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150 },
      { label: "Loja", field: "Loja", sort: "asc", width: 200 },
      { label: "Fornecedor", field: "Fornecedor", sort: "asc", width: 200 },
      { label: "Descrição", field: "Descrição", sort: "asc", width: 200 },
      { label: "Documento", field: "Documento", sort: "asc", width: 100 },
      { label: "Data Emissão", field: "Data Emissão", sort: "asc", width: 100 },
      { label: "Data Vencimento", field: "Data Vencimento", sort: "asc", width: 100 },
      { label: "Data Pagamento", field: "Data Pagamento", sort: "asc", width: 100 },
      { label: "Status", field: "Status", sort: "asc", width: 100 },
      { label: "Vlr. Lançamento", field: "Vlr. Lançamento", sort: "asc", width: 100 },
      { label: "Código Conta", field: "Código Conta", sort: "asc", width: 100 },
      { label: "Ação", field: "Ação", sort: "asc", width: 100 }
    ],
    rows: []
  })

  const statusBadgeClass = despesa => {
    switch (despesa.status_descricao) {
      case "Atrasada": {
        return "danger"
      }
      case "A pagar": {
        return "warning"
      }
      case "Paga": {
        return "success"
      }
      default: {
        return "primary"
      }
    }
  }

  useEffect(() => {
    const { tmpPeriods } = getPeriodos(false, false)

    setPeriods(tmpPeriods)

    if (props.user) {
      if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)

      if(possuiVinculo) {
        const companiesId = getCompaniesValuesByOptions(props.dashboardFilters.companiesSelected)
        buscarContasPagarLista(companiesId)
        props.buscarContasPagarHoje({ company: companiesId });
        props.buscarContasPagarProximosMeses({ company: companiesId });
        props.buscarContasPagarAtrasados({ company: companiesId });
        props.buscarContasPagarMes({ company: companiesId });
      }
    }
    props.navegarBreadCrumb( {title: `menu.label.bills`, link: location.pathname })
    return () => {
      setPeriods([])
    }
  }, [props.user])

  useEffect(() => {
    if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
      let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
      setCompaniesOptions(companiesOptions)
    } else {
      let companiesOptions = getCompanies(props.user)
      setCompaniesOptions(companiesOptions)
    }
    return () => {
      setCompaniesOptions([])
    }
  }, [props.user, props.empresas])

   const getColumns = (exportation) => {
    const columns = [
      { label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150 },
      { label: "Loja", field: "Loja", sort: "asc", width: 200 },
      { label: "Fornecedor", field: "Fornecedor", sort: "asc", width: 200 },
      { label: "Descrição", field: "Descrição", sort: "asc", width: 200 },
      { label: "Documento", field: "Documento", sort: "asc", width: 100 },
      { label: "Data Emissão", field: "Data Emissão", sort: "asc", width: 100 },
      { label: "Data Vencimento", field: "Data Vencimento", sort: "asc", width: 100 },
      { label: "Data Pagamento", field: "Data Pagamento", sort: "asc", width: 100 },
      { label: "Status", field: "Status", sort: "asc", width: 100 },
      { label: "Vlr. Lançamento", field: "Vlr. Lançamento", sort: "asc", width: 100 },
      { label: "Código Conta", field: "Código Conta", sort: "asc", width: 100 },
    ]

    if(!exportation) {
      columns.push({ label: "Ação", field: "Ação", sort: "asc", width: 100 });
    }
    return columns

  }

  const getRows = (withStyle) => {
    return (props.contasPagar || []).map(conta => ({
      "ID Cloud": conta.idCloud,
      Loja: conta.loja,
      Fornecedor: conta.fornec ? `${conta.fornec} - ${conta.nome_fornecedor ? conta.nome_fornecedor : 'N/A'}` : '',
      Descrição: conta.descricao,
      Documento: conta.documento,
      "Data Emissão": describeDate(conta.emissao),
      "Data Vencimento": describeDate(conta.vencimento),
      "Data Pagamento": describeDate(conta.data_pagamento),
      "Vlr. Lançamento": (withStyle ?
        <div className="text-right">
          { parseFloat(conta.valor_real).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL"
          }) }
        </div> :
         parseFloat(conta.valor_real).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL"
      })
      ),
      Status: (withStyle ? (
          <Badge
            className={ "font-size-12 badge-soft-" + statusBadgeClass(conta) }
            color={ statusBadgeClass(conta) }
            pill
          >
            { conta.status_descricao }
          </Badge>
        ) : conta.status_descricao
      ),
      "Código Conta": conta.codigo_conta,
      "Ação": (
        <div>
          { usePermissionEdit && conta.seq === 0 && <Link to="#" className="text-primary">
              <i className="mdi mdi-pencil font-size-18 me-3" id="btnEdittooltipcan" onClick={ e => toggleModal(conta) } title="Editar"/>
            </Link> }
          { usePermissionEdit && conta.seq !== 0 && <Link to="#" className="text-primary">
            <i className="mdi mdi-pencil font-size-18 me-3" id="btnEdittooltipcant" disabled title="Não é possível Editar, pois não foi criado no InfoCloud" />
          </Link> }
          { usePermissionRemove && conta.seq === 0 && <Link to="#" className="text-danger">
            <i className="mdi mdi-delete font-size-18 me-3" id="btnDeleteCan" onClick={ () => toggleModalRemove(conta)} title="Remover"/>
          </Link> }
          { usePermissionRemove && conta.seq !== 0 && <Link to="#" className="text-danger">
            <i className="mdi mdi-delete font-size-18 me-3" id="btnDeleteCant" disabled title="Não é possível Remover, pois não foi criado no InfoCloud"/>
          </Link> }
          { usePermissionEdit && conta.seq === 0 && !conta.data_pagamento && <Link to="#" className="text-success">
            <i className="mdi mdi-cash font-size-18 me-3" id="btnPayCan" onClick={ e => toggleModalPagamento(conta) } title="Pagar"/>
          </Link> }
          { usePermissionEdit && conta.seq !== 0 && !conta.data_pagamento && <Link to="#" className="text-success">
            <i className="mdi mdi-cash font-size-18 me-3" id="btnPayCant" disabled title="Não é possível Pagar, pois não foi criado no InfoCloud"/>
          </Link> }
          { usePermissionEdit && conta.seq === 0 && conta.data_pagamento && <Link to="#" className="text-danger">
            <i className="mdi mdi-cash-remove font-size-18 me-3" id="btnReverseCan" onClick={ e => toggleModalEstornar(conta) }
            title="Estonar"/>
          </Link> }
          { usePermissionEdit && conta.seq !== 0 && conta.data_pagamento && <Link to="#" className="text-danger">
            <i className="mdi mdi-cash-remove font-size-18 me-3" id="btnReverseCant" disabled
            title="Não é possível Estornar, pois não foi criado no InfoCloud"/>
          </Link> }
        </div>
      )
    }))
  }

  useEffect(() => {
    if (props.contasPagar && isArray(props.contasPagar)) {
      setTableData({
        columns: getColumns(),
        rows: getRows(true)
      })
    }

    return () => setTableData({ columns: [], rows: []})
  }, [props.contasPagar])

  const describeDiff = (A, B, numberOnly = false) => {
    A = parseFloat(A)
    B = parseFloat(B)

    const diff = A && B ? (100 * Math.abs((B - A) / B)).toFixed(2) : 0

    if (numberOnly) {
      return parseInt(diff)
    }

    return A >= B ? `+ ${ diff } %` : `- ${ diff } %`
  }

  const abbreviateNumber = (num, fixed) => {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0) {
      return "0"
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k] // append power
    return e
  }

  useEffect(() => {
    const nextMonth = brazilMoment(new Date()).add(1, 'month').format("MMM")
    const otherNextMonth = brazilMoment(new Date()).add(3, 'month').format("MMM")

    setReports([
      {
        title: "Pagar Hoje",
        icon: "mdi mdi-note-multiple-outline",
        color: "warning",
        value:
          props.contasPagarHoje && props.contasPagarHoje.periodo
            ? parseFloat(
              props.contasPagarHoje.periodo.valor || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : 0,
        desc: describeDiff(
          props.contasPagarHoje && props.contasPagarHoje.periodo
            ? props.contasPagarHoje.periodo.valor || 0
            : 0,
          props.contasPagarHoje &&
          props.contasPagarHoje.periodoAnterior
            ? props.contasPagarHoje.periodoAnterior.valor || 0
            : 0
        ),
        loading: props.contasPagarHojeLoading,
        series: [{
          name: "Valor",
          data:
            props.contasPagarHoje && props.contasPagarHoje.detalhado
              ? props.contasPagarHoje.detalhado.map(det => det.valor)
              : []
        }],
        diff: describeDiff(
          props.contasPagarHoje && props.contasPagarHoje.periodo
            ? props.contasPagarHoje.periodo.valor || 0
            : 0,
          props.contasPagarHoje &&
          props.contasPagarHoje.periodoAnterior
            ? props.contasPagarHoje.periodoAnterior.valor || 0
            : 0,
          true
        ),
        options: {
          chart: { sparkline: { enabled: !0 } },
          stroke: { curve: "smooth", width: 2 },
          colors: ["#f1b44c"],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: !1,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            }
          },
          tooltip: {
            fixed: { enabled: !1 },
            x: { show: !1 },
            marker: { show: !1 }
          },
          yaxis: {
            labels: {
              formatter: function(val, object) {
                return abbreviateNumber(val)
              }
            }
          }
        }
      },
      {
        title: `Pagar no Mês (${ brazilMoment(props.currentPeriod).format(
          "MMM"
        ) })`,
        icon: "mdi mdi-note-multiple-outline",
        color: "primary",
        value:
          props.contasPagarMes && props.contasPagarMes.periodo
            ? parseFloat(
              props.contasPagarMes.periodo.valor || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : 0,
        desc: describeDiff(
          props.contasPagarMes && props.contasPagarMes.periodo
            ? props.contasPagarMes.periodo.valor || 0
            : 0,
          props.contasPagarMes &&
          props.contasPagarMes.periodoAnterior
            ? props.contasPagarMes.periodoAnterior.valor || 0
            : 0
        ),
        loading: props.loadingDashboardNotasMeses,
        series: [
          {
            name: "Valor",
            data:
              props.contasPagarMes && props.contasPagarMes.detalhado
                ? props.contasPagarMes.detalhado.map(det => det.valor)
                : []
          }
        ],
        diff: describeDiff(
          props.contasPagarMes && props.contasPagarMes.periodo
            ? props.contasPagarMes.periodo.valor || 0
            : 0,
          props.contasPagarMes &&
          props.contasPagarMes.periodoAnterior
            ? props.contasPagarMes.periodoAnterior.valor || 0
            : 0,
          true
        ),
        options: {
          chart: { sparkline: { enabled: !0 } },
          stroke: { curve: "smooth", width: 2 },
          colors: ["#f1b44c"],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: !1,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            }
          },
          tooltip: {
            fixed: { enabled: !1 },
            x: { show: !1 },
            marker: { show: !1 }
          },
          yaxis: {
            labels: {
              formatter: function(val, object) {
                return abbreviateNumber(val)
              }
            }
          }
        }
      },
      {
        title: `Pagar Próximos 3 Meses (${nextMonth} - ${otherNextMonth})`,
        icon: "mdi mdi-note-multiple-outline",
        color: "info",
        value:
          props.contasPagarProximosMeses && props.contasPagarProximosMeses.periodo
            ? parseFloat(
              props.contasPagarProximosMeses.periodo.valor || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : 0,
        desc: describeDiff(
          props.contasPagarProximosMeses && props.contasPagarProximosMeses.periodo
            ? props.contasPagarProximosMeses.periodo.valor || 0
            : 0,
          props.contasPagarProximosMeses &&
          props.contasPagarProximosMeses.periodoAnterior
            ? props.contasPagarProximosMeses.periodoAnterior.valor || 0
            : 0
        ),
        loading: props.loadingDashboardNotasMeses,
        series: [
          {
            name: "Valor",
            data:
              props.contasPagarProximosMeses && props.contasPagarProximosMeses.detalhado
                ? props.contasPagarProximosMeses.detalhado.map(det => det.valor)
                : []
          }
        ],
        diff: describeDiff(
          props.contasPagarProximosMeses && props.contasPagarProximosMeses.periodo
            ? props.contasPagarProximosMeses.periodo.valor || 0
            : 0,
          props.contasPagarProximosMeses &&
          props.contasPagarProximosMeses.periodoAnterior
            ? props.contasPagarProximosMeses.periodoAnterior.valor || 0
            : 0,
          true
        ),
        options: {
          chart: { sparkline: { enabled: !0 } },
          stroke: { curve: "smooth", width: 2 },
          colors: ["#f1b44c"],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: !1,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            }
          },
          tooltip: {
            fixed: { enabled: !1 },
            x: { show: !1 },
            marker: { show: !1 }
          },
          yaxis: {
            labels: {
              formatter: function(val, object) {
                return abbreviateNumber(val)
              }
            }
          }
        }
      },
      {
        title: "Atrasados últ. 12 meses",
        icon: "mdi mdi-note-multiple-outline",
        color: "danger",
        value:
          props.contasPagarAtrasados && props.contasPagarAtrasados.periodo
            ? parseFloat(
              props.contasPagarAtrasados.periodo.valor || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : 0,
        desc: describeDiff(
          props.contasPagarAtrasados && props.contasPagarAtrasados.periodo
            ? props.contasPagarAtrasados.periodo.valor || 0
            : 0,
          props.contasPagarAtrasados && props.contasPagarAtrasados.periodoAnterior
            ? props.contasPagarAtrasados.periodoAnterior.valor || 0
            : 0
        ),
        loading: props.loadingDashboardNotasAnos,
        series: [
          {
            name: "Valor",
            data:
              props.contasPagarAtrasados && props.contasPagarAtrasados.detalhado
                ? props.contasPagarAtrasados.detalhado.map(det => det.valor)
                : []
          }
        ],
        diff: describeDiff(
          props.contasPagarAtrasados && props.contasPagarAtrasados.periodo
            ? props.contasPagarAtrasados.periodo.valor || 0
            : 0,
          props.contasPagarAtrasados && props.contasPagarAtrasados.periodoAnterior
            ? props.contasPagarAtrasados.periodoAnterior.valor || 0
            : 0,
          true
        ),
        options: {
          chart: { sparkline: { enabled: !0 } },
          stroke: { curve: "smooth", width: 2 },
          colors: ["#f1b44c"],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: !1,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            }
          },
          tooltip: {
            fixed: { enabled: !1 },
            x: { show: !1 },
            marker: { show: !1 }
          },
          yaxis: {
            labels: {
              formatter: function(val, object) {
                return abbreviateNumber(val)
              }
            }
          }
        }
      }
    ])

    return () => setReports([])
  }, [
    props.contasPagarHoje,
    props.contasPagarMes,
    props.contasPagarProximosMeses,
    props.contasPagarAtrasados
  ])

  const buscarContasPagarLista = (companiesId) => {
    setFind(true)
    props.buscarContasPagar({
      emissaoPeriod,
      emissaoStartDate,
      emissaoEndDate,
      vencimentoPeriod,
      vencimentoStartDate,
      vencimentoEndDate,
      situation,
      tipoConta,
      codigoFornecedor,
      company: companiesId || getCompaniesValuesByOptions(companies)
    })
  }

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }

    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const describeDate = dateStr => {
    if (!dateStr) {
      return "N/D"
    }

    return moment(dateStr).format("DD/MM/YY")
  }

  const onPickEmissaoStartDate = date => {
    setEmissaoStartDate(date)
    setEmissaoEndDate(date)
  }

  const onPickVencimentoStartDate = date => {
    setVencimentoStartDate(date)
    setVencimentoEndDate(date)
  }

  const csvDataHeader = () => {
    const columns = getColumns(true)
    return columns.map(column => {
      return { id: column.label, displayName: column.field }
    });
  };

  const csvData = () => {
    const rows = getRows(false)
    return Promise.resolve(rows.map(row => {
      return row
    }));
  };

  const printPDF = () => {

    let emissaoStart
    let emissaoEnd
    if (emissaoPeriod && emissaoPeriod !== 'period') {
      emissaoStart = brazilMoment(emissaoPeriod).clone().startOf('month').format('YYYY-MM-DD')
      emissaoEnd = brazilMoment(emissaoPeriod).clone().endOf('month').format('YYYY-MM-DD')
    } else if(emissaoStartDate && emissaoEndDate) {
      emissaoStart = brazilMoment(emissaoStartDate).clone().format('YYYY-MM-DD')
      emissaoEnd = brazilMoment(emissaoEndDate).clone().format('YYYY-MM-DD')
    } else {
      emissaoStart = brazilMoment(new Date()).clone().startOf('month').format('YYYY-MM-DD')
      emissaoEnd = brazilMoment(new Date()).clone().endOf('month').format('YYYY-MM-DD')
    }

    let vencimentoStart
    let vencimentoEnd
    if (vencimentoPeriod && vencimentoPeriod !== 'period') {
      vencimentoStart = brazilMoment(vencimentoPeriod).clone().startOf('month').format('YYYY-MM-DD')
      vencimentoEnd = brazilMoment(vencimentoPeriod).clone().endOf('month').format('YYYY-MM-DD')
    } else if(vencimentoStartDate && vencimentoEndDate) {
      vencimentoStart = brazilMoment(vencimentoStartDate).clone().format('YYYY-MM-DD')
      vencimentoEnd = brazilMoment(vencimentoEndDate).clone().format('YYYY-MM-DD')
    } else {
      vencimentoStart = brazilMoment(new Date()).clone().startOf('month').format('YYYY-MM-DD')
      vencimentoEnd = brazilMoment(new Date()).clone().endOf('month').format('YYYY-MM-DD')
    }

    props.relatorioExecutar({
      company: getCompaniesValuesByOptions(companies),
      emissaoStart,
      emissaoEnd,
      vencimentoStart,
      vencimentoEnd,
      situation,
      tipoConta,
      codigoFornecedor,
      reportName: 'contas_pagar',
    });
  }

  const toggleModal = item => {
    setDespesa(item ? item : {})
    setModal(!modal)
  }

  const toggleModalPagamento = (item) => {
    setDespesa(item)
    setModalPagamento(!modalPagamento)
  }

  const toggleModalRemove = item => {
    setDespesa(item)
    setModalExcluir(!modalExcluir)
  }

  const toggleModalEstornar = item => {
    setDespesa(item)
    setModalEstonar(!modalEstornar)
  }

  const saveLancamento = despesa => {
    setModal(!modal)
    if(despesa.id) {
      props.editarLancamento(despesa)
    } else {
      props.cadastrarLancamento(despesa)
    }
  }

  const payLancamento = despesa => {
    setModalPagamento(!modalPagamento)
    props.pagarLancamento(despesa)
  }

  const doDeleteLancamento = () => {
    setModalExcluir(!modalExcluir)
    props.removerLancamento(despesa)
  }

  const estornarLancamento = () => {
    setModalEstonar(!modalEstornar)
    props.estornarPagamentoLancamento(despesa)
  }

  if (!props.user || !props.user.vinculos.length || !(props.user.vinculos || []).filter(e => ["A", "T"].find(status => status == e.vinculo_status)).length) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title='menu.label.bills' />

          <Row>
            <Col lg="12">
              <div>
                <Row>
                  <OverView company={companies} />

                  <Col xl="8">
                    <CardWelcome />

                    <Row>
                      <MiniWidget reports={ reports } />
                    </Row>
                  </Col>
                </Row>

                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Filtros</h4>

                    <Form>
                      <Row>
                        <div className="col col-auto">
                          <FormFilterCompany
                              attribute="companies"
                              value={companies}
                              description="Empresa(s)"
                              setValue={ value => setCompanies(value) }
                              options={companiesOptions}
                              multiple={ true }
                              hasSelectAll={ !(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador) }
                          />
                        </div>

                        <div className="col col-auto">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Emissão</Label>
                            <select
                              className="form-select select2-search-disable"
                              value={ emissaoPeriod }
                              onChange={ e => setEmissaoPeriod(e.target.value) }
                            >
                              { isArray(periods) &&
                              periods.map((periodItem, key) => (
                                <option key={ periodItem.value } value={ periodItem.value }>
                                  { periodItem.label }
                                </option>
                              )) }
                            </select>
                          </FormGroup>
                        </div>

                        { emissaoPeriod && emissaoPeriod === "period" && (
                          <div className="col col-auto">
                            <div>
                              <FormGroup className="mt-3 mb-0">
                                <Label>Data Inicial</Label>
                                <DatePicker
                                  selected={ emissaoStartDate }
                                  onChange={ onPickEmissaoStartDate }
                                  className="form-control"
                                  placeholderText="Selecione uma data"
                                  locale="ptBR"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>

                              <FormGroup className="mt-3 mb-0">
                                <Label>Data Final</Label>
                                <DatePicker
                                  selected={ emissaoEndDate }
                                  onChange={ date => setEmissaoEndDate(date) }
                                  className="form-control"
                                  placeholderText="Selecione uma data"
                                  locale="ptBR"
                                  minDate={ emissaoStartDate }
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        ) }

                        <div className="col col-auto">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Vencimento</Label>
                            <select
                              className="form-select select2-search-disable"
                              value={ vencimentoPeriod }
                              onChange={ e => setVencimentoPeriod(e.target.value) }
                            >
                              { isArray(periods) &&
                                periods.map((periodItem, key) => (
                                  <option key={ periodItem.value } value={ periodItem.value }>
                                    { periodItem.label }
                                  </option>
                                )) }
                            </select>
                          </FormGroup>
                        </div>

                        { vencimentoPeriod && vencimentoPeriod === "period" && (
                          <div className="col col-auto">
                            <div>
                              <FormGroup className="mt-3 mb-0">
                                <Label>Data Inicial</Label>
                                <DatePicker
                                  selected={ vencimentoStartDate }
                                  onChange={ onPickVencimentoStartDate }
                                  className="form-control"
                                  placeholderText="Selecione uma data"
                                  locale="ptBR"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>

                              <FormGroup className="mt-3 mb-0">
                                <Label>Data Final</Label>
                                <DatePicker
                                  selected={ vencimentoEndDate }
                                  onChange={ date => setVencimentoEndDate(date) }
                                  className="form-control"
                                  placeholderText="Selecione uma data"
                                  locale="ptBR"
                                  minDate={ vencimentoStartDate }
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        ) }

                        <div className="col col-auto">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Situação</Label>
                            <select
                              className="form-select select2-search-disable"
                              value={ situation }
                              onChange={ e => setSituation(e.target.value) }>
                              { situations.map((item, key) => (
                                  <option key={ item.value } value={ item.value }>
                                    { item.label }
                                  </option>
                                )) }
                            </select>
                          </FormGroup>
                        </div>

                        <div className="col col-auto">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Centro de custo</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={ tipoConta }
                              onChange={ e => setTipoConta(e.target.value) }
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Fornecedor</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={ codigoFornecedor }
                              onChange={ e => setCodigoFornecedor(e.target.value) }
                            />
                          </FormGroup>
                        </div>

                        <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                          <div className="mt-3 col col-auto">
                            <Button color="primary" onClick={ e => buscarContasPagarLista() }
                                    disabled={ !usePermission('contasPagar_listar') }>
                              Buscar
                            </Button>
                          </div>
                          { find && !props.contasPagarLoading && !props.executaRelatorioLoading &&
                            <div className="mt-3 col col-auto">
                              <CsvDownloader
                                filename="dre"
                                extension=".csv"
                                separator=";"
                                wrapColumnChar=""
                                columns={ csvDataHeader() }
                                datas={ csvData() }>
                                <Button type="button" color="light">
                                  <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                  CSV
                                </Button>
                              </CsvDownloader>
                            </div> }
                          { !props.contasPagarLoading && !props.executaRelatorioLoading &&
                            <div className="mt-3 col col-auto">
                              <Button type="button" color="light" onClick={ e => printPDF() }>
                                <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                PDF
                              </Button>
                            </div>
                          }
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                { find && <Card>
                  { props.contasPagarLoading || props.loading ? (
                    <div className="loader-container position-absolute">
                      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 }/>
                    </div>
                  ) : (
                    <CardBody>
                      <div className="button-items my-3">
                        <Link to="#">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded waves-effect waves-light"
                            onClick={ e => toggleModal(null) }
                            disabled={ !usePermissionInsert }>
                            <i className="bx bx-plus" /> Criar lançamento
                          </Button>
                        </Link>
                      </div>

                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={ tableData }
                        entriesLabel={ "Mostrar Registros" }
                        infoLabel={ ["Exibindo", "a", "de", "registros"] }
                        noRecordsFoundLabel={ "Nenhum registro encontrado" }
                        paginationLabel={ ["Anterior", "Próximo"] }
                        searchLabel={ "Pesquisar" }
                      />
                    </CardBody>
                  ) }
                </Card> }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <LancamentoModal
        despesa={ despesa }
        isOpen={ modal }
        toggle={ toggleModal }
        saveLancamento={ saveLancamento }
        companies={ companiesOptions }
      />
      <PagamentoLancamentoModal
        despesa={ despesa }
        isOpen={ modalPagamento }
        toggle={ toggleModalPagamento }
        saveLancamento={ payLancamento }
        companies={ companiesOptions }
      />

      <Modal isOpen={ modalExcluir }>
        <ModalHeader tag="h4">Excluir lançamento</ModalHeader>
        <ModalBody>
          <Row form>
            <Col className="col-12">
              <p>Você realmente deseja excluir este lançamento?</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <button type="danger" className="btn btn-success save-event" onClick={ e => doDeleteLancamento() }>
                  Sim, excluir
                </button>
                <button type="button" className="btn btn-light ms-2" onClick={ e => setModalExcluir(false) }>
                  Cancelar
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal isOpen={ modalEstornar }>
        <ModalHeader tag="h4">Estornar lançamento</ModalHeader>
        <ModalBody>
          <Row form>
            <Col className="col-12">
              <p>Você realmente deseja estornar o pagamento deste lançamento?</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <button type="danger" className="btn btn-success save-event" onClick={ e => estornarLancamento() }>
                  Sim
                </button>
                <button type="button" className="btn btn-light ms-2" onClick={ e => setModalEstonar(false) }>
                  Cancelar
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ContasPagar.propTypes = {
  contasPagar: PropTypes.array,
  buscarContasPagar: PropTypes.func,
  buscarContasPagarHoje: PropTypes.func,
  buscarContasPagarMes: PropTypes.func,
  buscarContasPagarProximosMeses: PropTypes.func,
  buscarContasPagarAtrasados: PropTypes.func,
  relatorioExecutar: PropTypes.func,
  cadastrarLancamento: PropTypes.func,
  editarLancamento: PropTypes.func,
  removerLancamento: PropTypes.func,
  pagarLancamento: PropTypes.func,
}

const mapStatetoProps = state => {
  const { contasPagar, contasPagarLoading,
          contasPagarHoje, contasPagarHojeLoading,
          contasPagarMes, contasPagarMesLoading,
          contasPagarProximosMeses, contasPagarProximosMesesLoading,
          contasPagarAtrasados, contasPagarAtrasadosLoading, executaRelatorioLoading,
          loading, empresas, empresasLoading } = state.Profile
  const { user, dashboardFilters } = state.Login
  return { user, dashboardFilters, contasPagar, contasPagarLoading,
    contasPagarHoje, contasPagarHojeLoading,
    contasPagarMes, contasPagarMesLoading,
    contasPagarProximosMeses, contasPagarProximosMesesLoading,
    contasPagarAtrasados, contasPagarAtrasadosLoading, executaRelatorioLoading,
    loading, empresas, empresasLoading }
}

export default connect(mapStatetoProps, {
  buscarContasPagar,
  buscarContasPagarHoje,
  buscarContasPagarMes,
  buscarContasPagarProximosMeses,
  buscarContasPagarAtrasados,
  relatorioExecutar,
  cadastrarLancamento,
  editarLancamento,
  removerLancamento,
  pagarLancamento,
  estornarPagamentoLancamento,
  navegarBreadCrumb
})(withRouter(ContasPagar))
