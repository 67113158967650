import PropTypes from "prop-types"
import React from "react"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Simple bar
import SimpleBar from "simplebar-react"

import SidebarContent from "components/VerticalLayout/Sidebar-Menu"
import {checkIsMobile} from "../../helpers/utils";

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100" style={{ top: (
            (props.location.pathname === "/dashboard-padrao" ||
              props.location.pathname === "/notas-emitidas" ||
               props.location.pathname === "/movimento-vendas") && checkIsMobile() ? '58px' : '0')}}>
          { props.type !== "condensed" ? (
            <SimpleBar style={ { maxHeight: "100%" } }>
              <SidebarContent />
            </SimpleBar>
          ) : (
            <SidebarContent />
          ) }
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter((Sidebar)))
