import React, {Component} from "react"
import {Card, CardBody, Col, Row} from "reactstrap"

//Import Images

import ReactApexChart from "react-apexcharts"
import profileImg from "../../assets/images/profile-img.png"
import MiniCards from "./mini-card"
import {map} from "lodash"
import MiniWidget from "./mini-widget"
import {connect} from "react-redux"
import {getDashboardData} from "../../store/dashboard/actions"
import Loader from "react-loader-spinner"
import {withTranslation} from 'react-i18next';

class CardUser extends Component {

  constructor(props) {
    super(props)
    this.state = {
      settings_Menu: false,
      miniCards: [],
      reports: []
    }
    this.toggleSettings = this.toggleSettings.bind(this)
  }

  static describeDiff(A, B, numberOnly = false) {
    A = parseFloat(A)
    B = parseFloat(B)

    const diff = A && B ? (100 * Math.abs((B - A) / B)).toFixed(2) : 0

    if (numberOnly) {
      return parseInt(diff)
    }

    return A >= B ? `+ ${ diff } %` : `- ${ diff } %`
  }

  static describePercentage(A, B, numberOnly = false) {
    A = parseFloat(A)
    B = parseFloat(B)

    const diff = A && B ? (100 * Math.abs(A / B)).toFixed(2) : 0

    if (numberOnly) {
      return parseInt(diff)
    }

    return A >= B ? `+ ${ diff } %` : `- ${ diff } %`
  }

  static describeDiffColor(A, B) {
    A = parseFloat(A)
    B = parseFloat(B)

    return A >= B ? "success" : "warning"
  }

  static getDerivedStateFromProps(props, current_state) {
    console.debug("[getDerivedStateFromProps]", { props, current_state })

    // if (props.dashboardData) {
    const miniCard = [
      {
        title: props.t('dashboard.lista.vendashoje.label'),
        tooltip: props.t('dashboard.lista.vendashoje.tooltip'),
        iconClass: "bx-import",
        text:
          props.dashboardVendas &&
          Array.isArray(props.dashboardVendas.hoje) &&
          props.dashboardVendas.hoje.length &&
          props.dashboardVendas.hoje[0][0]
            ? parseFloat(
              props.dashboardVendas.hoje[0][0].Valor_Total || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        loading: !props.dashboardVendas
      },
      {
        title: props.t('dashboard.lista.notashoje.label'),
        tooltip: props.t('dashboard.lista.notashoje.tooltip'),
        iconClass: "bx-copy-alt",
        text:
          Array.isArray(props.dashboardNotasHoje) &&
          props.dashboardNotasHoje.length &&
          props.dashboardNotasHoje[0][0]

            ? parseFloat(
              props.dashboardNotasHoje[0][0].total_nf || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        loading: !props.dashboardNotasHoje
      },
      {
        title: (props.dashboardInicial === 'Padrão Food Service') ? props.t('dashboard.lista.ticketmediomesahoje.label') : props.t('dashboard.varejo.lista.ticketmediomesahoje.label'),
        tooltip: props.dashboardInicial === 'Padrão Food Service' ? props.t('dashboard.lista.ticketmediomesahoje.tooltip'): props.t('dashboard.varejo.lista.ticketmediomesahoje.tooltip'),
        iconClass: "bx-purchase-tag-alt",
        text:
          props.dashboardVendas &&
          props.dashboardVendas.hoje &&
          Array.isArray(props.dashboardVendas.hoje) &&
          props.dashboardVendas.hoje.length &&
          props.dashboardVendas.hoje[0][0]
            ? parseFloat(
              props.dashboardVendas.hoje[0][0].Ticket_Medio || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        loading: !props.dashboardVendas
      }]
    if (props.dashboardInicial === 'Padrão Food Service'){
      miniCard.push({
        title: props.t('dashboard.lista.ticketmediopessoahoje.label'),
        tooltip: props.t('dashboard.lista.ticketmediopessoahoje.tooltip'),
        iconClass: "bx-user",
        text:
          props.dashboardVendas &&
          props.dashboardVendas.hoje &&
          Array.isArray(props.dashboardVendas.hoje) &&
          props.dashboardVendas.hoje.length &&
          props.dashboardVendas.hoje[0][0]
            ? parseFloat(
              props.dashboardVendas.hoje[0][0].Ticket_Medio_Pessoa || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        loading: !props.dashboardVendas
      })
    }

    const reports = [
      {
        icon: "bx bx-archive-in",
        title: props.t('dashboard.lista.vendas.label'),
        tooltip: props.t('dashboard.lista.vendas.tooltip'),
        value:
          props.dashboardVendas &&
          props.dashboardVendas.periodo &&
          Array.isArray(props.dashboardVendas.periodo) &&
          props.dashboardVendas.periodo.length
            ? parseFloat(
              props.dashboardVendas.periodo[0][0].Valor_Total || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        badgeValue: CardUser.describeDiff(
          props.dashboardVendas &&
          props.dashboardVendas.periodo &&
          Array.isArray(props.dashboardVendas.periodo) &&
          props.dashboardVendas.periodo.length
            ? props.dashboardVendas.periodo[0][0].Valor_Total || 0
            : 0,
          props.dashboardVendas &&
          props.dashboardVendas.periodo_anterior &&
          Array.isArray(props.dashboardVendas.periodo_anterior) &&
          props.dashboardVendas.periodo_anterior.length
            ? props.dashboardVendas.periodo_anterior[0][0].Valor_Total || 0
            : 0
        ),
        color: CardUser.describeDiffColor(
          props.dashboardVendas &&
          props.dashboardVendas.periodo &&
          Array.isArray(props.dashboardVendas.periodo) &&
          props.dashboardVendas.periodo.length
            ? props.dashboardVendas.periodo[0][0].Qtd_Vendas || 0
            : 0,
          props.dashboardVendas &&
          props.dashboardVendas.periodo_anterior &&
          Array.isArray(props.dashboardVendas.periodo_anterior) &&
          props.dashboardVendas.periodo_anterior.length
            ? props.dashboardVendas.periodo_anterior[0][0].Qtd_Vendas || 0
            : 0
        ),
        desc: "Do período anterior",
        loading: !props.dashboardVendas
      },
      {
        icon: "bx bx-copy-alt",
        title: props.t('dashboard.lista.notasemitidas.label'),
        tooltip: props.t('dashboard.lista.notasemitidas.tooltip'),
        value: props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas) && props.dashboardNotas.informacoes_notas.length
          ? parseFloat(
            props.dashboardNotas.informacoes_notas[0][0].total_nf || 0
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL"
          })
          : "0",
        badgeValue: CardUser.describeDiff(
          props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas) && props.dashboardNotas.informacoes_notas.length
            ? props.dashboardNotas.informacoes_notas[0][0].total_nf || 0
            : 0,
          props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas_periodo_anterior) && props.dashboardNotas.informacoes_notas_periodo_anterior.length
            ? props.dashboardNotas.informacoes_notas_periodo_anterior[0][0]
            .total_nf || 0
            : 0
        ),
        color: CardUser.describeDiffColor(
          props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas) && props.dashboardNotas.informacoes_notas.length
            ? props.dashboardNotas.informacoes_notas[0][0].total_nf || 0
            : 0,
          props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas_periodo_anterior) && props.dashboardNotas.informacoes_notas_periodo_anterior.length
            ? props.dashboardNotas.informacoes_notas_periodo_anterior[0][0]
            .total_nf || 0
            : 0
        ),
        desc: "Do período anterior",
        loading: props.loadingDashboardNotas || !props.dashboardNotas
      },
      {
        icon: "bx bx-purchase-tag-alt",
        title: (props.dashboardInicial === 'Padrão Food Service') ? props.t('dashboard.lista.ticketmediomesa.label'): props.t('dashboard.varejo.lista.ticketmediomesa.label'),
        tooltip: props.dashboardInicial === 'Padrão Food Service' ? props.t('dashboard.lista.ticketmediomesa.tooltip') : props.t('dashboard.varejo.lista.ticketmediomesa.tooltip'),
        value:
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length
            ? parseFloat(
              props.dashboardVendas.periodo[0][0].Ticket_Medio || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        badgeValue: CardUser.describeDiff(
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length
            ? props.dashboardVendas.periodo[0][0].Ticket_Medio || 0
            : 0,
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo_anterior) && props.dashboardVendas.periodo_anterior.length
            ? props.dashboardVendas.periodo_anterior[0][0].Ticket_Medio || 0
            : 0
        ),
        color: CardUser.describeDiffColor(
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length
            ? props.dashboardVendas.periodo[0][0].Ticket_Medio || 0
            : 0,
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo_anterior) && props.dashboardVendas.periodo_anterior.length
            ? props.dashboardVendas.periodo_anterior[0][0].Ticket_Medio || 0
            : 0
        ),
        desc: "Do período anterior",
        loading: props.loadingDashboardVendas || !props.dashboardVendas
      }
    ]
    if (props.dashboardInicial === 'Padrão Food Service'){
      reports.push({
        icon: "bx bx-purchase-tag-alt",
        title: props.t('dashboard.lista.ticketmediopessoa.label'),
        tooltip: props.t('dashboard.lista.ticketmediopessoa.tooltip'),
        value:
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length
            ? parseFloat(
              props.dashboardVendas.periodo[0][0].Ticket_Medio_Pessoa || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL"
            })
            : "0",
        badgeValue: CardUser.describeDiff(
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length
            ? props.dashboardVendas.periodo[0][0].Ticket_Medio_Pessoa || 0
            : 0,
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo_anterior) && props.dashboardVendas.periodo_anterior.length
            ? props.dashboardVendas.periodo_anterior[0][0].Ticket_Medio_Pessoa || 0
            : 0
        ),
        color: CardUser.describeDiffColor(
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length
            ? props.dashboardVendas.periodo[0][0].Ticket_Medio_Pessoa || 0
            : 0,
          props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo_anterior) && props.dashboardVendas.periodo_anterior.length
            ? props.dashboardVendas.periodo_anterior[0][0].Ticket_Medio_Pessoa || 0
            : 0
        ),
        desc: "Do período anterior",
        loading: props.loadingDashboardVendas || !props.dashboardVendas
      })
    }
    return {
      miniCards: miniCard,
      reports: reports,
      chart: {
        options: {
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: "15px",
                  color: void 0,
                  offsetY: 60
                },
                value: {
                  offsetY: 22,
                  fontSize: "15px",
                  color: "#495057",
                  fontWeight: 700,
                  formatter: function(e) {
                    return e + "%"
                  }
                }
              }
            }
          },
          colors: ["#556ee6"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              shadeIntensity: 0.15,
              inverseColors: !1,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91]
            }
          },
          stroke: {
            dashArray: 4
          },
          labels: ["Notas Emitidas"]
        },
        series: [
          CardUser.describePercentage(
              props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas) && props.dashboardNotas.informacoes_notas.length
              ? props.dashboardNotas.informacoes_notas[0][0].total_nf || 0
              : 0,
            props.dashboardVendas &&
            props.dashboardVendas.periodo &&
            Array.isArray(props.dashboardVendas.periodo) &&
            props.dashboardVendas.periodo.length &&
            props.dashboardVendas.periodo[0]
              ? props.dashboardVendas.periodo[0][0].Valor_Total || 0
              : 0,
            true
          )
        ],
        quantidadeNotas:
            props.dashboardNotas && Array.isArray(props.dashboardNotas.informacoes_notas) && props.dashboardNotas.informacoes_notas.length ?
                props.dashboardNotas.informacoes_notas[0][0].Qtd_Notas_Emitidas || 0 :
                0,
        quantidadeVendas:
            props.dashboardVendas && Array.isArray(props.dashboardVendas.periodo) && props.dashboardVendas.periodo.length ?
                props.dashboardVendas.periodo[0][0].Qtd_Vendas || 0 :
                0
      }
    }
    // }
  }

  //Setting Menu
  toggleSettings() {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu
    }))
  }

  goToVinculos = () => null

  componentDidMount() {
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "vendas")
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "notas")
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "notasPeriodo")
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "notasHoje")
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xl="12">
            <Card className="overflow-hidden eq-height">
              <Row>
                <Col xs="12" xl="6" className="row bg-primary bg-soft">
                  <Col xs="6">
                    <div className="text-primary p-3">
                      <h5 className="text-primary">Bem-vindo ao InfoCloud!</h5>
                      <ul className="ps-3 mb-0 hover__cursor"
                          onClick={event => this.props.history.push("/vinculos")}>
                        <li className="py-1">
                          {
                            (this.props.user.vinculos || []).filter(e =>
                                ["A", "T"].find(
                                    status => status == e.vinculo_status
                                )
                            ).length
                          }{" "}
                          Vínculos Autorizados
                        </li>
                        <li className="py-1">
                          {this.props.quantidadeSolicitacoesPendentes &&
                          this.props.quantidadeSolicitacoesPendentes.pendentes ? this.props.quantidadeSolicitacoesPendentes.pendentes
                              : '0'}{" "}
                          Vínculos Pendentes
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs="6"className="align-self-end" style={{textAlign: "right"}}>
                    <img
                        src={profileImg}
                        alt=""
                        className="img-fluid"
                        style={{maxHeight: "80px"}}
                    />
                  </Col>
                </Col>
                { !this.state.chart.options ||
                !this.state.chart.series ||
                this.props.loadingDashboardNotas ||
                !this.props.dashboardNotas ||
                !this.props.dashboardVendas ? (
                    <Col xs="12" xl="6" className="text-center">
                      <Loader
                          type="ThreeDots"
                          color="#556EE6"
                          height={ 80 }
                          width={ 80 }
                      />
                    </Col>
                ) : (
                    <Col xs="12" xl="6">
                      <Card>
                        <CardBody>

                        <Row>
                          <Col lg="8">
                            <div id="line-chart" className="apex-charts" dir="ltr">
                              <ReactApexChart
                                  options={this.state.chart.options}
                                  series={this.state.chart.series}
                                  type="radialBar"
                                  height="180"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="text-muted">
                              <div className="mt-4">
                                <p className="mb-2">{ this.props.t('dashboard.lista.quantidadenotasemitidas.label') }</p>
                                <h5>
                                  {this.state.chart.quantidadeNotas}
                                </h5>
                              </div>
                              <div className="mt-4">
                                <p className="mb-2">{ this.props.t('dashboard.lista.quantidadevendas.label') }</p>
                                <h5>
                                  {this.state.chart.quantidadeVendas}
                                </h5>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        </CardBody>
                      </Card>
                    </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col xl="12">
            <Row>
              {map(this.state.miniCards, (card, key) => (
                  <MiniCards
                      title={card.title}
                      text={card.text}
                      tooltip={card.tooltip}
                      iconClass={card.iconClass}
                      key={ "_card_" + key }
                      id={ "_card_" + key }
                      loading={ card.loading }
                />
              )) }
            </Row>

            <Row>
              { map(this.state.reports, (report, key) => (
                <MiniWidget
                    icon={report.icon}
                    title={report.title}
                    tooltip={report.tooltip}
                    value={report.value}
                    badgeValue={report.badgeValue}
                    color={report.color}
                    desc={report.desc}
                    loading={report.loading}
                    key={ "_miniwidget_" + key }
                    id={ "_miniwidget_" + key }
                />
              )) }
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardNotas,
    dashboardNotas,
    loadingDashboardNotasHoje,
    dashboardNotasHoje,
    loadingDashboardVendas,
    dashboardVendas,
    dashboardInicial
  } = state.Login
  return {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardNotas,
    dashboardNotas,
    loadingDashboardNotasHoje,
    dashboardNotasHoje,
    loadingDashboardVendas,
    dashboardVendas,
    dashboardInicial
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  getDashboardData
})(CardUser))
