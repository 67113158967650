import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, Col, Container, Form, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {connect} from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import usePermission from "helpers/permission/usePermission"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import CertificadosDigitaisTable from "./certificados-digitais-table"
import FormFilterSwitch from "../../components/Common/form-filter-switch"
import FormFilterNumber from "../../components/Common/form-filter-number"
import {AvForm} from "availity-reactstrap-validation";
import {removerEmpresaCertificadoDigital} from "../../store/auth/profile/actions";
import Loader from "react-loader-spinner";
import { navegarBreadCrumb } from "../../store/navigator/actions"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CertificadoDigitalLista = props => {

    const ref = useRef()

    const [filters, setFilters] = useState(null)
    const [certificadoVencimento, setCertificadoVencimento] = useState("true")
    const [certificadoDiasVencimento, setCertificadoDiasVencimento] = useState("")
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [modalRemove, setModalRemove] = useState(false)
    const location = useLocation();
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
    const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
    const usePermissionEdit = usePermission("empresas_editar")

    useEffect(() => {

        if (possuiVinculo) {
            populateFiltros()
        }

        props.navegarBreadCrumb( {title: `menu.label.certificates`, link: location.pathname })

        return () => {
            setCertificadoVencimento("")
            setCertificadoDiasVencimento("")
        }
    }, [])

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const removerCertificadoDigital = () => {
        props.removerEmpresaCertificadoDigital({ id: itemSelecionado.id }, null)
        setModalRemove(false)

        setTimeout(() => ref.current?.search(), 300)
    }

    const populateFiltros = () => {
        const filters = []

        if (certificadoVencimento) filters.push({
            id: 'certificadoVencimento',
            value: certificadoVencimento,
            condition: Filters.equals
        })
        if (certificadoDiasVencimento) filters.push({
            id: 'certificadoDiasVencimento',
            value: certificadoDiasVencimento,
            condition: Filters.lessThanOrEqualTo
        })

        filters.push({
            id: 'certificadoNaoEnviado',
            value: 'false'
        })

        if(isAdmin) filters.push({ id: 'ignoreCompany', value: true, condition: Filters.equals })

        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => ref.current?.search(), 300)
    }

    const inserirCertificado = () => {
        props.history.push("/enviar-certificado")
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            {props.empresaCertificadoLoading ? (<div className="loader-container position-absolute">
                <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={80}
                    width={80}
                />
            </div>) : (
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title='menu.label.certificates'/>

                    <Row>
                        <Col lg="12">
                            {props.empresaCertificadoLoading}
                            <div>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterSwitch
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="certificadoVencimento"
                                                        description="Certificado vencido"
                                                        value={certificadoVencimento}
                                                        changeHandeler={setCertificadoVencimento}
                                                    />
                                                </div>

                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterNumber
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="certificadoDiasVencimento"
                                                        description="Dias para vencimento do certificado"
                                                        value={certificadoDiasVencimento}
                                                        changeHandeler={setCertificadoDiasVencimento}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="secondary" className="w-md"
                                                                onClick={e => inserirCertificado()}>
                                                            Inserir Certificado digital
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && <Card>
                                    <CardBody>
                                        <CertificadosDigitaisTable ref={ref} filters={filters}
                                                                   history={props.history}
                                                                   remover={(item) => showConfirmDelete(item)}
                                                                   usePermissionEdit={usePermissionEdit}>
                                        </CertificadosDigitaisTable>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> )}
            <Modal isOpen={ modalRemove }>
                <ModalHeader tag="h4">Confirmação</ModalHeader>
                <ModalBody>
                    <AvForm
                        onValidSubmit={ (e, v) => {
                            removerCertificadoDigital()
                        } }
                    >
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente remover o certificado digital?
                            </Col>
                        </Row>
                        <Row style={ { paddingTop: 10 } }>
                            <Col>
                                <div className="text-right">
                                    <button type="submit" className="btn btn-success save-event">
                                        Confirmar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light ms-2"
                                        onClick={ e => setModalRemove(false) }
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

CertificadoDigitalLista.propTypes = {
    removerEmpresaCertificadoDigital: PropTypes.func,
}

const mapStatetoProps = state => {
    const { empresaCertificadoLoading } = state.Profile
    const {user} = state.Login
    return {user, empresaCertificadoLoading}
}

export default connect(mapStatetoProps, {
    removerEmpresaCertificadoDigital,
    navegarBreadCrumb
})(withRouter(CertificadoDigitalLista))
