import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label, Modal, ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import { connect } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"
import { Editor } from "react-draft-wysiwyg"
import "react-datepicker/dist/react-datepicker.css"

import "assets/scss/datatables.scss"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment-timezone"

import {
  buscarNCMs, doCreateNCM, doEditNCM, doRemoveNCM
} from "../../store/auth/profile/actions"
import { isArray } from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import { getDashboardData } from "../../store/auth/login/actions"
import { AvForm } from "availity-reactstrap-validation"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import NCMTable from "./NCM-table"
import FormFilterPeriod from "../../components/Common/form-filter-period"
import { getDatasPeloPeriodo, getMonths, getPeriodos, getPeriodsStartEndDateByType } from "../../helpers/utils"
import { Filters } from "../../common/data/definitions"
import FormFilterSwitch from "../../components/Common/form-filter-switch"
import { convertFromRaw, EditorState } from "draft-js"
import { withTranslation } from "react-i18next"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const TabelaNCM = props => {

  const ref = useRef()
  const [modal, setModal] = useState(false)
  const [itemRemove, setItemRemove] = useState()
  const [modalObservacao, setModalObservacao] = useState(false)
  const [itemObservacao, setItemObservacao] = useState()
  const toggleObservacao = () => setModalObservacao(!modalObservacao)
  const location = useLocation()
  const usePermissionRemove = usePermission("tabelaNCM_excluir")
  const usePermissionEdit = usePermission("tabelaNCM_editar")
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").toDate()
  )
  const [filters, setFilters] = useState({
    ncm: "", description: "", category: "",
    present: false, removed: false,
    incDateStart: startDate, incDateEnd: new Date(), incDatePeriod: null,
    altDateStart: startDate, altDateEnd: new Date(), altDatePeriod: null
  })
  const [filtersObj, setFiltersObj] = useState([])

  const [periods, setPeriods] = useState([])
  const [monthsOptions, setMonthsOptions] = useState([])

  const isInforangra = props.user?.usuario_info

  const doUpdate = () => {
    const { tmpPeriods } = getPeriodos(true, false, true, true, false)
    const months = getMonths()
    setMonthsOptions(months)
    setPeriods(tmpPeriods)
    populateFilters()
    props.navegarBreadCrumb({ title: `menu.label.table.ncm`, link: location.pathname })
  }

  const goToNCM = item => {
    props.doEditNCM(item)
    props.history.push("/ncm" + (item ? `/${item.id}` : ""))
  }

  const showConfirmDeleteNCM = item => {
    setItemRemove(item)
    setModal(true)
  }

  const showObservation = item => {
    setItemObservacao(item)
    setModalObservacao(true)
  }

  const deleteNCM = () => {
    props.doRemoveNCM(itemRemove)
    setModal(false)
    doUpdate()
  }

  useEffect(() => {
    doUpdate()
  }, [])

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
      <AlertaSemVinculo />
    )
  }

  const populateFilters = () => {
    const newFilter = []

    if (filters.ncm) {
      newFilter.push({ id: "ncm", value: filters.ncm, condition: Filters.contains })
    }
    if (filters.description) {
      newFilter.push({ id: "descricao", value: filters.description, condition: Filters.contains })
    }
    if (filters.category) {
      newFilter.push({ id: "categoria", value: filters.category, condition: Filters.contains })
    }

    if (filters.incDatePeriod) {
      let datasIncPeloPeriodo = getDatasPeloPeriodo("period", filters.incDateStart, filters.incDateEnd)

      newFilter.push({ id: "IncData", value: datasIncPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo })
      newFilter.push({ id: "IncData", value: datasIncPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo })
    }

    if (filters.altDatePeriod) {
      let datasAltPeloPeriodo = getDatasPeloPeriodo("period", filters.altDateStart, filters.altDateEnd)

      newFilter.push({ id: "AltData", value: datasAltPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo })
      newFilter.push({ id: "AltData", value: datasAltPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo })
    }

    if (filters.present) {
      newFilter.push({ id: "present", value: true, condition: Filters.equals })
    } else if (filters.removed) {
      newFilter.push({ id: "removed", value: true, condition: Filters.equals })
    }

    setFiltersObj(newFilter)
  }

  const pesquisar = () => {
    populateFilters()
    setTimeout(() => ref?.current?.search(), 300)
  }

  const setFilterPeriodType = (field, value, fieldStart, fieldEnd) => {
    if (value === "Selecione") {
      setFilters((prevFilters) => ({...prevFilters, [field]: null, [fieldStart]: null, [fieldEnd]: null }))
      return
    }
    let periodStart, periodEnd

    const periodsObj = getPeriodsStartEndDateByType(value)
    periodStart = periodsObj.periodStart
    periodEnd = periodsObj.periodEnd

    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
      [fieldStart]: periodStart.toDate(),
      [fieldEnd]: periodEnd.toDate()
    }))
  }

  const setFilterValue = (field, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }))
  }

  const monthChange = (field, value, fieldStart, fieldEnd) => {
    const startMonth = moment(value).startOf("month")
    const endMonth = moment(startMonth).endOf("month")
    setFilters((prevFilters) => ({ ...prevFilters, [fieldStart]: startMonth.toDate(), [fieldEnd]: endMonth.toDate() }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="menu.label.table.ncm" />
          <div className="button-items my-3">
            <Link to="/ncm">
              <Button
                type="button"
                color="success"
                className="btn-rounded waves-effect waves-light"
                onClick={e => props.doCreateNCM()}
                disabled={!isInforangra && !usePermission("tabelaNCM_inserir")}
              >
                <i className="bx bx-plus" /> Cadastrar
              </Button>
            </Link>
          </div>

          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Tabela NCM</h4>

                    <Form>
                      <Row>
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <Label>NCM</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={filters.ncm}
                              onChange={e => setFilterValue("ncm", e.target.value)}
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Descrição</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={filters.description}
                              onChange={e => setFilterValue("description", e.target.value)}
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Categoria</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={filters.category}
                              onChange={e => setFilterValue("category", e.target.value)}
                            />
                          </FormGroup>
                        </div>

                      </Row>
                      <Row>
                        <FormFilterPeriod
                          periodValue={filters.incDatePeriod}
                          periodChangeHandeler={e => setFilterPeriodType("incDatePeriod", e, "incDateStart", "incDateEnd")}
                          options={periods}
                          startDateValue={filters.incDateStart}
                          startDateChangeHandeler={e => {
                            setFilterValue("incDateStart", e)
                          }}
                          endDateValue={filters.incDateEnd}
                          endDateChangeHandeler={e => setFilterValue("incDateEnd", e)}
                          t={props.t}
                          periodDescription={"Data inclusão"}
                          months={monthsOptions}
                          monthChange={e => monthChange("incDatePeriod", e, "incDateStart", "incDateEnd")}
                        />

                        <FormFilterPeriod
                          periodValue={filters.altDatePeriod}
                          periodChangeHandeler={e => setFilterPeriodType("altDatePeriod", e, "altDateStart", "altDateEnd")}
                          options={periods}
                          startDateValue={filters.altDateStart}
                          startDateChangeHandeler={e => {
                            setFilterValue("altDateStart", e)
                          }}
                          endDateValue={filters.altDateEnd}
                          endDateChangeHandeler={e => setFilterValue("altDateEnd", e)}
                          t={props.t}
                          periodDescription={"Data alteração"}
                          months={monthsOptions}
                          monthChange={e => monthChange("altDatePeriod", e, "altDateStart", "altDateEnd")}
                        />

                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <FormFilterSwitch
                              classNameFormGrupo="mt-3 mb-0"
                              attribute="present"
                              description="Vigente"
                              value={filters.present}
                              disabled={filters.removed}
                              trueValue={true}
                              falseValue={false}
                              changeHandeler={e => {
                                setFilterValue("present", !filters.present)
                                if (e === true) {
                                  setFilterValue("removed", false)
                                }
                              }}
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <FormFilterSwitch
                              classNameFormGrupo="mt-3 mb-0"
                              attribute="removed"
                              description="Excluídos"
                              value={filters.removed}
                              disabled={filters.present}
                              trueValue={true}
                              falseValue={false}
                              changeHandeler={e => {
                                setFilterValue("removed", !filters.removed)
                              }}
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6 align-self-start mt-4 pt-1">
                          <div className="mt-3">
                            <Button
                              type="button"
                              color="primary"
                              className="w-md"
                              onClick={e => pesquisar()}
                              disabled={!usePermission("tabelaNCM_listar")}>
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                {filters && <Card>
                  <CardBody>
                    <NCMTable ref={ref} filters={filtersObj}
                              history={props.history}
                              usePermissionEdit={usePermissionEdit}
                              usePermissionRemove={usePermissionRemove}
                              isUsuarioInforAngra={isInforangra}
                              navegarEditarNCM={goToNCM}
                              showConfirmDeleteNCM={showConfirmDeleteNCM}
                              showObservation={showObservation}>
                    </NCMTable>
                  </CardBody>
                </Card>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal}>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={(e, v) => {
              deleteNCM()
            }}
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover esse registro?
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={e => setModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalObservacao}
             role="dialog"
             autoFocus={true}
             centered={true}
             toggle={toggleObservacao}
      >
        <ModalHeader tag="h4" toggle={toggleObservacao}>Observação</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <Editor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                editorState={itemObservacao ? EditorState.createWithContent(convertFromRaw(JSON.parse(itemObservacao))) : EditorState.createEmpty()}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

TabelaNCM.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func
}

const mapStatetoProps = state => {
  const { ncmsLoading, ncms } = state.Profile
  const { user } = state.Login
  return {
    ncmsLoading,
    ncms,
    user
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  buscarNCMs,
  getDashboardData,
  doCreateNCM,
  doEditNCM,
  doRemoveNCM,
  navegarBreadCrumb
})(withRouter(TabelaNCM)))
