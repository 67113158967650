import { DASHBOARD, DASHBOARD_DATA } from "./actionTypes"
import { LOGOUT_USER_SUCCESS } from "../auth/login/actionTypes"

const initialState = {
  error: "",
  success: "",
}

const Dashboard = (state = initialState, action) => {

  switch (action.type) {
    case DASHBOARD:
      if (action.payload.type) {
        const tmp = {}
        tmp[`loadingDashboard${ capitalizeFirstLetter(action.payload.type) }`] = true
        tmp[`dashboard${ capitalizeFirstLetter(action.payload.type) }`] = null
        state = { ...state, ...tmp }
      } else {
        state = { ...state, ...{ loadingDashboard: true } }
      }

      state = {
        ...state,
        ...{ currentPeriod: action.payload.period || new Date() }
      }
      break
    case DASHBOARD_DATA:
      if (action.payload.type) {
        const tmp = {}
        tmp[`loadingDashboard${ capitalizeFirstLetter(action.payload.type) }`] = false
        tmp[`dashboard${ capitalizeFirstLetter(action.payload.type) }`] = action.payload.data
        state = { ...state, ...tmp }
      } else {
        state = {
          ...state,
          ...{
            dashboardData: Object.keys(action.payload).length
              ? action.payload
              : null,
            loadingDashboard: false
          }
        }

        if (state.dashboardData) {
          state.dashboardData.top_produtos[0].map(top_produto => {
            top_produto.qtd_total = parseInt(top_produto.qtd_total)
            return top_produto
          })
        }
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...initialState }
      break
    default:
      return state
  }
  return state
}

function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Dashboard
