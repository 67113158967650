import React, { Component } from "react"
import { Button, Card, CardBody, Col } from "reactstrap"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../store/auth/register/actions"
import { getDashboardData } from "../../store/dashboard/actions"
import { apiError } from "../../store/auth/login/actions"
import ReactEcharts from "echarts-for-react"
import Loader from "react-loader-spinner"
import { setUserPreference } from "../../store/auth/profile/actions"
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";

class SalesAnalytics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      graphType:
        props.preferences && props.preferences.Grafico_Mais_Vendidos
          ? props.preferences.Grafico_Mais_Vendidos
          : "value"
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    // console.debug("[sales-analytics][getDerivedStateFromProps]", { props, current_state })

    if (props.dashboardMaisVendidos) {
      const data = props.dashboardMaisVendidos[0]
        ? props.dashboardMaisVendidos[0].sort((n1, n2) =>
          current_state.graphType === "quantity"
            ? n2.qtd_total - n1.qtd_total
            : n2.valor_total - n1.valor_total
        )
        : []

      return {
        series: data.map(e =>
          current_state.graphType === "quantity" ? e.qtd_total : e.valor_total
        ),
        options: {
          labels: data.map(e => e.descricao),
          colors: data.map(
            e => `#${ Math.floor(Math.random() * 16777215).toString(16) }`
          ),
          legend: { show: !1 },
          plotOptions: {
            pie: {
              donut: {
                size: "70%"
              }
            }
          }
        },
        dashboardData: props.dashboardMaisVendidos,
          chart_options: {
            toolbox: {
              show: false
            },
            tooltip: {
              trigger: "item",
              formatter:
                current_state.graphType === "quantity"
                  ? "{a} <br/>{b} : {c} ({d}%)"
                  : object =>
                    `${ parseFloat(object.value).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    }) } (${ object.percent }%)`
            },
            legend: {
              orient: "vertical",
              left: "left",
            data: data.map(e => e.descricao),
              textStyle: {
                color: ["#74788d"]
              }
            },
          color: data.map(
                e => `#${ Math.floor(Math.random() * 16777215).toString(16) }`
          ),
            series: [
              {
              name: props.t('dashboard.lista.produtos.maisvendidos.label'),
                type: "pie",
                radius: "55%",
                center: ["70%", "40%"],
              data: data.map(e => {
                return {
                  value:
                    current_state.graphType === "quantity"
                      ? e.qtd_total
                      : e.valor_total,
                  name: e.descricao
                }
              }),
                itemStyle: {
                  normal: {
                    label: {
                      show: window.innerWidth > 700
                    },
                    labelLine: {
                      show: window.innerWidth > 700
                    }
                  },
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)"
                  }
                }
              }
            ]
        }
      }
    }

    return null
  }

  componentDidMount() {
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "maisVendidos")
  }

  setGraphType(graphType) {
    this.setState(prevState => ({ graphType }))
    this.props.setUserPreference("Grafico_Mais_Vendidos", graphType)
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="6">
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h4 className="card-title mb-4">
                  { this.props.t('dashboard.lista.produtos.label') }
                  <Tooltip id='produtos' tooltip={ this.props.t('dashboard.lista.produtos.tooltip') }></Tooltip>
                </h4>
                    <div className="ms-auto">
                      <div className="toolbar d-flex flex-wrap gap-2 text-end">
                        <Button
                          color="light"
                          size="sm"
                          type="button"
                          className={
                            this.state.graphType === "quantity" ? "active" : ""
                          }
                          onClick={ () => this.setGraphType("quantity") }
                        >
                          Em Quantidade
                        </Button>
                        <Button
                          color="light"
                          size="sm"
                          type="button"
                          className={
                            this.state.graphType === "value" ? "active" : ""
                          }
                          onClick={ () => this.setGraphType("value") }
                        >
                          Em Valor
                        </Button>
                      </div>
                    </div>
                </div>
                { this.props.loadingDashboardMaisVendidos ||
                !this.state.chart_options ? (
                  <div className="loader-container position-absolute">
                    <Loader
                      type="ThreeDots"
                      color="#556EE6"
                      height={ 80 }
                      width={ 80 }
                    />
                  </div>
                ) : (
                  <div>
                    <ReactEcharts
                      style={ { height: "300px" } }
                      option={ this.state.chart_options }
                    />
                  </div>
                  ) }
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

SalesAnalytics.propTypes = {
  loadingDashboardMaisVendidos: PropTypes.any,
  dashboards: PropTypes.any,
  getDashboardData: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardMaisVendidos,
    dashboardMaisVendidos,
    preferences
  } = state.Login
  return {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardMaisVendidos,
    dashboardMaisVendidos,
    preferences,
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  getDashboardData,
  setUserPreference
})(SalesAnalytics))
